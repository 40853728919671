import React from "react";
import PropTypes from 'prop-types';
import { imageTypes, videoTypes, docTypes, audioTypes, techTypes } from 'src/omnia/utils/file-type-helpers';


const icons = {
    image: '/assets/icons/icon-image.svg',
    video: '/assets/icons/icon-video.svg',
    document: '/assets/icons/icon-document.svg',
    audio: '/assets/icons/icon-audio.svg',
    tech: '/assets/icons/icon-tech.svg',
};

export const FileIcon = (props) => {
    const {extension} = props;

    let icon;

    if (!extension) {
        icon = '/assets/icons/icon-other.svg';
    } else {

        if(imageTypes.includes(extension)) {
            icon = icons.image;
        } else if(videoTypes.includes(extension)) {
            icon = icons.video;
        } else if(docTypes.includes(extension)) {
            icon = icons.document;
        } else if(audioTypes.includes(extension)) {
            icon = icons.audio;
        } else if(techTypes.includes(extension)) {
            icon = icons.tech;
        } else {
            icon = '/assets/icons/icon-other.svg'
        }
    }

    return <img width={60} height='auto' src={icon}/>;
};

FileIcon.propTypes = {
    extension: PropTypes.string
};
