import React from "react";
import {useNotifications} from "src/omnia/hooks/use-notifications";
import useApi from "src/omnia/hooks/use-api";
import {Formik, useFormik} from "formik";
import * as Yup from "yup";
import authService from "src/omnia/services/auth-service";
import PropTypes from "prop-types";
import {
    Box,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Fade,
    FormHelperText,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {setUserData} from "src/omnia/store/actions/account-actions";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import SaveButton from "../../../../../elements/save-button";
import {APP_SETTING} from "../../../../../../../setup";

function ChangePassword({className, ...rest}) {
    const {notify, notifySuccess} = useNotifications();
    const {put} = useApi();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: ''
        },
        validationSchema: Yup.object().shape({
            oldPassword: Yup.string().required(t('form.is_required', {field: t('core.old_password')})),
            newPassword: Yup.string()
                .min(10, t('form.min_characters', {value: 10}))
                .required(t('form.is_required', {field: t('core.new_password')}))
                .test('str', t('must_have_a_number'), val => {
                    return /\d/.test(val);
                })
                .test('str', t('form.must_have_special_character'), val => {
                    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val);
                })
                .notOneOf([Yup.ref('oldPassword')], t('form.passwords_cannot_be_the_same')),
            newPasswordConfirm: Yup.string()
                .oneOf([Yup.ref('newPassword'), null], t('core.passwords_dont_match'))
                .required(t('form.is_required', {field: t('core.confirm_password')}))
        }),
        onSubmit: (values, helpers) => {
            helpers.setSubmitting(true);
            put('core/me/change_password', {
                new_password: formik.values.newPassword,
                old_password: formik.values.oldPassword
            }).then((newAccess) => {
                authService.setSession(newAccess.token);
                dispatch(setUserData(newAccess.user));
                notifySuccess(t);
                helpers.resetForm();
            }).finally(() => {
                helpers.setSubmitting(false);
            })
        }
    })

    return (
        <Fade in={true} timeout={APP_SETTING?.transitionDuration || 500}>
            <Card className={className}{...rest}>
                <CardHeader title={t('core.change_password')}/>
                <Divider/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item md={4} sm={6} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.oldPassword && formik.errors.oldPassword)}
                                fullWidth
                                helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                                label={t('core.old_password')}
                                name="oldPassword"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="password"
                                value={formik.values.oldPassword}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.newPassword && formik.errors.newPassword)}
                                fullWidth
                                helperText={formik.touched.newPassword && formik.errors.newPassword}
                                label={t('core.new_password')}
                                name="newPassword"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="password"
                                value={formik.values.newPassword}
                            />
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <TextField
                                error={Boolean(formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm)}
                                fullWidth
                                helperText={formik.touched.newPasswordConfirm && formik.errors.newPasswordConfirm}
                                label={t('core.confirm_password')}
                                name="newPasswordConfirm"
                                onBlur={formik.handleBlur}
                                onChange={formik.handleChange}
                                type="password"
                                value={formik.values.newPasswordConfirm}
                            />
                        </Grid>
                    </Grid>
                    {formik.errors.submit && (
                        <Box ml={2}>
                            <FormHelperText error>
                                {formik.errors.submit}
                            </FormHelperText>
                        </Box>
                    )}
                    <Grid container spacing={2} mt={1}>
                        <Grid item md={10}>
                            <Typography variant="caption">
                                {t('core.change_password_info')}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider/>
                <Box
                    p={2}
                    display="flex"
                    justifyContent="flex-end"
                >
                    <SaveButton
                        formik={formik}
                        autoSave={false}

                        // loading={formik.isSubmitting}
                        // onClick={formik.handleSubmit}
                        // label={t('core.change_password')}
                    />
                </Box>
            </Card>
        </Fade>
    );
}

ChangePassword.propTypes = {
    className: PropTypes.string
}

export default ChangePassword;