import React, {useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import ChevronDownIcon from '@untitled-ui/icons-react/build/esm/ChevronDown';
import ChevronRightIcon from '@untitled-ui/icons-react/build/esm/ChevronRight';
import {Box, ButtonBase, Chip, Collapse, IconButton, SvgIcon, Tooltip, Typography} from '@mui/material';
import {RouterLink} from 'src/omnia/components/elements/router-link';
import {useTranslation} from "react-i18next";
import OnIcon from "../../../elements/icon";
import HistoryPopover from "../../../modules/intelligence/history-popover";
import usePopover from "../../../../hooks/use-popover";
import SearchDialog from "../../../elements/search-dialog";
import SearchButton from "../../components/search-button";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useNavigate} from "react-router-dom";

export const SideNavItem = (props) => {
    const {
        active,
        children,
        chip = null,
        depth = 0,
        disabled,
        external,
        icon,
        items,
        tooltip,
        label,
        superCustomAction,
        open: openProp,
        path,
        title
    } = props;

    const [open, setOpen] = useState(!!openProp);
    const [hovered, setHovered] = useState(false);
    const popover = usePopover();
    const navigate = useNavigate();
    const {isMobile} = useIsMobile();

    const {t} = useTranslation();

    const handleToggle = useCallback((event) => {
        if (event.target.closest('.MuiIconButton-root')) {
            return; // Ignore clicks originating from the IconButton
        }

        if(isMobile){
            setOpen((prevOpen) => !prevOpen);
        } else if(items?.filter?.(item => item.path).length > 0) {
            navigate(items?.filter?.(item => item.path)[0].path);
        } else {
            setOpen((prevOpen) => !prevOpen);
        }
    }, []);

    const offset = depth === 0 ? 0 : (depth - 1) * 16;

    const buttonBaseStyle = {
        alignItems: 'center',
        borderRadius: 1,
        display: 'flex',
        justifyContent: 'flex-start',
        pl: `${(depth === 0 ? 5 : 42) + offset}px`,
        pr: '14px',
        // py: (depth === 0) ? '5px' : '8px',
        py: 0,
        textAlign: 'left',
        width: '100%',
        ...(active && {
            ...(depth === 0 && {
                backgroundColor: 'var(--nav-item-active-bg)'
            })
        }),
        '&:hover': {
            backgroundColor: 'var(--nav-item-hover-bg)'
        }
    }

    const titleStyle = {
        color: 'var(--nav-item-color)',
        flexGrow: 1,
        fontFamily: (theme) => theme.typography.fontFamily,
        fontWeight: depth > 0 ? 400 : 600,
        py: depth > 0 ? 1.5 : 0,
        whiteSpace: 'nowrap',
        ...(active && {
            color: 'var(--nav-item-active-color)'
        }),
        ...(disabled && {
            color: 'var(--nav-item-disabled-color)'
        })
    }

    // Initiate content
    let content;

    // Branch
    if (children) {
        content = (
            <li id='side-nav-item-with-childs'>
                <ButtonBase
                    disabled={disabled}
                    onClick={handleToggle}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    sx={buttonBaseStyle}
                >
                    {(depth === 0) && (
                        <Box
                            component="span"
                            sx={{
                                alignItems: 'center',
                                color: 'var(--nav-item-icon-color)',
                                display: 'inline-flex',
                                justifyContent: 'center',
                                py: (depth === 0) ? '5px' : '8px',
                                mr: 2,
                                ...(active && {
                                    color: 'var(--nav-item-icon-active-color)'
                                })
                            }}
                        >
                            <IconButton
                                sx={{
                                    ...{
                                        borderRadius: '5px',
                                        padding: 0.25,
                                    },
                                    ...(active ? {
                                        color: 'var(--nav-item-icon-active-color)'
                                    } : {
                                        color: 'var(--nav-item-icon-color)',
                                    }),
                                    ...((hovered || open) ? {
                                        backgroundColor: 'var(--nav-item-hover-bg)',
                                    } : {}),
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setOpen((prevOpen) => !prevOpen);
                                }}
                                onMouseDown={(event) => {
                                    event.stopPropagation(); // Stops mousedown event
                                }}
                            >
                                {(hovered || open) ? (
                                        <SvgIcon
                                            fontSize="small"
                                            sx={{
                                                ...(active ? {
                                                    color: 'var(--nav-item-icon-active-color)'
                                                } : {
                                                    color: 'var(--nav-item-chevron-color)',
                                                })
                                            }}
                                        >
                                            {open ? <ChevronDownIcon/> : <ChevronRightIcon/>}
                                        </SvgIcon>
                                ) : (
                                    <OnIcon iconName={icon} size="small" />
                                )}
                            </IconButton>
                        </Box>
                    )}
                    <Box
                        component="span"
                        sx={titleStyle}
                    >
                        <Typography
                            variant={depth > 0 ? "body2" : "body1"}
                            sx={{fontWeight: depth > 0 ? 400 : 600, lineHeight: 1}}
                        >
                            {t(title)}
                        </Typography>
                    </Box>

                </ButtonBase>
                <Collapse in={open}>
                    {children}
                </Collapse>
            </li>
        );
    }

    // Leaf
    else {
        content = (
            <li
                id='side-nav-item-plain'
                ref={popover.anchorRef}
                onClick={() => {
                    if (popover.open) {
                        if (isMobile)
                            popover.handleClose();
                    } else {
                        popover.handleOpen();
                    }
                }}
            >
                {superCustomAction === 'extend-intelligence-chats' && (
                    <HistoryPopover popover={popover}/>
                )}
                {superCustomAction === 'search' ? (
                    <>
                        <SearchDialog popover={popover}/>
                        <SearchButton
                            depth={depth}
                            offset={offset}
                        />
                    </>
                ) : (
                    <ButtonBase
                        disabled={disabled}
                        sx={buttonBaseStyle}
                        component={RouterLink}
                        href={path}
                    >
                        {(depth === 0) && (
                            <Box
                                component="span"
                                sx={{
                                    alignItems: 'center',
                                    color: 'var(--nav-item-icon-color)',
                                    display: 'inline-flex',
                                    justifyContent: 'center',
                                    py: (depth === 0) ? '5px' : '8px',
                                    mr: 2,
                                    ...(active && {
                                        color: 'var(--nav-item-icon-active-color)'
                                    })
                                }}
                            >
                                <IconButton
                                    sx={{
                                        borderRadius: '5px',
                                        padding: 0.25,
                                        ...(active ? {
                                            color: 'var(--nav-item-icon-active-color)'
                                        } : {
                                            color: 'var(--nav-item-icon-color)',
                                        }),
                                    }}
                                >
                                    <OnIcon iconName={icon} size="small" />
                                </IconButton>
                            </Box>
                        )}
                        <Box
                            component="span"
                            sx={titleStyle}
                        >
                            <Typography
                                variant={depth > 0 ? "body2" : "body1"}
                                sx={{fontWeight: depth > 0 ? 400 : 600, lineHeight: 1}}
                            >
                                {t(title)}
                            </Typography>
                        </Box>
                        {label && (
                            <Box
                                component="span"
                                sx={{ml: 2}}
                            >
                                {label}
                            </Box>
                        )}
                        {chip && (
                            <Chip
                                label={chip}
                                variant="filled"
                                size="small"
                                sx={{height: 20, fontSize: 9}}
                            />
                        )}
                    </ButtonBase>
                )}

            </li>
        )
    }

    if(tooltip){
        return (
            <Tooltip enterDelay={1000} title={t(tooltip)} placement="right">
                {content}
            </Tooltip>
        );
    }

    return content;
};

SideNavItem.propTypes = {
    active: PropTypes.bool,
    children: PropTypes.node,
    depth: PropTypes.number,
    chip: PropTypes.any,
    disabled: PropTypes.bool,
    external: PropTypes.bool,
    icon: PropTypes.node,
    open: PropTypes.bool,
    path: PropTypes.string,
    items: PropTypes.array,
    tooltip: PropTypes.string,
    label: PropTypes.string,
    superCustomAction: PropTypes.string,
    title: PropTypes.string.isRequired
};
