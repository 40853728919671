import React, {useEffect, useState} from "react";
import FullscreenWrapper from "../../../elements/fullscreen-wrapper";
import {IconButton, OutlinedInput, SvgIcon, Tooltip} from "@mui/material";
import PropTypes from "prop-types";
import EditIcon from "@untitled-ui/icons-react/build/esm/Edit05";
import FilterIcon from "@untitled-ui/icons-react/build/esm/FilterFunnel01";
import DeleteIcon from "@untitled-ui/icons-react/build/esm/Trash01";
import ArchiveIcon from "@untitled-ui/icons-react/build/esm/Archive";
import AllIcon from "@untitled-ui/icons-react/build/esm/EyeOff";
import LabelFilter from "@untitled-ui/icons-react/build/esm/Tag01";
import KanbanView from "src/omnia/components/modules/projects/board-card/kanban-board";
import BoardFilters from "./board-filters";
import EditBoard from "src/omnia/components/modules/projects/board-card/kanban-board/board-edit-dialog";
import {useNavigate} from "react-router";
import useApi from "src/omnia/hooks/use-api";
import useSocket from "src/omnia/hooks/use-socket";
import {
    addUpdateCards,
    deleteCard,
    deleteList,
    setBoard,
    hideArchived,
    setBoardId,
    setBoardLoading,
    showArchived,
    updateCard,
    updateLabels,
    updateList,
    updateQuery,
    updateSpaceId
} from "../../../../store/actions/kanban-actions";
import {useDispatch, useSelector} from "react-redux";
import ActiveUsersBubbles from "../../core/ActiveUsersBubbles";
import {useTranslation} from "react-i18next";
import { produce } from "immer";
import {useIsMobile} from "../../../../hooks/use-is-mobile";

const BoardSocketHandler = ({board}) => {

    const [activeUsers, setActiveUsers] = useState([]);
    const {user} = useSelector(state => state.account);
    const {boardId} = useSelector((state) => state.kanban);
    const dispatch = useDispatch();
    const includeArchivedTickets = useSelector((state) => state.kanban.filter.showArchived);

    const { sendMessage, isConnected } = useSocket("/board_socket/" + board?.id, (packet) => {

        switch (packet.kind) {
            case "update_labels": {
                dispatch(updateLabels(packet.data));
                break;
            }
            case "update_loading": {
                dispatch(setBoardLoading(packet.data));
                break;
            }
            case "status_change": {
                if (user.id !== packet.user.id) {
                    if (packet.mode === "enter") {
                        setActiveUsers(prev => produce(prev, draft => {
                            if (draft.filter(u => u.id === packet.user.id).length === 0) {
                                draft.push(packet.user);
                            }
                        }));
                    } else {
                        setActiveUsers(prev => prev.filter(u => u.id !== packet.user.id));
                    }
                }
                break;
            }
            case "cards_batch": {
                dispatch(addUpdateCards(packet.data));
                break;
            }
            case "board_update": {
                dispatch(setBoard(packet.data));
                break;
            }
            case "delete_card": {
                dispatch(deleteCard(boardId, packet.data.card_id));
                break;
            }
            case "delete_list": {
                dispatch(deleteList(boardId, packet.data.id));
                break;
            }
            case "space_id": {
                dispatch(updateSpaceId(packet.data));
                break;
            }
            case "list": {
                dispatch(updateList(boardId, packet.data.id, packet.data));
                break;
            }
            case "card": {
                dispatch(updateCard(boardId, packet.data));
                break;
            }
            case "updated_cards": {
                dispatch(addUpdateCards(packet.data));
                break;
            }
            case "board_id": {
                dispatch(setBoardId(packet.data));
                break;
            }
            default: {
                console.log('Board channel message not recognized')
                break;
            }
        }

    });

    useEffect(() => {
        if(isConnected){
            sendMessage({
                'kind': 'filterChange',
                'show_archived': includeArchivedTickets
            })
        }
    }, [isConnected, includeArchivedTickets]);

    return (
        <ActiveUsersBubbles users={activeUsers}/>
    );
}

function BoardContent({open = false, board, onReload, feature, handleCloseBoard}) {

    const [editOpen, setOpen] = useState(false);
    const [openFilterBoard, setOpenFilterBoard] = useState(false);
    const {del} = useApi();
    const {t} = useTranslation();
    const navigate = useNavigate();
    const filter = useSelector(state => state.kanban.filter);
    const {isMobile} = useIsMobile();
    const dispatch = useDispatch();

    const handleChange = () => {
        if (!filter['showArchived']) {
            dispatch(showArchived());
        } else {
            dispatch(hideArchived());
        }
    }

    const handleQueryChange = (event) => {
        dispatch(updateQuery(event.target.value));
    }

    const openEditBoard = () => {
        setOpen(true);
    }

    const closeEditBoard = () => {
        setOpen(false);
    }

    const handleOpenFilterBoard = () => {
        setOpenFilterBoard(true);
    }

    const handleCloseFilterBoard = () => {
        setOpenFilterBoard(false);
    }

    const handleDelete = () => {
        if (window.confirm(t("notify.are_you_sure"))) {
            del('pm/project-boards', board.id).then(() => {
                navigate('/groon/projects/view/' + board.space + '/overview');
            })
        }
    }

    const boardActions = isMobile ? [
        {
            name: (
                <Tooltip
                    enterDelay={1000}
                    title={t("common.filters")}
                >
                    <IconButton
                        color="inherit"
                        size="large"
                    >
                        <FilterIcon/>
                    </IconButton>
                </Tooltip>
            ),
            action: handleOpenFilterBoard
        },
        {
            name: <Tooltip enterDelay={1000} title={t("common.edit")}>
                <IconButton color="inherit" size="large">
                    <EditIcon/>
                </IconButton>
            </Tooltip>,
            action: openEditBoard
        },
        {
            name: <Tooltip enterDelay={1000} title={t("common.delete")}><IconButton color="inherit"  size="large"><DeleteIcon/></IconButton></Tooltip>,
            action: handleDelete
        },
    ] : [
        {
            name: (
                <OutlinedInput
                    placeholder={t("common.sst_type_anything")}
                    name="search"
                    onChange={handleQueryChange}
                    value={filter['query'] || ''}
                    size="small"
                    sx={{width: 300, mr: 2}}
                />
            ),
            action: () => {
            }
        },
        {
            name: (
                <>
                    {filter['showArchived'] ? (
                        <Tooltip title={t("projects.boards.hide_archived")}>
                            <IconButton color="inherit" size="large" onClick={handleChange}>
                                <SvgIcon fontSize="small">
                                    <AllIcon/>
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title={t("projects.boards.show_archived")}>
                            <IconButton color="inherit" size="large" onClick={handleChange}>
                                <SvgIcon fontSize="small">
                                    <ArchiveIcon/>
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            ),
            action: () => {
            }
        },
        {
            name: (
                <Tooltip title={t("projects.boards.filter_labels")}>
                    <IconButton color="inherit" size="large" onClick={() => alert(t('common.coming_soon'))}>
                        <SvgIcon fontSize="small">
                            <LabelFilter/>
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            ),
            action: () => {
            }
        },
        {
            name: (
                <Tooltip title={t("common.edit")}>
                    <IconButton color="inherit" size="large">
                        <SvgIcon fontSize="small">
                            <EditIcon/>
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            ), action: openEditBoard
        },
        {
            name: (
                <Tooltip title={t("common.delete")}>
                    <IconButton color="inherit" size="large">
                        <SvgIcon fontSize="small">
                            <DeleteIcon/>
                        </SvgIcon>
                    </IconButton>
                </Tooltip>
            ), action: handleDelete
        },
    ]

    return (
        <>
            {open && (
                <BoardSocketHandler board={board} />
            )}
            <FullscreenWrapper
                fullscreen={true}
                closeCallback={handleCloseBoard}
                open={open}
                actions={boardActions}
                title={board.name}
            >
                <KanbanView id={board.id} featured={feature}/>
            </FullscreenWrapper>
            <BoardFilters open={openFilterBoard} onClose={handleCloseFilterBoard}/>
            <EditBoard open={editOpen} board={board} onClose={closeEditBoard} onSuccess={onReload}/>
        </>
    );
}

BoardSocketHandler.propTypes = {
    board: PropTypes.object.isRequired
}

BoardContent.propTypes = {
    board: PropTypes.object,
    onReload: PropTypes.func,
    feature: PropTypes.number,
    handleCloseBoard: PropTypes.func,
    open: PropTypes.bool
}

export default BoardContent;