import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import {useDispatch} from 'react-redux';
import {Box, Button, Checkbox, OutlinedInput, Input, IconButton} from '@mui/material';
import {Trash as TrashIcon} from "src/omnia/icons/trash";
import {updateCheckItem, deleteCheckItem} from 'src/omnia/store/actions/kanban-actions';
import {useNotifications} from "src/omnia/hooks/use-notifications";
import useApi from "src/omnia/hooks/use-api";

const KanbanCheckItemRoot = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  paddingBottom: theme.spacing(1),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(1)
}));

function CheckItem(props) {

    const {
        card,
        checklist,
        boardId,
        checkItem,
        editing = false,
        onEditCancel = () => {},
        onEditInit = () => {},
        onEditComplete = () => {},
        ...rest
    } = props;

    const dispatch = useDispatch();
    const { del, put } = useApi();
    const {notify} = useNotifications();
    const [name, setName] = useState(checkItem.name);

    const handleStateChange = (event) => {
        event.persist();
        const state = event.target.checked ? 'complete' : 'incomplete';
        put('pm/project-board-list-card-checklist-items', {...{id: checkItem.id}, ...{state}}).then(checkItem => {
            dispatch(updateCheckItem(boardId, card.id, checklist.id, checkItem))
        });
    };

    const handleNameChange = (event) => {
        event.persist();
        setName(event.target.value);
    };

    const handleSave = () => {
        put('pm/project-board-list-card-checklist-items', {...{id: checkItem.id}, ...{name}}).then(checkItem => {
            dispatch(updateCheckItem(boardId, card.id, checklist.id, checkItem))
            onEditComplete();
        });
    };

    const handleCancel = () => {
        setName(checkItem.name);
        onEditCancel();
    };

    const handleDelete = async () => {
        del('pm/project-board-list-card-checklist-items', checkItem.id).then(() => {
            dispatch(deleteCheckItem(boardId, card.id, checklist.id, checkItem.id));
        })
    };

    return (
        <KanbanCheckItemRoot {...rest}>
            <Checkbox
                edge="start"
                checked={checkItem.state === 'complete'}
                onChange={handleStateChange}
                sx={{mr: 1}}
            />
            {editing
                ? (
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            width: '100%'
                        }}
                    >
                        <OutlinedInput
                            onChange={handleNameChange}
                            value={name}
                            onKeyDown={(e) => {
                                if(e.keyCode === 13){
                                    handleSave();
                                }
                            }}
                            sx={{
                                flexGrow: 1,
                                my: '1px',
                                '& .MuiInputBase-input': {
                                    px: 2,
                                    py: 1
                                }
                            }}
                        />
                        <Button
                            onClick={handleSave}
                            size="small"
                            sx={{ml: 2}}
                            variant="contained"
                        >
                            Speichern
                        </Button>
                        <Button
                            onClick={handleCancel}
                            size="small"
                            sx={{ml: 2}}
                        >
                            Abbrechen
                        </Button>
                    </Box>
                )
                : (
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            flexGrow: 1
                        }}
                    >
                        <Input
                            disableUnderline
                            fullWidth
                            onClick={onEditInit}
                            value={checkItem.name}
                            sx={{
                                borderColor: 'transparent',
                                borderRadius: 1,
                                borderStyle: 'solid',
                                borderWidth: 1,
                                cursor: 'text',
                                m: '-1px',
                                '&:hover': {
                                    backgroundColor: 'action.selected'
                                },
                                '& .MuiInputBase-input': {
                                    fontWeight: 500,
                                    px: 2,
                                    py: 1
                                }
                            }}
                        />
                        <IconButton
                            onClick={handleDelete}
                            sx={{ml: 2}}
                            size="small"
                        >
                            <TrashIcon fontSize="small"/>
                        </IconButton>
                    </Box>
                )}
        </KanbanCheckItemRoot>
    );
}

CheckItem.propTypes = {
    card: PropTypes.object.isRequired,
    checklist: PropTypes.object.isRequired,
    checkItem: PropTypes.object.isRequired,
    className: PropTypes.string,
    editing: PropTypes.bool,
    onEditCancel: PropTypes.func,
    boardId: PropTypes.number,
    onEditComplete: PropTypes.func,
    onEditInit: PropTypes.func
};

export default CheckItem;
