import React, {useCallback, useEffect, useRef, useMemo, useState} from "react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {
    Box,
    Chip,
    CircularProgress,
    Dialog,
    DialogContent,
    Divider,
    InputAdornment,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Stack,
    Tooltip,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import debounce from "lodash.debounce";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useNavigate} from "react-router-dom";
import OnIcon from "../icon";
import {defaultNavigationConfig} from "../../layouts/dashboard/config";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import useRenderObjects from "../../../hooks/data-rendering/use-render-objects";
import useGroonSettings from "../../../hooks/use-groon-settings";

function SearchDialog({popover, sx = {}}) {

    const {t} = useTranslation();
    const [body, setBody] = useState('');
    const {get, post} = useOmniaApi({autoError: false});
    const inputEl = useRef(null);
    const [currentlySelected, setCurrentlySelected] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [searchResults, setSearchResults] = useState({});
    const [activeSearchFilters, setActiveSearchFilters] = useState([]);
    const [disabledSearchFilters, setDisabledSearchFilters] = useState([]);
    const {hasRights} = useSecurityCheck();
    const [loadingEndpoints, setLoadingEndpoints] = useState([]);
    const navigate = useNavigate();
    const textInputRef = useRef(null);
    const requestIdRef = useRef(0);
    const listRef = useRef(null);
    const disabledFiltersRef = useRef();
    const renderObjects = useRenderObjects(currentlySelected, popover.handleClose);
    textInputRef.current = body;
    disabledFiltersRef.current = disabledSearchFilters;
    const configurationSettings = useGroonSettings();

    const settingsRoutes = configurationSettings?.flatMap(obj => obj.settings)?.map(i => {
        return {
            title: t(i?.label),
            icon: i?.icon,
            path: '/groon/control/settings#' + i?.value,
            permissions: i?.rights || [],
        }
    });

    const specialRoutes = [

        // Account Menu
        {
            title: t('layout.intelligence.studio'),
            icon: 'Star06',
            path: '/groon/ai/studio',
            permissions: ['creative_studio']
        },
        {
            title: t('core.chat'),
            icon: 'MessageChatCircle',
            path: '/groon/chat',
            permissions: ['social_intranet']
        },
        {
            title: t('layout.assistants'),
            icon: 'MessageSmileCircle',
            path: '/groon/ai/assistants',
            permissions: []
        },
        {
            title: t('core.workflows.title'),
            icon: 'Route',
            path: '/groon/workflow',
            permissions: ['process_automation']
        },
        {
            title: t('layout.profile'),
            icon: 'User01',
            path: '/groon/profile',
            permissions: ['social_intranet']
        },
        {
            title: t('attributes.organization'),
            icon: 'Building07',
            path: '/groon/organization',
            permissions: ['manage_organization']
        },
        {
            title: t('core.permissions.control_center.name'),
            icon: 'Speedometer03',
            path: '/groon/control',
            permissions: ['control_center']
        },
        {
            title: t('common.themes'),
            icon: 'Brush03',
            path: '/groon/themes',
            permissions: ['theme_management']
        },

        // Control Center
        {
            title: t('mgt.index.architecture'),
            icon: 'Server01',
            path: '/groon/control',
            permissions: ['control_center']
        },
        {
            title: t('mgt.index.security'),
            icon: 'Lock01',
            path: '/groon/control#security',
            permissions: ['control_center', 'omnia_security']
        },
        {
            title: t('mgt.index.data_files'),
            icon: 'Database02',
            path: '/groon/control#files_storage',
            permissions: ['control_center', 'omnia_files']
        },
        {
            title: t('mgt.index.permissions'),
            icon: 'LockKeyholeSquare',
            path: '/groon/control#permissions',
            permissions: ['control_center', 'omnia_rights']
        },
        {
            title: t('mgt.index.jobs_tasks'),
            icon: 'Dataflow03',
            path: '/groon/control#jobs_tasks',
            permissions: ['control_center']
        },
        {
            title: t('mgt.index.connectors'),
            icon: 'Wifi',
            path: '/groon/control#connectors',
            permissions: ['control_center', 'omnia_connectors']
        },
        {
            title: t('mgt.index.monitoring'),
            icon: 'BarChart04',
            path: '/groon/control#monitoring',
            permissions: ['control_center', 'omnia_channels']
        },
        {
            title: t('mgt.index.ticket_system'),
            icon: 'Bookmark',
            path: '/groon/control#development',
            permissions: ['control_center', 'omnia_development']
        },
    ].concat(settingsRoutes);

    const transformNavigationData = (navigationConfig) => {
        const result = [];

        for (const key in navigationConfig) {
            if (Object.prototype.hasOwnProperty.call(navigationConfig, key)) {
                const section = navigationConfig[key];

                const topLevelEntry = {
                    title: t(section.title),
                    icon: section.icon || null,
                    path: section.path || null,
                    permissions: Array.isArray(section.permissions) ? section.permissions : []
                };

                if (topLevelEntry.title || topLevelEntry.path || topLevelEntry.icon) {
                    result.push(topLevelEntry);
                }

                if (Array.isArray(section.items)) {
                    for (const item of section.items) {
                        const entry = {
                            title: t(item.title),
                            icon: item.icon || null,
                            path: item.path || null,
                            permissions: Array.isArray(item.permissions) ? item.permissions : []
                        };
                        result.push(entry);
                    }
                }
            }
        }

        return result;
    }

    const handleSearch = () => {
        if (!textInputRef.current)
            return;
        const currentRequestId = ++requestIdRef.current;
        setLoadingEndpoints(['search']);
        get('setup/search', {search: textInputRef.current, disabled_object_filters: disabledFiltersRef.current}).then(response => {
            if (currentRequestId === requestIdRef.current) {
                const navPaths = transformNavigationData(defaultNavigationConfig)?.concat(specialRoutes)?.filter(p => (p?.path || null) !== null);
                const pathHits = navPaths?.filter(path => {
                    const rightsOkay = hasRights(path?.permissions || []);
                    const titleHit = path?.title?.toLowerCase().includes(textInputRef.current.toLowerCase());
                    return rightsOkay && titleHit;
                })
                const searchActive = response?.active_object_filters || [];
                const searchDisabled = response?.disabled_object_filters || [];
                setActiveSearchFilters(searchActive.concat(searchDisabled));
                setSearchResults({...{paths: pathHits?.slice(0, 8)}, ...response?.results});
            }
        }).finally(() => {
            if (currentRequestId === requestIdRef.current) {
                setLoadingEndpoints(prev => prev.filter(endpoint => endpoint !== 'search'));
            }
        })

    }

    const toggleDisableFilter = (filter) => {
        setDisabledSearchFilters(prev => {
            // Check if it already exists
            if (prev.includes(filter)) {
                return prev.filter(f => f !== filter);
            } else {
                return [...prev, filter];
            }
        });
    }

    const debouncedChangeHandler = useCallback(debounce(handleSearch, 500), []);

    const handleChange = useCallback((event) => {
        setBody(event.target.value);
        if(event.target.value === ''){
            setActiveSearchFilters([]);
            setDisabledSearchFilters([]);
        }
    }, []);

    const handleNavigate = (link, newTaget = false) => {
        popover.handleClose();
        if (newTaget) {
            window.open(link, '_blank', 'noopener,noreferrer');
        } else {
            navigate(link);
        }
    };

    const storeChosenSearchResult = (objectKind, objectId) => {
        post('setup/search_result', {
            kind: objectKind,
            id: objectId,
            query: textInputRef.current
        }).catch((errors) => console.log('Error while storing search result', errors));
    };

    const handleDialogContentClick = (e) => {
        const clickable = e.target.closest('[data-kind][data-id]');
        if (clickable) {
            const objectKind = clickable.getAttribute('data-kind');
            const objectId = clickable.getAttribute('data-id');
            storeChosenSearchResult(objectKind, objectId);
            popover.handleClose();
        }
    };

    const scrollToItem = (index) => {
        if (flattenedResults[index]) {
            const {kind, data} = flattenedResults[index];
            const list = listRef.current;
            if (list) {
                const item = list.querySelector(`[data-kind="${kind}"][data-id="${data?.id || data?.value || data?.path}"]`);
                if (item) {
                    item.scrollIntoView({block: 'nearest', behavior: 'smooth'});
                }
            }
        }
    };

    const flattenedResults = useMemo(() => {
        const flatList = [];
        Object.keys(searchResults).forEach(key => {
            const results = searchResults[key];
            if (key === 'paths' && results?.length > 0) {
                results.forEach(item => {
                    flatList.push({kind: key, data: item});
                });
            } else if (results?.length > 0) {
                results.forEach(item => {
                    flatList.push({kind: key, data: item});
                });
            }
        });
        return flatList;
    }, [JSON.stringify(searchResults)]);

    const handleKeyDown = useCallback((e) => {
        if (flattenedResults.length === 0) return;
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedIndex(prev => {
                const newIndex = (prev + 1) % flattenedResults.length;
                setCurrentlySelected(flattenedResults[newIndex]);
                scrollToItem(newIndex);
                return newIndex;
            });
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedIndex(prev => {
                const newIndex = (prev - 1 + flattenedResults.length) % flattenedResults.length;
                setCurrentlySelected(flattenedResults[newIndex]);
                scrollToItem(newIndex);
                return newIndex;
            });
        } else if (e.key === 'Enter') {
            e.preventDefault();
            if (currentlySelected?.data?.object_link) {
                handleNavigate(currentlySelected?.data?.object_link);
                storeChosenSearchResult(currentlySelected?.kind, currentlySelected?.data?.id);
                popover.handleClose();
            } else if (listRef.current) {
                const {kind, data} = currentlySelected;
                const item = listRef.current.querySelector(`[data-kind="${kind}"][data-id="${data?.id || data?.value || data?.path}"]`);

                if (item) {
                    item.click();
                }

            }
        }
    }, [JSON.stringify(flattenedResults), currentlySelected, handleNavigate, popover]);

    useEffect(() => {
        if (flattenedResults.length > 0) {
            setSelectedIndex(0);
            setCurrentlySelected(flattenedResults[0]);
            scrollToItem(0);
        } else {
            setSelectedIndex(-1);
            setCurrentlySelected(null);
        }
    }, [JSON.stringify(flattenedResults)]);

    useEffect(() => {
        if (listRef.current && Object.keys(searchResults).length > 0) {
            listRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [JSON.stringify(searchResults)]);

    useEffect(() => {
        const currentInput = inputEl.current;
        if (currentInput) {
            currentInput.addEventListener('keydown', handleKeyDown);
            return () => {
                currentInput.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [handleKeyDown]);

    useEffect(() => {
        if (popover.open && inputEl.current) {
            inputEl.current.focus();
        }
    }, [searchResults, popover.open]);

    return (
        <Dialog
            open={popover.open}
            onClose={popover.handleClose}
            maxWidth="sm"
            fullWidth={true}
            PaperProps={{sx: {borderRadius: '28px'}}}
            keepMounted={true}
            disableAutoFocus
            disableEnforceFocus
            TransitionProps={{
                onEnter: () => {
                    if (inputEl.current) {
                        inputEl.current.focus();
                        if (body) {
                            inputEl.current.select();
                        }
                    }
                }
            }}
        >
            <DialogContent
                sx={{p: 0}}
                onClick={handleDialogContentClick}
            >
                <Box sx={{p: 1}}>
                    <OutlinedInput
                        fullWidth
                        autoFocus={true}
                        inputRef={inputEl}
                        value={body}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                            if (!['Enter', 'ArrowDown', 'ArrowUp'].includes(e.key)) {
                                debouncedChangeHandler();
                            }
                        }}
                        sx={{height: 45, borderRadius: '25px', ...sx}}
                        placeholder={t('common.groon_search')}
                        endAdornment={
                            <InputAdornment position="end">
                                <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center">
                                    {loadingEndpoints.length > 0 && <CircularProgress size={18}/>}
                                    {activeSearchFilters?.sort((a, b) => a.localeCompare(b)).map(filter => (
                                        <Chip
                                            key={'filter-' + filter}
                                            onDelete={() => {
                                                toggleDisableFilter(filter);
                                                debouncedChangeHandler();
                                            }}
                                            color={disabledSearchFilters?.includes(filter) ? 'default' : 'primary'}
                                            deleteIcon={(
                                                <Tooltip title={disabledSearchFilters?.includes(filter) ? t('common.activate') : t('common.deactivate')} >
                                                    {disabledSearchFilters?.includes(filter) ? (
                                                        <OnIcon iconName="CheckCircle"/>
                                                    ) : (
                                                        <OnIcon iconName="XCircle"/>
                                                    )}
                                                </Tooltip>
                                            )}
                                            label={filter}
                                        />
                                    ))}
                                </Stack>
                            </InputAdornment>
                        }
                    />
                </Box>
                {body !== '' && (
                    <List
                        sx={{p: 0, maxHeight: 600, overflowY: 'auto'}}
                        ref={listRef}
                    >

                        {Object.keys(searchResults).map(key => {

                            const results = searchResults[key];

                            return (
                                <React.Fragment key={key}>
                                    {(key === 'paths' && results?.length > 0) ? (
                                        <>
                                            <Divider sx={{mt: 2}}/>
                                            {renderObjects('paths', results)}
                                        </>
                                    ) : (results?.length > 0) && (
                                        <>
                                            <Divider sx={{my: 2}}/>
                                            <ListItem>
                                                <ListItemText primary={t('core.search.' + key)}
                                                              primaryTypographyProps={{variant: 'overline'}}/>
                                            </ListItem>
                                            {renderObjects(key, results)}
                                        </>
                                    )}
                                </React.Fragment>
                            )
                        })}

                    </List>
                )}
            </DialogContent>
        </Dialog>
    )
}

SearchDialog.propTypes = {
    popover: PropTypes.object,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    sx: PropTypes.object
};

export default SearchDialog;
