import React, {useCallback} from "react";
import PropTypes from "prop-types";
import {usePopover} from "../../../../hooks/use-popover";
import {
    Box,
    Card,
    Divider,
    IconButton,
    Menu, MenuItem,
    menuItemClasses,
    Stack,
    SvgIcon,
    Typography
} from "@mui/material";
import DotsVerticalIcon from "@untitled-ui/icons-react/build/esm/DotsVertical";
import {ItemIcon} from "./item-icon";
import moment from "moment/moment";
import {useDialog} from "../../../../hooks/use-dialog";
import Trash02Icon from "@untitled-ui/icons-react/build/esm/Trash01";
import {bytesToSize} from "../../../../utils/bytesToSize";
import {useTranslation} from "react-i18next";
import FileModal from "../file-modal";

const HoverableFileCard = (props) => {
    const {file, onDelete, onChange, renderTags, renderActions, allowDeletion, selected = false} = props;
    const popover = usePopover();
    const {t} = useTranslation();
    const detailsDialog = useDialog();

    const handleDelete = useCallback(() => {
        popover.handleClose();
        onDelete?.(file.id);
    }, [file, popover, onDelete]);

    let size = bytesToSize(file.size);

    return (
        <>
            <FileModal
                file={detailsDialog.data}
                open={detailsDialog.open}
                onClose={detailsDialog.handleClose}
                onUpdate={onChange}
                deleteCallback={handleDelete}
            />
            <Card
                key={file.id}
                onClick={() => detailsDialog.handleOpen?.(file)}
                sx={{
                    backgroundColor: 'transparent',
                    cursor: 'pointer',
                    boxShadow: 0,
                    transition: (theme) => theme.transitions.create(['background-color, box-shadow'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: 200
                    }),
                    border: selected
                        ? "2px solid"
                        : "1px solid",
                    borderColor: selected
                        ? "primary.main"
                        : "divider",
                    '&:hover': {
                        backgroundColor: 'background.paper',
                        boxShadow: 5
                    }
                }}
                variant="outlined"
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={2}
                    sx={{
                        px: 2,
                        height: 35
                    }}
                >
                    <Box>
                        {file.is_public && (
                            <Typography variant="overline" color="textSecondary">
                                {t("common.public")}
                            </Typography>
                        )}
                    </Box>
                    {(onDelete && allowDeletion) && (
                        <IconButton
                            onClick={popover.handleOpen}
                            ref={popover.anchorRef}
                        >
                            <SvgIcon fontSize="small">
                                <DotsVerticalIcon/>
                            </SvgIcon>
                        </IconButton>
                    )}
                </Stack>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            mb: 1,
                            px: 2,
                            pt: 1
                        }}
                    >
                        <Box
                            sx={{
                                display: 'inline-flex',
                            }}
                        >
                            <ItemIcon
                                type={file.type}
                                extension={file.type}
                            />
                        </Box>
                    </Box>
                    <Typography
                        sx={{px: 2}}
                        variant="subtitle2"
                    >
                        {file.name}
                    </Typography>
                    <Divider sx={{my: 1}}/>
                    <Stack
                        alignItems="center"
                        direction="row"
                        justifyContent="space-between"
                        spacing={1}
                        sx={{width: '100%', px: 2, pb: 1}}
                    >
                        <Stack
                            direction="column"
                            spacing={0}
                            alignItems="flex-start"
                            justifyContent="center"
                        >
                            <Typography
                                color="text.secondary"
                                variant="body2"
                            >
                                {size}
                            </Typography>
                            <Typography
                                color="text.secondary"
                                variant="caption"
                            >
                                {t("common.uploaded_on_from_now", {fromNow: moment(file?.created_at).fromNow()})}
                            </Typography>
                        </Stack>
                        <Box>
                            {renderTags && renderTags(file)}
                        </Box>
                    </Stack>
                </Box>
            </Card>
            <Menu
                anchorEl={popover.anchorRef.current}
                anchorOrigin={{
                    horizontal: 'right',
                    vertical: 'bottom'
                }}
                onClose={popover.handleClose}
                onClick={popover.handleClose}
                open={popover.open}
                sx={{
                    [`& .${menuItemClasses.root}`]: {
                        fontSize: 14,
                        '& svg': {
                            mr: 1
                        }
                    }
                }}
                transformOrigin={{
                    horizontal: 'right',
                    vertical: 'top'
                }}
            >
                {allowDeletion && (
                    <MenuItem
                        onClick={handleDelete}
                        sx={{color: 'error.main'}}
                    >
                        <SvgIcon fontSize="small">
                            <Trash02Icon/>
                        </SvgIcon>
                        {t("common.delete")}
                    </MenuItem>
                )}
                {renderActions && renderActions(file).map((action, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => action.onClick(file)}
                    >
                        <SvgIcon fontSize="small">
                            {action.icon}
                        </SvgIcon>
                        {action.name}
                    </MenuItem>
                ))}

            </Menu>

        </>
    )

}

HoverableFileCard.propTypes = {
    file: PropTypes.object.isRequired,
    onDelete: PropTypes.func,
    renderActions: PropTypes.func,
    renderTags: PropTypes.func,
    allowDeletion: PropTypes.bool,
    selected: PropTypes.bool,
}

export default HoverableFileCard