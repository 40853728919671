import React from 'react';
import {useTranslation} from "react-i18next";
import {Switch, Tooltip} from "@mui/material";
import ServerSideDataTable from "../../../elements/tables/server-side-data-table";
import useOmniaApi from "../../../../hooks/use-omnia-api";

function PlatformAdAccountsTable({...rest}) {
    const {t} = useTranslation();
    const {post, put} = useOmniaApi();

    const handleToggle = (id, isActive) => {

        post(`sm/platform-ad-accounts/${id}/toggle`, {
            is_active: isActive, // Send the updated status
        })

    }

    const columns = [
        {
            field: 'reference_id',
            headerAlign: 'left',
            align: 'left',
            headerName: t("Ads Account ID"),
            width: 200,
        },
        {
            field: 'descriptive_name',
            headerAlign: 'left',
            align: 'left',
            headerName: t("Name")
        },
        {
            field: 'is_active',
            headerName: t("Active"),
            type: 'singleSelect',
            renderCell: (data) => {
                return(
                    <Tooltip title={t('Activate this to use Account')}>
                        <Switch
                            checked={data.row.is_active}
                            onChange={() => (handleToggle(data.id, !data.row.is_active))}
                        />
                    </Tooltip>
                )
            }
        }
    ];

    return (
        <ServerSideDataTable
            title={t('marketing.platforms.ad_accounts')}
            id='facebook-ad-accounts'
            endpoint='sm/platform-ad-accounts'
            defaultState={[
                {
                    field: 'name',
                    width: 150,
                    headerName: t("attributes.name"),
                    sorted: null
                },
                {
                    field: 'reference_id',
                    pinned: null,
                    width: 150,
                    headerName: t("Refernce ID"),
                    sorted: null
                },
                {
                    field: 'is_active',
                    pinned: null,
                    width: 150,
                    headerName: t("Active"),
                    sorted: null
                }
            ]}
            columns={columns}
            {...rest}
        />
    );
}

export default PlatformAdAccountsTable;