import React, {useEffect, Fragment, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box, Card, CardActions, Divider, Button, Input, IconButton, OutlinedInput, LinearProgress, Typography} from '@mui/material';
import { Trash as TrashIcon } from 'src/omnia/icons/trash';
import {
    updateChecklist,
    deleteChecklist
} from 'src/omnia/store/actions/kanban-actions';
import CheckItem from './check-item';
import CheckItemAdd from './check-item-add';
import {useNotifications} from "src/omnia/hooks/use-notifications";
import { produce } from "immer";
import { styled } from '@mui/material/styles';
import useApi from "src/omnia/hooks/use-api";

const KanbanChecklistRoot = styled('div')``;

function Checklist({card, checklist, ...rest}) {

    const boardId = useSelector(state => state.kanban.boardId);
    const dispatch = useDispatch();
    const {notify} = useNotifications();
    const [checklistData, setChecklistData] = useState({checkItems: [], name: ""});
    const { put, del } = useApi();
    const [name, setName] = useState(checklist.name);
    const [editingName, setEditingName] = useState(null);
    const [editingCheckItem, setEditingCheckItem] = useState(null);

    const handleNameEdit = () => {
        setEditingName(true);
    };

    const handleNameChange = (event) => {
        event.persist();
        setName(event.target.value);
    };

    const handleNameSave = async () => {

        if (!name || name === checklist.name) {
            setEditingName(false);
            setName(checklist.name);
            return;
        }

        setEditingName(false);
        put('pm/project-board-list-card-checklists', {...{id: checklist.id}, ...{name}}).then(checklist => {
            dispatch(updateChecklist(boardId, card.id, checklist));
        });

    };

    const handleNameCancel = () => {
        setEditingName(false);
        setName(checklist.name);
    };

    const handleDelete = async () => {
        if(window.confirm("Soll die Checkliste wirklich gelöscht werden?")){
            del('pm/project-board-list-card-checklists', checklist.id).then(() => {
                dispatch(deleteChecklist(boardId, card.id, checklist.id));
            });
        }
    };

    const handleCheckItemEditInit = (checkItemId) => {
        setEditingCheckItem(checkItemId);
    };

    const handleCheckItemEditCancel = () => {
        setEditingCheckItem(null);
    };

    const handleCheckItemEditComplete = () => {
        setEditingCheckItem(null);
    };

    useEffect(() => {
        setChecklistData(produce(checklist, (draft) => {
            draft.checkItems = draft.checkItems.sort((a, b) => (a.id > b.id) ? 1 : -1);
        }));
        setName(checklist.name);
    }, [checklist]);

    const totalCheckItems = checklistData.checkItems.length;
    const completedCheckItems = (checklistData.checkItems.filter((checkItem) => checkItem.state === 'complete')).length;
    const completePercentage = totalCheckItems === 0 ? 100 : (completedCheckItems / totalCheckItems) * 100;

    let sortedItems = produce(checklist.checkItems, draft => {
        draft.sort((a, b) => {
            let dateA = new Date(a.created_at);
            let dateB = new Date(b.created_at);
            if((a.state === 'complete') && (b.state !== 'complete'))
                return 1;
            if((a.state !== 'complete') && (b.state === 'complete'))
                return -1;
            return (dateA > dateB) ? 1 : -1;
        })
    });

    return (
        <KanbanChecklistRoot {...rest}>
            <Card variant="outlined">
                <Box sx={{ p: 3 }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        {editingName
                            ? (
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        width: '100%'
                                    }}
                                >
                                    <OutlinedInput
                                        onChange={handleNameChange}
                                        value={name}
                                        sx={{
                                            flexGrow: 1,
                                            '& .MuiInputBase-input': {
                                                px: 2,
                                                py: 1
                                            }
                                        }}
                                    />
                                    <Button
                                        onClick={handleNameSave}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        variant="contained"
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        onClick={handleNameCancel}
                                        size="small"
                                        sx={{ ml: 2 }}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            )
                            : (
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        flexGrow: 1
                                    }}
                                >
                                    <Input
                                        disableUnderline
                                        fullWidth
                                        onClick={handleNameEdit}
                                        value={checklist.name}
                                        sx={{
                                            borderColor: 'transparent',
                                            borderRadius: 1,
                                            borderStyle: 'solid',
                                            borderWidth: 1,
                                            cursor: 'text',
                                            m: '-1px',
                                            '&:hover': {
                                                backgroundColor: 'action.selected'
                                            },
                                            '& .MuiInputBase-input': {
                                                fontWeight: 500,
                                                px: 2,
                                                py: 1
                                            }
                                        }}
                                    />
                                    <IconButton
                                        onClick={handleDelete}
                                        sx={{ ml: 2 }}
                                        size="small"
                                    >
                                        <TrashIcon fontSize="small" />
                                    </IconButton>
                                </Box>
                            )}
                    </Box>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            mt: 3
                        }}
                    >
                        <Typography
                            color="textSecondary"
                            variant="caption"
                        >
                            {Math.round(completePercentage)}
                            %
                        </Typography>
                        <Box
                            sx={{
                                flexGrow: 1,
                                ml: 2
                            }}
                        >
                            <LinearProgress
                                color="primary"
                                sx={{
                                    borderRadius: 1,
                                    height: 8,
                                    '& .MuiLinearProgress-bar': {
                                        borderRadius: 'inherit'
                                    }
                                }}
                                value={completePercentage}
                                variant="determinate"
                            />
                        </Box>
                    </Box>
                </Box>
                <Divider />
                {sortedItems.map((checkItem) => (
                    <Fragment key={checkItem.id}>
                        <CheckItem
                            boardId={boardId}
                            editing={editingCheckItem === checkItem.id}
                            checkItem={checkItem}
                            card={card}
                            checklist={checklist}
                            key={checkItem.id}
                            onEditCancel={handleCheckItemEditCancel}
                            onEditComplete={handleCheckItemEditComplete}
                            onEditInit={() => handleCheckItemEditInit(checkItem.id)}
                        />
                        <Divider />
                    </Fragment>
                ))}
                <CardActions>
                    <CheckItemAdd boardId={boardId} card={card} checklist={checklist}/>
                </CardActions>
            </Card>
        </KanbanChecklistRoot>
    )

}

Checklist.propTypes = {
    className: PropTypes.string,
    card: PropTypes.object.isRequired,
    checklist: PropTypes.object.isRequired
};

export default Checklist;
