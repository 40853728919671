import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {DragDropContext} from 'react-beautiful-dnd';
import {Box, CircularProgress} from '@mui/material';
import {moveCard} from 'src/omnia/store/actions/kanban-actions';
import List from './list';
import ListAdd from './list-add';
import useApi from "src/omnia/hooks/use-api";
import PropTypes from "prop-types";
import {useTheme} from "@mui/system";

function KanbanView({id, featured = null}) {

    const theme = useTheme();
    const dispatch = useDispatch();
    const {post} = useApi();
    const lists = useSelector((state) => state.kanban.lists);
    const boardId = useSelector((state) => state.kanban.boardId);

    const handleDragEnd = async ({source, destination, draggableId}) => {

        // Dropped outside the list
        if (!destination)
            return;

        // Card has not been moved
        if (source.droppableId === destination.droppableId && source.index === destination.index)
            return;

        // dispatch movement
        dispatch(moveCard(id, parseInt(draggableId), parseInt(destination.index), parseInt(destination.droppableId)));

        // Call API to move the card (and broadcast the change to all others)
        post('pm/project-board-list-cards/' + draggableId + '/move', {position: destination.index, list: destination.droppableId}).catch(() => {
            dispatch(moveCard(id, parseInt(draggableId), parseInt(source.index), parseInt(source.droppableId)));
        })
    };

    return (
        <Box style={{
            backgroundColor: theme.palette.background.dark,
            display: 'flex',
            overflow: 'hidden',
            flexDirection: 'column',
            position: 'absolute',
            height: 'calc(100vh - 55px)',
            top: 55,
            bottom: 0,
            right: 0,
            left: 0
        }}>
            {(boardId !== id) ? (
                <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress/>
                </div>
            ) : (
                <DragDropContext
                    onDragEnd={handleDragEnd}
                    direction="horizontal"
                    isDropDisabled={false}
                >
                    <div style={{
                        flexGrow: 1,
                        flexShrink: 1,
                        display: 'flex',
                        overflowY: 'hidden',
                        overflowX: 'auto'
                    }}>
                        <div style={{
                            display: 'flex',
                            paddingTop: theme.spacing(3),
                            paddingBottom: theme.spacing(3),
                            paddingLeft: theme.spacing(1),
                            paddingRight: theme.spacing(1)
                        }}>
                            {lists.allIds.map((listId) => (
                                <List featured={featured} key={listId} listId={listId}/>
                            ))}
                            <ListAdd/>
                        </div>
                    </div>
                </DragDropContext>
            )}
        </Box>
    );
}

KanbanView.propTypes = {
    id: PropTypes.number.isRequired,
    featured: PropTypes.number
}

export default KanbanView;
