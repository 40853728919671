import React, { useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import {
    Autocomplete,
    Avatar,
    Box,
    Drawer,
    FormHelperText,
    IconButton,
    MenuItem,
    Popover,
    Stack,
    SvgIcon,
    TextField,
    Tooltip,
    Typography,
    Grid,
    useMediaQuery
} from '@mui/material';
import {TaskStatus} from './task-status';
import {Minus, Plus, Save, Trash} from "react-feather";
import {useTranslation} from "react-i18next";
import useApi from "src/omnia/hooks/use-api";
import {useNotifications} from "src/omnia/hooks/use-notifications";
import {useCreateSrc} from "src/omnia/hooks/use-create-src";
import {useSelector} from "react-redux";
import useCoreFormik from "../../../../hooks/formik/use-core-formik";
import {PopoverTextField} from "../../../elements/popover-textfield";
import {useTheme} from "@mui/system";
import SmartSelector from "../../../elements/smart-selector";
import {DateTimePicker} from "@mui/x-date-pickers-pro";
import SaveButton from "../../../elements/save-button";
import InlineEditInput from "../../../elements/inline-edit-input";

export const TaskDrawer = ({task, onClose, onRemove, open = false, ...other}) => {

    const mdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const users = useSelector((state) => state.users.users);
    const [author, setAuthor] = useState(null);
    const theme = useTheme();
    const getSrc = useCreateSrc();
    const { notify } = useNotifications();
    const { put, del, post } = useApi();
    const [hoveredUser, setHoveredUser] = useState(null);
    const [assigned, setAssigned] = useState([]);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const addUserOpen = Boolean(anchorEl);

    const handleAddAssigned = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDelete = () => {
        if(window.confirm(t("notify.are_you_sure"))){
            del('core/tasks', task.id).then(() => {
                onRemove(task);
                onClose();
                notify(t("notify.deleted"), "success");
            });
        }
    }

    const handleSave = (values, helpers) => {
        helpers.setSubmitting(true);
        put('core/tasks', values).then((res) => {
            // onClose?.();
            // notifySuccess(t);
        }).finally(() => {
            helpers.setSubmitting(false);
        });
    }

    const handleRemoveAssigned = (user) => {
        if(window.confirm(t("notify.are_you_sure"))){
            del('core/tasks/' + task.id + '/assigned', null, {user_id: user.id}).then(res => {
                setAssigned(assigned.filter(res => res.id !== user.id));
            });
        }
    }

    const handleSaveUser = (user) => {
        post('core/tasks/' + task.id + '/assigned', {user_id: user.id}).then(res => {
            setAssigned([...assigned, user]);
        });
    }

    const {taskFormik, TASK_STATUS, TASK_PRIORITIES} = useCoreFormik(task, handleSave, true);

    useEffect(() => {
        if(task && users.filter(u => u.id === task.created_by).length > 0)
            setAuthor(users.filter(u => u.id === task.created_by)[0]);
        if(task)
            setAssigned(users?.filter(u => task?.assigned_to?.includes(u.id)) || []);
    }, [users, task]);

    if(!task)
        return null;

    return (
        <Drawer
            anchor="right"
            onClose={onClose}
            open={open}
            PaperProps={{
                sx: {
                    width: '100%',
                    maxWidth: 500,
                }
            }}
            {...other}
        >
            <>
                {(!mdUp && (
                    <Stack
                        alignItems={{
                            sm: 'center'
                        }}
                        direction={{
                            xs: 'column-reverse',
                            sm: 'row'
                        }}
                        justifyContent={{
                            sm: 'space-between'
                        }}
                        spacing={1}
                        sx={{p: 3}}
                    >
                        {/*<TaskStatus*/}
                        {/*    formik={taskFormik}*/}
                        {/*    options={TASK_STATUS}*/}
                        {/*/>*/}
                        <Stack
                            justifyContent="flex-end"
                            alignItems="center"
                            direction="row"
                            spacing={1}
                        >
                            {/*<IconButton onClick={taskFormik.handleSubmit}>*/}
                            {/*    <SvgIcon>*/}
                            {/*        <Save/>*/}
                            {/*    </SvgIcon>*/}
                            {/*</IconButton>*/}

                            <IconButton onClick={onClose}>
                                <SvgIcon>
                                    <XIcon/>
                                </SvgIcon>
                            </IconButton>
                        </Stack>
                    </Stack>
                ))}
                <Box sx={{p: 3}}>
                    <Grid
                        container
                        spacing={2}
                    >
                        <Grid item xs={12} sx={{mt: 4, mb: 4}}>
                            <Stack direction="row" alignItems="center" sx={{width: '100%'}} justifyContent="space-between">
                                <Stack sx={{width: '100%'}}>
                                    <InlineEditInput
                                        variant="h4"
                                        placeholder={t('attributes.name')}
                                        fullWidth
                                        withHint={false}
                                        size='small'
                                        name="name"
                                        {...taskFormik.getFieldProps('name')}
                                        onChange={(value) => taskFormik.setFieldValue('name', value)}
                                        error={taskFormik.touched.name && Boolean(taskFormik.errors.name)}
                                    />
                                    {(taskFormik.touched.name && taskFormik.errors.name) && (
                                        <Typography variant="caption"
                                                    color={(taskFormik.touched.name && Boolean(taskFormik.errors.name)) ? 'error' : 'text.secondary'}>
                                            {taskFormik.touched.name && taskFormik.errors.name}
                                        </Typography>
                                    )}
                                </Stack>
                                <Stack sx={{width: 200}} justifyContent="flex-end" direction="row">
                                    <TaskStatus
                                        formik={taskFormik}
                                        options={TASK_STATUS}
                                    />
                                </Stack>
                            </Stack>
                        </Grid>
                        {task?.shared_task && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                >
                                    <Typography
                                        color="text.secondary"
                                        variant="caption"
                                    >
                                        {t('attributes.created_by')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                >
                                    <Tooltip enterDelay={1000} title={author?.name}>
                                        <Avatar src={getSrc(author?.avatar?.view)}/>
                                    </Tooltip>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                >
                                    <Typography
                                        color="text.secondary"
                                        variant="caption"
                                    >
                                        {t('core.tasks.assigned_to')}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sm={8}
                                >
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        flexWrap="wrap"
                                        spacing={1}
                                    >
                                        <Stack direction="row" spacing={1}>
                                            {assigned?.map(user => (
                                                <Box
                                                    key={user.id}
                                                    sx={{position: 'relative', cursor: 'pointer'}}
                                                    onMouseEnter={() => setHoveredUser(user.id)}
                                                    onMouseLeave={() => setHoveredUser(null)}
                                                >
                                                    <Tooltip enterDelay={1000} placement="bottom" title={user.name}>
                                                        <Avatar
                                                            key={user.id}
                                                            src={getSrc(user?.avatar?.view)}
                                                        />
                                                    </Tooltip>
                                                    {(hoveredUser === user.id) &&
                                                        <Tooltip enterDelay={1000} title={t("common.remove")}>
                                                            <IconButton
                                                                sx={{
                                                                    position: 'absolute',
                                                                    top: -5,
                                                                    left: -5,
                                                                    width: '20px',
                                                                    height: '20px',
                                                                    padding: 0,
                                                                    color: 'white',
                                                                    backgroundColor: theme.palette.error.main,
                                                                    '&:hover': {
                                                                        backgroundColor: theme.palette.error.dark
                                                                    }
                                                                }}
                                                                onClick={() => handleRemoveAssigned(user)}
                                                            >
                                                                <Minus size={15} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    }
                                                </Box>
                                            ))}
                                            <Avatar sx={{cursor: 'pointer', width: 40, height: 40}} onClick={handleAddAssigned}>
                                                <SvgIcon fontSize="small">
                                                    <Plus />
                                                </SvgIcon>
                                            </Avatar>
                                        </Stack>
                                        <Popover
                                            open={addUserOpen}
                                            anchorEl={anchorEl}
                                            onClose={handleClose}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'center',
                                            }}
                                        >
                                            <Autocomplete
                                                options={users.filter(u => !task?.assigned_to.includes(u.id))}
                                                getOptionLabel={(option) => option.name}
                                                style={{ width: 300 }}
                                                renderInput={(params) => <PopoverTextField autoFocus {...params} label={t("common.choose_user")} />}
                                                onClose={handleClose}
                                                onChange={(event, value) => handleSaveUser(value)}
                                            />
                                        </Popover>
                                    </Stack>
                                </Grid>
                            </>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                select
                                fullWidth={true}
                                label={t('attributes.priority')}
                                value={taskFormik.values.priority}
                                name="priority"
                                onChange={taskFormik.handleChange}
                            >
                                {TASK_PRIORITIES.map(priority => (
                                    <MenuItem key={priority.value} value={priority.value}>
                                        {t(priority.label)}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <DateTimePicker
                                label={t('attributes.due_date')}
                                yearsOrder="desc"
                                format="dd.MM.yyyy HH:mm"
                                ampm={false}
                                views={['month', 'day']}
                                slotProps={{
                                    textField: {fullWidth: true}
                                }}
                                value={taskFormik.values.due_date ? (new Date(taskFormik.values.due_date)) : null}
                                onChange={(newValue) => taskFormik.setFieldValue('due_date', newValue)}
                                onBlur={() => taskFormik.setFieldTouched('due_date')}
                                onClose={() => taskFormik.setFieldTouched('due_date')}
                                onAccept={() => taskFormik.setFieldTouched('due_date')}
                            />
                            {Boolean(taskFormik.touched.due_date && taskFormik.errors.due_date) && (
                                <Box m2={2}>
                                    <FormHelperText error>
                                        {taskFormik.errors.due_date}
                                    </FormHelperText>
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SmartSelector
                                endpoint='pm/projects'
                                query={{ordering: 'name', state__not: 'finished'}}
                                labelField="title"
                                values={taskFormik.values?.project?.id || taskFormik.values?.project || null}
                                name="project"
                                label={t('attributes.project')}
                                handleChange={taskFormik.handleChange}
                                multiple={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SmartSelector
                                endpoint='crm/contacts'
                                query={{ordering: 'last_name'}}
                                values={taskFormik.values.contact?.id || taskFormik.values.contact || null}
                                name="contact"
                                secondaryLabelField='email'
                                label={t('attributes.contact')}
                                handleChange={taskFormik.handleChange}
                                multiple={false}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <SmartSelector
                                endpoint='tms/orders'
                                query={{ordering: 'number', state__not_in: ['completed', 'canceled', 'returned']}}
                                values={taskFormik.values?.order?.id || taskFormik.values?.order || null}
                                name="order"
                                labelField='number'
                                label={t('resources.orders.order')}
                                handleChange={taskFormik.handleChange}
                                multiple={false}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth={true}
                                label={t('attributes.notes')}
                                value={taskFormik.values.description}
                                rows={10}
                                name="description"
                                onChange={taskFormik.handleChange}
                                multiline={true}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        left: 16
                    }}
                >
                    <IconButton onClick={handleDelete}>
                        <SvgIcon color="error">
                            <Trash />
                        </SvgIcon>
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 16,
                        right: 16
                    }}
                >
                    <SaveButton formik={taskFormik} autoSave={true} />
                </Box>
            </>
        </Drawer>
    );
};

TaskDrawer.propTypes = {
    task: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    taskId: PropTypes.string,
    onRemove: PropTypes.func,
};
