/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import {
    SET_TOP,
    SET_MIDDLE,
    SET_BOTTOM,
    LOG_ERROR,
    SET_ERROR_SYNCED,
    SET_NAV_BAR_MODE
} from 'src/omnia/store/actions/client-actions';
import { produce } from "immer";

const initialState = {
    // Current scroll position
    scrollPos: "top",
    // Errors while rendering components
    errors: [],
    // An incrementing id for the errors that occur
    errorId: 1,
    // Nav bar mode
    navBarMode: 'flying'
};

const clientReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_NAV_BAR_MODE: {
            const { mode } = action.payload;
            return produce(state, draft => {
                draft.navBarMode = mode;
            })
        }

        case SET_ERROR_SYNCED: {
            const { id } = action.payload;
            return produce(state, draft => {
                draft.errors = state.errors.filter(e => e.id !== id);
            })
        }

        case LOG_ERROR: {
            const { error, info, type } = action.payload;
            return produce(state, draft => {
                // Case it all to a string
                draft.errors = state.errors.concat([{
                    'id': state.errorId,
                    // 'error': error,
                    // 'info': info,
                    'error': error.toString(),
                    'info': info.toString(),
                    'type': type
                }]);
                draft.errorId += 1;
            })
        }

        case SET_TOP:
            if(state.scrollPos !== "top")
                return produce(state, draft => {
                    draft.scrollPos = "top"
                })
            return state;

        case SET_MIDDLE:
            if(state.scrollPos !== "middle")
                return produce(state, draft => {
                    draft.scrollPos = "middle"
                })
            return state;

        case SET_BOTTOM:
            if(state.scrollPos !== "bottom")
                return produce(state, draft => {
                    draft.scrollPos = "bottom"
                })
            return state;

        default: {
            return state;
        }
    }
};

export default clientReducer;
