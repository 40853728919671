import React, { useState, useRef, useEffect } from 'react';
import { InputBase, Stack, Typography, useTheme, Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import {useTranslation} from "react-i18next";

const InlineEditInput = (props) => {
    const {
        prefix = '',
        prefixColor = 'text.primary',
        prefixVariant = null,
        name,
        placeholder = '',
        value,
        onChange = () => {},
        defaultEditing = false,
        autoFocus = true,
        tooltipPlacement = 'left',
        fullWidth = true,
        withHint = true,
        variant = 'body1',
        onDone = null,
        sx = {},
        inputStyles = {},
        ...rest
    } = props;

    const { t } = useTranslation();
    const [editing, setEditing] = useState(defaultEditing);
    const [showTooltip, setShowTooltip] = useState(value !== "" && withHint);
    const inputRef = useRef(null);
    const theme = useTheme();

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowTooltip(false);
        }, 4000);

        return () => clearTimeout(timer);
    }, []);

    const handleInputClick = () => {
        if (!editing) {
            setEditing(true);
        }
    };

    const handleChange = () => {
        if(inputRef.current)
            onChange(inputRef.current?.value || '');
    }

    useEffect(() => {
        if (editing) {
            inputRef.current.focus();
            // Move cursor to the end of the text
            const length = inputRef.current.value.length;
            inputRef.current.setSelectionRange(length, length);
        }
    }, [editing]);

    const handleInputBlur = () => {
        setEditing(false);
        onDone?.();
        if (onChange && inputRef.current) {
            onChange(inputRef.current?.value);
        }
    };

    const typographyStyles = theme.typography[variant];

    return (
        <Tooltip
            title={withHint ? t('common.click_to_edit') : null}
            open={showTooltip}
            placement={tooltipPlacement}
            arrow
        >
            <Stack
                direction="row"
                alignItems="center"
                spacing={1}
            >
                {prefix && (
                    <Typography
                        variant={prefixVariant || variant}
                        sx={typographyStyles}
                        color={prefixColor}
                    >
                        {prefix}
                    </Typography>
                )}
                <InputBase
                    inputRef={inputRef}
                    name={name}
                    fullWidth={fullWidth}
                    autoFocus={autoFocus}
                    value={value}
                    placeholder={placeholder}
                    readOnly={!editing}
                    onBlur={handleInputBlur}
                    onChange={handleChange}
                    onClick={handleInputClick}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            inputRef.current.blur();
                        }
                    }}
                    sx={{
                        ...typographyStyles,
                        padding: 0,
                        margin: 0,
                        lineHeight: 'inherit',
                        cursor: editing ? 'text' : 'default',
                        height: 'auto',
                        ...sx
                    }}
                    inputProps={{
                        style: {
                            ...{
                                padding: 0,
                                margin: 0,
                                height: 'auto',
                                color: 'text.secondary',
                            },
                            ...inputStyles
                        },
                    }}
                    {...rest}
                />
            </Stack>
        </Tooltip>
    );
};

InlineEditInput.propTypes = {
    value: PropTypes.string,
    sx: PropTypes.object,
    inputStyles: PropTypes.object,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    prefixVariant: PropTypes.string,
    prefixColor: PropTypes.string,
    prefix: PropTypes.string,
    fullWidth: PropTypes.bool,
    withHint: PropTypes.bool,
    defaultEditing: PropTypes.bool,
    onChange: PropTypes.func,
    variant: PropTypes.string,
    autoFocus: PropTypes.bool,
    tooltipPlacement: PropTypes.string,
};

export default InlineEditInput;
