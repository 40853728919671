import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {
    Box,
    Card,
    CardContent,
    Chip, Fade,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    Stack,
    SvgIcon, Tab, Tabs,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import DeleteIcon from '@untitled-ui/icons-react/build/esm/Trash01';
import DownloadIcon from '@untitled-ui/icons-react/build/esm/Download01';
import RenameIcon from '@untitled-ui/icons-react/build/esm/Edit05';
import moment from 'moment';
import {Link as RouterLink} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import FullscreenWrapper from 'src/omnia/components/elements/fullscreen-wrapper';
import UserAvatar from '../../modules/core/UserAvatar';
import FileRenameDialog from './file-rename-dialog';
import FileGenericViewer from './file-generic-viewer';
import {bytesToSize} from 'src/omnia/utils/bytesToSize';
import useApi from '../../../hooks/use-api';
import {useIsMobile} from '../../../hooks/use-is-mobile';
import useGenerateVisibility from "../../../hooks/use-generate-visibility";
import {APP_SETTING} from "../../../../setup";
import OnIcon from "../icon";
import useTabs from "../../../hooks/use-tabs";
import DataGraph from "../data-graph";
import ShortLinkStats from "../../modules/core/short-links/short-link-stats";

function FileModal({file, open, deleteCallback = null, onUpdate, onClose}) {
    const theme = useTheme();
    const {t} = useTranslation();
    const {get, post} = useApi();
    const {isMobile} = useIsMobile();
    const [actions, setActions] = useState([]);
    const [usage, setUsage] = useState([]);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [data, setData] = useState(null);
    const {tab, handleTabChange} = useTabs('overview');
    const {visible} = useGenerateVisibility(usage?.length);
    const pdfWrapperRef = useRef();

    const owner = useSelector((state) => {
        if (file && file.owner) {
            const possibleOwner = state.users.users.find((u) => u.id === file.owner);
            return possibleOwner ?? null;
        }
        return null;
    });

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).catch((err) => {
            console.error('Failed to copy link:', err);
        });
    };

    const downloadFile = () => {
        if (file) {
            window.location.href = file.view;
        }
    };

    const handleRename = (update) => {
        setData(update);
        if (onUpdate) {
            onUpdate(update);
        }
    };

    const handleTogglePublic = () => {
        post(`core/files/${file.id}/toggle_public`).then((response) => {
            setData(response);
            if (onUpdate) {
                onUpdate(response);
            }
        });
    };

    const openRenameDialog = () => {
        setRenameDialogOpen(true);
    };

    const closeRenameDialog = () => {
        setRenameDialogOpen(false);
    };

    useEffect(() => {
        if (file) {
            setData(file);
        }
    }, [file]);

    useEffect(() => {
        if (open && file) {
            get(`core/files/${file.id}/usage`).then((response) => {
                setUsage(response);
            });
        }
    }, [file, open, get]);

    useEffect(() => {
        if (data) {
            const tmp = [];

            // Delete action
            if (data.can_delete && deleteCallback !== null) {
                tmp.push({
                    name: (
                        <Tooltip title={t('common.tooltips.remove_file')}>
                            <IconButton color="inherit">
                                <SvgIcon fontSize="small">
                                    <DeleteIcon/>
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    ),
                    action: () => {
                        onClose();
                        deleteCallback(data.id);
                    },
                });
            }

            // Toggle public & rename if allowed
            if (data.can_edit) {
                tmp.push({
                    name: (
                        <Tooltip
                            title={
                                data.is_public
                                    ? t('common.tooltips.make_private')
                                    : t('common.tooltips.make_public')
                            }
                        >
                            <IconButton color="inherit">
                                <OnIcon size="small" iconName={data.is_public ? 'Lock01' : 'Globe04'} />
                            </IconButton>
                        </Tooltip>
                    ),
                    action: handleTogglePublic,
                });
                tmp.push({
                    name: (
                        <Tooltip title={t('common.tooltips.rename_file')}>
                            <IconButton color="inherit">
                                <SvgIcon fontSize="small">
                                    <RenameIcon/>
                                </SvgIcon>
                            </IconButton>
                        </Tooltip>
                    ),
                    action: openRenameDialog,
                });
            }

            // Download
            tmp.push({
                name: (
                    <Tooltip title={t('common.tooltips.download')}>
                        <IconButton color="inherit">
                            <SvgIcon fontSize="small">
                                <DownloadIcon/>
                            </SvgIcon>
                        </IconButton>
                    </Tooltip>
                ),
                action: downloadFile,
            });

            setActions(tmp);
        } else {
            setActions([]);
        }
    }, [data, onClose, t, deleteCallback]);

    if (!data) {
        return null;
    }

    return (
        <FullscreenWrapper
            closeCallback={onClose}
            open={open}
            actions={actions}
            fullScreen={isMobile}
            title={data.name}
        >
            <FileRenameDialog
                open={renameDialogOpen}
                file={data}
                handleClose={closeRenameDialog}
                onSuccess={handleRename}
            />

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Tabs value={tab} onChange={handleTabChange}>
                        <Tab value="overview" label={t('common.overview')}/>
                        {data?.is_public && (
                            <Tab value="downloads" label={t('attributes.downloads')}/>
                        )}
                        <Tab value="connections" label={t('common.data_graph')}/>
                        <Tab value="chat" label={t('common.ask_questions')} disabled={true}/>
                    </Tabs>
                </Grid>
                <Grid item xs={12} md={5} lg={4}>
                    <Card style={{padding: 0}}>
                        <List>
                            <ListItem disableGutters divider>
                                <Stack direction="row" alignItems="center" spacing={1} sx={{px: 2}}>
                                    <UserAvatar
                                        alt="Person"
                                        user={owner}
                                        component={RouterLink}
                                        to={`/groon/profile/${owner?.id}`}
                                    />
                                    <Stack>
                                        <Typography variant="body1" color="textPrimary">
                                            {owner ? (
                                                <Link
                                                    underline="hover"
                                                    variant="h6"
                                                    component={RouterLink}
                                                    color="textPrimary"
                                                    to={`/groon/profile/${owner.id}`}
                                                >
                                                    {owner.name}
                                                </Link>
                                            ) : (
                                                <Link
                                                    underline="hover"
                                                    variant="h6"
                                                    component={RouterLink}
                                                    color="textPrimary"
                                                >
                                                    {t('common.module')}
                                                </Link>
                                            )}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {owner ? t('attributes.published') : t('attributes.generated')}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </ListItem>
                            <ListItem disableGutters divider sx={{px: 2}}>
                                <ListItemText
                                    primary={data.name}
                                    secondary={t('attributes.name')}
                                    slotProps={{
                                        primaryTypographyProps: {
                                            noWrap: true,
                                            variant: 'body1',
                                            color: 'textPrimary',
                                        },
                                        secondaryTypographyProps: {
                                            variant: 'caption',
                                            color: 'textSecondary',
                                        },
                                    }}
                                />
                            </ListItem>
                            <ListItem disableGutters divider sx={{px: 2}}>
                                <ListItemText
                                    secondary={t('attributes.status')}
                                    primary={data.is_public ? t('common.public') : t('common.private')}
                                    slotProps={{
                                        primaryTypographyProps: {
                                            noWrap: true,
                                            variant: 'body1',
                                            color: 'textPrimary',
                                        },
                                        secondaryTypographyProps: {
                                            variant: 'caption',
                                            color: 'textSecondary',
                                        },
                                    }}
                                />
                            </ListItem>
                            {data.is_public && (
                                <ListItem
                                    disableGutters
                                    divider
                                    sx={{px: 2}}
                                    secondaryAction={
                                        <Box mr={1}>
                                            <IconButton onClick={() => copyToClipboard(data.public_link)}>
                                                <OnIcon iconName="Copy01" size="small"/>
                                            </IconButton>
                                        </Box>
                                    }
                                >
                                    <ListItemText
                                        secondary={t('attributes.url')}
                                        primary={data?.public_link || 'Kein Link vorhanden'}
                                        slotProps={{
                                            primaryTypographyProps: {
                                                noWrap: true,
                                                variant: 'body1',
                                                color: 'textPrimary',
                                            },
                                            secondaryTypographyProps: {
                                                variant: 'caption',
                                                color: 'textSecondary',
                                            },
                                        }}
                                    />
                                </ListItem>
                            )}
                            <ListItem disableGutters divider sx={{px: 2}}>
                                <ListItemText
                                    secondary={owner ? t('attributes.uploaded') : t('attributes.created')}
                                    primary={moment(data.created_at).format('DD.MM.yyyy')}
                                    slotProps={{
                                        primaryTypographyProps: {
                                            noWrap: true,
                                            variant: 'body1',
                                            color: 'textPrimary',
                                        },
                                        secondaryTypographyProps: {
                                            variant: 'caption',
                                            color: 'textSecondary',
                                        },
                                    }}
                                />
                            </ListItem>
                            <ListItem disableGutters sx={{px: 2}}>
                                <ListItemText
                                    secondary={t('attributes.size')}
                                    primary={bytesToSize(data.size)}
                                    slotProps={{
                                        primaryTypographyProps: {
                                            noWrap: true,
                                            variant: 'body1',
                                            color: 'textPrimary',
                                        },
                                        secondaryTypographyProps: {
                                            variant: 'caption',
                                            color: 'textSecondary',
                                        },
                                    }}
                                />
                            </ListItem>
                        </List>
                    </Card>
                    <Box mt={2}>
                        {usage.map((u, i) => (
                            <Fade in={visible[i]} key={i} timeout={APP_SETTING?.transitionDuration || 500}>
                                <Chip
                                    label={u}
                                    color="primary"
                                    variant="contained"
                                    style={{margin: theme.spacing(0.5)}}
                                />
                            </Fade>
                        ))}
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} lg={8}>
                    {tab === 'overview' && (
                        <Card>
                            <CardContent
                                ref={pdfWrapperRef}
                                style={{padding: 0, margin: 0, width: '100%'}}
                            >
                                <FileGenericViewer file={data} pdfWrapperRef={pdfWrapperRef}/>
                            </CardContent>
                        </Card>
                    )}
                    {tab === 'connections' && (
                        <DataGraph
                            kind='files'
                            object={file}
                            height={500}
                            maxDepth={2}
                            initialActiveObjects={['users', 'files', 'projects']}
                        />
                    )}
                    {tab === 'downloads' && (
                        <ShortLinkStats
                            withTitle={false}
                            direction="vertical"
                            selectedIds={[file?.short_link]}
                        />
                    )}
                </Grid>
            </Grid>
        </FullscreenWrapper>
    );
}

FileModal.propTypes = {
    file: PropTypes.object.isRequired,
    deleteCallback: PropTypes.func,
    onUpdate: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
};

export default FileModal;
