import React, {useState} from "react";
import {
    Box,
    CardActionArea,
    Card,
    CardHeader,
    ListItem,
    Stack,
    Typography,
    Avatar,
    Grid, Badge
} from "@mui/material";
import {useTranslation} from "react-i18next";
import OnIcon from "../../../elements/icon";
import SaveButton from "../../../elements/save-button";
import OverviewList from "../../../elements/overview-list";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import IntegrationDialog from "./integration-dialog";
import {useDialog} from "../../../../hooks/use-dialog";
import ConnectorIcon from "../connectors/connector-icon";
import AddIntegrationDialog from "../../core/integrations/add-integration-dialog";
import usePagination from "../../../../hooks/use-pagination";
import { produce } from "immer";

function Integrations() {

    const {t} = useTranslation();
    const [loadingStates, setLoadingStates] = useState({});
    const {get} = useOmniaApi();
    const integrationDialog = useDialog();
    const addIntegrationDialog = useDialog();
    const tokenIntegrations = usePagination({
        endpoint: 'core/token-integrations',
        page: 1,
        pageSize: 100,
    })
    const configIntegrations = usePagination({
        endpoint: 'core/config-integrations',
        page: 1,
        pageSize: 100,
    })

    const handleStartIntegrationProcess = (connector) => {

        if(!connector?.authenticator){
            alert(t('mgt.connectors.no_authenticator'));
        } else {
            if(connector?.authenticator === 'OAuth2'){
                setLoadingStates((prev) => ({ ...prev, [connector?.id]: true }));
                get('/core/connectors/' + connector?.id + '/get_login_url').then(data => {
                    const width = 600;
                    const height = 600;
                    const left = (window.screen.width / 2) - (width / 2);
                    const top = (window.screen.height / 2) - (height / 2);
                    window.open(data['url'], '_blank', `width=${width},height=${height},left=${left},top=${top}`);
                }).finally(() => {
                    setLoadingStates((prev) => ({ ...prev, [connector?.id]: false }));
                })
            } else {
                addIntegrationDialog.handleOpen(connector);
            }

        }

    }

    const rawIntegrations = [].concat(tokenIntegrations?.elements)?.concat(configIntegrations?.elements);

    const integrations = produce(rawIntegrations, (draft) => {
        return draft.sort((a, b) => {

            let value = a?.connector?.name.localeCompare(b?.connector?.name);
            if(value === 0){
                value = a.name.localeCompare(b.name);
            }
            return value;

        });
    })

    return (
        <>
            <AddIntegrationDialog
                open={addIntegrationDialog.open}
                onClose={addIntegrationDialog.handleClose}
                connector={addIntegrationDialog.data}
            />
            <Box>
                <Grid container spacing={2}>
                    {integrations?.map((integration) => (
                        <Grid item xs={6} sm={4} lg={3} key={integration?.id}>
                            <Badge
                                color="error"
                                badgeContent={['inaccessible', 'error', 'about_to_expire']?.includes(integration?.state) ? 1 : 0}
                                sx={{width: '100%'}}
                            >
                                <Card sx={{width: '100%'}}>
                                    <CardActionArea onClick={() => integrationDialog.handleOpen(integration)}>
                                        <Stack
                                            sx={{width: '100%', p: 3}}
                                            direction="column"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                        >
                                            <Avatar
                                                alt='connector-logo'
                                                src={integration?.connector?.logo_ui || null}
                                                sx={{width: 34, height: 34}}
                                            >
                                                <OnIcon iconName={integration?.connector?.icon_ui || 'IntersectCircle'}/>
                                            </Avatar>
                                            <Stack direction="column" alignItems="center">
                                                <Typography variant="h6" align="center" sx={{lineHeight: 1}} noWrap>
                                                    {integration?.connector?.name}
                                                </Typography>
                                                <Typography variant="body1" color="textSecondary" align="center" noWrap>
                                                    {integration?.name}
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </CardActionArea>
                                </Card>
                            </Badge>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <Card>
                <CardHeader
                    title={t('mgt.settings.integrations.title')}
                />
                <OverviewList
                    endpoint='/core/connectors'
                    height='auto'
                    pageSize={10}
                    query={{ordering: 'name', is_integration: true, authenticator__isnull: false}}
                    withSearch={true}
                    renderItem={(integratableConnector) => (
                        <ListItem key={integratableConnector.id} sx={{width: '100%'}}>
                            <Stack
                                sx={{width: '100%', py: 0.5}}
                                direction="row"
                                spacing={2}
                                key={integratableConnector?.id}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <ConnectorIcon connector={integratableConnector}/>
                                <Box flex={1}>
                                    <Typography variant="h6">{integratableConnector?.name}</Typography>
                                    <Typography
                                        variant="body2">{integratableConnector?.subline}</Typography>
                                </Box>
                                <SaveButton
                                    disabled={integratableConnector.is_turned_on === false}
                                    endIcon={<OnIcon iconName="Plus"/>}
                                    variant="outlined"
                                    onClick={() => handleStartIntegrationProcess(integratableConnector)}
                                    label={t('common.add')}
                                    loading={loadingStates[integratableConnector?.id] || false}
                                />
                            </Stack>
                        </ListItem>
                    )}
                />
            </Card>
            <IntegrationDialog
                open={integrationDialog.open}
                integration={integrationDialog.data}
                onClose={integrationDialog.handleClose}
            />
        </>
    )
}

export default Integrations;