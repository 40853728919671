import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {Avatar, Tooltip, Typography, colors, Stack} from '@mui/material';
import {useCreateSrc} from "src/omnia/hooks/use-create-src";
import {useTheme} from "@mui/system";
import OnIcon from "../../elements/icon";


function StackAvatars(props) {

    const {avatars = [], limit = 3, diameter = 35, direction = 'row', ...rest} = props;

    const getSrc = useCreateSrc();
    let lastIndex = 0;
    const theme = useTheme();

    const avatarNodes = avatars.slice(0, limit).map((item) => (
        <Tooltip
            key={++lastIndex}
            title={item?.name}
            placement={direction === 'row' ? 'bottom' : 'right'}
            enterDelay={1000}
        >
            <Avatar
                style={{
                    ...{
                        border: `3px solid ${theme.palette.background.default}`,
                        '&:hover': {
                            zIndex: 2
                        },
                        backgroundColor: theme.palette.neutral[theme.palette.mode === 'light' ? 200 : 600],
                        color: theme.palette.text.primary,
                        height: diameter,
                        width: diameter,
                        cursor: 'pointer'
                    },
                    ...(direction === 'row' ? {marginLeft: -20} : {}),
                    ...(direction === 'column' ? {marginTop: -20} : {})
                }}
                src={getSrc(item?.avatar)}
            >
                <OnIcon iconName="User01" size="small" />
            </Avatar>
        </Tooltip>
    ));

    if (avatars.length > limit) {
        avatarNodes.push(
            <Avatar
                key={++lastIndex}
                style={{
                    ...{
                        border: `3px solid ${theme.palette.background.default}`,
                        '&:hover': {
                            zIndex: 2
                        },
                        backgroundColor: theme.palette.neutral[theme.palette.mode === 'light' ? 200 : 600],
                        color: theme.palette.text.primary,
                        height: diameter,
                        width: diameter
                    },
                    ...(direction === 'row' ? {marginLeft: -20} : {}),
                    ...(direction === 'column' ? {marginTop: -20} : {})
                }}
            >
                <
                    Typography
                    color="inherit"
                    variant="subtitle2"
                >
                    +
                    {avatars.length - limit}
                </Typography>
            </Avatar>
        )
        ;
    }

    return (
        <Stack spacing={1} direction={direction} {...rest}>
            {avatarNodes}
        </Stack>
    );
}

StackAvatars.propTypes = {
    avatars: PropTypes.array,
    className: PropTypes.string,
    direction: PropTypes.string,
    limit: PropTypes.number,
    diameter: PropTypes.number,
};

export default memo(StackAvatars);
