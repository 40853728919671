import React, {useEffect, useState} from "react";
import {
    Avatar,
    Box,
    Chip,
    List,
    Menu,
    ListItem,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    Typography,
    CircularProgress, Switch, Stack
} from "@mui/material";
import {useTranslation} from "react-i18next";
import useApi from "../../../hooks/use-api";
import PropTypes from "prop-types";
import OnIcon from "../icon";
import MultiSectionAutocomplete from "../multisection-autocomplete";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import {useSelector} from "react-redux";

function SharedWithItem({ item, onRemoveItem, disabled = false, onChangeItemAccess }) {
    const { t } = useTranslation();
    const isUser = item.kind === 'user' || item.kind === 'users';
    const displayName = item.entity?.name || 'No Name';
    const displaySubtext = isUser
        ? item.entity?.email || ''
        : t('core.group_' + item.entity?.type);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleChipClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleAccessChange = (newAccess) => {
        handleMenuClose();
        if (newAccess === 'remove') {
            if(window.confirm(t('notify.are_you_sure'))){
                onRemoveItem?.(item)
            }
        } else {
            onChangeItemAccess?.(item, newAccess);
        }
    };

    return (
        <ListItem sx={{ px: 0 }} disabled={disabled}>
            <ListItemAvatar>
                {isUser ? (
                    <Avatar sizes="small" src={item.entity.avatar?.view} alt={displayName} >
                        <OnIcon iconName="User01" />
                    </Avatar>
                ) : (
                    <Avatar sizes="small">
                        <OnIcon iconName="Users01" />
                    </Avatar>
                )}
            </ListItemAvatar>
            <ListItemText
                primary={displayName}
                secondary={displaySubtext}
            />
            {item?.access === 'owner' ? (
                <Chip disabled label={t('core.owner')} />
            ) : (
                <>
                    <Chip
                        label={t(`core.${item.access}`)}
                        onClick={handleChipClick}
                        disabled={disabled}
                        variant="outlined"
                    />
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => handleAccessChange('read')}>
                            {t('core.read')}
                        </MenuItem>
                        <MenuItem onClick={() => handleAccessChange('write')}>
                            {t('core.write')}
                        </MenuItem>
                        <MenuItem onClick={() => handleAccessChange('remove')}>
                            <Typography color="error">
                                {t('common.remove')}
                            </Typography>
                        </MenuItem>
                    </Menu>
                </>
            )}
        </ListItem>
    );
}

function SharingFormContent({ entity, endpoint, onConfigChange, ...rest }) {
    const { t } = useTranslation();
    const [sharingItems, setSharingItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isInitial, setIsInitial] = useState(true);
    const [owner, setOwner] = useState(null);
    const [createdInConfig, setCreatedInConfig] = useState(entity?.created_in);
    const [isPublic, setIsPublic] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const user = useSelector((state) => state.account.user);
    const { get, post } = useApi();
    const { hasRights } = useSecurityCheck();

    const userGroups = user?.user_groups || [];

    const handleGroupSelect = (group) => {
        const oldCreatedIn = createdInConfig;
        setCreatedInConfig(group);
        setAnchorEl(null);

        // Send that info to the server
        post(`${endpoint}/${entity?.id}/update_created_in`, group).catch(() => {
            setCreatedInConfig(oldCreatedIn);
        });

    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const addItem = (kind, selectedEntity) => {
        // Add a new item with default access = "read"
        setSharingItems((prevItems) => {
            const newItems = [...prevItems];
            // Check if item is already in the list
            const existingItem = newItems.find((i) => i.entity.id === selectedEntity.id && i.kind === kind);
            if (!existingItem) {
                newItems.push({
                    kind,
                    entity: selectedEntity,
                    access: 'read',
                });
            }
            return newItems.sort((a, b) => {
                const aName = a.entity?.name || a.entity?.first_name;
                const bName = b.entity?.name || b.entity?.first_name;
                return aName.localeCompare(bName);
            });
        });
    };

    const removeItem = (itemToRemove) => {
        setSharingItems((prevItems) => prevItems.filter((item) => {
            return !(item.entity.id === itemToRemove.entity.id && item.kind === itemToRemove.kind);
        }));
    };

    const changeItemAccess = (itemToChange, newAccess) => {
        setSharingItems((prevItems) => {
            const newItems = prevItems.map((item) => {
                if (item.entity.id === itemToChange.entity.id && item.kind === itemToChange.kind) {
                    return { ...item, access: newAccess };
                }
                return item;
            });
            return newItems.sort((a, b) => {
                const aName = a.entity?.name || a.entity?.first_name;
                const bName = b.entity?.name || b.entity?.first_name;
                return aName.localeCompare(bName);
            });
        });
    };

    useEffect(() => {
        let sharingConfig = {public: isPublic, users: {read: [], write: []}, groups: {read: [], write: []}};
        for(let i = 0; i < sharingItems.length; i++){
            sharingConfig[sharingItems[i].kind][sharingItems[i].access].push(sharingItems[i].entity.id);
        }
        if(!isInitial)
            onConfigChange?.(sharingConfig)
    }, [isPublic, sharingItems]);

    useEffect(() => {
        if (entity) {
            setLoading(true);
            get(`${endpoint}/${entity?.id}/sharing_config`)
                .then((data) => {

                    // Set owner
                    setOwner({
                        kind: 'users',
                        entity: data.owner,
                        access: 'owner'
                    });

                    // Convert sharingStats structure into unified sharingItems array
                    const combinedItems = [];
                    if (data?.users) {
                        data.users.read?.forEach((user) => {
                            combinedItems.push({
                                kind: 'users',
                                entity: user,
                                access: 'read'
                            });
                        });
                        data.users.write?.forEach((user) => {
                            combinedItems.push({
                                kind: 'users',
                                entity: user,
                                access: 'write'
                            });
                        });
                    }
                    if (data?.groups) {
                        data.groups.read?.forEach((group) => {
                            combinedItems.push({
                                kind: 'groups',
                                entity: group,
                                access: 'read'
                            });
                        });
                        data.groups.write?.forEach((group) => {
                            combinedItems.push({
                                kind: 'groups',
                                entity: group,
                                access: 'write'
                            });
                        });
                    }

                    const sortedItems = combinedItems.sort((a, b) => {
                        const aName = a.entity?.name || a.entity?.first_name;
                        const bName = b.entity?.name || b.entity?.first_name;
                        return aName.localeCompare(bName);
                    });

                    setIsPublic(data.public);
                    setSharingItems(sortedItems);
                })
                .finally(() => {
                    setLoading(false);
                    setTimeout(() => {
                        setIsInitial(false);
                    }, 500);
                });
        }
    }, [entity?.id, endpoint]);

    return (
        <Box {...rest}>
            <Stack
                onClick={handleMenuOpen}
                spacing={0.5}
                alignItems="center"
                direction="row"
                sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    cursor: "pointer",
                    color: "textSecondary",
                    "&:hover": { textDecoration: "underline" },
                    mb: 2,
                }}
            >
                <Typography variant="body1" color="textPrimary" component="span">
                    {createdInConfig ? t("common.created_in_group", {group: createdInConfig?.name,}) : t("common.not_created_in")}
                </Typography>
                <OnIcon iconName="ChevronDown" size="small" />
            </Stack>

            <MultiSectionAutocomplete
                config={[
                    {
                        kind: "users",
                        label: t("attributes.users"),
                        labelField: "name",
                        endpoint: "core/users",
                        query: { id__not_in: [owner?.entity.id, ...sharingItems.filter(i => i.kind === 'users').map((i) => i.entity.id)] }
                    },
                    {
                        kind: "groups",
                        label: t("core.groups"),
                        labelField: "name",
                        endpoint: "core/user-groups",
                        query: { id__not_in: sharingItems.filter(i => i.kind === 'groups').map((i) => i.entity.id) }
                    }
                ]}
                placeholder={t("core.add_people_or_groups")}
                onSelect={addItem}
            />
            {loading ? (
                <div
                    style={{
                        height: 240,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <Box mt={3}>

                    {hasRights('share_users_groups') && (
                        <Box>
                            <Typography variant="h6" ml={1}>
                                {t('core.people_with_access')}
                            </Typography>
                            <List>
                                {owner && (
                                    <SharedWithItem
                                        item={owner}
                                    />
                                )}
                                {sharingItems.map((item) => (
                                    <SharedWithItem
                                        key={`${item.kind}-${item.entity.id}`}
                                        item={item}
                                        onRemoveItem={removeItem}
                                        onChangeItemAccess={changeItemAccess}
                                    />
                                ))}
                            </List>
                        </Box>
                    )}

                    {hasRights('share_globally') && (
                        <Box>
                            <Typography variant="h6" ml={1} mt={3}>
                                {t('core.general_access')}
                            </Typography>
                            <List>
                                <ListItem sx={{ px: 0 }}>
                                    <ListItemAvatar>
                                        <Avatar sizes="small">
                                            <OnIcon iconName="Building07" />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={t('core.general_access_title')}
                                        secondary={t('core.general_access_info')}
                                    />
                                    {isPublic === null ? (
                                        <CircularProgress size={16} />
                                    ) : (
                                        <Switch
                                            checked={isPublic}
                                            onChange={(e) => setIsPublic(e.target.checked)}
                                        />
                                    )}
                                </ListItem>
                            </List>
                        </Box>
                    )}
                </Box>
            )}

            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                {userGroups?.map((group) => (
                    <MenuItem key={group.id} onClick={() => handleGroupSelect(group)}>
                        {group.name}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    );
}

SharingFormContent.propTypes = {
    entity: PropTypes.object.isRequired,
    endpoint: PropTypes.string.isRequired,
    onConfigChange: PropTypes.func,
}

SharedWithItem.propTypes = {
    item: PropTypes.object.isRequired,
    onRemoveItem: PropTypes.func,
    onChangeItemAccess: PropTypes.func,
    disabled: PropTypes.bool,
}

export default SharingFormContent;
