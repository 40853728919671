import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {MenuItem, Stack} from "@mui/material";
import usePopover from "../../../../hooks/use-popover";
import PropTypes from "prop-types";
import AssistantLogo from "../assistants/assistant-logo";
import AssistantTitle from "../assistants/assistant-title";

const AssistentMenuItem = (props) => {

    const {
        assistant,
        onSelect,
        checked,
        disableUnavailable = true,
        dense = false,
        mode = 'click',
        ...rest
    } = props;

    const popover = usePopover(true);
    const { isMobile } = useIsMobile();

    return (
        <>
            <MenuItem
                key={assistant.id}
                onClick={() => onSelect(assistant)}
                disabled={disableUnavailable && !assistant?.llm?.is_accessible}
                onMouseEnter={() => {
                    if(!isMobile) {
                        popover.handleOpen();
                        popover.queueOpen();
                    }
                }}
                onMouseLeave={() => {
                    if(!isMobile){
                        popover.queueClose();
                    }
                }}
                ref={popover.anchorRef}
                sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', minHeight: isMobile ? 22 : 'auto', py: dense ? 0.5 : 1, px: 1}}
                {...rest}
            >

                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    sx={{width: '100%'}}
                >
                    <AssistantLogo assistant={assistant} />
                    <AssistantTitle assistant={assistant} justifyContent="space-between" />
                </Stack>

                {/*{(!hovered && checked) && (*/}
                {/*    <Checkbox checked={true} />*/}
                {/*)}*/}

            </MenuItem>
        </>
    )
}

AssistentMenuItem.propTypes = {
    assistant: PropTypes.object,
    onSelect: PropTypes.func,
    checked: PropTypes.bool,
    dense: PropTypes.bool,
    mode: PropTypes.oneOf(['switch', 'click']),
    disableUnavailable: PropTypes.bool,
}

export default AssistentMenuItem;