import React, { useState, useEffect } from 'react';
import { Button, CircularProgress, Grid, Hidden, Typography } from '@mui/material';
import { Document, Page, pdfjs } from 'react-pdf';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";

pdfjs.GlobalWorkerOptions.workerSrc = `/assets/js/pdf.worker.js`;

function PdfViewer({ rawFile, pdfWrapperRef, ...rest }) {
    const [numPages, setNumPages] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [pdfViewerWidth, setPdfWidth] = useState(100);
    const { t } = useTranslation();

    useEffect(() => {
        if (pdfWrapperRef && pdfWrapperRef.current) {
            setPdfWidth(parseInt(pdfWrapperRef.current.offsetWidth));
        }
    }, [pdfWrapperRef]);

    const onDocumentLoadSuccess = ({ numPages: loadedNumPages }) => {
        setNumPages(loadedNumPages);
    };

    const handlePageDown = () => setCurrentPage((prev) => prev - 1);
    const handlePageUp = () => setCurrentPage((prev) => prev + 1);

    if (rawFile === null) {
        return (
            <div style={{ height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div style={{ height: 'auto', minHeight: 500, width: '100%' }} {...rest}>
            <Document
                file={rawFile}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={console.error}
                style={{ height: '100%' }}
                error={(
                    <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="caption">
                            {t('common.download_to_view')}
                        </Typography>
                    </div>
                )}
                renderMode="canvas"
                loading={(
                    <div style={{ height: '100%', minHeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress />
                    </div>
                )}
            >
                <Page
                    width={pdfViewerWidth}
                    pageNumber={currentPage}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                />
            </Document>

            <Grid container spacing={2} sx={{ p: 3 }}>
                <Grid item xs={6} sm={4}>
                    <Button disabled={currentPage === 1} onClick={handlePageDown} variant="text" size="small">
                        {t('common.previous')}
                    </Button>
                </Grid>
                <Hidden only="xs">
                    <Grid item xs={6} sm={4} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Typography variant="caption">
                            {t('common.site_of', { site: currentPage, total: numPages })}
                        </Typography>
                    </Grid>
                </Hidden>
                <Grid item xs={6} sm={4} style={{ textAlign: 'right' }}>
                    <Button disabled={currentPage === numPages} onClick={handlePageUp} variant="text" size="small">
                        {t('common.next')}
                    </Button>
                </Grid>
            </Grid>
        </div>
    );
}

PdfViewer.propTypes = {
    rawFile: PropTypes.object,
    pdfWrapperRef: PropTypes.object,
};

export default PdfViewer;
