import React from 'react';
import {Grid} from '@mui/material';
import ChangePassword from "./ChangePassword";
import Sessions from "./Sessions";

export default function Security({ ...rest }) {
    return (
        <Grid container spacing={2} {...rest}>
            <Grid item xs={12}>
                <ChangePassword />
            </Grid>
            <Grid item xs={12}>
                <Sessions />
            </Grid>
        </Grid>
    )
}
