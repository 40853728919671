import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import useApi from "src/omnia/hooks/use-api";
import {Autocomplete, Button, Chip, Grid, TextField, Typography} from "@mui/material";
import {useNotifications} from "src/omnia/hooks/use-notifications";
import { NumericFormat } from "react-number-format";
import {Alert} from "@mui/lab";
import useTmsFormik from "src/omnia/hooks/formik/use-tms-formik";
import {useTranslation} from "react-i18next";
import SaveButton from "../../../elements/save-button";

function ShippingZone({onDone, onRemove, countries = [], zone = null}){

    const { t } = useTranslation();
    const [ autoKey, setAutoKey ] = useState(0);
    const { put, del } = useApi();
    const { notify, notifySuccess } = useNotifications();
    const zoneRef = useRef();
    zoneRef.current = zone;

    const handleSubmit = (values, helpers) => {
        helpers.setSubmitting(true);
        put('tms/shipping_zones', values).then((data) => {
            notifySuccess(t);
            if(onDone)
                onDone(data);
        }).finally(() => {
            helpers.setSubmitting(false);
        });
    }

    const handleDelete = () => {
        if(window.confirm(t('notify.are_you_sure'))){
            del('tms/shipping_zones', zoneRef.current?.id).then(() => {
                notifySuccess(t);
                if(onRemove)
                    onRemove(zoneRef.current?.id);
            });
        }
    }

    const handleAddCountry = (name, id) => {
        if(shippingZoneFormik.values.countries.filter(c => c.id === id).length === 0){
            const updatedCountries = shippingZoneFormik.values.countries.concat([id]);
            shippingZoneFormik.setFieldValue('countries', updatedCountries)
        } else {
            notify(t('notify.already_added'), "error");
        }
        setAutoKey(prev => prev + 1);
    }

    const handleCountryRemoval = (id) => {
        const updatedCountries = shippingZoneFormik.values.countries.filter(c => c !== id);
        shippingZoneFormik.setFieldValue('countries', updatedCountries);
    }

    const { shippingZoneFormik } = useTmsFormik(zone, handleSubmit, true);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Alert severity="info">
                    {t('resources.sales.shipping_info')}
                </Alert>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('attributes.name')}
                    name="name"
                    value={shippingZoneFormik.values.name}
                    placeholder={t('resources.sales.name_placeholder')}
                    error={Boolean(shippingZoneFormik.touched.name && shippingZoneFormik.errors.name)}
                    fullWidth
                    helperText={shippingZoneFormik.touched.name && shippingZoneFormik.errors.name}
                    onBlur={shippingZoneFormik.handleBlur}
                    onChange={shippingZoneFormik.handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <NumericFormat
                    value={parseFloat(shippingZoneFormik.values.free_shipping_after) / 100}
                    error={Boolean(shippingZoneFormik.touched.free_shipping_after && shippingZoneFormik.errors.free_shipping_after)}
                    helperText={shippingZoneFormik.touched.free_shipping_after && shippingZoneFormik.errors.free_shipping_after}
                    onBlur={shippingZoneFormik.handleBlur}
                    fixedDecimalScale={false}
                    fullWidth
                    label={t('resources.sales.free_shipping_from')}
                    name="free_shipping_after"
                    isNumericString
                    placeholder={t('resources.sales.leave_empty_if_not_applicable')}
                    customInput={TextField}
                    suffix={'€'}
                    decimalScale={2}
                    type="text"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    onValueChange={({ floatValue: v }) => {
                        shippingZoneFormik.setFieldValue('free_shipping_after', typeof v === 'undefined' ? null : parseInt(v * 100))
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <NumericFormat
                    value={parseFloat(shippingZoneFormik.values.max_allowed_order_volume) / 100}
                    error={Boolean(shippingZoneFormik.touched.max_allowed_order_volume && shippingZoneFormik.errors.max_allowed_order_volume)}
                    helperText={shippingZoneFormik.touched.max_allowed_order_volume && shippingZoneFormik.errors.max_allowed_order_volume}
                    fixedDecimalScale={false}
                    fullWidth
                    label={t('resources.sales.max_online_order_volume')}
                    name="max_allowed_order_volume"
                    isNumericString
                    customInput={TextField}
                    suffix={'€'}
                    decimalScale={2}
                    type="text"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    onValueChange={({ value: v }) => {
                        shippingZoneFormik.setFieldValue('max_allowed_order_volume', typeof v === 'undefined' ? null : parseInt(v * 100))
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <Autocomplete
                    options={countries}
                    key={autoKey}
                    filterOptions={(opt,val ) => {
                        return opt.filter(o => o.name.toLowerCase().includes(val.inputValue.toLowerCase()));
                    }}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, selection) => {
                        if(selection){
                            handleAddCountry(selection.name, selection.code);
                        }
                    }}
                    fullWidth={true}
                    onKeyDown={(e) => {
                        // wait for enter
                        if(e.keyCode === 13){
                            let results = countries.filter(c => c.name === e.target.value);
                            if(results.length === 1){
                                handleAddCountry(results[0].name, results[0].code);
                            } else {
                                if(results.length > 1){
                                    notify(t('common.please_select_from_list'), "error");
                                }
                            }
                        }
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t('resources.sales.new_country')}
                            error={Boolean(shippingZoneFormik.touched.countries && shippingZoneFormik.errors.countries)}
                            helperText={shippingZoneFormik.errors.countries ? shippingZoneFormik.errors.countries : t('common.enter_to_add')}
                        />
                    )}
                />
            </Grid>
            <Grid item xs={12} pt={0} pb={2}>
                {shippingZoneFormik.values?.countries?.length === 0 ? (
                    <div style={{height: 25, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                        <Typography variant="body2">
                            {t('resources.sales.no_countries_selected')}
                        </Typography>
                    </div>
                ) : (
                    <>
                        {shippingZoneFormik.values?.countries?.map((countryCode, i) => (
                            <Chip
                                key={'attribute-' + i}
                                color="default"
                                sx={{mr: 1, mt: 1}}
                                onDelete={() => handleCountryRemoval(countryCode)}
                                label={countries.find(c => c.code === countryCode)?.name || countryCode}
                            />
                        ))}
                    </>
                )}
            </Grid>
            <Grid item xs={12} sx={{mt: 4}}>
                <Grid container spacing={3}>
                    {zone && (
                        <Grid item xs={6}>
                            <Button
                                sx={{mr: 2}}
                                fullWidth
                                onClick={handleDelete}
                                disabled={shippingZoneFormik.isSubmitting}
                                variant="outlined"
                                color="error"
                            >
                                {t('resources.sales.delete_zone')}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={zone ? 6 : 12}>
                        <SaveButton
                            // fullWidth
                            // onClick={shippingZoneFormik.handleSubmit}
                            // loading={shippingZoneFormik.isSubmitting}
                            // variant="contained"
                            // color="primary"
                            // label={zone ? t('common.save') : t('common.add')}
                            // noIcon
                            formik={shippingZoneFormik}
                        />
                    </Grid>
                </Grid>
            </Grid>
            {/*<Grid item xs={12}>*/}
            {/*    <Button*/}
            {/*        disabled={shippingZoneFormik.isSubmitting}*/}
            {/*        color="primary"*/}
            {/*        variant="contained"*/}
            {/*        onClick={shippingZoneFormik.handleSubmit}*/}
            {/*    >*/}
            {/*        {t('common.save')}*/}
            {/*    </Button>*/}
            {/*</Grid>*/}
        </Grid>
    )
}

ShippingZone.propTypes = {
    onDone: PropTypes.func,
    countries: PropTypes.array,
    zone: PropTypes.object
}

export default ShippingZone;