import chroma from "chroma-js";
import {alpha} from "@mui/material/styles";

const withAlphas = (color) => {
    return {
        ...color,
        alpha4: alpha(color.main, 0.04),
        alpha8: alpha(color.main, 0.08),
        alpha12: alpha(color.main, 0.12),
        alpha30: alpha(color.main, 0.30),
        alpha50: alpha(color.main, 0.50)
    };
};

export default function createColorPalette(color){

    let lightFont = "#FFFFFF";
    let darkFont = "#090909";

    let pl = chroma(color).brighten().hex();
    let pll = chroma(pl).brighten().hex();
    let pd = chroma(color).darken().hex();
    let pdd = chroma(pd).darken().hex();
    let ct = chroma.deltaE(color, lightFont) > chroma.deltaE(color, darkFont) ? lightFont : darkFont;

    return withAlphas({
        lightest: pll,
        light: pl,
        main: color,
        dark: pd,
        darkest: pdd,
        contrast_text: ct
    });

};