import React from "react";
import {Button, IconButton, MenuItem, Stack, Tooltip, Typography} from "@mui/material";
import {useDialog} from "../../../hooks/use-dialog";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import SharingDialog from "./sharing-dialog";
import OnIcon from "../icon";
import {useSecurityCheck} from "../../../hooks/use-security-check";

function ShareButton(props){

    const {
        entity,
        endpoint,
        onOpen = null,
        variant = 'menu-item',
        onClose = null,
        withIcon = false,
        tooltipPlacement = 'left'
    } = props;

    const { t } = useTranslation();
    const dialog = useDialog();
    const { hasRights } = useSecurityCheck();

    const handleOpenDialog = (event) => {
        if(event){
            event.preventDefault();
            event.stopPropagation();
        }
        dialog.handleOpen(entity);
        onOpen?.();
    }

    const handleCloseDialog = (event) => {
        if(event){
            event.preventDefault();
            event.stopPropagation();
        }
        dialog.handleClose();
        onClose?.();
    }

    if(!entity)
        return null;

    if(!entity?.is_owner || !hasRights(['share_users_groups', 'share_globally'], 'or'))
        return null;

    return (
        <>
            <Tooltip title={t('common.tooltips.share_object', {name: entity?.object_name})} placement={tooltipPlacement}>
                {variant === 'button' && (
                    <Button
                        startIcon={<OnIcon iconName="Share02" /> }
                        variant="contained"
                        color="primary"
                        onClick={dialog.handleOpen}
                    >
                        {t('common.share')}
                    </Button>
                )}
                {variant === 'menu-item' && (
                    <MenuItem
                        variant="contained"
                        color="primary"
                        onClick={handleOpenDialog}
                    >
                        <Stack direction="row" alignItems="center" spacing={2}>
                            {withIcon && (
                                <OnIcon iconName="Share02" size="small" />
                            )}
                            <Typography>
                                {t('common.use_together')}
                            </Typography>
                        </Stack>
                    </MenuItem>
                )}
                {variant === 'icon-button' && (
                    <IconButton
                        onClick={handleOpenDialog}
                    >
                        <Stack direction="row" alignItems="center" spacing={2}>
                            <OnIcon iconName="Share02" size="small" />
                        </Stack>
                    </IconButton>
                )}
            </Tooltip>
            <SharingDialog
                open={dialog.open}
                onClose={handleCloseDialog}
                entity={entity}
                endpoint={endpoint}
            />
        </>
    )
}

ShareButton.propTypes = {
    entity: PropTypes.object.isRequired,
    endpoint: PropTypes.string.isRequired,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    tooltipPlacement: PropTypes.string,
    withIcon: PropTypes.bool,
    variant: PropTypes.oneOf(['button', 'menu-item', 'icon-button'])
}

export default ShareButton;