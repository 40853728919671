import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Plus as PlusIcon} from "src/omnia/icons/plus"
import {Box, Button, OutlinedInput, Link, Typography, SvgIcon} from '@mui/material';
import {updateCard} from 'src/omnia/store/actions/kanban-actions';
import { useTheme } from '@mui/system';
import useApi from "src/omnia/hooks/use-api";
import {useNavigate} from "react-router-dom";

function CardAdd({listId, onChangeExpanded, ...rest}) {

    const dispatch = useDispatch();
    const theme = useTheme();
    const boardId = useSelector(state => state.kanban.boardId);
    const [isExpanded, setExpanded] = useState(false);
    const { post } = useApi();
    const navigate = useNavigate();
    const [name, setName] = useState('');

    const handleChange = (event) => {
        event.persist();
        setName(event.target.value);
    };

    const handleAddInit = () => {
        setExpanded(true);
    };

    const handleAddCancel = () => {
        setExpanded(false);
        setName('');
    };

    const handleAddConfirm = () => {
        post('pm/project-board-lists/' + listId + '/cards', {list: listId, name: (name || 'Neue Karte')}).then(card => {
            dispatch(updateCard(boardId, card));
            setExpanded(false);
            setName('');

            // Navigate to the same URL but with #{cardId} at the end
            navigate(window.location.pathname + '#' + card.id);
        });
    };

    useEffect(() => {
        if(onChangeExpanded)
            onChangeExpanded(isExpanded);
    }, [isExpanded]);

    return (
        <div {...rest}>
            {isExpanded
                ? (
                    <>
                        <OutlinedInput
                            autoFocus
                            fullWidth
                            placeholder="Neues Ticket"
                            name="cardName"
                            onKeyDown={(e) => {
                                if(e.keyCode === 13){
                                    handleAddConfirm();
                                }
                            }}
                            onChange={handleChange}
                            sx={{
                                backgroundColor: 'backgroundPaper',
                                '& .MuiInputBase-input': {
                                    px: 2,
                                    py: 1
                                },
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: theme.palette.grey[400]
                                }
                            }}
                            value={name}
                        />
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex',
                                mt: 2
                            }}
                        >
                            <Button
                                onClick={handleAddConfirm}
                                size="small"
                                startIcon={(
                                    <SvgIcon fontSize="small">
                                        <PlusIcon />
                                    </SvgIcon>
                                )}
                                variant="contained"
                            >
                                Hinzufügen
                            </Button>
                            <Button
                                onClick={handleAddCancel}
                                size="small"
                                sx={{ml: 2}}
                            >
                                Abbrechen
                            </Button>
                        </Box>
                    </>
                )
                : (
                    <Link
                        onClick={handleAddInit}
                        sx={{
                            alignItems: 'center',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'flex-start'
                        }}
                        underline="none"
                    >
                        <PlusIcon sx={{color: 'action.active'}}/>
                        <Typography
                            color="textSecondary"
                            variant="subtitle1"
                        >
                            Ticket hinzufügen
                        </Typography>
                    </Link>
                )}
        </div>
    );
}

CardAdd.propTypes = {
    listId: PropTypes.string.isRequired,
    onChangeExpanded: PropTypes.func
};

export default CardAdd;
