import React from "react";
import {Box, Fade, IconButton, Tooltip} from '@mui/material';
import {useSecurityCheck} from "../../../../hooks/use-security-check";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import OnIcon from "../../../elements/icon";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {APP_SETTING} from "../../../../../setup";
import PropTypes from "prop-types";

export const CreatedMediaButton = ({unmount = false}) => {

    const { isFlying } = useIsMobile();
    const { t } = useTranslation();
    const { hasRights } = useSecurityCheck();
    const navigate = useNavigate();
    const location = useLocation();

    const openCreatedMedia = () => {
        navigate('/groon/id/created-media');
    }

    const show = location.pathname?.includes('groon/id/') && hasRights('creative_studio');

    if(!show && unmount)
        return null;

    return (
        <Fade in={show} timeout={APP_SETTING?.transitionDuration || 500}>
            <Box>
                <Tooltip enterDelay={1000} title={t("core.open_created_media")} placement={isFlying ? 'left' : 'bottom'}>
                    <IconButton onClick={openCreatedMedia}>
                        <OnIcon iconName="Bookmark" />
                    </IconButton>
                </Tooltip>
            </Box>
        </Fade>
    );
};

CreatedMediaButton.propTypes = {
    unmount: PropTypes.bool
}