import React, { createContext, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { produce } from "immer";

// Create the Context
export const AppModeContext = createContext();

// Create the Provider Component
export const AppModeProvider = ({ children }) => {

    const location = useLocation();

    const [isBackend, setIsBackend] = useState(location.pathname !== '/');
    const [isService, setIsService] = useState(false);
    const [service, setService] = useState(null);
    const [serviceTheme, setServiceTheme] = useState(null);
    const [backendTheme, setBackendTheme] = useState(null);
    const [websiteTheme, setWebsiteTheme] = useState(null);
    const [loadingElements, setLoadingElements] = useState([]);
    const isWebsite = useMemo(() => !isService && !isBackend, [isService, isBackend]);

    const addLoadingElement = (element) => {
        setLoadingElements(prev => produce(prev, draft => {
            if (!draft.includes(element)) {
                draft.push(element);
            }
        }));
    }

    const removeLoadingElement = (element) => {
        setLoadingElements(prev => prev.filter(e => e !== element));
    }

    // If any component is loading, move GROON into the loading state
    const loading = loadingElements.length > 0;

    // Value to be passed to the context
    const value = useMemo(() => ({
        loading,
        isService, isWebsite, isBackend, setIsService, setIsBackend,
        service, setService,
        serviceTheme, setServiceTheme, backendTheme, setBackendTheme, websiteTheme, setWebsiteTheme,
        addLoadingElement, removeLoadingElement
    }), [
        loading, isService, isWebsite, isBackend, service, serviceTheme, backendTheme, websiteTheme
    ]);

    return (
        <AppModeContext.Provider value={value}>
            {children}
        </AppModeContext.Provider>
    );
};

export const AppModeConsumer = AppModeContext.Consumer;
