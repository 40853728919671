import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    Link,
    Typography
} from "@mui/material";
import useApi from "src/omnia/hooks/use-api";
import ShippingZone from "./shipping-zone";
import ShippingMap from "./shipping-map";
import {Link as RouterLink} from "react-router-dom";
import ParcelOptions from "./parcel-options";
import { NumericFormat } from "react-number-format";
import ServerSideDataTable from "../../../elements/tables/server-side-data-table";
import {useTranslation} from "react-i18next";
import {useSecurityCheck} from "../../../../hooks/use-security-check";
import { produce } from "immer";


export default function DeliveryOptions() {

    const {t} = useTranslation();
    const [addOpen, setAddOpen] = useState(false);
    const [zones, setZones] = useState([]);
    const [currentEdit, setCurrentEdit] = useState(null);
    const {get, del} = useApi();
    const {hasRights} = useSecurityCheck();
    const [countries, setCountries] = useState([]);
    const [geo, setGeo] = useState({});

    const handleEditZone = (zone) => {
        setCurrentEdit(zone);
    }

    const handleOpenAdd = () => {
        setAddOpen(true);
    }

    const handleClose = () => {
        setAddOpen(false);
        setCurrentEdit(null);
    }

    const loadWorldMap = () => {
        get('tms/shipping_zones/world_map').then(data => {
            setGeo(data);
        })
    }

    const loadShippingZones = () => {
        get('tms/shipping_zones').then(data => {
            setZones(data);
        })
    }

    const loadCountries = () => {
        get('visitor/countries').then(data => {
            setCountries(data);
        });
    }

    const handleDone = (zone) => {
        loadWorldMap();
        handleClose();
        setZones(prev => produce(prev, draft => {
            let index = draft.findIndex(z => z.id === zone.id);
            if (index !== -1) {
                draft[index] = zone;
            } else {
                draft.push(zone);
            }
        }));
    }

    const handleRemove = (zoneId) => {
        loadWorldMap();
        handleClose();
        setZones(prev => prev.filter(z => z.id !== zoneId));
    }

    useEffect(() => {
        loadWorldMap();
        loadShippingZones();
        loadCountries();
    }, []);

    let columns = [];
    columns.push({
        field: 'name', type: 'string', headerName: t('attributes.name'), width: 225, renderCell: (data) => (
            <Link underline="hover" component={RouterLink} onClick={() => handleEditZone(data.row)} variant="h6"
                  style={{fontSize: 16}} color="textPrimary">
                {data.row.name}
            </Link>
        )
    });
    columns.push({
        field: 'free_shipping_after',
        type: 'string',
        headerName: t('resources.sales.free_shipping_from'),
        width: 250,
        renderCell: (data) => {
            if (data.row.free_shipping_after === null) {
                return (
                    <Typography variant="body1">
                        {t('mgt.index.deactivated')}
                    </Typography>
                )
            } else {
                return (
                    <Typography variant="body1">
                        <NumericFormat
                            decimalScale={2}
                            value={parseFloat(data.row.free_shipping_after) / 100}
                            fixedDecimalScale={false}
                            displayType={'text'}
                            thousandSeparator={'.'}
                            decimalSeparator={','}
                            suffix={' €'}
                        />
                    </Typography>
                )
            }
        }
    });
    columns.push({
        field: 'max_allowed_order_volume',
        type: 'number',
        align: 'left',
        headerAlign: 'left',
        sortable: true,
        headerName: t('resources.sales.max_online_order_volume'),
        width: 250,
        valueGetter: (data) => parseFloat(data) / 100,
        renderCell: (data) => {
            return (
                <Typography variant="body1">
                    <NumericFormat
                        decimalScale={2}
                        value={parseFloat(data.row.max_allowed_order_volume) / 100}
                        fixedDecimalScale={false}
                        displayType={'text'}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        suffix={' €'}
                    />
                </Typography>
            )
        }
    });

    return (
        <Box>
            <Grid container alignItems="stretch" spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardHeader title={t('resources.sales.delivery_regions')}
                                    subheader={t('resources.sales.map_countries_colored')}/>
                        <Box sx={{p: 2, display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                            <ShippingMap zones={zones} data={geo}/>
                        </Box>
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Card style={{
                        display: 'flex',
                        alignContent: 'flex-start',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        width: '100%'
                    }}>
                        <CardHeader
                            title={t('resources.sales.sales_regions')}
                            subheader={t('resources.sales.group_for_zones')}
                            action={(
                                <Button
                                    color="secondary"
                                    size="small"
                                    onClick={() => !(addOpen || currentEdit) ? handleOpenAdd() : handleClose()}
                                >
                                    {(addOpen || currentEdit) ? t('common.cancel') : t('common.add')}
                                </Button>
                            )}
                        />
                        {(addOpen || currentEdit) ? (
                            <CardContent sx={{pt: 0}}>
                                <ShippingZone
                                    countries={countries}
                                    zone={currentEdit}
                                    onDone={handleDone}
                                    onRemove={handleRemove}
                                />
                            </CardContent>
                        ) : (
                            <ServerSideDataTable
                                noCard
                                noHeader
                                endpoint='tms/shipping_zones'
                                id='shipping-zones'
                                canRemove={hasRights('tms_settings')}
                                defaultState={[
                                    {
                                        field: 'name',
                                        pinned: 'left',
                                        width: 200,
                                        sorted: 'asc'
                                    },
                                    {
                                        field: 'free_shipping_after',
                                        pinned: null,
                                        width: 150,
                                        sorted: null
                                    },
                                    {
                                        field: 'max_allowed_order_volume',
                                        pinned: null,
                                        width: 250,
                                        sorted: null
                                    },
                                ]}
                                columns={columns}
                            />
                        )}
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <ParcelOptions zones={zones}/>
                </Grid>
            </Grid>
        </Box>
    )
}