import React from "react";
import {Avatar} from "@mui/material";
import OnIcon from "../../../elements/icon";
import {useTheme} from "@mui/system";

function ConnectorIcon({connector}){
    const theme = useTheme();

    return (
        <>
            {connector.logo_ui ? (
                <Avatar
                    src={connector.logo_ui}
                    sx={{height: 40, width: 40, borderRadius: 1, border: 'solid', borderColor: theme.palette.divider}}
                    variant="circular"
                />
            ) : connector.icon_ui && (
                <Avatar
                    sx={{height: 40, width: 40, borderRadius: 1, border: 'solid', borderColor: theme.palette.divider, backgroundColor: theme.palette.background.paper}}
                    variant="circular"
                >
                    <OnIcon
                        iconName={connector.icon_ui}
                        sx={{color: theme.palette.text.primary}}
                    />
                </Avatar>
            )}
        </>
    )
}

export default ConnectorIcon;