import React from "react";
import {memo, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useOmniaApi from "../../../../../hooks/use-omnia-api";
import {useTheme} from "@mui/system";
import formatNumber from "../../../../../utils/format-number";
import moment from "moment/moment";
import {Box, CircularProgress, IconButton, Link, Menu, MenuItem, Stack, Typography} from "@mui/material";
import {Chart} from "../../../../elements/chart";
import PropTypes from "prop-types";
import OnIcon from "../../../../elements/icon";

const KPIChart = memo(function KPIChart({kpi, withTitle = false, small = false, height = 200}) {
    const {t} = useTranslation();
    const {get} = useOmniaApi();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selection, setSelection] = useState((kpi?.datapoint_count || 0) > 200 ? 'daily_avg' : 'intraday');
    const theme = useTheme();

    const options = {
        chart: {
            id: 'basic-line',
            title: {
                show: false
            },
            zoom: {
                enabled: false,
                type: 'x',
                autoScaleYaxis: true,
            },
            sparkline: {
                enabled: small
            },
            type: 'line',
            toolbar: {
                show: false,
            },
            background: 'transparent',
            animations: { enabled: false },
        },
        dataLabels: {
            enabled: false
        },
        grid: {
            borderColor: theme.palette.divider,
            strokeDashArray: 2,
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        xaxis: {
            type: 'datetime',
            labels: {
                show: false
            }
        },
        yaxis: {
            show: !small,
            tickAmount: 2,
            labels: {
                formatter: function (val) {
                    if (val >= 1e6) return (val / 1e6).toFixed(2) + 'm';
                    else if (val >= 1e3) return (val / 1e3).toFixed(2) + 'k';
                    else return val.toFixed(0);
                },
            },
        },
        legend: {
            show: false,
            position: 'top',
        },
        markers: {
            strokeColors: theme.palette.background.paper,
            size: 0
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        theme: {
            mode: theme.palette.mode
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    if (typeof (val) !== "undefined") {
                        return formatNumber({
                            number: val,
                            decimals: kpi.decimal_places,
                            suffix: kpi.suffix,
                            abbreviate: true
                        });
                    } else {
                        return ""
                    }
                }
            },
            x: {
                formatter: function (val) {
                    let format = selection === 'intraday' ? 'HH:mm:ss' : 'DD.MM.YYYY';
                    if (typeof (val) !== "undefined") {
                        return moment(new Date(val)).format(format)
                    } else {
                        return ""
                    }
                }
            }
        },
        colors: [theme.palette.primary.main],
    };

    options.series = [{
        name: kpi.visual_name,
        data: data.sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime()).map(dp => ({
            x: (new Date(dp.x)).getTime(),
            y: parseFloat(dp.y),
        }))
    }];

    useEffect(() => {
        setLoading(true);
        get('core/kpis/' + kpi?.id + '/values', {option: selection}).then((response) => {
            setData(response);
        }).finally(() => {
            setLoading(false);
        })
    }, [selection, kpi?.id]);

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (value) => {
        setAnchorEl(null);
        if (value) setSelection(value);
    };

    return (
        <>
            <Stack
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                spacing={2}
            >
                <Stack direction="column">
                    <Stack direction="row" alignItems="center" spacing={2}>
                        {withTitle ? (
                            <>
                                {kpi?.is_aggregate_of && (
                                    <Link
                                        variant="h6"
                                        noWrap={true}
                                        sx={{lineHeight: 1}}
                                        color="textPrimary"
                                        underline="hover"
                                        to='#'
                                    >
                                        {kpi?.is_aggregate_of?.visual_name}
                                    </Link>
                                )}
                                <Typography
                                    variant={kpi?.is_aggregate_of ? 'subtitle1' : 'h6'}
                                    noWrap={true}
                                    sx={{lineHeight: 1}}
                                    color={kpi?.is_aggregate_of ? 'textSecondary' : 'textPrimary'}
                                >
                                    {(kpi?.aggregated_after ? (kpi?.aggregated_after + ': ') : '') + kpi?.visual_name}
                                </Typography>
                            </>
                        ) : (
                            <Typography variant="h6" noWrap={true} sx={{lineHeight: 0.8}} color={'textPrimary'}>
                                {t("common.details")}
                            </Typography>
                        )}
                    </Stack>
                    <Typography variant="caption" color="textSecondary">
                        {t('core.' + selection)}
                    </Typography>
                </Stack>
                <IconButton
                    onClick={handleClick}
                    // onMouseEnter={handleClick}
                >
                    <OnIcon iconName="DotsHorizontal" />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => handleClose()}
                >
                    <MenuItem onClick={() => handleClose('daily_end')}>{t("core.daily_end")}</MenuItem>
                    <MenuItem onClick={() => handleClose('daily_min')}>{t("core.daily_min")}</MenuItem>
                    <MenuItem onClick={() => handleClose('daily_max')}>{t("core.daily_max")}</MenuItem>
                    <MenuItem onClick={() => handleClose('daily_avg')}>{t("core.daily_avg")}</MenuItem>
                    <MenuItem onClick={() => handleClose('daily_median')}>{t("core.daily_median")}</MenuItem>
                    <MenuItem onClick={() => handleClose('intraday')}>{t('core.intraday')}</MenuItem>
                </Menu>
            </Stack>
            <Box>
                {loading ? (
                    <div style={{height: height, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress/>
                    </div>
                ) : (
                    <Chart
                        options={options}
                        series={options.series}
                        type="line"
                        width="100%"
                        height={height}
                    />
                )}
            </Box>
        </>
    )

}, (prevProps, nextProps) => {

    const sameId = prevProps.kpi.id === nextProps.kpi.id;
    const samePrefix = prevProps.kpi.prefix === nextProps.kpi.prefix;
    const sameDecimals = prevProps.kpi.decimal_places === nextProps.kpi.decimal_places;
    const withTitleSame = prevProps.withTitle === nextProps.withTitle;
    const heightSame = prevProps.height === nextProps.height;

    return sameId && samePrefix && sameDecimals && withTitleSame && heightSame;
});

KPIChart.propTypes = {
    kpi: PropTypes.object.isRequired,
    withTitle: PropTypes.bool,
    small: PropTypes.bool,
    height: PropTypes.number
}

export default KPIChart;