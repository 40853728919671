import React from "react";
import PropTypes from "prop-types";
import useApi from "src/omnia/hooks/use-api";
import { NumericFormat } from "react-number-format";
import {Grid, TextField, Button, Chip} from "@mui/material";
import {Alert} from "@mui/lab";
import SmartSelector from "src/omnia/components/elements/smart-selector";
import useTmsFormik from "../../../../../hooks/formik/use-tms-formik";
import {useTranslation} from "react-i18next";
import SaveButton from "../../../../elements/save-button";

function ParcelDetails({parcel = null, onDone, onDelete, zones}) {

    const {t} = useTranslation();
    const {put, del} = useApi();

    const onSubmit = (values, {resetForm, setSubmitting}) => {
        setSubmitting(true);
        put('tms/parcels', values).then((parcel) => {
            resetForm();
            if (onDone)
                onDone(parcel);
        }).finally(() => {
            setSubmitting(false);
        })
    };

    const {parcelFormik} = useTmsFormik(parcel, onSubmit, true);

    const handleDelete = () => {
        if (window.confirm(t('notify.are_you_sure'))) {
            del('tms/parcels', parcel.id).then(() => {
                if (onDelete)
                    onDelete(parcel.id);
            });
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <TextField
                    error={Boolean(parcelFormik.touched.name && parcelFormik.errors.name)}
                    fullWidth
                    helperText={parcelFormik.touched.name && parcelFormik.errors.name}
                    label={t('attributes.name')}
                    name="name"
                    onBlur={parcelFormik.handleBlur}
                    onChange={parcelFormik.handleChange}
                    value={parcelFormik.values.name}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <NumericFormat
                    value={parseFloat(parcelFormik.values.max_weight_kg)}
                    helperText={parcelFormik.errors.max_weight_kg}
                    fixedDecimalScale={false}
                    error={Boolean(parcelFormik.errors.max_weight_kg)}
                    fullWidth
                    label={t('attributes.weight_max')}
                    name="max_weight_kg"
                    isNumericString
                    customInput={TextField}
                    suffix={' kg'}
                    decimalScale={2}
                    type="text"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    onValueChange={({value: v}) => {
                        if (v === "")
                            v = 0;
                        parcelFormik.setFieldValue('max_weight_kg', v);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <NumericFormat
                    value={parseFloat(parcelFormik.values.max_length_cm)}
                    helperText={parcelFormik.errors.max_length_cm}
                    fixedDecimalScale={false}
                    error={Boolean(parcelFormik.errors.max_length_cm)}
                    fullWidth
                    label={t('attributes.length_max')}
                    name="max_length_cm"
                    isNumericString
                    customInput={TextField}
                    suffix={' cm'}
                    decimalScale={0}
                    type="text"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    onValueChange={({value: v}) => {
                        if (v === "")
                            v = 0;
                        parcelFormik.setFieldValue('max_length_cm', v);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <NumericFormat
                    value={parseFloat(parcelFormik.values.max_width_cm)}
                    helperText={parcelFormik.errors.max_width_cm}
                    fixedDecimalScale={false}
                    error={Boolean(parcelFormik.errors.max_width_cm)}
                    fullWidth
                    label={t('attributes.width_max')}
                    name="max_width_cm"
                    isNumericString
                    customInput={TextField}
                    suffix={' cm'}
                    decimalScale={0}
                    type="text"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    onValueChange={({value: v}) => {
                        if (v === "")
                            v = 0;
                        parcelFormik.setFieldValue('max_width_cm', v);
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
                <NumericFormat
                    value={parseFloat(parcelFormik.values.max_height_cm)}
                    helperText={parcelFormik.errors.max_height_cm}
                    fixedDecimalScale={false}
                    error={Boolean(parcelFormik.errors.max_height_cm)}
                    fullWidth
                    label={t('attributes.height_max')}
                    name="max_height_cm"
                    isNumericString
                    customInput={TextField}
                    suffix={' cm'}
                    decimalScale={0}
                    type="text"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    onValueChange={({value: v}) => {
                        if (v === "")
                            v = 0;
                        parcelFormik.setFieldValue('max_height_cm', v);
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <NumericFormat
                    value={parseFloat(parcelFormik.values.cost_euro) / 100}
                    fixedDecimalScale={false}
                    helperText={parcelFormik.touched.cost_euro && parcelFormik.errors.cost_euro}
                    error={Boolean(parcelFormik.touched.cost_euro && parcelFormik.errors.cost_euro)}
                    fullWidth
                    label={t('resources.sales.parcel_cost_gross')}
                    name="cost_euro"
                    isNumericString
                    customInput={TextField}
                    suffix={'€'}
                    decimalScale={2}
                    type="text"
                    thousandSeparator={'.'}
                    decimalSeparator={','}
                    onValueChange={({floatValue: v}) => {
                        parcelFormik.setFieldValue('cost_euro', typeof v === 'undefined' ? null : parseInt(v * 100));
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <SmartSelector
                    values={parcelFormik.values.zones}
                    error={Boolean(parcelFormik.touched.zones && parcelFormik.errors.zones)}
                    helperText={parcelFormik.touched.zones && parcelFormik.errors.zones}
                    label={t('resources.sales.usage_for_zones')}
                    handleChange={(selected) => {
                        parcelFormik.setFieldValue('zones', selected.target.value);
                    }}
                    name="zones"
                    options={zones}
                />
            </Grid>
            {parcelFormik.values.zones.length === 0 && (
                <Grid item xs={12}>
                    <Alert severity="error">
                        {t('resources.sales.not_used_for_any_zone')}
                    </Alert>
                </Grid>
            )}
            <Grid item xs={12} sx={{mt: 4}}>
                <Grid container spacing={3}>
                    {parcel && (
                        <Grid item xs={6}>
                            <Button
                                sx={{mr: 2}}
                                fullWidth
                                onClick={handleDelete}
                                disabled={parcelFormik.isSubmitting}
                                variant="outlined"
                                color="error"
                            >
                                {t('resources.sales.delete_parcel')}
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={parcel ? 6 : 12}>
                        <SaveButton
                            // fullWidth
                            // onClick={parcelFormik.handleSubmit}
                            // loading={parcelFormik.isSubmitting}
                            // variant="contained"
                            // color="primary"
                            label={parcel ? t('common.save') : t('common.add')}
                            formik={parcelFormik}
                            // noIcon
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

ParcelDetails.propTypes = {
    parcel: PropTypes.object,
    onDone: PropTypes.func,
    onDelete: PropTypes.func,
}

export default ParcelDetails;