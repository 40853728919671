import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Card, CardContent, Chip, CardHeader, Fade, Divider, Grid, TextField} from '@mui/material';
import {Autocomplete} from '@mui/material';
import useApi from "src/omnia/hooks/use-api";
import PropTypes from "prop-types";
import {useNotifications} from "src/omnia/hooks/use-notifications";
import {useTranslation} from "react-i18next";
import {Skeleton} from "@mui/lab";

function CompetencesSelector({className, ...rest}) {

    const user = useSelector(state => state.account.user);
    const [options, setOptions] = useState([]);
    const {t} = useTranslation();
    const [competencies, setCompetencies] = useState(null);
    const [statusState, setStatusState] = useState(1);
    const {del, get, put} = useApi();
    const {notify, notifySuccess} = useNotifications();

    const handleDelete = (id) => () => {
        del('connect/competencies', id)
            .then(() => {
                setCompetencies(competencies.filter(c => c.id !== id));
                updateAllOptions();
                notifySuccess(t);
            })
    }

    const updateAllOptions = () => {
        get('connect/competencies/all_options').then(options => {
            setOptions(options);
        })
    }

    const updateCompetencies = () => {
        get('connect/competencies').then(cmps => {
            setCompetencies(cmps)
        }).catch(() => {
            setCompetencies([])
        })
    }

    useEffect(() => {
        updateCompetencies();
        updateAllOptions();
    }, [])

    if (competencies === null)
        return (
            <Skeleton variant="rounded" height={200}/>
        );

    return (
        <Card className={className}{...rest}>
            <CardHeader title={t('core.competencies')}/>
            <Divider/>
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        {competencies.map(c => {
                            return (
                                <Chip
                                    label={c.name}
                                    key={'o8asp9gdoö' + c.id}
                                    onDelete={handleDelete(c.id)}
                                    color="primary"
                                    style={{margin: 2}}
                                />
                            )
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={options}
                            key={statusState}
                            filterOptions={(opt, val) => {
                                // filter options
                                let results = opt.filter(o => !user.competencies.includes(o.name)).filter(o => o.name.toLowerCase().includes(val.inputValue.toLowerCase()));
                                // when length of result is none create new
                                if (results.length === 0)
                                    results = [{
                                        name: val.inputValue
                                    }];
                                return results;
                            }}
                            getOptionLabel={(option) => option.name}
                            fullWidth={true}
                            onKeyDown={(e) => {
                                // wait for enter
                                if (e.keyCode === 13) {
                                    put('connect/competencies', {name: e.target.value})
                                        .then(newCompetence => {
                                            notifySuccess(t);
                                            // add new competence to options
                                            setCompetencies(competencies.concat([newCompetence]));
                                            // update key to rerender component
                                            setStatusState(statusState + 1);
                                            // update options
                                            setOptions(options.filter(o => o.name !== newCompetence.name));
                                        })
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('core.new_competence')}
                                    helperText={t('common.enter_to_add')}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

CompetencesSelector.propTypes = {
    className: PropTypes.string
}

export default CompetencesSelector;
