import React, {useEffect, useState} from "react";
import {
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider,
    Stack,
    Switch,
    TextField, ToggleButton, ToggleButtonGroup,
    Typography
} from "@mui/material";
import useApi from "../../../../hooks/use-api";
import {useTranslation} from "react-i18next";
import {useNotifications} from "../../../../hooks/use-notifications";
import SaveButton from "../../../elements/save-button";
import LlmAssistantSelector from "../model-selector/llm-assistant-selector";
import OnIcon from "../../../elements/icon";
import AssistantCapabilitiesSelector from "../assistants/assistant-capabilities-selector";

function CustomInstructions() {
    const {t} = useTranslation();
    const [initialLoading, setInitialLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [settings, setSettings] = useState(null);
    const {notifySuccess} = useNotifications();
    const {get, post} = useApi();

    const handleSave = () => {
        setLoading(true);
        post('core/user-settings', settings).finally(() => {
            setLoading(false);
            notifySuccess(t);
        });
    }

    const handleChange = (name, value) => {
        setSettings(prev => {
            return {
                ...prev,
                [name]: value
            }
        });
    }

    useEffect(() => {
        setInitialLoading(true);
        get('core/user-settings/obtain').then(response => {
            setSettings(response);
        }).finally(() => {
            setInitialLoading(false);
        })
    }, []);

    return (
        <Stack spacing={2}>
            <Card>
                {initialLoading ? (
                    <div style={{
                        height: '200px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <CircularProgress size={20}/>
                    </div>

                ) : (
                    <Stack
                        direction="column"
                        spacing={2}
                        sx={{width: '100%'}}
                    >
                        <CardContent>
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h6" color="textPrimary">
                                        {t('mgt.settings.default_model')}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('mgt.settings.default_model_info')}
                                    </Typography>
                                </Stack>
                                <LlmAssistantSelector
                                    onSelect={(value) => {
                                        handleChange('default_assistant', value?.id);
                                    }}
                                    value={settings?.default_assistant}
                                />
                            </Stack>
                        </CardContent>
                        {/*<CardContent>*/}
                        {/*    <Stack direction="column" spacing={1}>*/}
                        {/*        <Typography variant="h6" color="textPrimary">*/}
                        {/*            {t('intelligence.assistants.model_capabilities')}*/}
                        {/*        </Typography>*/}
                        {/*        <Typography variant="body2" color="textSecondary">*/}
                        {/*            {t('intelligence.assistants.model_capabilities_info')}*/}
                        {/*        </Typography>*/}
                        {/*    </Stack>*/}
                        {/*    <AssistantCapabilitiesSelector*/}
                        {/*        values={settings}*/}
                        {/*        handleChange={(event) => handleChange(event.target.name, event.target.value)}*/}
                        {/*    />*/}
                        {/*</CardContent>*/}
                        <Divider />
                        <CardContent>
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h6" color="textPrimary">
                                        {t('intelligence.assistants.can_memorize')}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('intelligence.assistants.can_memorize_info')}
                                    </Typography>
                                </Stack>
                                <Switch
                                    onChange={(event, val) => {
                                        handleChange('can_memorize', val);
                                    }}
                                    checked={settings?.can_memorize || false}
                                />
                            </Stack>
                        </CardContent>
                        <Divider />
                        <CardContent>
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h6" color="textPrimary">
                                        {t('mgt.settings.personal_information')}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('mgt.settings.personal_information_info')}
                                    </Typography>
                                </Stack>
                                <Switch
                                    onChange={(event, val) => {
                                        handleChange('personal_information_active', val);
                                    }}
                                    checked={settings?.personal_information_active || false}
                                />
                            </Stack>
                        </CardContent>
                        <Divider />
                        <CardContent>
                            <Stack
                                direction="row"
                                spacing={2}
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{mb: 4}}
                            >
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="h6" color="textPrimary">
                                        {t('mgt.settings.custom_instructions')}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        {t('mgt.settings.custom_instructions_active_info')}
                                    </Typography>
                                </Stack>
                                <Switch
                                    onChange={(event, val) => {
                                        handleChange('custom_instructions_active', val);
                                    }}
                                    checked={settings?.custom_instructions_active || false}
                                />
                            </Stack>
                            <TextField
                                label={t('mgt.settings.custom_instructions')}
                                error={settings?.custom_instructions?.length > 1000}
                                helperText={(settings?.custom_instructions?.length > 1000) && t('form.max_characters', {count: 1000})}
                                fullWidth
                                rows={7}
                                multiline
                                onChange={(event) => handleChange('custom_instructions', event.target.value)}
                                value={settings?.custom_instructions || ''}
                            />
                        </CardContent>
                        <Divider/>
                    </Stack>
                )}
                <CardActions>
                    <Stack direction="row-reverse" sx={{width: '100%'}}>
                        <SaveButton
                            loading={loading}
                            onClick={handleSave}
                        />
                    </Stack>
                </CardActions>
            </Card>
        </Stack>
    )
}

export default CustomInstructions;