import React from 'react';
import ReactH5AudioPlayer from 'react-h5-audio-player';
import PropTypes from "prop-types";
import 'react-h5-audio-player/lib/styles.css';

function AudioViewer({ file, ...rest }) {

    return (
        <div style={{ width: '100%', margin: '0 auto' }} {...rest}>
            <ReactH5AudioPlayer
                src={file?.view}
                autoPlay={false}
                showJumpControls={true}
                customAdditionalControls={[]}
                layout="stacked-reverse"
                style={{ borderRadius: '10px', height: 200 }}
            />
        </div>
    );
}

AudioViewer.propTypes = {
    file: PropTypes.object.isRequired,
}

export default AudioViewer;
