import React, {useEffect, useRef, useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from "react-redux";
import {
    Box,
    Card,
    CardMedia,
    Divider,
    Grid,
    IconButton,
    Link,
    SvgIcon,
    Tooltip,
    Typography,
    colors,
    MenuItem,
    Menu,
    CardActionArea,
    CardContent,
    Fade, Chip,
} from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import useApi from "src/omnia/hooks/use-api";
import {useSelector} from "react-redux";
import {useCreateSrc} from "src/omnia/hooks/use-create-src";
import {likeSpace, dislikeSpace, updateSpace} from "src/omnia/store/actions/spaces-actions";
import { useNavigate } from "react-router";
import {useTheme} from "@mui/system";
import {useSettings} from "../../../hooks/use-settings";
import useImageLoader from "../../../hooks/use-image-loader";
import {useTranslation} from "react-i18next";
import {APP_SETTING} from "../../../../setup";
import OnIcon from "../../elements/icon";

function CardMediaArea({src, height = 200}){
    const theme = useTheme();
    const settings = useSettings();
    const { imageSrc } = useImageLoader(src, settings.paletteMode === 'light' ? '/assets/preload/omnia_wide_light.jpg' : '/assets/preload/omnia_wide_dark.jpg');
    return (
        <CardMedia
            style={{
                height: height,
                backgroundColor: theme.palette.background.dark
            }}
            image={imageSrc}
        />
    )
}

function SpaceCard(props) {

    const {
        project,
        onMembershipChange = () => {},
        withType = false,
        onJoinedSpace,
        noDetails = false,
        detailLimit = 2,
        height = 200,
        selected = false,
        ...rest
    } = props;

    const theme = useTheme();
    const [isLiked, setLiked] = useState(false);
    const [likes, setLikes] = useState(false);
    const [open, setOpen] = useState(false);
    const [showDue, setShowDue] = useState(false);
    const [duration, setDuration] = useState('');
    const [showType, setShowType] = useState(false);
    const [showLocation, setShowLocation] = useState(false);
    const [operationLoading, setOperationLoading] = useState(false);
    const invitationMenuRef = useRef();
    const [userIsMember, setUserIsMember] = useState(false);
    const [data, setData] = useState(project);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.account);
    const getSrc = useCreateSrc();
    const { post } = useApi();

    const handleOpenSpace = () => {
        navigate("/groon/projects/view/" + data.id + "/overview")
    }

    const openInvitationMenu = () => {
        setOpen(true);
    }

    const closeInvitationMenu = () => {
        setOpen(false);
    }

    const leaveSpace = () => {
        setOperationLoading(true);
        closeInvitationMenu();
        // perform api request
        post('pm/projects/' + data.id + '/leave_project')
            .then((data) => {
                // dispatch new version after join
                dispatch(updateSpace(data));
                // handle change in component details
                setUserIsMember(false);
                setData(prev => {
                    prev['has_accepted'] = null;
                    return prev;
                });
                if(onMembershipChange)
                    onMembershipChange(data);
            })
            .finally(() => {
                setOperationLoading(false);
            });
    }

    const approveInvitation = () => {
        setOperationLoading(true);
        closeInvitationMenu();
        // perform api requet
        post('pm/projects/' + data.id + '/accept_invitation')
            .then((data) => {
                // dispatch new version after join
                dispatch(updateSpace(data));
                // handle internal change of details
                setData(data);
                setUserIsMember(true);
            })
            .finally(() => {
                setOperationLoading(false);
            });
    }

    const handleJoinSpace = () => {
        setOperationLoading(true);
        setUserIsMember(true);
        // perform api request
        post('pm/projects/' + data.id + '/join_space')
            .then((data) => {
                // dispatch new version after join
                dispatch(updateSpace(data));
                // handle change of details
                setData(prev => {
                    prev['has_accepted'] = true;
                    return prev;
                });
                setUserIsMember(true);
                if(onJoinedSpace)
                    onJoinedSpace(data);
            })
            .finally(() => {
                setOperationLoading(false);
            });
    }

    const handleLike = () => {
        setLiked(true);
        setLikes((prevLikes) => prevLikes + 1);
        // dispatch like space action
        post('pm/projects/' + data.id + '/like').then(space => {
            dispatch(likeSpace(space));
        });
    };

    const handleUnlike = () => {
        setLiked(false);
        setLikes((prevLikes) => prevLikes - 1);
        // dispatch dislike space action
        post('pm/projects/' + data.id + '/dislike').then(space => {
            dispatch(dislikeSpace(space));
        });
    };

    useEffect(() => {
        let hasMemberInstance = data.member.filter(m => m.id === user.id).length > 0;
        let acceptedIsNull = data.has_accepted === null;
        setUserIsMember(!(!hasMemberInstance || acceptedIsNull));
    }, []);

    useEffect(() => {
        // set project details
        setData(project);

        // handle liked and likes
        setLiked(project.is_liked);
        setLikes(project.likes);

        // handle show information
        setShowDue(false);
        setShowType(false);
        setShowLocation(false);

        // initiate counter
        let counter = 0;
        let hasDue = (project.due_date !== null && project.due_date !== "");
        let hasLoc = (project.location !== null && project.location !== "");
        let hasType = (project.type !== null && project.type !== "");

        // check cases
        if(project.is_member){
            if(hasDue && counter < detailLimit){
                setShowDue(true);
                counter += 1;
            }
            if(hasLoc && counter < detailLimit){
                setShowLocation(true);
                counter += 1;
            }
        }
        if(hasType && counter < detailLimit)
            setShowType(true);

        // handle duration
        setDuration(moment.duration(project.total_usage, 'minutes').humanize())
    }, [project]);

    return (
        <Fade in timeout={APP_SETTING?.transitionDuration || 500}>
            <Card
                sx={{
                    border: selected
                        ? "3px solid"
                        : "none",
                    borderColor: selected
                        ? "primary.main"
                        : "divider",
                }}
                {...rest}
            >
                <CardActionArea
                    onClick={handleOpenSpace}
                    sx={{
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                    }}
                >
                    {(project.state === 'is_closed') && (
                        <Chip
                            label={t("projects.finished")}
                            color="primary"
                            style={{
                                position: 'absolute',
                                top: 10,
                                left: 10,

                            }}
                        />
                    )}
                    {(withType && project.type === 'Intern') && (
                        <div style={{
                            backgroundColor: theme.palette.background.paper,
                            padding: theme.spacing(0.75),
                            position: 'absolute',
                            top: 5,
                            right: 5,
                            borderRadius: 15
                        }}>
                            <Typography variant="caption" color="inherit">
                                {project.type}
                            </Typography>
                        </div>
                    )}
                    <CardMediaArea src={getSrc(data.media)} height={height} />
                    <CardContent>
                        <Box display="flex" alignItems="center" mt={2}>
                            <Typography variant="h6" color="textPrimary">
                                {data.title}
                            </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                            <Typography variant="body2" color="textSecondary">
                                {((data.access === 't') && data.team) && (
                                    <>
                                        {'Von '}
                                        <Link underline="hover" color="textPrimary" component={RouterLink} to={"/groon/team/" + data.team.id} style={{fontSize: 15}} variant="h6">
                                            {data.team.name}
                                        </Link>
                                        {' | '}
                                    </>
                                )}
                                {project.last_opened
                                    ? t("projects.last_opened", {fromNow: moment(project.last_opened).fromNow()})
                                    : t("common.last_active_from_now", {fromNow: moment(data.modified_at ).fromNow()})
                                }
                            </Typography>
                        </Box>
                        {!noDetails && (
                            <Box mt={2} mb={3} style={{height: 40}}>
                                <Typography color="textSecondary" variant="body2">
                                    {t("projects.total_time_spent", {duration: duration})}
                                </Typography>
                            </Box>
                        )}
                        {!noDetails && (
                            <Grid alignItems="center" container justifyContent="space-between" spacing={2}>
                                {(showLocation) && (
                                    <Grid item>
                                        <Typography variant="body2" sx={{fontWeight: 800}} color="textPrimary">
                                            {data.location}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {t("Location")}
                                        </Typography>
                                    </Grid>
                                )}
                                {(showType) && (
                                    <Grid item>
                                        <Typography variant="body2" sx={{fontWeight: 800}} color="textPrimary">
                                            {data.type}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {t("Type")}
                                        </Typography>
                                    </Grid>
                                )}
                                {(showDue) && (
                                    <Grid item>
                                        <Typography variant="body2" sx={{fontWeight: 800}} color={data.is_overdue ? "error" : "textPrimary" }>
                                            {moment(data.due_date).format('DD.MM.YYYY')}
                                        </Typography>
                                        <Typography variant="body2" color={data.is_overdue ? "error" : "textSecondary" }>
                                            {t("Due date")}
                                        </Typography>
                                    </Grid>
                                )}
                            </Grid>
                        )}
                    </CardContent>
                </CardActionArea>
                <Divider />
                <Box py={2} pl={2} pr={3} display="flex" alignItems="center">
                    {isLiked ? (
                        <Tooltip enterDelay={1000} title={t("I dislike")}>
                            <IconButton style={{color: colors.red[600]}} onClick={handleUnlike} size="small">
                                <FavoriteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title={t("I like")}>
                            <IconButton onClick={handleLike} size="small">
                                <FavoriteBorderIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )}
                    <Typography variant="subtitle2" color="textSecondary">
                        {likes}
                    </Typography>
                    {(data.access === 'c') && (
                        <>
                            <SvgIcon fontSize="small" color="action" sx={{ml: 2, mr: 1}}>
                                <OnIcon iconName="Users01" size="small" />
                            </SvgIcon>
                            <Typography variant="subtitle2" color="textSecondary">
                                <span>{data.member.length}</span>
                            </Typography>
                        </>
                    )}
                    <Box flexGrow={1} />
                    {data.access === 't' && (
                        <Typography variant="caption" color="textSecondary">
                            {t("Team space")}
                        </Typography>
                    )}
                    {data.access === 'o' && (
                        <Typography variant="caption" color="textSecondary">
                            {t("Open space")}
                        </Typography>
                    )}
                    {data.access === 'c' && (
                        <>
                            {!operationLoading ? (
                                <>

                                    {userIsMember ? (
                                        <>
                                            {!data.has_accepted ? (
                                                <>
                                                    <Tooltip title={t("Respond to invitation")}>
                                                        <IconButton onClick={openInvitationMenu} ref={invitationMenuRef} size="small">
                                                            <OnIcon iconName="Mail01" size="small" />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Menu
                                                        keepMounted
                                                        anchorEl={invitationMenuRef.current}
                                                        open={open}
                                                        onClose={closeInvitationMenu}
                                                    >
                                                        <MenuItem onClick={approveInvitation}>{t("Join project")}</MenuItem>
                                                        <MenuItem onClick={leaveSpace}>{t("Decline invitation")}</MenuItem>
                                                    </Menu>
                                                </>
                                            ) : (
                                                <Tooltip title="Projekt verlassen">
                                                    <IconButton
                                                        onClick={() => {
                                                            if(window.confirm(t("notify.are_you_sure")))
                                                                leaveSpace()
                                                        }}
                                                        size="small"
                                                    >
                                                        <OnIcon iconName="LogOut01" size="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {data.access === 'o' ? (
                                                <Tooltip title={t("Join project")}>
                                                    <IconButton onClick={handleJoinSpace} size="small">
                                                        <OnIcon iconName="LogIn01" size="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            ) : (
                                                <Tooltip title={t("Space is closed")}>
                                                    <IconButton size="small">
                                                        <OnIcon iconName="Lock02" size="small" />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : null}
                        </>
                    )}
                </Box>
            </Card>
        </Fade>
    );
}

CardMediaArea.propTypes = {
    src: PropTypes.string,
    height: PropTypes.number
}

SpaceCard.propTypes = {
    withType: PropTypes.bool,
    project: PropTypes.object.isRequired,
    detailLimit: PropTypes.number,
    onMembershipChange: PropTypes.func,
    onJoinedSpace: PropTypes.func,
    height: PropTypes.number,
    selected: PropTypes.bool,
    noDetails: PropTypes.bool
};

export default SpaceCard;
