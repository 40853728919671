import React from "react";
import {useTheme} from "@mui/system";
import PropTypes from "prop-types";

function AiModelImage({aiModel, sizePixels = 20}){

    const theme = useTheme();

    if(!aiModel)
        return null;

    return (
        <>
            {aiModel?.logo && <img alt='logo' style={{height: sizePixels, width: sizePixels}} src={aiModel?.logo + (theme.palette.mode === 'light' ? '-dark.png' : '-light.png')} />}
        </>
    )
}

AiModelImage.propTypes = {
    aiModel: PropTypes.object.isRequired,
    sizePixels: PropTypes.number
}

export default AiModelImage;