import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import {ErrorBoundary} from "react-error-boundary";
import {CircularProgress, Stack, Typography} from "@mui/material";
import useApi from "src/omnia/hooks/use-api";
import useDimensions from "../../../hooks/use-dimensions";
import { pdfjs } from 'react-pdf';
import { imageTypes, videoTypes, docTypes, audioTypes } from 'src/omnia/utils/file-type-helpers';
import {useTranslation} from "react-i18next";
import ImageViewer from "./file-viewers/image-viewer";
import VideoViewer from "./file-viewers/video-viewer";
import AudioViewer from "./file-viewers/audio-viewer";
import PdfViewer from "./file-viewers/pdf-viewer";
import OnIcon from "../icon";
import {useTheme} from "@mui/system";

pdfjs.GlobalWorkerOptions.workerSrc = `/assets/js/pdf.worker.js`;

const DisplayNotPossible = () => {

    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent="center"
            sx={{ height: '100%', width: '100%', minHeight: 400 }}
        >
            <Stack direction="column" alignItems="center" spacing={2}>
                <OnIcon
                    iconName="File06"
                    size="large"
                    sx={{ color: theme.palette.text.secondary }}
                />
                <Typography variant="h5" color="textSecondary">
                    {t('core.file_can_not_be_displayed')}
                </Typography>
            </Stack>
        </Stack>
    )
}

function FileRenderErrorBoundary({ children }) {
    const [hasError, setHasError] = React.useState(false);

    // Fallback render logic
    const ErrorFallback = () => (
        <DisplayNotPossible />
    );

    // Custom error boundary logic
    const errorHandler = (error, errorInfo) => {
        console.error('Error rendering file:', error, errorInfo);
        setHasError(true);
    };

    if (hasError) {
        return <ErrorFallback />;
    }

    return (
        <ErrorBoundary fallbackRender={ErrorFallback} onError={errorHandler}>
            {children}
        </ErrorBoundary>
    );
}

function FileGenericViewer({ file, pdfWrapperRef, ...rest }) {
    const [rawFile, setRawFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const { getFile } = useApi();
    const { width } = useDimensions();

    useEffect(() => {
        // For doc-type files (PDF, etc.) we fetch the raw file for the PdfViewer.
        if (docTypes.includes(file['type'].toLowerCase())) {
            getFile(file)
                .then((f) => {
                    setRawFile(f);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [file, getFile]);

    if (loading) {
        return (
            <div style={{ height: 500, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <FileRenderErrorBoundary>
            {/* Decide which viewer to show */}
            {imageTypes.includes(file['type'].toLowerCase()) && (
                <ImageViewer file={file} {...rest} />
            )}
            {videoTypes.includes(file['type'].toLowerCase()) && (
                <VideoViewer file={file} {...rest} />
            )}
            {audioTypes.includes(file['type'].toLowerCase()) && (
                <AudioViewer file={file} {...rest} />
            )}
            {docTypes.includes(file['type'].toLowerCase()) && (
                <PdfViewer rawFile={rawFile} pdfWrapperRef={pdfWrapperRef} width={width} {...rest} />
            )}
            {/* Fallback */}
            {!imageTypes.includes(file['type'].toLowerCase()) &&
                !videoTypes.includes(file['type'].toLowerCase()) &&
                !audioTypes.includes(file['type'].toLowerCase()) &&
                !docTypes.includes(file['type'].toLowerCase()) && (
                    <DisplayNotPossible />
                )}
        </FileRenderErrorBoundary>
    );
}

FileGenericViewer.propTypes = {
    file: PropTypes.object.isRequired,
    pdfWrapperRef: PropTypes.object,
};

export default FileGenericViewer;