import { useCallback, useRef, useState } from 'react';
import debounce from "lodash.debounce";

export function usePopover(noDelay) {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const delay = noDelay ? 0 : 200;

    // Memoize the open and close handlers to prevent unnecessary re-renders
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handleToggle = useCallback(() => {
        setOpen((prevState) => !prevState);
    }, []);

    const handlePopoverOperation = (operation) => {
        if (operation === 'close') {
            handleClose();
        }
        if (operation === 'open') {
            handleOpen();
        }
    }

    // Use debounce to limit the frequency of open and close operations
    const queueOpen = useCallback(debounce(() => handlePopoverOperation('open'), delay), [delay]);
    const queueClose = useCallback(debounce(() => handlePopoverOperation('close'), delay), [delay]);

    return {
        anchorRef,
        handleClose,
        handleOpen,
        queueClose,
        queueOpen,
        handleToggle,
        open
    };
}

export default usePopover;