import React from "react";
import {CircularProgress, Stack, Typography} from "@mui/material";
import OnIcon from "../../../elements/icon";
import {useTranslation} from "react-i18next";
import AssistentMenuItem from "./assistant-menu-item";
import PropTypes from "prop-types";
import {useTheme} from "@mui/system";

function LlmAssistantsList({assistants, isLoading, onSelect, selectedId, dense, disableUnavailable}) {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Stack
            direction="column"
            spacing={dense ? 0 : 1}
        >
            <Typography variant="overline" sx={{px: 2, mt: 1, mb: 3}}>
                {t('layout.assistants')}
            </Typography>
            {assistants.length === 0 ? (
                <div style={{display: 'flex', height: 250, justifyContent: 'center', alignItems: 'center'}}>
                    {isLoading ? (
                        <div style={{
                            height: '100px',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}>
                            <CircularProgress size={20} />
                        </div>
                    ) : (
                        <Stack
                            direction="column"
                            spacing={0}
                            alignItems='center'
                            color='textSecondary'
                            sx={{textAlign: 'center', mx: 2}}
                        >
                            <OnIcon iconName="Star06" size="large" sx={{color: theme.palette.text.secondary}}/>
                            <Typography variant="h6" color="textSecondary">
                                {t('intelligence.models.no_models_found')}
                            </Typography>
                            <Typography variant="body1" color="textSecondary">
                                {t('intelligence.models.no_models_found_info')}
                            </Typography>
                        </Stack>
                    )}
                </div>
            ) : assistants.map((assistant) => (
                <AssistentMenuItem
                    key={'assistant-' + assistant.id}
                    assistant={assistant}
                    onSelect={onSelect}
                    checked={selectedId === assistant.id}
                    disableUnavailable={disableUnavailable}
                    dense={dense}
                />
            ))}
        </Stack>
    )
}

LlmAssistantsList.propTypes = {
    assistants: PropTypes.array,
    isLoading: PropTypes.bool,
    dense: PropTypes.bool,
    disableUnavailable: PropTypes.bool,
    onSelect: PropTypes.func,
    selectedId: PropTypes.string,
}

LlmAssistantsList.propTypes = {
    assistants: [],
    isLoading: false,
    dense: false,
    disableUnavailable: true,
    onSelect: () => {},
    selectedId: null,
}

export default LlmAssistantsList;