export default function formatNumber({
                                         number,
                                         decimals = 2,
                                         suffix = "",
                                         prefix = "",
                                         abbreviate = false
                                     }) {
    const numericNumber = parseFloat(number);

    let magnitude = "";
    let divisor = 1;
    let significantFigures = decimals;

    if (abbreviate) {
        if (numericNumber >= 1e9) {
            magnitude = "B";
            divisor = 1e9;
            significantFigures = 2;
        } else if (numericNumber >= 1e6) {
            magnitude = "M";
            divisor = 1e6;
            significantFigures = 2;
        } else if (numericNumber >= 1e3) {
            magnitude = "k";
            divisor = 1e3;
            significantFigures = 2;
        }
    }

    const adjustedNumber = numericNumber / divisor;

    const fixedNum = adjustedNumber.toFixed(significantFigures);
    const [whole, decimal] = fixedNum.split('.');
    const formattedWhole = parseInt(whole, 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Adjusting the logic here
    let result;
    if (magnitude) {
        result = decimal ? `${formattedWhole}.${decimal}` : formattedWhole;
    } else {
        result = decimal ? `${formattedWhole},${decimal}` : formattedWhole;
    }

    result = `${prefix}${result} ${magnitude} ${suffix}`;

    return result.trim(); // trim to remove any unnecessary whitespace
}
