import { Button, Card, CardContent, CardHeader, Stack, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { alpha } from '@mui/material/styles';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import {useTheme} from "@mui/system";
import useOmniaApi from "../../hooks/use-omnia-api";

function FileSelector(props) {

    const {
        value,
        onChange,
        name,
        description = '',
        label,
        asBackground = false,
        withOverlay = false,
        defaultImagePath = '',
        maxWidth = '100%',
        maxHeight = '100%',
        sx = {},
        onRemove
    } = props;

    const theme = useTheme();
    const [preview, setPreview] = useState(null);
    const { t } = useTranslation();
    const fileInputRef = useRef(null);

    const handleDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const url = URL.createObjectURL(file);
        setPreview(url);
        onChange({
            target: {
                name: name,
                value: file
            }
        });
    }, [name, onChange]);

    const { getInputProps } = useDropzone({
        onDrop: handleDrop,
        multiple: false,
        accept: 'image/*',
    });

    const selectFile = () => {
        fileInputRef.current.click();
    };

    useEffect(() => {
        // Update the preview when the `value` prop changes
        if (value) {
            if (value?.view) {
                setPreview(value?.view);
            } else if (typeof value === 'object') {
                setPreview(URL.createObjectURL(value));
            } else {
                // setPreview(null);
            }
        } else {
            // setPreview(null);
        }
    }, [value]);

    const imageStyle = {
        maxHeight: maxHeight || '100%',
        maxWidth: maxWidth || '100%',
    };

    // Define the overlay style
    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        backgroundColor: theme.palette.mode === 'light' ? alpha(theme.palette.background.paper, 0.5) : alpha(theme.palette.background.paper, 0.8),
        zIndex: 1,
    };

    return (
        <Card
            sx={{
                position: 'relative',
                height: asBackground ? 400 : '100%',
                overflow: 'hidden', // Ensure the overlay does not extend outside the card
                ...(asBackground && (defaultImagePath || preview) ? {
                    backgroundImage: `url(${preview || defaultImagePath})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                } : {}),
                ...sx
            }}
        >
            {/* Conditionally render the overlay based on `asBackground` and `withOverlay` */}
            {withOverlay && <div style={overlayStyle} />}

            <CardHeader
                title={label || t('common.select_file')}
                subheader={description}
                action={(
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                    >
                        <Button
                            color="primary"
                            variant="text"
                            onClick={selectFile}
                        >
                            {t('common.select')}
                        </Button>
                        {(value && onRemove) && (
                            <Button
                                color="error"
                                variant="text"
                                onClick={() => onRemove(name)}
                            >
                                {t('common.remove')}
                            </Button>
                        )}
                    </Stack>
                )}
                sx={{ position: asBackground ? 'relative' : 'initial', zIndex: 2 }}
            />
            {!asBackground && (
                <Stack
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: 300, width: '100%', position: 'relative', zIndex: 2 }}
                >
                    {preview ? (
                        <img src={preview} alt="Preview" style={imageStyle} />
                    ) : defaultImagePath ? (
                        <img src={defaultImagePath} alt="Default" style={imageStyle} />
                    ) : (
                        <Stack sx={{ height: 100, width: '100%' }} justifyContent="center" alignItems="center">
                            <Typography variant="body2" color="textSecondary">
                                {t('common.no_file_selected')}
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            )}
            <input
                {...getInputProps()}
                style={{ display: "none" }}
                ref={fileInputRef}
                type="file"
            />
        </Card>
    );
}

FileSelector.propTypes = {
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func,
    name: PropTypes.string.isRequired,
    asBackground: PropTypes.bool,
    withOverlay: PropTypes.bool,
    sx: PropTypes.object,
    value: PropTypes.any,
    defaultImagePath: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    maxWidth: PropTypes.string,
    maxHeight: PropTypes.string,
};

export default FileSelector;
