import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    CircularProgress,
    ListItem,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText, Chip, Typography, IconButton, Tooltip
} from "@mui/material";
import _ from "lodash";
import useApi from "src/omnia/hooks/use-api";
import ParcelDetails from "./parcel-details";
import { produce } from "immer";
import {ToggleLeft, ToggleRight} from "react-feather";
import { NumericFormat } from "react-number-format";
import {Scrollbar} from "src/omnia/components/elements/scrollbar";
import {useTranslation} from "react-i18next";

function ParcelOptions({zones}){

    const { t } = useTranslation();
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [parcelToEdit, setParcelToEdit] = useState(null);
    const [loading, setLoading] = useState(true);
    const [parcels, setParcels] = useState([]);
    const { get, post } = useApi();

    const handleOpenDetails = () => {
        setParcelToEdit(null);
        setDetailsOpen(true);
    }

    const handleDeletedParcel = (id) => {
        setParcels(prev => prev.filter(p => p.id !== id));
        setParcelToEdit(null);
        setDetailsOpen(false);
    }

    const handleDoneDetails = (updatedParcel) => {
        setParcels(produce(parcels, draft => {
            let index = _.findIndex(parcels, {id: updatedParcel.id});
            if(index !== -1){
                draft[index] = updatedParcel;
            } else {
                draft = parcels.concat([updatedParcel]);
            }
            return draft;
        }));
        setParcelToEdit(null);
        handleCloseDetails();
    }

    const handleCloseDetails = () => {
        setDetailsOpen(false);
    }

    const handleEditParcel = (parcel) => {
        setParcelToEdit(parcel);
        setDetailsOpen(true);
    }

    const handleToggleActive = (parcel) => {
        post('tms/parcels/' + parcel.id + (parcel.is_active ? '/deactivate' : '/activate')).then(() => {
            setParcels(produce(parcels, draft => {
                let index = _.findIndex(parcels, {id: parcel.id});
                if(index !== -1){
                    draft[index]['is_active'] = !parcel.is_active;
                }
                return draft;
            }))
        });
    }

    useEffect(() => {
        get('tms/parcels').then(allParcels => {
            setParcels(allParcels);
        }).finally(() => setLoading(false));
    }, []);

    return (
        <Card>
            <CardHeader
                title={t('resources.sales.parcels')}
                subheader={t('resources.sales.parcel_options_for_delivery')}
                action={(
                    <Button color="secondary" size="small" onClick={() => !detailsOpen ? handleOpenDetails() : handleCloseDetails()}>
                        {detailsOpen ? t('common.cancel') : t('common.add')}
                    </Button>
                )}
            />
            {loading ? (
                <CardContent style={{height: '100%', display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    <div>
                        <CircularProgress />
                    </div>
                </CardContent>
            ) : (
                <>
                    {detailsOpen ? (
                        <CardContent style={{top: 0, position: 'relative'}}>
                            <ParcelDetails
                                zones={zones}
                                parcel={parcelToEdit}
                                onDone={handleDoneDetails}
                                onDelete={handleDeletedParcel}
                            />
                        </CardContent>
                    ) : (
                        <div>
                            {parcels.length > 0 ? (
                                <List>
                                    {parcels.map((parcel, i) => {
                                        const l = parseFloat(parcel.max_length_cm).toFixed(0);
                                        const b = parseFloat(parcel.max_width_cm).toFixed(0);
                                        const h = parseFloat(parcel.max_height_cm).toFixed(0);
                                        const dimensions = '(' + l + ' x ' + b + ' x ' + h + ' cm)';
                                        const parcelDetails = 'Maximal ' + parseFloat(parcel.max_weight_kg).toFixed(1) + ' kg ' + dimensions;
                                        return (
                                            <ListItem
                                                key={i}
                                                disablePadding
                                                secondaryAction={(
                                                    <IconButton onClick={() => handleToggleActive(parcel)}>
                                                        {parcel.is_active ? (
                                                            <Tooltip title="Deaktivieren">
                                                                <ToggleRight />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title="Aktivieren">
                                                                <ToggleLeft />
                                                            </Tooltip>
                                                        )}
                                                    </IconButton>
                                                )}
                                            >
                                                <ListItemButton onClick={() => handleEditParcel(parcel)}>
                                                    <ListItemIcon>
                                                        <Chip label={
                                                            <NumericFormat
                                                                value={parseFloat(parcel.cost_euro) / 100}
                                                                fixedDecimalScale={false}
                                                                suffix={" €"}
                                                                decimalScale={2}
                                                                displayType="text"
                                                                thousandSeparator={'.'}
                                                                decimalSeparator={','}
                                                            />
                                                        } />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={parcel.name}
                                                        secondary={parcelDetails}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            ) : (
                                <div style={{height: 200, display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                                    <Typography variant="body1">
                                        {t('resources.sales.no_parcels')}
                                    </Typography>
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}
        </Card>
    )
}

export default ParcelOptions;