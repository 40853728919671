import React from "react";
import {useTranslation} from "react-i18next";
import {Chip, Tooltip} from "@mui/material";
import PropTypes from "prop-types";
import useIntelligenceFormik from "../../../hooks/formik/use-intelligence-formik";

function AiModelStateChip({model, size = 'medium'}){

    const { t } = useTranslation();
    const { STATE_COLORS } = useIntelligenceFormik();

    if(!model)
        return null

    return (
        <Tooltip title={t('intelligence.models.states.' + model.state + '_info', {name: model?.connector?.name || '--'})} placement="left">
            <Chip size={size} label={t('intelligence.models.states.' + model.state)} color={STATE_COLORS?.[model.state]}/>
        </Tooltip>
    )
}

AiModelStateChip.propTypes = {
    model: PropTypes.object.isRequired,
    size: PropTypes.string
}

export default AiModelStateChip;