import React, { useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const useMgtFormik = (initialData, onSubmit, enableReinitialize = false) => {
    const { t } = useTranslation();

    const hexColorRegex = /^#([0-9A-F]{3}){1,2}$/i;

    const themeFormik = useCallback(useFormik({
        initialValues: initialData ? initialData : {

            main_background: null,
            auth_background: null,
            error_403: null,
            error_404: null,
            error_500: null,
            favicon: null,
            icon: null,
            main_logo_dark: null,
            main_logo_light: null,
            mobile_logo_light: null,
            mobile_logo_dark: null,

            primary_color_lightest: '#97978b',
            primary_color_light: '#6e6e63',
            primary_color_main: '#5b5b50',
            primary_color_dark: '#3c3c32',
            primary_color_darkest: '#1c1c12',
            primary_color_contrast_text: '#FFFFFF',

            secondary_color_lightest: '#eaeadd',
            secondary_color_light: '#dbdbce',
            secondary_color_main: '#b7b7aa',
            secondary_color_dark: '#97978b',
            secondary_color_darkest: '#6f6f63',
            secondary_color_contrast_text: '#090909',

            accent_color_lightest: '#e6e159',
            accent_color_light: '#e3dc3e',
            accent_color_main: '#e1ca37',
            accent_color_dark: '#dfb42d',
            accent_color_darkest: '#dc9d23',
            accent_color_contrast_text: '#090909',

            success_color_lightest: '#F0FDF9',
            success_color_light: '#3FC79A',
            success_color_main: '#10B981',
            success_color_dark: '#0B815A',
            success_color_darkest: '#134E48',
            success_color_contrast_text: '#ffffff',

            info_color_lightest: '#ECFDFF',
            info_color_light: '#CFF9FE',
            info_color_main: '#06AED4',
            info_color_dark: '#0E7090',
            info_color_darkest: '#164C63',
            info_color_contrast_text: '#FFFFFF',

            warning_color_lightest: '#FFFAEB',
            warning_color_light: '#FEF0C7',
            warning_color_main: '#F79009',
            warning_color_dark: '#B54708',
            warning_color_darkest: '#7A2E0E',
            warning_color_contrast_text: '#FFFFFF',

            error_color_lightest: '#FEF3F2',
            error_color_light: '#FEE4E2',
            error_color_main: '#F04438',
            error_color_dark: '#B42318',
            error_color_darkest: '#7A271A',
            error_color_contrast_text: '#FFFFFF',

            spacing: 6,
            button_radius: 20,
            card_radius: 20,
            dialog_radius: 10,
            message_radius: 10,
            popover_radius: 10,
            input_radius: 10
        },
        enableReinitialize: enableReinitialize,
        validationSchema: Yup.object().shape({
            primary_color_lightest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            primary_color_light: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            primary_color_main: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            primary_color_dark: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            primary_color_darkest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            primary_color_contrast_text: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            secondary_color_lightest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            secondary_color_light: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            secondary_color_main: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            secondary_color_dark: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            secondary_color_darkest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            secondary_color_contrast_text: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            accent_color_lightest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            accent_color_light: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            accent_color_main: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            accent_color_dark: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            accent_color_darkest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            accent_color_contrast_text: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            success_color_lightest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            success_color_light: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            success_color_main: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            success_color_dark: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            success_color_darkest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            success_color_contrast_text: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            info_color_lightest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            info_color_light: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            info_color_main: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            info_color_dark: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            info_color_darkest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            info_color_contrast_text: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            warning_color_lightest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            warning_color_light: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            warning_color_main: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            warning_color_dark: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            warning_color_darkest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            warning_color_contrast_text: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            error_color_lightest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            error_color_light: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            error_color_main: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            error_color_dark: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            error_color_darkest: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            error_color_contrast_text: Yup.string().matches(hexColorRegex, t('form.invalid_hex')),
            button_radius: Yup.string().max(10, t('form.max_characters', {value: 10})),
            card_radius: Yup.string().max(10, t('form.max_characters', {value: 10})),
        }),
        onSubmit: onSubmit
    }), [initialData, onSubmit]);

    return {
        themeFormik
    }
}

export default useMgtFormik;
