import React from "react";
import {Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import LogoSelector from "./logo-selector";

function SectionLogo({formik}){

    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={2} sx={{p: 2}}>
                <Grid item xs={12} sx={{mb: 6}}>
                    <Typography
                        variant="h6"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.logo')}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.logo_instructions')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography
                        variant="subtitle1"
                    >
                        {t('mgt.settings.main_logo')}
                    </Typography>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <LogoSelector
                        variant="light"
                        location="desktop"
                        value={formik.values.main_logo_light}
                        onChange={formik.handleChange}
                        name="main_logo_light"
                    />
                </Grid>
                <Grid item xs={12} xl={6}>
                    <LogoSelector
                        variant="dark"
                        location="desktop"
                        value={formik.values.main_logo_dark}
                        onChange={formik.handleChange}
                        name="main_logo_dark"
                    />
                </Grid>
                <Grid item xs={12} sx={{mt: 4}}>
                    <Typography
                        variant="subtitle1"
                    >
                        {t('mgt.settings.mobile_logo')}
                    </Typography>
                </Grid>
                <Grid item xs={12} xl={6}>
                    <LogoSelector
                        variant="light"
                        location="mobile"
                        value={formik.values.mobile_logo_light}
                        onChange={formik.handleChange}
                        name="mobile_logo_light"
                    />
                </Grid>
                <Grid item xs={12} xl={6}>
                    <LogoSelector
                        variant="dark"
                        location="mobile"
                        value={formik.values.mobile_logo_dark}
                        onChange={formik.handleChange}
                        name="mobile_logo_dark"
                    />
                </Grid>
            </Grid>
        </>
    )
}

SectionLogo.propTypes = {
    formik: PropTypes.object.isRequired
}

export default SectionLogo;