import React, {useEffect, useState} from "react";
import {Box, Card, CardMedia} from "@mui/material";
import {Skeleton} from "@mui/lab";
import PropTypes from "prop-types";
import FileModal from "./file-modal";
import {useDialog} from "../../../hooks/use-dialog";
import {useTheme} from "@mui/system";

const ImageCard = ({ image, quality = 'highest', sx = {}, onLoaded = null, onUpdate = null, onDelete = null, containerRef }) => {

    const theme = useTheme();
    const [loaded, setLoaded] = useState(false);
    const [containerWidth, setContainerWidth] = useState(500);
    const dialog = useDialog();

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
        }
    }, [containerRef.current]);

    const handleLoaded = () => {
        setLoaded(true);
        onLoaded?.();
    };

    const aspectRatio = image?.width / image?.height;
    const skeletonHeight = containerWidth / aspectRatio;

    return (
        <Box ref={containerRef} sx={{...{ width: '100%', position: 'relative' }, ...sx}}>
            {!loaded && (
                <Skeleton
                    variant="rounded"
                    width={Math.round(containerWidth)}
                    height={Math.round(skeletonHeight)}
                    sx={{borderRadius: (theme?.config?.card_radius || '10') + 'px'}}
                />
            )}
            <FileModal
                file={image}
                open={dialog.open}
                onClose={dialog.handleClose}
                onUpdate={onUpdate}
                deleteCallback={onDelete}
            />
            <Card
                sx={{
                    backgroundColor: 'transparent',
                    boxShadow: 'none',
                }}
            >
                <CardMedia
                    component="img"
                    onLoad={handleLoaded}
                    onClick={dialog.handleOpen}
                    image={image?.['view_' + quality] || image?.view}
                    sx={{
                        display: loaded ? 'block' : 'none',
                        cursor: 'pointer',
                        width: '100%',
                        height: 'auto',
                    }}
                />
            </Card>
        </Box>
    );
};

ImageCard.propTypes = {
    image: PropTypes.object.isRequired,
    containerRef: PropTypes.object,
    quality: PropTypes.string,
    onLoaded: PropTypes.func,
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    sx: PropTypes.object,
};

export default ImageCard;