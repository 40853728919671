import React from 'react';
import {Box, Grid, Fade, Typography} from "@mui/material";
import FileCard from "src/omnia/components/elements/file-representations/file-card";
import useApi from "src/omnia/hooks/use-api";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import { updateAttachments } from 'src/omnia/store/actions/kanban-actions';
import { produce } from "immer";
import {APP_SETTING} from "../../../../../../../setup";

function Attachments({className, card, ...rest}){

    const boardId = useSelector(state => state.kanban.boardId);
    const dispatch = useDispatch();
    const { post } = useApi();

    const deleteFile = (file) => {
        post('pm/project-board-list-cards/' + card.id + '/remove_attachment', {attachment: file})
            .then(data => {
                dispatch(updateAttachments(boardId, data));
            });
    }

    const updateFile = (file) => {
        let updatedCard = produce(card, draft => {
            let updated = [];
            for(let i = 0; i < card.attachments.length; i++){
                if(card.attachments[i].id === file.id){
                    updated.push(file);
                } else {
                    updated.push(card.attachments[i]);
                }
            }
            draft['attachments'] = updated;
        })
        dispatch(updateAttachments(boardId, updatedCard));
    }

    if(card.attachments.length === 0)
        return null;

    return (
        <div className={className} {...rest}>
            <Box mt={2}>
                <Typography variant="overline" color="textSecondary">
                    Dateien
                </Typography>
            </Box>
            <Box mt={1}>
                <Grid container spacing={2}>
                    {card.attachments.map(file => (
                        <Fade in={true} timeout={APP_SETTING?.transitionDuration || 500} key={'file-' + file?.id}>
                            <Grid item xs={12} sm={6}>
                                <FileCard
                                    file={file}
                                    deleteCallback={deleteFile}
                                    onUpdate={updateFile}
                                    showMedia={false}
                                />
                            </Grid>
                        </Fade>
                    ))}
                </Grid>
            </Box>
        </div>
    )
}

Attachments.propTypes = {
    className: PropTypes.string,
    card: PropTypes.object
}

export default Attachments;