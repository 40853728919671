/**
 * A function that takes a number (a count of things) and returns in the MUI grid system what the single col would be
 */

export default function countToCol(count) {
    if (count === 1) return 12;
    if (count === 2) return 6;
    if (count === 3) return 4;
    if (count === 4 || count === 5) return 3;
    if (count === 6) return 2;
    return 2;
}