import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import PropTypes from 'prop-types';
import {Avatar, CircularProgress, IconButton, OutlinedInput, Stack, SvgIcon, TextField, Tooltip} from '@mui/material';
import {addComment} from 'src/omnia/store/actions/kanban-actions';
import {useCreateSrc} from "src/omnia/hooks/use-create-src";
import useApi from "src/omnia/hooks/use-api";
import Attachment01Icon from "@untitled-ui/icons-react/build/esm/Attachment02";
import EmojiIcon from "@mui/icons-material/TagFaces";
import SendIcon from "@untitled-ui/icons-react/build/esm/ArrowUp";


function NewComment({cardId, className, onCommentAdded, ...rest}) {
    const boardId = useSelector(state => state.kanban.boardId);
    const dispatch = useDispatch();
    const {post} = useApi();
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);

    const handleChange = (event) => {
        event.persist();
        setMessage(event.target.value);
    };

    const handleSend = () => {
        setSending(true);
        post('pm/project-board-list-cards/' + cardId + '/comments', {message: message}).then(comment => {
            setMessage('');
            onCommentAdded(comment);
        }).finally(() => {
            setSending(false);
        })
    }

    const handleKeyDown = async (event) => {
        event.persist();
        if (event.keyCode === 13 && message) {
            event.preventDefault();
            handleSend();
        }
    };

    return (
        <div
            className={className}
            style={{display: 'flex', alignItems: 'center'}}
            {...rest}
        >
            <OutlinedInput
                disabled={sending}
                fullWidth
                multiline
                maxRows={8}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                size="small"
                sx={{ minHeight: 45 }}
                placeholder="Kommentar"
                value={message}
                inputProps={{
                    fontSize: 40
                }}
                endAdornment={sending ? <CircularProgress size={20} /> : (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {message && (
                            <IconButton
                                color="primary"
                                disabled={!(message) || sending}
                                sx={{
                                    backgroundColor: 'primary.main',
                                    color: 'primary.contrastText',
                                    '&:hover': {
                                        backgroundColor: 'primary.dark'
                                    }
                                }}
                                size="small"
                                onClick={handleSend}
                            >
                                <SvgIcon fontSize="small">
                                    <SendIcon />
                                </SvgIcon>
                            </IconButton>
                        )}
                    </Stack>
                )}
                {...rest}
            />
        </div>
    );
}

NewComment.propTypes = {
    cardId: PropTypes.string.isRequired,
    className: PropTypes.string
};

export default NewComment;
