import { loadLanguages } from "redux-multilanguage";

export const UPDATE_SETTING = '@shop/update-setting';
export const UPDATE_VISITOR = '@shop/update-visitor';
export const UPDATE_SCROLL = '@shop/update-scroll';
export const UPDATE_MISSING_TRANSLATION = '@shop/update-missing-translation';
export const UPDATE_CART = '@shop/update-cart';
export const ADD_VOUCHER = '@shop/add-voucher';
export const REMOVE_VOUCHER = '@shop/remove-voucher';
export const RESET_VOUCHERS = '@shop/reset-password-vouchers';
export const REMOVE_FROM_CART = '@shop/remove-from-cart';
export const CLEAR_CART = '@shop/clear-cart';
export const UPDATE_WISHLIST = '@shop/update-wishlist';
export const REMOVE_FROM_WISHLIST = '@shop/remove-from-wishlist';
export const CLEAR_WISHLIST = '@shop/clear-wishlist';
export const SET_COUNTRY = '@shop/set-country';
export const RESET_COUNTRY = '@shop/reset-password-country';
export const SET_DELIVERABLE_PRODUCTS = '@shop/set-deliverable-products';
export const SET_COUNTRIES = '@shop/set-countries';
export const SET_PRIVACY_HANDLED = '@shop/set-privacy-handled';
export const ADD_PRIVACY_SETTING = '@shop/add-privacy-setting';
export const UPDATE_PRIVACY_SETTING = '@shop/udpate-privacy-setting';
export const REMOVE_PRIVACY_SETTING = '@shop/remove-privacy-setting';
export const UPDATE_OPEN_MOTIVATIONS = '@shop/update-open-motivations';
export const UPDATE_SHIPPING_COST = '@shop/update-shipping-cost';
export const SET_DELIVERABLE_COUNTRIES = '@shop/set-deliverable-countries';
export const MULTILANGUAGE_CHANGE_LANGUAGE = '@multilanguage/change-language';
export const STORE_THEME = '@marketing/store-theme';
export const UPDATE_CURRENT_SITE = '@marketing/update-current-site';
export const UPDATE_SITE_SETUP = '@marketing/update-site-setup';
export const SEND_MESSAGE = '@marketing/send-message-internal';
export const REMOVE_MESSAGE = '@marketing/remove-message-internal';
export const SET_INITIALIZED = '@marketing/set-initialized';

export const setInitialized = () => ({
    type: SET_INITIALIZED,
});

export const sendArchitectMessage = (message) => ({
    type: SEND_MESSAGE,
    payload: message,
});

export const removeArchitectMessage = (id) => ({
    type: REMOVE_MESSAGE,
    id,
});

export function updateCurrentSite(currentSite){
    return dispatch => dispatch({
        type: UPDATE_CURRENT_SITE,
        payload: {
            currentSite
        }
    })
}

export function updatePrivacySetting(allPrivacySettings){
    return dispatch => dispatch({
        type: UPDATE_PRIVACY_SETTING,
        payload: {
            allPrivacySettings
        }
    })
}

export function updateThemeOptions(themeOptions){
    return dispatch => dispatch({
        type: STORE_THEME,
        payload: {
            themeOptions
        }
    })
}

export function addPrivacySetting(privateToAdd){
    return dispatch => dispatch({
        type: ADD_PRIVACY_SETTING,
        payload: {
            privateToAdd
        }
    })
}

export function removePrivacySetting(privateToRemove){
    return dispatch => dispatch({
        type: REMOVE_PRIVACY_SETTING,
        payload: {
            privateToRemove
        }
    })
}

export function markPrivacyAsHandled(){
    return dispatch => dispatch({
        type: SET_PRIVACY_HANDLED,
        payload: {}
    })
}

export function setCountry(countryData){
    return (dispatch) => dispatch({
        type: SET_COUNTRY,
        payload: {
            countryData
        }
    })
}

export function saveSetting(data, key) {
    return (dispatch) => dispatch({
        type: UPDATE_SETTING,
        payload: {
            data, key
        }
    })
}

export function updateMissingTranslation(phrase){
    return dispatch => dispatch({
        type: UPDATE_MISSING_TRANSLATION,
        payload: {
            phrase
        }
    })
}

export function updateScrollPosition(scroll) {
    return (dispatch) => dispatch({
        type: UPDATE_SCROLL,
        payload: {
            scroll
        }
    })
}

export function saveLanguages(translations) {
    let data = {
        languages: translations
    };
    return (dispatch) => dispatch(loadLanguages(data));
}

export function updateOpenMotivations(motivations){
    return dispatch => dispatch({
        type: UPDATE_OPEN_MOTIVATIONS,
        payload: {
            motivations
        }
    })
}

export function changeLanguage(identifier){
    return dispatch => dispatch({
        type: MULTILANGUAGE_CHANGE_LANGUAGE,
        payload: {
            identifier
        }
    })
}
