import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {useCallback, useMemo} from "react";

const useTmsFormik = (initialData = {}, onSubmit, enableReinitialize = false) => {
    const {t} = useTranslation();

    const memoizedInitialValues = useMemo(() => {
        return initialData;
    }, [JSON.stringify(initialData)]);

    const allowReInit = memoizedInitialValues !== null;

    const memoizedOnSubmit = useCallback(onSubmit, [onSubmit]);

    const CURRENCIES = useMemo(() => ['euro'], []);

    const PRODUCT_KINDS = useMemo(() => [
        {
            label: t('resources.products.kinds.physical'),
            value: 'physical'
        },
        {
            label: t('resources.products.kinds.digital'),
            value: 'digital'
        },
        {
            label: t('resources.products.kinds.service'),
            value: 'service'
        }
    ], [])

    const PRODUCT_UNITS = useMemo(() => [
        {
            label: t('resources.products.units.t'),
            value: 't',
            valid_for: ['physical']
        },
        {
            label: t('resources.products.units.kg'),
            value: 'kg',
            valid_for: ['physical']
        },
        {
            label: t('resources.products.units.g'),
            value: 'g',
            valid_for: ['physical']
        },
        {
            label: t('resources.products.units.mg'),
            value: 'mg',
            valid_for: ['physical']
        },
        {
            label: t('resources.products.units.km'),
            value: 'km',
            valid_for: ['physical']
        },
        {
            label: t('resources.products.units.m'),
            value: 'm',
            valid_for: ['physical']
        },
        {
            label: t('resources.products.units.cm'),
            value: 'cm',
            valid_for: ['physical']
        },
        {
            label: t('resources.products.units.mm'),
            value: 'mm',
            valid_for: ['physical']
        },
        {
            label: t('resources.products.units.m3'),
            value: 'm3',
            valid_for: ['physical']
        },
        {
            label: t('resources.products.units.l'),
            value: 'l',
            valid_for: ['physical']
        },
        {
            label: t('resources.products.units.stk'),
            value: 'stk',
            valid_for: ['physical', 'digital', 'service']
        },
        {
            label: t('resources.products.units.min'),
            value: 'min',
            valid_for: ['service']
        },
        {
            label: t('resources.products.units.hrs'),
            value: 'hrs',
            valid_for: ['service']
        },
        {
            label: t('resources.products.units.day'),
            value: 'day',
            valid_for: ['service']
        },
        {
            label: t('resources.products.units.mnt'),
            value: 'mnt',
            valid_for: ['service']
        },
    ], []);

    const INVOICE_STATES = useMemo(() => [
        'waiting_for_payment',
        'payment_overdue',
        'partly_paid',
        'paid',
        'in_repayment',
        'completed',
        'canceled',
        'corrected'
    ], [])

    const UNITS_TO_DECIMALS = useMemo(() => {
        return {
            t: 2,
            kg: 1,
            g: 0,
            mg: 0,
            km: 2,
            m: 2,
            cm: 1,
            mm: 0,
            m3: 2,
            l: 2,
            stk: 0,
            min: 0,
            hrs: 1,
            day: 1,
            mnt: 0
        };
    }, []);

    const PRODUCT_STATUS = useMemo(() => [
        {
            label: t('resources.products.status.draft'),
            value: 'draft',
        },
        {
            label: t('resources.products.status.private'),
            value: 'private',
        },
        {
            label: t('resources.products.status.public'),
            value: 'public',
        },
    ], []);

    const ORDER_STATES = useMemo(() => [
        { value: 'offer', label: t('resources.orders.states.offer') },
        { value: 'offer_declined', label: t('resources.orders.states.offer_declined') },
        { value: 'in_creation', label: t('resources.orders.states.in_creation') },
        { value: 'waiting_for_processing', label: t('resources.orders.states.waiting_for_processing') },
        { value: 'is_processing', label: t('resources.orders.states.is_processing') },
        { value: 'processing_overdue', label: t('resources.orders.states.processing_overdue') },
        { value: 'in_delivery', label: t('resources.orders.states.in_delivery') },
        { value: 'delivered', label: t('resources.orders.states.delivered') },
        { value: 'completed', label: t('resources.orders.states.completed') },
        { value: 'in_return', label: t('resources.orders.states.in_return') },
        { value: 'returned', label: t('resources.orders.states.returned') },
        { value: 'canceled', label: t('resources.orders.states.canceled') },
    ], []);

    const FINAL_ORDER_STATES = useMemo(() => ["completed", "returned", "canceled", "offer_declined"], [])

    const NON_PHYSICAL_UNITS = useMemo(() => ['min', 'hrs', 'day', 'mnt'], []);

    const RECEIPT_DATE_TYPES = useMemo(() => [
        { value: 'delivery_date', label: t('resources.receipts.date_types.delivery_date') },
        { value: 'performance_date', label: t('resources.receipts.date_types.performance_date') },
        { value: 'delivery_period', label: t('resources.receipts.date_types.delivery_period') },
        { value: 'performance_period', label: t('resources.receipts.date_types.performance_period') },
        { value: 'no_delivery_date', label: t('resources.receipts.date_types.no_delivery_date') },

    ], [])

    const PAYMENT_STATES = useMemo(() => [
        {
            label: t('resources.payments.states.open'),
            value: 'open'
        },
        {
            label: t('resources.payments.states.canceled'),
            value: 'canceled'
        },
        {
            label: t('resources.payments.states.failed'),
            value: 'failed'
        },
        {
            label: t('resources.payments.states.paid'),
            value: 'paid'
        },
    ], [])

    const PAYMENT_PROVIDER = useMemo(() => [
        {
            label: t('resources.payments.providers.internal'),
            value: 'internal',
            disabled: false
        },
        {
            label: t('resources.payments.providers.mollie'),
            value: 'mollie',
            disabled: false
        },
        {
            label: t('resources.payments.providers.paypal'),
            value: 'paypal',
            disabled: true
        },
        {
            label: t('resources.payments.providers.stripe'),
            value: 'stripe',
            disabled: true
        }
    ], [])

    const isPhysicalGood = useCallback((values) => {
        if(!values)
            return false;
        return values?.kind === 'physical'
    }, []);

    const getItemKind = useCallback((item) => {
        const bestPick = !NON_PHYSICAL_UNITS.includes(item?.unit) ? 'physical' : 'service';
        if(item?.product_variant){
            return item?.product_variant?.product_kind || bestPick;
        }
        // FIXME: digital can only be per product
        return bestPick;
    }, [NON_PHYSICAL_UNITS])

    const getItemsKind = useCallback((items) => {
        // If there are no items, return null
        if(!items || items.length === 0)
            return null;
        // Check if all items have the same getItemKind()
        const kinds = items.map(item => getItemKind(item));
        // If there is only one kind, return it
        if(kinds.every(kind => kind === kinds[0]))
            return kinds[0];
        // If there are multiple kinds, return null
        console.log('Items have different kinds')
        return null;
    }, [getItemKind])

    const getUnit = useCallback((unit) => {
        return PRODUCT_UNITS.find(u => u.value === unit) || null;
    }, [PRODUCT_UNITS])

    const isWeightUnit = useCallback((unit) => {
        const unitElement = getUnit(unit);
        if(!unitElement)
            return false;
        return ['t', 'kg', 'g', 'mg'].includes(unitElement.value)
    }, [getUnit])

    const getUnitSuffix = useCallback((unit) => {
        const hit = getUnit(unit);
        return hit ? (' / ' + hit?.label) : '';
    }, [getUnit])

    const orderInitialValues = useMemo(() => ({
        state: 'in_creation',
        order_type: 'outbound',
        date: null,
        number: null,
        date_from: null,
        date_to: null,
        date_type: 'no_delivery_date',
        days_until_processing_overdue: 2,
        date_when_processing_overdue: null,
        days_until_delivery_auto_arrival: 3,
        return_deadline_days: 14,
        return_deadline_date: null,
        first_name: '',
        last_name: '',
        organization: '',
        email: '',
        phone: '',

        contact_person: '',
        street: '',
        house_number: '',
        addition: '',
        city: '',
        postal: '',
        country: 'DE',

        net_shipment_cost: 0,
        gross_shipment_cost: 0,
        total_discount_percentage: 0.00,
        total_discount_euro: 0,
        project: null,
        contact: null,
        visitor: null,
        device: null,
        created_by: null,
        last_state_update: null,
        modified_at: null,
        items: []
    }), []);

    const orderItemInitialValues = useMemo(() => ({
        id: null,
        position_title: '',
        position_description: '',
        product_variant: null,
        quantity: null,
        unit: 'stk',
        discount_percentage: null,
        discount_amount: null,
        value_added_tax: 19.00,
        price_net: null,
        price_gross: null,
        placed_in_parcel: null
    }), []);

    const paymentTermsInitialValues = useMemo(() => ({
        name: 'Bitte zahlen Sie innerhalb von 10 Tagen',
        payment_term: 10,
        discount_period: null,
        discount_rate: null
    }), []);

    const serviceDateInitialValue = useMemo(() => ({
        date_from: new Date(),
        date_to: null,
        date_type: 'delivery_date',
    }), []);

    const paymentInitialValues = useMemo(() => ({
        identifier: null,
        state: 'open',
        checkout_link: null,
        amount: null,
        currency: 'euro',
        invoice: null,
        cash_account: null,
        payment_provider: 'internal',
        method: null,
        country_code: null
    }), []);

    const productInitialValues = useMemo(() => {
        return memoizedInitialValues ? memoizedInitialValues : {
            name: '',
            description: '',
            short_description: '',

            is_gross_price: true,
            vat_rate: 19.0,
            kind: 'physical',
            status: 'draft',
            unit: 'stk',
            price_euro: 0,
            offer_price_euro: null,
            article_number: '',
            is_product_to_sell: false,
            is_new: false,
            is_deleted: false,
            tags: [],
            categories: [],
            featured: null,
            pictures: [],
            file: null,
        };
    }, [memoizedInitialValues])

    const orderItemValidation = Yup.object().shape({
        id: Yup.number().nullable(),
        position_title: Yup.string().nullable().required(t('form.is_required', { field: t('resources.orders.items.position') })),
        position_description: Yup.string().nullable(),
        product_variant: Yup.object().nullable(),
        quantity: Yup.number().typeError(t('form.invalid_number')).required(t('form.is_required', { field: t('attributes.quantity') })).nullable(),
        unit: Yup.string().oneOf(PRODUCT_UNITS.map(unit => unit.value)).required(t('form.is_required', {field: t('attributes.unit')})),
        discount_percentage: Yup.number().min(0, t('form.min_value', { value: 0 })).max(100, t('form.max_value', { value: 100 })).nullable(),
        discount_amount: Yup.number().typeError(t('form.invalid_number')).min(0, t('form.min_value', { value: 0 })),
        value_added_tax: Yup.number().typeError(t('form.invalid_number')).min(0, t('form.min_value', { value: 0 })).max(100, t('form.max_value', { value: 100 })),
        price_net: Yup.number().typeError(t('form.invalid_number')).required(t('form.is_required', { field: t('attributes.price_net') })).nullable(),
        price_gross: Yup.number().typeError(t('form.invalid_number')).required(t('form.is_required', { field: t('attributes.price_gross') })).nullable(),
        placed_in_parcel: Yup.string().nullable()
    });

    const orderValidation = Yup.object().shape({
        state: Yup.string().oneOf(ORDER_STATES.map(option => option.value)).required(t('form.is_required', { field: t('attributes.state') })),
        order_type: Yup.string().required(t('form.is_required', { field: t('attributes.order_type') })),
        date: Yup.date().nullable(),
        number: Yup.string().nullable(),
        date_from: Yup.date().nullable(),
        date_to: Yup.date().nullable(),
        days_until_processing_overdue: Yup.number().min(1, t('form.min_value', { value: 1 })).required(t('form.is_required', { field: t('attributes.days_until_processing_overdue') })),
        date_when_processing_overdue: Yup.date().nullable(),
        days_until_delivery_auto_arrival: Yup.number().nullable().min(0, t('form.min_value', { value: 0 })),
        return_deadline_days: Yup.number().min(1, t('form.min_value', { value: 1 })).required(t('form.is_required', { field: t('attributes.return_deadline_days') })),
        return_deadline_date: Yup.date().nullable(),
        first_name: Yup.string().nullable(),
        last_name: Yup.string().nullable(),
        organization: Yup.string().nullable(),
        email: Yup.string().email(t('form.invalid_email')).nullable(),
        phone: Yup.string().nullable(),

        contact_person: Yup.string().nullable(),
        street: Yup.string().nullable().required(t('form.is_required', { field: t('attributes.street') })),
        house_number: Yup.string().nullable().required(t('form.is_required', { field: t('attributes.house_number') })),
        addition: Yup.string().nullable(),
        city: Yup.string().nullable().required(t('form.is_required', { field: t('attributes.city') })),
        postal: Yup.string().nullable().required(t('form.is_required', { field: t('attributes.postal') })),
        country: Yup.string().nullable().required(t('form.is_required', { field: t('attributes.country') })),

        net_shipment_cost: Yup.number().min(0, t('form.min_value', { value: 0 })).required(t('form.is_required', { field: t('attributes.net_shipment_cost') })),
        gross_shipment_cost: Yup.number().min(0, t('form.min_value', { value: 0 })).required(t('form.is_required', { field: t('attributes.gross_shipment_cost') })),
        total_discount_percentage: Yup.number().min(0, t('form.min_value', { value: 0 })).max(100, t('form.max_value', { value: 100 })).required(t('form.is_required', { field: t('attributes.total_discount_percentage') })),
        total_discount_euro: Yup.number().min(0, t('form.min_value', { value: 0 })).required(t('form.is_required', { field: t('attributes.total_discount_euro') })),
        project: Yup.number().nullable(),
        contact: Yup.number().nullable().required(t('form.is_required', { field: t('attributes.contact') })),
        visitor: Yup.number().nullable(),
        device: Yup.number().nullable(),
        created_by: Yup.number().nullable(),
        last_state_update: Yup.date().nullable(),
        modified_at: Yup.date().nullable(),
        items: Yup.array().of(orderItemValidation).test('all-items-same-kind', t('resources.orders.items.all_items_must_be_same_kind'), function (value) {
            if(!value || value.length === 0)
                return true;
            return value.every(item => getItemKind(item) === getItemKind(value[0]));
        })
    })

    const paymentTermsValidation = Yup.object().shape({
        payment_term: Yup.number().min(0, t('form.min_value', { value: 0 })).max(365, t('form.max_value', { value: 365 })),
        discount_period: Yup.number().min(0, t('form.min_value', { value: 0 })).max(365, t('form.max_value', { value: 365 })).nullable(),
        discount_rate: Yup.number().min(0, t('form.min_value', { value: 0 })).max(100, t('form.max_value', { value: 100 })).nullable(),
    })

    const serviceDateValidation = Yup.object().shape({
        date_type: Yup.string().required(t('form.is_required', { field: t('attributes.date_type') })).oneOf(RECEIPT_DATE_TYPES.map(dt => dt.value)),
        date_from: Yup.date().nullable(),
        date_to: Yup.date().nullable(),
    })

    const paymentTermsFormik = useFormik({
        initialValues: memoizedInitialValues ? memoizedInitialValues : paymentTermsInitialValues,
        enableReinitialize: allowReInit,
        validationSchema: paymentTermsValidation,
        onSubmit: memoizedOnSubmit,
    })

    const orderFormik = useFormik({
        initialValues: memoizedInitialValues ? memoizedInitialValues : orderInitialValues,
        enableReinitialize: allowReInit,
        validationSchema: orderValidation,
        onSubmit: memoizedOnSubmit,
    })

    const offerFormik = useFormik({
        initialValues: memoizedInitialValues ? memoizedInitialValues : {
            number: null,
            pdf_file: null,
            title: 'Angebot',
            text: 'Gerne bieten wir Ihnen an:',
            postscript: 'Wir freuen uns auf Ihre Auftragserteilung und sichern eine einwandfreie Ausführung zu.',
            date: new Date(),
            due_date: new Date(new Date().setDate(new Date().getDate() + 30)),
            payment_terms: null,
            // service_date: serviceDateInitialValue,
            order: orderInitialValues
        },
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            number: Yup.string().nullable(),
            pdf_file: Yup.string().nullable(),
            title: Yup.string().nullable(),
            text: Yup.string().nullable(),
            postscript: Yup.string().nullable(),
            date: Yup.date().required(t('form.is_required', {field: t('attributes.date')})),
            due_date: Yup.date().nullable(),
            payment_terms: Yup.number().nullable(),
            // service_date: serviceDateValidation,
            order: orderValidation,
        }),
        onSubmit: memoizedOnSubmit,
    })

    const productFormik = useFormik({
        initialValues: productInitialValues,
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            name: Yup.string().max(400, t('form.max_length', {length: 400})).required(t('form.is_required', {field: t('attributes.name')})),
            description: Yup.string(),
            short_description: Yup.string(),
            kind: Yup.string().oneOf(PRODUCT_KINDS.map(kind => kind.value)).required(t('form.is_required', {field: t('attributes.kind')})),
            status: Yup.string().oneOf(PRODUCT_STATUS.map(status => status.value)).required(t('form.is_required', {field: t('attributes.status')})),
            unit: Yup.string()
                .oneOf(PRODUCT_UNITS.map(unit => unit.value))
                .required(t('form.is_required', {field: t('attributes.unit')}))
                .test('service-unit-not-okay', t('resources.products.service_unit_not_allowed'), function (value, values) {
                    if(values.parent.kind === 'service'){
                        return ['stk', 'min', 'hrs', 'day', 'mnt'].includes(value);
                    }
                    return true;
                })
                .test('downloadable-unit-not-okay', t('resources.products.download_unit_not_allowed'), function (value, values) {
                    if(values.parent.kind === 'digital'){
                        return value === 'stk';
                    }
                    return true;
                })
                .test('physical-unit-not-okay', t('resources.products.physical_unit_not_allowed'), function (value, values) {
                    if(values.parent.kind === 'physical'){
                        return !['min', 'hrs', 'day', 'mnt'].includes(value);
                    }
                    return true;
                }),
            price_euro: Yup.number().typeError(t('form.invalid_price')).min(0, t('form.min_value', {value: 0})).required(t('form.is_required', {field: t('attributes.price')})),
            offer_price_euro: Yup.number().typeError(t('form.invalid_price')).nullable().test('is-less-than-price', t('resources.products.offer_price_must_be_less'), function (value, values) {
                return !value || value < this.parent.price_euro;
            }),
            article_number: Yup.string().nullable().required(t('form.is_required', {field: t('attributes.article_number')})),
            vat_rate: Yup.number().min(0, t('form.min_value', {value: 0})).max(100, t('form.max_value', {value: 100})),
            is_gross_price: Yup.bool(),
            is_product_to_sell: Yup.bool(),
            is_new: Yup.bool().required(t('form.is_required', {field: t('resources.products.is_new')})),
            tags: Yup.array().of(Yup.object()),
            categories: Yup.array().of(Yup.object()),
            featured: Yup.number().nullable(),
            pictures: Yup.array().of(Yup.number()),
            file: Yup.number().nullable(),
        }),
        onSubmit: memoizedOnSubmit,
    });

    const productVariantFormik = useFormik({
        initialValues: memoizedInitialValues ? memoizedInitialValues : {
            initial_quantity: 0,
            article_number: null,
            price_euro: null,
            offer_price_euro: null,
            weight: null,
            variant_values: {}
        },
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            article_number: Yup.string().nullable(),
            price_euro: Yup.number().typeError(t('form.invalid_price')).nullable().min(0, t('form.min_value', {value: 0})),
            offer_price_euro: Yup.number().typeError(t('form.invalid_price')).nullable().test('is-less-than-price', t('resources.products.offer_price_must_be_less'), function (value, values) {
                return !value || value < this.parent.price_euro;
            }),
            weight: Yup.number().typeError(t('form.invalid_weight')).nullable().min(0, t('form.min_value', {value: 0})),
            // variant_values: Yup.object(),
        }),
        onSubmit: memoizedOnSubmit,
    })

    const shippingZoneFormik = useFormik({
        initialValues: memoizedInitialValues ? memoizedInitialValues : {
            name: "",
            countries: [],
            max_allowed_order_volume: 1000000,
            free_shipping_after: null,
            shipping_methods: []
        },
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('form.is_required', {field: t('attributes.name')})),
            countries: Yup.array().of(Yup.string()).required(t('form.is_required', {field: t('attributes.countries')})).min(1, t('form.min_array_length', {length: 1})),
            max_allowed_order_volume: Yup.number().min(1, t('form.min_value', {value: 1})),
            free_shipping_after: Yup.number().nullable().min(0, t('form.min_value', {value: 0})),
        }),
        onSubmit: memoizedOnSubmit,
    })

    const parcelFormik = useFormik({
        initialValues: memoizedInitialValues ? memoizedInitialValues : {
            name: '',
            max_weight_kg: 2,
            max_width_cm: 200,
            max_height_cm: 200,
            max_length_cm: 200,
            cost_euro: 399,
            zones: []
        },
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            name: Yup.string().max(100, t('form.max_length', {characters: 100})).required(t('form.is_required', {field: t('attributes.name')})),
            cost_euro: Yup.number().min(0, t('form.min_value', {value: 0})),
            max_weight_kg: Yup.number().min(0, t('form.min_value', {value: 0})),
            max_width_cm: Yup.number().min(0, t('form.min_value', {value: 0})),
            max_height_cm: Yup.number().min(0, t('form.min_value', {value: 0})),
            max_length_cm: Yup.number().min(0, t('form.min_value', {value: 0})),
            zones: Yup.array().min(1, t('form.min_array_length', {length: 1})),
        }),
        onSubmit: memoizedOnSubmit,
    })

    const invoiceFormik = useFormik({
        initialValues: memoizedInitialValues ? memoizedInitialValues : {
            number: null,
            state: 'waiting_for_payment',
            invoice_type: 'outbound',
            service_date: serviceDateInitialValue,
            payment_terms: null,

            // Financial Information
            discount_rate: 0,
            discount_amount: 0,

            // Receipt Information
            street: '',
            house_number: '',
            addition: '',
            city: '',
            postal: '',
            country: 'DE',

            // References
            orders: [],
            contact: null,
            items: [],

            // Receipt
            date: new Date(),
            title: 'Rechnung',
            text: 'Wir stellen Ihnen folgende Leistungen in Rechnung:',
            postscript: 'Wir freuen uns auf Ihre Zahlung und bedanken uns für Ihr Vertrauen.',
            pdf_file: null,

        },
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            number: Yup.string().nullable(),
            state: Yup.string().oneOf(INVOICE_STATES).required(t('form.is_required', {field: t('attributes.state')})),
            invoice_type: Yup.string().oneOf(['outbound', 'inbound']).required(t('form.is_required', {field: t('attributes.invoice_type')})),
            service_date: serviceDateValidation,
            payment_terms: Yup.number().nullable(),

            // Financial Information
            discount_rate: Yup.number().min(0, t('form.min_value', {value: 0})),
            discount_amount: Yup.number().min(0, t('form.min_value', {value: 0})),

            // Receipt Information
            street: Yup.string().nullable().required(t('form.is_required', { field: t('attributes.street') })),
            house_number: Yup.string().nullable().required(t('form.is_required', { field: t('attributes.house_number') })),
            addition: Yup.string().nullable(),
            city: Yup.string().nullable().required(t('form.is_required', { field: t('attributes.city') })),
            postal: Yup.string().nullable().required(t('form.is_required', { field: t('attributes.postal') })),
            country: Yup.string().nullable().required(t('form.is_required', { field: t('attributes.country') })),

            // References
            orders: Yup.array().of(Yup.number()),
            contact: Yup.number().nullable().required(t('form.is_required', { field: t('attributes.contact') })),
            items: Yup.array().of(orderItemValidation),

            // Receipt
            date: Yup.date().required(t('form.is_required', {field: t('attributes.date')})),
            title: Yup.string().required(t('form.is_required', {field: t('attributes.title')})),
            text: Yup.string().required(t('form.is_required', {field: t('attributes.text')})),
            postscript: Yup.string().required(t('form.is_required', {field: t('attributes.postscript')})),
            pdf_file: Yup.string().nullable(),
        }),
        onSubmit: onSubmit
    })

    const paymentFormik = useFormik({
        initialValues: memoizedInitialValues ? memoizedInitialValues : paymentInitialValues,
        enableReinitialize: allowReInit,
        validationSchema: useMemo(() => Yup.object().shape({
            identifier: Yup.string().nullable(),
            state: Yup.string().oneOf(PAYMENT_STATES.map(p => p.value)),
            checkout_link: Yup.string().nullable(),
            amount: Yup.number().min(0, t('form.min_value', {value: 0})).required(t('form.is_required', {field: t('resources.payments.payment_amount')})),
            currency: Yup.string().oneOf(CURRENCIES),
            invoice: Yup.number().nullable().required(t('form.is_required', {field: t('resources.invoices.invoice')})),
            cash_account: Yup.number().nullable().required(t('form.is_required', {field: t('resources.payments.cash_account')})),
            payment_provider: Yup.string().oneOf(PAYMENT_PROVIDER.map(p => p.value)),
            method: Yup.string().nullable(),
            country_code: Yup.string().nullable()
        }), []),
        onSubmit: onSubmit
    })

    return {
        // Forms
        productFormik,
        offerFormik,
        productVariantFormik,
        parcelFormik,
        invoiceFormik,
        orderFormik,
        shippingZoneFormik,
        paymentTermsFormik,
        paymentFormik,

        // Helpers
        isPhysicalGood,
        getUnitSuffix,
        getUnit,
        isWeightUnit,
        getItemKind,
        getItemsKind,

        // Additional
        orderItemInitialValues,

        // Constants
        PRODUCT_UNITS,
        PRODUCT_KINDS,
        PRODUCT_STATUS,
        UNITS_TO_DECIMALS,
        RECEIPT_DATE_TYPES,
        ORDER_STATES,
        FINAL_ORDER_STATES,
        PAYMENT_STATES,
        PAYMENT_PROVIDER,
    };

};

export default useTmsFormik;