import React, {forwardRef, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box, Card as MuiCard, CardContent, CardMedia, SvgIcon, Typography} from '@mui/material';
import {
    CheckSquare as CheckIcon,
    Clock as DueIcon,
    Eye as EyeIcon,
    File as FileIcon,
    MessageCircle as MessageIcon
} from 'react-feather';
import StackAvatars from 'src/omnia/components/modules/core/StackAvatars';
import CardEditModal from './card-dialog';
import {useCreateSrc} from "src/omnia/hooks/use-create-src";
import {useSettings} from "src/omnia/hooks/use-settings";
import useImageLoader from "src/omnia/hooks/use-image-loader";
import {useTheme} from "@mui/system";
import {useLocation, useNavigate} from "react-router";

function cardSelector(state, cardId) {
    const {cards} = state.kanban;
    return cards.byId[cardId];
}

function CardMediaArea({src}){
    const settings = useSettings();
    const { imageSrc } = useImageLoader(src, settings.paletteMode === 'light' ? '/assets/preload/omnia_wide_light.jpg' : '/assets/preload/omnia_wide_dark.jpg');
    return <CardMedia style={{height: 200}} image={imageSrc}/>;
}

const Card = forwardRef(({cardId, dragging = false, preOpen = false, index, list, style = {}, ...rest}, ref) => {
    const card = useSelector((state) => cardSelector(state, cardId));
    const filter = useSelector(state => state.kanban.filter);
    const [members, setMembers] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector(state => state.account.user);
    const users = useSelector(state => state.users.users);
    const getSrc = useCreateSrc();
    const theme = useTheme();

    const handleOpen = () => {
        navigate(location.pathname + '#' + cardId);
    };

    useEffect(() => {
        if(!(typeof card === "undefined" || card === null)){
            setMembers(users.filter(u => card.members.includes(u.id)));
        }
    }, [card]);

    if(typeof card === "undefined" || card === null)
        return <div ref={ref}></div>;

    const nameHit = card.name && filter['query'] && card.name.toLowerCase().includes(filter['query'].toLowerCase());
    const descriptionHit = card.description && filter['query'] && card.description.toLowerCase().includes(filter['query'].toLowerCase());
    const labelHit = card.labels && filter['query'] && card.labels.filter(l => l.name.toLowerCase().includes(filter['query'].toLowerCase())).length > 0;

    if(filter['query'] !== "" && !nameHit && !descriptionHit && !labelHit)
        return <div style={{display: 'none'}} index={index} ref={ref}/>;

    return (
        <div
            index={index}
            ref={ref}
            style={{...style, ...{outline: 'none', paddingTop: theme.spacing(0.75), paddingBottom: theme.spacing(0.75)}}}
            {...rest}
        >
            <MuiCard
                raised={dragging}
                variant={dragging ? 'elevation' : 'outlined'}
                onClick={handleOpen}
            >
                {card.cover && (
                    <CardMediaArea src={getSrc(card.cover)} />
                )}
                <CardContent style={{padding: 10}}>
                    <Typography variant="h6" color="textPrimary">
                        {card.name}
                    </Typography>
                    <Box mt={1} display="flex" alignItems="center">
                        {card.is_archived ? (
                            <Typography variant="body2" color="textSecondary">
                                Ticket ist archiviert
                            </Typography>
                        ) : (
                            <>
                                {card.subscribers.filter(id => user.id === id).length > 0 && (
                                    <SvgIcon sx={{mr: 1}} color="action" fontSize="small">
                                        <EyeIcon/>
                                    </SvgIcon>
                                )}
                                {card.due && (
                                    <SvgIcon
                                        sx={{mr: 1}}
                                        color="action"
                                        fontSize="small"
                                        style={card.is_overdue ? {color: theme.palette.error.main} : {}}
                                    >
                                        <DueIcon/>
                                    </SvgIcon>
                                )}
                                {card.attachments.length > 0 && (
                                    <SvgIcon sx={{mr: 1}} color="action" fontSize="small">
                                        <FileIcon/>
                                    </SvgIcon>
                                )}
                                {card.checklists.length > 0 && (
                                    <SvgIcon sx={{mr: 1}} color="action" fontSize="small">
                                        <CheckIcon/>
                                    </SvgIcon>
                                )}
                                {card?.has_comments && (
                                    <SvgIcon sx={{mr: 1}} color="action" fontSize="small">
                                        <MessageIcon/>
                                    </SvgIcon>
                                )}
                                {card.labels.length > 0 && (
                                    <>
                                        {card.labels.map((label, i) => (
                                            <div key={'card_lab_' + i} style={{
                                                height: '13px',
                                                marginRight: '5px',
                                                width: '13px',
                                                borderRadius: '10px',
                                                backgroundColor: label['color'],
                                            }}/>
                                        ))}
                                    </>
                                )}
                            </>
                        )}
                        <Box flexGrow={1}/>
                        {members.length > 0 && (
                            <StackAvatars avatars={members} limit={5}/>
                        )}
                    </Box>
                </CardContent>
            </MuiCard>
            <CardEditModal
                card={card}
                list={list}
            />
        </div>
    );
});

// Add display name to card forward reference
Card.displayName = "Card"

CardMediaArea.propTypes = {
    src: PropTypes.string.isRequired,
    classes: PropTypes.string
}

Card.propTypes = {
    cardId: PropTypes.number.isRequired,
    className: PropTypes.string,
    dragging: PropTypes.bool,
    preOpen: PropTypes.bool,
    index: PropTypes.number,
    list: PropTypes.object.isRequired,
    style: PropTypes.object
};

export default Card;