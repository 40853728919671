/* eslint-disable no-shadow */
import React, {useState, useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {
    Droppable,
    Draggable
} from 'react-beautiful-dnd';
import {
    Input,
    Box,
    ClickAwayListener,
    Fade,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    OutlinedInput, Chip,
} from '@mui/material';
import {
    deleteList,
    clearList,
    archiveList,
    updateList
} from 'src/omnia/store/actions/kanban-actions';
import Card from './card';
import CardAdd from './card-add';
import { DotsHorizontal as DotsHorizontalIcon } from 'src/omnia/icons/dots-horizontal';
import {useTheme} from "@mui/system";
import useApi from "src/omnia/hooks/use-api";
import {APP_SETTING} from "../../../../../../setup";

function selectList(state, listId) {
    const {lists} = state.kanban;

    return lists.byId[listId];
}

function List({className, featured = false, listId, ...rest}) {

    const theme = useTheme();
    const [isMenuOpen, setMenuOpen] = useState(false);
    const list = useSelector((state) => selectList(state, listId));
    const dispatch = useDispatch();
    const boardId = useSelector(state => state.kanban.boardId);
    const cards = useSelector(state => state.kanban.cards);
    const showArchived = useSelector(state => state.kanban.filter.showArchived);
    const { put, del, post } = useApi();
    const moreRef = useRef(null);
    const [name, setName] = useState('');
    const [isRenaming, setRenaming] = useState(false);
    const [isAddingTicket, setIsAddingTicket] = useState(false);
    const filteredCards = Object.values(cards.byId).filter(card => {
        if(showArchived){
            return card.listId === parseInt(listId);
        } else {
            return (card.listId === parseInt(listId)) && (!card.is_archived);
        }
    }).sort((a, b) => a.position - b.position );

    const handleMenuOpen = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    const handleChange = (event) => {
        event.persist();
        setName(event.target.value);
    };

    const handleRenameInit = () => {
        setRenaming(true);
        setMenuOpen(false);
    };

    const handleRename = async () => {
        if (!name) {
            setName(list.name);
            setRenaming(false);
            return;
        }

        const update = {name};

        setRenaming(false);
        put('pm/project-board-lists', {...update, ...{id: listId}}).then(data => {
            dispatch(updateList(boardId, list.id, data));
        })
    };

    const handleDelete = async () => {
        setMenuOpen(false);

        del('pm/project-board-lists', list.id).catch(() => {
            dispatch(deleteList(boardId, list.id));
        })
    };

    const handleArchive = async () => {
        setMenuOpen(false);
        post('pm/project-board-lists/' + list.id + '/archive').then(() => {
            dispatch(archiveList(boardId, list.id));
        });
    }

    const handleClear = async () => {
        if(window.confirm("Sollen wirklich alle Tickets dieser Liste gelöscht werden?")){
            setMenuOpen(false);
            post('pm/project-board-lists/' + listId + '/clear').then(() => {
                dispatch(clearList(boardId, list.id));
            });
        }
    };

    const handleAddTicketChange = (isAdding) => {
        setIsAddingTicket(isAdding);
    }

    useEffect(() => {
        if (typeof (list) !== "undefined" && list !== null)
            setName(list.name);
    }, [list]);

    if (typeof (list) === "undefined" || !list)
        return null;

    return (
        <div className={className}{...rest}>
            <Fade in={true} timeout={APP_SETTING?.transitionDuration || 500}>
                <Box style={{
                    marginLeft: theme.spacing(1),
                    marginRight: theme.spacing(1),
                    display: 'flex',
                    flexDirection: 'column',
                    overflowY: 'hidden',
                    overflowX: 'hidden',
                    width: 300,
                    [theme.breakpoints.down('sm')]: {
                        width: 250
                    }
                }}>
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',
                            pr: 2,
                            py: 1
                        }}
                    >
                        {isRenaming
                            ? (
                                <ClickAwayListener onClickAway={handleRename}>
                                    <OutlinedInput
                                        autoFocus
                                        fullWidth
                                        size="small"
                                        onBlur={handleRename}
                                        onChange={handleChange}
                                        value={name}
                                        sx={{
                                            backgroundColor: 'backgroundPaper',
                                            '& .MuiInputBase-input': {
                                                px: 2,
                                                py: 1
                                            }
                                        }}
                                    />
                                </ClickAwayListener>
                            )
                            : (
                                <Input
                                    disableUnderline
                                    fullWidth
                                    size="small"
                                    onClick={handleRenameInit}
                                    value={list.name}
                                    sx={{
                                        borderColor: 'transparent',
                                        borderRadius: 1,
                                        borderStyle: 'solid',
                                        borderWidth: 1,
                                        cursor: 'text',
                                        m: '-1px',
                                        '&:hover': {
                                            backgroundColor: 'action.selected'
                                        },
                                        '& .MuiInputBase-input': {
                                            fontWeight: 500,
                                            px: 2,
                                            py: 1
                                        }
                                    }}
                                />
                            )}
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: 'flex'
                            }}
                        >
                            <Chip
                                sx={{ ml: 2 }}
                                label={filteredCards.length}
                            />
                            <IconButton
                                sx={{ ml: 2 }}
                                edge="end"
                                onClick={handleMenuOpen}
                                ref={moreRef}
                            >
                                <DotsHorizontalIcon fontSize="small" />
                            </IconButton>
                        </Box>
                    </Box>
                    {/*<Divider/>*/}
                    <Box
                        sx={{
                            backgroundColor: () => theme.palette.mode === 'dark'
                                ? theme.palette.neutral[800]
                                : theme.palette.neutral[200],
                            borderRadius: theme.config?.card_radius + 'px',
                        }}
                    >
                        <Droppable
                            droppableId={list.id.toString()}
                            type="card"
                            isCombineEnabled={true}
                            ignoreContainerClipping={false}
                            direction="vertical"
                            isDropDisabled={false}
                        >
                            {(provided) => (
                                <Box
                                    minHeight={80}
                                    ref={provided.innerRef}
                                    style={isAddingTicket ? {
                                        flexGrow: 1,
                                        overflowY: 'auto',
                                        paddingTop: theme.spacing(1),
                                        paddingBottom: theme.spacing(1),
                                        paddingLeft: theme.spacing(1.5),
                                        paddingRight: theme.spacing(1.5),
                                        maxHeight: 'calc(100vh - 265px)'
                                    } : {
                                        flexGrow: 1,
                                        overflowY: 'auto',
                                        paddingTop: theme.spacing(1),
                                        paddingBottom: theme.spacing(1),
                                        paddingLeft: theme.spacing(1.5),
                                        paddingRight: theme.spacing(1.5),
                                        maxHeight: 'calc(100vh - 200px)'
                                    }}
                                >

                                    {filteredCards.map((card, index) => (
                                        <Draggable
                                            draggableId={card.id.toString()}
                                            index={index}
                                            key={card.id}
                                        >
                                            {(provided, snapshot) => (
                                                <Card
                                                    cardId={card.id}
                                                    preOpen={featured === card.id.toString()}
                                                    dragging={snapshot.isDragging}
                                                    index={index}
                                                    key={card.id}
                                                    list={list}
                                                    ref={provided.innerRef}
                                                    style={{...provided.draggableProps.style}}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                />
                                            )}
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                </Box>
                            )}
                        </Droppable>
                        <Divider
                            sx={{
                                borderColor: (theme) => theme.palette.mode === 'dark'
                                    ? theme.palette.neutral[700]
                                    : theme.palette.neutral[300]
                            }}
                        />
                        <Box p={2}>
                            <CardAdd onChangeExpanded={handleAddTicketChange} listId={list.id.toString()}/>
                        </Box>
                    </Box>
                    <Menu
                        keepMounted
                        anchorEl={moreRef.current}
                        open={isMenuOpen}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        PaperProps={{style: {width: 240}}}
                        getContentAnchorEl={null}
                    >
                        <MenuItem onClick={handleRenameInit}>
                            Umbenennen
                        </MenuItem>
                        <MenuItem onClick={handleClear}>
                            Liste Leeren
                        </MenuItem>
                        <MenuItem onClick={handleArchive}>
                            Archivieren
                        </MenuItem>
                        <MenuItem onClick={handleDelete}>
                            Löschen
                        </MenuItem>
                    </Menu>
                </Box>
            </Fade>
        </div>
    );
}

List.propTypes = {
    className: PropTypes.string,
    featured: PropTypes.bool,
    listId: PropTypes.string.isRequired
};

export default List;