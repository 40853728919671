import {useEffect, useState} from "react";

function useGenerateVisibility(count, delay = 25){

    const [visible, setVisible] = useState(new Array(count || 0).fill(false));

    useEffect(() => {
        const elements = new Array(count || 0).fill(false);
        const timeouts = elements.map((_, index) =>
            setTimeout(() => {
                setVisible(prevVisible => {
                    const newVisible = [...prevVisible];
                    newVisible[index] = true;
                    return newVisible;
                });
            }, ((delay ? delay : 25) * index))
        );

        return () => timeouts.forEach(clearTimeout);
    }, [count]);

    return {
        visible
    }
}

export default useGenerateVisibility;