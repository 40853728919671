import React from "react";
import {Box, Card, Fade, Grow, Stack, Typography} from "@mui/material";
import {APP_SETTING} from "../../../setup";
import OnIcon from "./icon";
import {useTheme} from "@mui/system";

function TeaserCard({title, body, height = 300}) {

    const theme = useTheme();

    return (
        <Fade in={true} timeout={APP_SETTING?.transitionDuration || 500}>
            <Card sx={{width: '100%'}}>
                <div style={{
                    minHeight: height,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Box p={2}>
                        <Stack spacing={2} alignItems="center" direction="column">
                            {/*<OnIcon*/}
                            {/*    iconName="InfoCircle"*/}
                            {/*    size="large"*/}
                            {/*    sx={{color: theme.palette.text.primary}}*/}
                            {/*/>*/}
                            <Typography variant="h5" textAlign="center">
                                {title}
                            </Typography>
                            <Typography variant="subtitle1" textAlign="center">
                                {body}
                            </Typography>
                        </Stack>
                    </Box>
                </div>
            </Card>
        </Fade>
    )
}

export default TeaserCard;