import React from "react";
import {Box, Divider, Grid, Slider, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import LogoSelector from "./logo-selector";
import ColorPicker from "../../../elements/color-picker";

function SectionShapes({formik}){

    const { t } = useTranslation();

    const marks = [
        {
            value: 0,
            label: '0 px',
        },
        {
            value: 10,
            label: '10 px',
        },
        {
            value: 20,
            label: '20 px',
        },
        {
            value: 30,
            label: '30 px',
        },
        {
            value: 40,
            label: '40 px',
        },
    ];

    function valuetext(value) {
        return `${value} px`;
    }

    return (
        <>
            <Grid container spacing={8} sx={{p: 2, mb: 2}}>
                <Grid item xs={12}>
                    <Typography
                        variant="h6"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.shapes')}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.shapes_instructions')}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{my: 4}} />
            <Grid container spacing={8} sx={{p: 4}}>
                <Grid item xs={12} lg={6}>
                    <Box mt={2}>
                        <Typography
                            variant="subtitle1"
                        >
                            {t('mgt.settings.button_radius')}
                        </Typography>
                        <Slider
                            defaultValue={20}
                            getAriaValueText={valuetext}
                            step={1}
                            value={parseInt(formik.values.button_radius)}
                            onChange={event => {
                                formik.handleChange({
                                    target: {
                                        name: 'button_radius',
                                        value: event.target.value
                                    }
                                })
                            }}
                            max={40}
                            valueLabelDisplay="auto"
                            marks={marks}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box mt={2}>
                        <Typography
                            variant="subtitle1"
                        >
                            {t('mgt.settings.card_radius')}
                        </Typography>
                        <Slider
                            defaultValue={20}
                            getAriaValueText={valuetext}
                            step={1}
                            value={parseInt(formik.values.card_radius)}
                            onChange={event => {
                                formik.handleChange({
                                    target: {
                                        name: 'card_radius',
                                        value: event.target.value
                                    }
                                })
                            }}
                            max={40}
                            valueLabelDisplay="auto"
                            marks={marks}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box mt={2}>
                        <Typography
                            variant="subtitle1"
                        >
                            {t('mgt.settings.dialog_radius')}
                        </Typography>
                        <Slider
                            defaultValue={20}
                            getAriaValueText={valuetext}
                            step={1}
                            value={parseInt(formik.values.dialog_radius)}
                            onChange={event => {
                                formik.handleChange({
                                    target: {
                                        name: 'dialog_radius',
                                        value: event.target.value
                                    }
                                })
                            }}
                            max={40}
                            valueLabelDisplay="auto"
                            marks={marks}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box mt={2}>
                        <Typography
                            variant="subtitle1"
                        >
                            {t('mgt.settings.popover_radius')}
                        </Typography>
                        <Slider
                            defaultValue={20}
                            getAriaValueText={valuetext}
                            step={1}
                            value={parseInt(formik.values.popover_radius)}
                            onChange={event => {
                                formik.handleChange({
                                    target: {
                                        name: 'popover_radius',
                                        value: event.target.value
                                    }
                                })
                            }}
                            max={40}
                            valueLabelDisplay="auto"
                            marks={marks}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box mt={2}>
                        <Typography
                            variant="subtitle1"
                        >
                            {t('mgt.settings.input_radius')}
                        </Typography>
                        <Slider
                            defaultValue={20}
                            getAriaValueText={valuetext}
                            step={1}
                            value={parseInt(formik.values.input_radius)}
                            onChange={event => {
                                formik.handleChange({
                                    target: {
                                        name: 'input_radius',
                                        value: event.target.value
                                    }
                                })
                            }}
                            max={40}
                            valueLabelDisplay="auto"
                            marks={marks}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <Box mt={2}>
                        <Typography
                            variant="subtitle1"
                        >
                            {t('mgt.settings.message_radius')}
                        </Typography>
                        <Slider
                            defaultValue={20}
                            getAriaValueText={valuetext}
                            step={1}
                            value={parseInt(formik.values.message_radius)}
                            onChange={event => {
                                formik.handleChange({
                                    target: {
                                        name: 'message_radius',
                                        value: event.target.value
                                    }
                                })
                            }}
                            max={40}
                            valueLabelDisplay="auto"
                            marks={marks}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    <Box mt={2}>
                        <Typography
                            variant="subtitle1"
                        >
                            {t('mgt.settings.spacing')}
                        </Typography>
                        <Slider
                            defaultValue={6}
                            // getAriaValueText={valuetext}
                            step={1}
                            value={parseInt(formik.values.spacing)}
                            onChange={event => {
                                formik.handleChange({
                                    target: {
                                        name: 'spacing',
                                        value: event.target.value
                                    }
                                })
                            }}
                            min={0}
                            max={20}
                            valueLabelDisplay="auto"
                            marks={marks}
                        />
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}

SectionShapes.propTypes = {
    formik: PropTypes.object.isRequired
}

export default SectionShapes;