import React, { useState } from "react";
import {useTheme} from "@mui/system";
import {Tooltip, Box, Typography, SvgIcon, Stack, Button, Card, GlobalStyles} from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { oneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import parse, { domToReact } from 'html-react-parser';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@untitled-ui/icons-react/build/esm/Copy01';
import { useTranslation } from "react-i18next";
import { marked } from 'marked';
import PropTypes from 'prop-types';
import linkifyHtml from "linkify-html";

const getHtmlWrapperStyles = (theme) => {
    return {
        fontFamily: `Font-${theme?.config?.id}, -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"`,
        color: theme.palette.text.primary,
        '& h1': {
            fontSize: (theme?.config?.font_h1_size || 24) + 'px',
            fontWeight: theme?.config?.font_h1_weight || 700,
            lineHeight: theme?.config?.font_h1_line_height || 1.2,
            marginTop: '0 !important',
            marginBottom: '0 !important',
        },
        '& h2': {
            fontSize: (theme?.config?.font_h2_size || 22) + 'px',
            fontWeight: theme?.config?.font_h2_weight || 700,
            lineHeight: theme?.config?.font_h2_line_height || 1.2,
            marginTop: '0 !important',
            marginBottom: '0 !important',
        },
        '& h3': {
            fontSize: (theme?.config?.font_h3_size || 20) + 'px',
            fontWeight: theme?.config?.font_h3_weight || 700,
            lineHeight: theme?.config?.font_h3_line_height || 1.2,
            marginTop: '0 !important',
            marginBottom: '0 !important',
        },
        '& h4': {
            fontSize: (theme?.config?.font_h4_size || 18) + 'px',
            fontWeight: theme?.config?.font_h4_weight || 700,
            lineHeight: theme?.config?.font_h4_line_height || 1.2,
            marginTop: '0 !important',
            marginBottom: '0 !important',
        },
        '& h5': {
            fontSize: (theme?.config?.font_h5_size || 16) + 'px',
            fontWeight: theme?.config?.font_h5_weight || 700,
            lineHeight: theme?.config?.font_h5_line_height || 1.2,
            marginTop: '0 !important',
            marginBottom: '0 !important',
        },
        '& h6': {
            fontSize: (theme?.config?.font_h6_size || 14) + 'px',
            fontWeight: theme?.config?.font_h6_weight || 700,
            lineHeight: theme?.config?.font_h6_line_height || 1.2,
            marginTop: '0 !important',
            marginBottom: '0 !important',
        },
        '& ol': {
            fontSize: (theme?.config?.font_body1_size || 12) + 'px',
            fontWeight: theme?.config?.font_body1_weight || 400,
            lineHeight: theme?.config?.font_body1_line_height || 1.5,
            paddingLeft: '20px !important',
            marginBottom: '10px !important',
            marginTop: '10px !important',
        },
        '& li': {
            fontSize: (theme?.config?.font_body1_size || 12) + 'px',
            fontWeight: theme?.config?.font_body1_weight || 400,
            lineHeight: theme?.config?.font_body1_line_height || 1.5,
            marginBottom: '0px !important',
        },
        '& ul': {
            fontSize: (theme?.config?.font_body1_size || 12) + 'px',
            fontWeight: theme?.config?.font_body1_weight || 400,
            lineHeight: theme?.config?.font_body1_line_height || 1.5,
            paddingLeft: '20px !important',
            marginBottom: '10px !important',
            marginTop: '10px !important',
        },
        '& a': {
            color: theme.palette.accent.main,
            textDecoration: 'none',
            cursor: 'pointer',
            '&:hover': {
                // textDecoration: 'underline',
                color: theme.palette.accent.dark,
            },
        },

        '& p': {
            fontSize: (theme?.config?.font_body1_size || 12) + 'px',
            fontWeight: theme?.config?.font_body1_weight || 400,
            lineHeight: theme?.config?.font_body1_line_height || 1.5,
            marginBlockEnd: '0 !important',
            marginBlockStart: '0 !important',
        },

        // This is the key addition:
        '& p:empty': {
            // Depending on what you want visually, adjust the margins
            marginBlockStart: '1em !important',
            marginBlockEnd: '1em !important',

            // Optionally, you can also force it to be visible as an actual blank line by using a pseudo-element
            // If you do want a visible blank, uncomment the lines below:
            /*
            '&:before': {
                content: '"\\00a0"', // Non-breaking space
                display: 'inline-block'
            }
            */
        },

        maxWidth: '100%',
        boxSizing: 'border-box',
    }
};

function HtmlWrapper(props) {
    const {
        html,
        styles = {},
        ...rest
    } = props;

    const theme = useTheme();

    const CodeBlock = ({ language = 'plaintext', value = '' }) => {
        const [copied, setCopied] = useState(false);
        const { t } = useTranslation();

        const handleCopy = () => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        };

        return (
            <Card sx={{ my: 2, maxWidth: '100%' }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    sx={{ maxWidth: '100%' }}
                    p={2}
                >
                    <Typography variant="body1">
                        {language}
                    </Typography>
                    <CopyToClipboard text={value} onCopy={handleCopy}>
                        <Tooltip title={copied ? t('common.copied') : t('common.copy')} placement="top">
                            <Button size="small">
                                <Stack direction="row" spacing={1} alignItems="center" sx={{ mx: 1 }}>
                                    <SvgIcon fontSize="small">
                                        <FileCopyIcon />
                                    </SvgIcon>
                                    <Typography variant="body1">
                                        {t('common.copy_code')}
                                    </Typography>
                                </Stack>
                            </Button>
                        </Tooltip>
                    </CopyToClipboard>
                </Box>
                <SyntaxHighlighter
                    language={language}
                    style={theme.palette.mode === 'dark' ? oneDark : oneLight}
                    customStyle={{
                        borderRadius: (theme?.config?.card_radius || 10) + 'px',
                        backgroundColor: theme.palette.background.paper
                    }}
                    codeTagProps={{
                        style: {
                            backgroundColor: theme.palette.background.paper
                        }
                    }}
                    CodeTag={({ className, children }) => (
                        <code
                            className={className}
                            style={{
                                fontSize: (theme?.config?.font_body1_size || 12) + 'px',
                                fontWeight: theme?.config?.font_body1_weight || 400,
                            }}
                        >
                            {children}
                        </code>
                    )}
                >
                    {value}
                </SyntaxHighlighter>
            </Card>
        );
    };

    CodeBlock.propTypes = {
        language: PropTypes.string,
        value: PropTypes.string
    };

    const getCommentedTag = (tag, language) => {
        switch (language) {
            case 'javascript':
            case 'typescript':
                return `/* ${tag} */`;
            case 'python':
                return `# ${tag}`;
            case 'ruby':
                return `# ${tag}`;
            case 'php':
                return `/* ${tag} */`;
            case 'java':
            case 'csharp':
                return `// ${tag}`;
            default:
                return `<!-- ${tag} -->`;
        }
    };

    const getCode = (node, language) => {
        return node.children.map(child => {
            // Process text in the code block
            if (child.type === 'text') {
                return child.data;
            }
            // Process HTML tags in the code block
            if (child.type === 'tag') {
                const tagAsString = getCode(child, language);
                return getCommentedTag(tagAsString, language);
            }
            // Fallback
            return getCode(child);
        }).join('');
    };

    const renderHtml = (html) => {
        if (!html) return null;

        const linkified = linkifyHtml(html, {
            defaultProtocol: 'https',
            attributes: {
                target: '_blank',
                rel: 'noopener noreferrer',
            },
        });

        return parse(marked(linkified), {
            replace: domNode => {
                if (domNode.name === 'pre' && domNode.children && domNode.children.length) {
                    const codeNode = domNode.children.find(child => child.name === 'code');
                    if (codeNode && codeNode.children.length) {
                        const language = codeNode.attribs.class?.replace('language-', '') || '';
                        // Get the processed code
                        const code = getCode(codeNode, language);
                        return (
                            <CodeBlock
                                language={language}
                                value={code}
                            />
                        );
                    }
                }
                return domToReact(domNode);
            }
        });
    };

    return (
        <>
            <Box
                className='html-wrapper'
                sx={{
                    ...getHtmlWrapperStyles(theme),
                    ...styles
                }}
                {...rest}
            >
                {renderHtml(html)}
            </Box>
            <GlobalStyles
                styles={{
                    '.html-wrapper::selection': {
                        backgroundColor: 'red',
                        color: 'white',
                    },
                    '.html-wrapper::-moz-selection': {
                        backgroundColor: 'red',
                        color: 'white',
                    },
                }}
            />
        </>
    );
}

HtmlWrapper.propTypes = {
    html: PropTypes.string.isRequired,
    styles: PropTypes.object
};

export default HtmlWrapper;
export { getHtmlWrapperStyles };
