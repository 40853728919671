import React, {useState} from "react";
import PropTypes from 'prop-types';
import {useDropzone} from 'react-dropzone';
import {
    Box,
    Button,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText, Stack, SvgIcon,
    Tooltip,
    Typography
} from '@mui/material';
import {X as XIcon} from 'src/omnia/icons/x';
import {bytesToSize} from 'src/omnia/utils/bytesToSize';
import {useTranslation} from "react-i18next";
import UploadIcon from '@untitled-ui/icons-react/build/esm/UploadCloud02';
import OnIcon from "./icon";
import SettingsItems from "./settings/settings-items";
import usePopover from "../../hooks/use-popover";
import SmartPopper from "./smart-popper";
import {useTheme} from "@mui/system";

const FileConfiguration = ({settings, file, config, onChange}) => {

    const popover = usePopover();

    const handleChange = (event) => {
        onChange?.({file, config: {...config, [event.target.name]: event.target.value}});
    }

    return (
        <>
            <Tooltip title={null} placement="top">
                <IconButton
                    ref={popover.anchorRef}
                    edge="end"
                    onClick={popover.handleOpen}
                    // onMouseEnter={popover.queueOpen}
                    // onMouseLeave={popover.queueClose}
                >
                    <OnIcon iconName='Settings01' fontSize="small"/>
                </IconButton>
            </Tooltip>
            <SmartPopper
                popover={popover}
                placement="bottom"
                minWidth={500}
                disableHover={true}
            >
                <Box p={2}>
                    <SettingsItems
                        settings={config}
                        fields={settings}
                        handleChange={handleChange}
                    />
                </Box>
            </SmartPopper>

        </>
    )
}

export const FileDropzone = (props) => {

    const {
        accept,
        title = null,
        subtitle = null,
        icon = 'UploadCloud02',
        // eslint-disable-next-line no-unused-vars
        disabled,
        files = [],
        // eslint-disable-next-line no-unused-vars
        getFilesFromEvent,
        maxFiles,
        maxSize,
        minSize,
        // eslint-disable-next-line no-unused-vars
        noClick,
        // eslint-disable-next-line no-unused-vars
        noDrag,
        // eslint-disable-next-line no-unused-vars
        noDragEventsBubbling,
        // eslint-disable-next-line no-unused-vars
        noKeyboard,
        onDrop,
        // eslint-disable-next-line no-unused-vars
        onDropAccepted,
        // eslint-disable-next-line no-unused-vars
        onDropRejected,
        // eslint-disable-next-line no-unused-vars
        onFileDialogCancel,
        onFileConfigChange,
        onRemove,
        onRemoveAll,
        onUpload,
        // eslint-disable-next-line no-unused-vars
        preventDropOnDocument,
        showButtons = true,
        height = 400,
        fileSettings = null,
        ...other
    } = props;

    const handleDrop = (newFiles) => {

        if(fileSettings === null){
            onDrop(newFiles);
        } else {
            onDrop(newFiles?.map(file => {
                return {
                    file: file,
                    config: {}
                }
            }));
        }

    }

    // We did not add the remaining props to avoid component complexity
    // but you can simply add it if you need to.
    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept,
        maxFiles,
        maxSize,
        minSize,
        onDrop: handleDrop
    });

    const {t} = useTranslation();
    const theme = useTheme();
    const withConfig = fileSettings !== null;

    const getIconName = (type) => {
        if(type?.includes('image')){
            return "Image01"
        }
        if(type?.includes('video')){
            return "Film02"
        }
        return "File02"
    }

    return (
        <>
            <Box
                sx={{
                    height: height,
                    width: '100%',
                    alignItems: 'center',
                    border: 1,
                    borderRadius: (theme?.config?.input_radius || '10') + 'px',
                    borderStyle: 'dashed',
                    borderColor: 'divider',
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    outline: 'none',
                    p: 6,
                    ...(isDragActive && {
                        backgroundColor: 'action.active',
                        opacity: 0.5
                    }),
                    '&:hover': {
                        backgroundColor: 'action.hover',
                        cursor: 'pointer',
                        opacity: 0.5
                    }
                }}
                {...other}
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <Stack
                    direction="column"
                    alignItems="center"
                    spacing={2}
                >
                    {icon && (
                        <OnIcon iconName={icon} fontSize="large"/>
                    )}
                    <Stack
                        direction="column"
                        alignItems="center"
                        spacing={0.5}
                    >
                        {title && (
                            <Typography variant="h6" sx={{textAlign: 'center'}}>
                                {title}
                            </Typography>
                        )}
                        {subtitle && (
                            <Typography variant="body1" sx={{textAlign: 'center'}}>
                                {subtitle}
                            </Typography>
                        )}
                    </Stack>
                </Stack>
            </Box>
            {files.length > 0 && (
                <Box>
                    <List sx={{maxHeight: 300, overflow: 'auto'}}>
                        {files.map((file) => (
                            <ListItem
                                key={file.path}
                                sx={{
                                    border: 1,
                                    borderColor: 'divider',
                                    borderRadius: 1,
                                    '& + &': {
                                        mt: 1
                                    }
                                }}
                            >
                                <ListItemIcon>
                                    <OnIcon iconName={getIconName(withConfig ? file?.file?.type : file?.type)} fontSize="small"/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={withConfig ? file?.file?.name : file?.name}
                                    primaryTypographyProps={{
                                        color: 'textPrimary',
                                        variant: 'subtitle2'
                                    }}
                                    secondary={bytesToSize(withConfig ? file?.file?.size : file?.size)}
                                />
                                <Stack direction="row" spacing={1}>
                                    {fileSettings && (
                                        <FileConfiguration
                                            settings={fileSettings}
                                            file={file?.file}
                                            config={file?.config}
                                            onChange={onFileConfigChange}
                                        />
                                    )}
                                    <Tooltip placement="top" title={t("common.remove")}>
                                        <IconButton
                                            edge="end"
                                            onClick={() => onRemove?.(file)}
                                        >
                                            <XIcon fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                            </ListItem>
                        ))}
                    </List>
                    {showButtons && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                mt: 2
                            }}
                        >
                            <Button
                                onClick={onRemoveAll}
                                size="small"
                                type="button"
                            >
                                {t("common.remove_all")}
                            </Button>
                            <Button
                                onClick={onUpload}
                                size="small"
                                sx={{ml: 2}}
                                type="button"
                                variant="contained"
                            >
                                {t("common.buttons.upload")}
                            </Button>
                        </Box>
                    )}
                </Box>
            )}
        </>
    );
};

FileDropzone.propTypes = {
    accept: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string.isRequired)
    ]),
    disabled: PropTypes.bool,
    showButtons: PropTypes.bool,
    files: PropTypes.array,
    getFilesFromEvent: PropTypes.func,
    maxFiles: PropTypes.number,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    noClick: PropTypes.bool,
    noDrag: PropTypes.bool,
    noDragEventsBubbling: PropTypes.bool,
    noKeyboard: PropTypes.bool,
    onDrop: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
    onFileDialogCancel: PropTypes.func,
    onRemove: PropTypes.func,
    onRemoveAll: PropTypes.func,
    onUpload: PropTypes.func,
    preventDropOnDocument: PropTypes.bool,
    height: PropTypes.number,
    fileSettings: PropTypes.array,
    onFileConfigChange: PropTypes.func,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    icon: PropTypes.string,
};
