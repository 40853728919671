import { produce } from 'immer';
import {
    UPDATE_SITE,
    UPDATE_ENTITY,
    REMOVE_ENTITY,
    INIT_ENTITY,
    SET_ADD_ELEMENT,
} from 'src/omnia/store/actions/site-designer-actions';

const initialState = {
    site: null,
    addElementOpen: false,
    initialized: false,
    sections: [],
    rows: [],
    columns: [],
    elements: [],
    texts: [],
    images: []
};

function handleUpdate(collection, item) {

    if (!item.hasOwnProperty('position')) {
        // Fallback: just do old logic if there's no 'position'
        const idx = collection.findIndex(i => i.id === item.id);
        if (idx !== -1) {
            collection[idx] = item;
        } else {
            collection.push(item);
        }
        return;
    }

    // 1. Remove if it already exists
    const existingIndex = collection.findIndex(i => i.id === item.id);
    let oldPos = null;
    if (existingIndex !== -1) {
        oldPos = collection[existingIndex].position;
        collection.splice(existingIndex, 1);
    }

    const newPos = item.position;

    // 2. Shift other items if item is "moving"
    if (oldPos !== null && oldPos !== newPos) {
        if (newPos < oldPos) {
            // Move up: shift everything in [newPos .. oldPos-1] down by +1
            collection.forEach(obj => {
                if (obj.hasOwnProperty('position') &&
                    obj.position >= newPos &&
                    obj.position < oldPos
                ) {
                    obj.position += 1;
                }
            });
        } else {
            // Move down: shift everything in (oldPos .. newPos] up by -1
            collection.forEach(obj => {
                if (obj.hasOwnProperty('position') &&
                    obj.position > oldPos &&
                    obj.position <= newPos
                ) {
                    obj.position -= 1;
                }
            });
        }
    }
    // 3. If it's a brand new item, or no old position was found:
    else if (oldPos === null) {
        // shift items with position >= newPos by +1
        collection.forEach(obj => {
            if (obj.hasOwnProperty('position') && obj.position >= newPos) {
                obj.position += 1;
            }
        });
    }

    // 4. Insert the item at its newPos exactly
    collection.push(item);

    // You can optionally re-sort if you always want them in ascending position in memory
    collection.sort((a, b) => a.position - b.position);

}

function handleRemove(collection, itemId) {
    // Remove the item if it exists
    const index = collection.findIndex(i => i.id === itemId);
    if (index !== -1) {
        // Get the item
        const item = collection[index];

        // Remove it from collections
        collection.splice(index, 1);

        // If it has a position attribute, re-sort & reindex
        if (item.hasOwnProperty('position')) {
            // Sort by the position field (lowest to highest)
            collection.sort((a, b) => a.position - b.position);

            // 4. Reassign positions from 0..(length-1) to ensure no gaps
            collection.forEach((elem, idx) => {
                elem.position = idx;
            });
        }
    }
}

const siteDesignerReducer = (state = initialState, action) => {

    let updatedState = state;

    switch (action.type) {

        case UPDATE_SITE: {
            updatedState = produce(state, draft => {
                draft.site = action.payload.site;
                draft.initialized = true;
            });
            break;
        }

        case SET_ADD_ELEMENT: {
            updatedState = produce(state, draft => {
                draft.addElementOpen = action.payload.open;
            });
            break;
        }

        case INIT_ENTITY: {
            updatedState = produce(state, draft => {
                const entity = action.payload.entity;
                draft[entity] = action.payload.objects;
                // Sort collection after initialization, only if items have 'position' attribute
                if (draft[entity].length > 0 && draft[entity][0].hasOwnProperty('position')) {
                    draft[entity].sort((a, b) => a.position - b.position);
                }
            });
            break;
        }

        case UPDATE_ENTITY: {
            updatedState = produce(state, draft => {
                const entity = action.payload.entity;
                let data = action.payload.object;
                if(action.payload.extendData){
                    const index = draft[entity]?.findIndex(i => i.id === data?.id);
                    if (index >= 0) {
                        data = produce(draft[entity][index], draft => {
                            Object.keys(data).forEach(key => {
                                draft[key] = data[key];
                            });
                        });
                    }
                }
                handleUpdate(draft[entity], data);
            });
            break;
        }

        case REMOVE_ENTITY: {
            updatedState = produce(state, draft => {
                const entity = action.payload.entity;
                handleRemove(draft[entity], action.payload.objectId);
            });
            break;
        }

    }

    // console.log('redux update', state, updatedState, action);

    return updatedState;
};

export default siteDesignerReducer;
