import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {colors} from '@mui/material';
import {useTheme} from "@mui/system";

function OnlineIndicator({size = 'medium', status = 'offline', ...rest}) {

    const theme = useTheme();
    const [bg, setBackground] = useState(colors.grey[500]);
    const [sz, setSize] = useState(theme.spacing(1));

    useEffect(() => {
        if (status === 'offline')
            setBackground(colors.grey[500]);
        if (status === 'away')
            setBackground(theme.palette.warning.main);
        if (status === 'busy')
            setBackground(theme.palette.error.main);
        if (status === 'online')
            setBackground(theme.palette.success.main);
    }, [status]);

    useEffect(() => {
        if (size === 'small')
            setSize(theme.spacing(0.9))
        if (size === 'medium')
            setSize(theme.spacing(1.2))
        if (size === 'large')
            setSize(theme.spacing(2))
    }, [size]);

    return (
        <span
            style={{
                display: 'inline-block',
                borderRadius: '50%',
                flexGrow: 0,
                height: sz,
                width: sz,
                backgroundColor: bg,
                flexShrink: 0
            }}
            {...rest}
        />
    );
}

OnlineIndicator.propTypes = {
    className: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    status: PropTypes.oneOf(['online', 'offline', 'away', 'busy'])
};

export default OnlineIndicator;
