import React, { useState } from "react";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    Dialog,
    Stack,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useApi from "../../../hooks/use-api";
import PropTypes from "prop-types";
import SaveButton from "../save-button";
import { useNotifications } from "../../../hooks/use-notifications";
import SharingFormContent from "./sharing-form-content";
import { useSelector } from "react-redux";

function SharingDialog({ open, onClose, entity, endpoint }) {
    const { t } = useTranslation();
    const [saving, setSaving] = useState(false);
    const [sharingConfig, setSharingConfig] = useState({});
    const { notifySuccess } = useNotifications();
    const user = useSelector((state) => state.account.user);
    const { post } = useApi();

    const handleSave = () => {
        setSaving(true);
        post(`${endpoint}/${entity?.id}/update_sharing_config`, sharingConfig)
            .then(() => {
                onClose();
                notifySuccess(t);
            })
            .finally(() => {
                setSaving(false);
            });
    };

    const userGroups = user?.user_groups || [];

    if(!entity?.is_owner)
        return null;

    return (
        <Dialog open={open} fullWidth={true} maxWidth="sm" onClose={onClose}>
            <DialogTitle>
                <Typography variant="h4">
                    {t("core.share_content", {
                        name: entity?.object_name || entity?.entity_name || "Objekt",
                    })}
                </Typography>
            </DialogTitle>

            <DialogContent sx={{ minHeight: 300 }}>
                <SharingFormContent
                    entity={entity}
                    endpoint={endpoint}
                    onConfigChange={setSharingConfig}
                    mt={2}
                />
            </DialogContent>

            <DialogActions>
                <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
                    <Button onClick={onClose} variant="outlined">
                        {t("common.cancel")}
                    </Button>
                    <SaveButton loading={saving} variant="contained" onClick={handleSave} />
                </Stack>
            </DialogActions>

        </Dialog>
    );
}

SharingDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    entity: PropTypes.object.isRequired,
    endpoint: PropTypes.string.isRequired,
};

export default SharingDialog;
