import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import DataTable from "./data-table";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import {useNotifications} from "../../../hooks/use-notifications";
import PropTypes from "prop-types";
import useOmniaApi from "../../../hooks/use-omnia-api";
import {useTranslation} from "react-i18next";
import {Card, Chip, CircularProgress, Typography} from "@mui/material";
import getCleanUrl from "../../../utils/get-clean-url";
import {useIsMobile} from "../../../hooks/use-is-mobile";
import MobileDataGrid from "./mobile-table";
import moment from "moment";
import {addEndpointToList, removeEndpointFromList, setModelMeta} from "../../../store/actions/datatable-actions";
import {NumericFormat} from "react-number-format";

const defaultPaginationModel = {
    pageSize: 10,
    page: 0,
}

const ServerSideDataTable = memo(function ServerSideDataTable(props) {

    const {
        id,
        endpoint,
        height = 800,
        metaEndpoint,
        defaultQuery = {},
        defaultState = null,
        excludeFields = [],
        excludeEditableFields = [],
        canEdit = true,
        canRemove = false,
        columns = null,
        noCard = false,
        handleRemove = null,
        updateListener = null,
        flashWarnings = false,
        ...rest
    } = props;

    const [state, setState] = useState(null);
    const {get, put, del, flashErrors} = useOmniaApi({autoError: false});
    const tableStates = useSelector(state => state.datatable.tableStates);
    const updates = useSelector(state => state.datatable.updates);
    const modelMeta = useSelector(state => state.datatable.modelMeta);
    const {isMobile} = useIsMobile();
    const [paginationModel, setPaginationModel] = useState(defaultPaginationModel);
    const [updateListenerInitial, setUpdateListenerInitial] = useState(true);
    const [loadingMeta, setLoadingMeta] = useState(typeof modelMeta?.[endpoint] === 'undefined');
    const [finalColumns, setFinalColumns] = useState(null);
    const [deleteActive, setDeleteActive] = useState(false);
    const [fetching, setFetching] = useState(true);
    const user = useSelector(state => state.account.user);
    const [count, setCount] = useState(0);
    const [rows, setRows] = useState([]);
    const {notify, notifySuccess} = useNotifications();
    const {t} = useTranslation();
    const requestIdRef = useRef(0);
    const dispatch = useDispatch();
    const prevConfigRef = useRef({updateTimestamp: 0, groupId: user?.current_group?.id || null})
    const stateRef = useRef();

    stateRef.current = state;

    const updateTimestamp = updates?.[updateListener || endpoint] || 0;

    const transformToDefaultState = (instructions, columns) => {

        // Check if instructions are null
        if(!instructions)
            return null;

        // Initialize the state structure based on the provided "MUI Grid State" form
        const gridState = {
            columns: {
                columnVisibilityModel: {},
                dimensions: {},
                orderedFields: ['__check__'],
                filter: { filterModel: {} },
                pagination: { paginationModel: { page: 0, pageSize: 10 } },
            },
            pinnedColumns: { left: [], right: [] },
            sorting: { sortModel: [] },
        };

        // If __check__ is not contained in instructions, set it also as invisible
        if (!instructions.some(column => column.field === '__check__')){
            gridState.columns.columnVisibilityModel['__check__'] = false;
        }

        instructions.forEach(column => {
            gridState.columns.columnVisibilityModel[column.field] = true; // Assuming all fields are visible
            gridState.columns.dimensions[column.field] = { maxWidth: -1, minWidth: 50, width: column.width || 200 };
            if(gridState.columns.orderedFields.indexOf(column.field) === -1)
                gridState.columns.orderedFields.push(column.field);
            if (column.sorted) {
                gridState.sorting.sortModel.push({ field: column.field, sort: column.sorted });
            }
            if (column.pinned) {
                gridState.pinnedColumns[column.pinned].push(column.field);
            }
        });

        // Iterate over all other columns and set them to be invisible
        columns.forEach(column => {
            if (!gridState.columns.columnVisibilityModel[column.field]) {
                gridState.columns.columnVisibilityModel[column.field] = false;
            }
        });

        return gridState;
    }

    const handleWarning = (warning) => {
        if (flashWarnings)
            notify(t(warning), "error");
    }

    const handleRemoveData = useCallback((ids) => {
        if (window.confirm(t("notify.are_you_sure"))) {
            del(getCleanUrl(metaEndpoint || endpoint), ids).then(() => {
                notifySuccess(t);
                if(handleRemove)
                    handleRemove(ids);
                handleStateChange(state, {page: 0, pageSize: paginationModel.pageSize});
            }).catch(errors => {
                flashErrors(errors);
            })
        }
    }, [endpoint, deleteActive])

    const handleStateChange = (state, newSlice) => {

        // Make sure that we do not update the state if the layout was just changed
        if (typeof (newSlice.colDef) === "undefined") {

            let newState = state;
            // Check if the new slice (updated part of state) is the pagination model
            if (typeof (newSlice) !== "undefined" && typeof (newSlice.page) !== "undefined") {
                setPaginationModel(newSlice);
                newState = {...newState, ...{pagination: {paginationModel: newSlice}}}
            }

            // Set the updated state
            setState(newState);
        }

    }

    const transformGridStateToQuery = useCallback((gridState, defaultQuery) => {

        const query = {...defaultQuery, ...{page: 0, size: 10}};

        // Handle mobile search
        const search = gridState?.searchQuery || null;
        if(search){
            query['search'] = search;
        }

        // Handle Pagination
        if (gridState?.pagination && gridState?.pagination?.paginationModel) {
            query.page = gridState.pagination.paginationModel.page;
            query.size = isMobile ? 10 : gridState.pagination.paginationModel.pageSize;
        }

        // Handle Filtering
        if (!isMobile) {
            const filterItems = gridState?.filter && gridState?.filter?.filterModel?.items || [];
            if (filterItems && filterItems.length) {
                filterItems.forEach(item => {

                    let value = item.value;

                    if (value instanceof Date) {
                        value = value.toISOString();
                    }

                    if (!(typeof value === 'undefined' || value === null)) {
                        switch (item.operator) {
                            case 'is':
                                query[item.field] = value;
                                break;
                            case 'contains':
                                query[`${item.field}__icontains`] = value;
                                break;
                            case 'equals':
                                query[item.field] = value;
                                break;
                            case 'greaterThan':
                                query[`${item.field}__gt`] = value;
                                break;
                            case '>':
                                query[`${item.field}__gt`] = value;
                                break;
                            case 'greaterThanOrEqual':
                                query[`${item.field}__gte`] = value;
                                break;
                            case '>=':
                                query[`${item.field}__gte`] = value;
                                break;
                            case 'lessThan':
                                query[`${item.field}__lt`] = value;
                                break;
                            case '<':
                                query[`${item.field}__lt`] = value;
                                break;
                            case 'lessThanOrEqual':
                                query[`${item.field}__lte`] = value;
                                break;
                            case '<=':
                                query[`${item.field}__lte`] = value;
                                break;
                            case 'after':
                                query[`${item.field}__gt`] = value;
                                break;
                            case 'onOrAfter':
                                query[`${item.field}__gte`] = value;
                                break;
                            case 'onOrBefore':
                                query[`${item.field}__lte`] = value;
                                break;
                            case 'before':
                                query[`${item.field}__lt`] = value;
                                break;
                            case 'isEmpty':
                                query[`${item.field}__isnull`] = true;
                                break;
                            case 'isNotEmpty':
                                query[`${item.field}__isnull`] = false;
                                break;
                            case 'startsWith':
                                query[`${item.field}__istartswith`] = value;
                                break;
                            case 'endsWith':
                                query[`${item.field}__iendswith`] = value;
                                break;
                            case 'isAnyOf':
                                query[`${item.field}__in`] = value;
                                break;
                            case 'not':
                                query[`${item.field}__not`] = value;
                                break;
                            case 'doesNotEqual':
                                query[`${item.field}__not`] = value;
                                break;
                            case '!=':
                                query[`${item.field}__nequal`] = value;
                                break;
                            case '=':
                                query[`${item.field}__exact`] = value;
                                break;
                            default:
                                console.log('Not supported filter:', item);
                                notify(t("common.sst_filter_not_supported"), "warning");
                                break;
                        }
                    }

                });
            }
        }

        // Check if the logic is or
        if (gridState?.filter && gridState?.filter?.filterModel?.logicOperator) {
            if (gridState.filter.filterModel.logicOperator === 'or') {
                notify(t("common.sst_or_filter_not_supported"), "error");
            }
        }

        // Handle Sorting
        const sortItems = gridState?.sorting && gridState?.sorting?.sortModel;
        if (sortItems && sortItems.length) {
            // I'm only considering the first sort model for simplicity. You can extend this to handle multiple sort fields.
            const sortItem = sortItems[0];
            if (sortItem.sort === 'desc') {
                query.ordering = `-${sortItem.field}`;
            } else {
                query.ordering = sortItem.field;
            }
        }

        // Translate page from 0-based MUI to 1-based GROON pagination system
        query['page'] = query['page'] + 1;

        return query;
    }, [isMobile]);

    const getDateTimeObject = (data) => {
        if (!data || data === 'None')
            return null;
        return new Date(data);
    }

    const renderDateObject = (data) => {
        if(!data.value)
            return null;
        return (
            <Typography variant="body2" color="inherit">
                {moment(data.value).format('DD.MM.YYYY')}
            </Typography>
        );
    }

    const renderDateTimeObject = (data) => {
        if(!data.value)
            return null;
        return (
            <Typography variant="body2" color="inherit">
                {moment(data.value).format('DD.MM.YYYY - HH:mm') + ' Uhr'}
            </Typography>
        );
    }

    const handleLoadData = (withNotice) => {
        if(typeof withNotice === 'undefined' || withNotice === true)
            setFetching(true);
        const currentRequestId = ++requestIdRef.current;
        let query = transformGridStateToQuery(stateRef.current, defaultQuery);
        get(endpoint, query)
            .then(response => {
                if (currentRequestId === requestIdRef.current) {
                    setCount(response.count);
                    setRows(response.results);
                }
            })
            .catch(errors => {
                for (let i = 0; i < errors.length; i++) {
                    if (errors[i].message === 'Invalid page.') {
                        handleStateChange(state, {page: 0, pageSize: paginationModel.pageSize});
                    } else {
                        // notify(t("Grid could not load details"), "error");
                        flashErrors(errors);
                        handleWarning('Table Error: Error while fetching details: ' + errors);
                    }
                }
            })
            .finally(() => {
                if (currentRequestId === requestIdRef.current) {
                    setFetching(false);
                }
            })
    }

    const processModelMeta = (data) => {
        // Check if
        if (canRemove) {
            if (!data.can_delete)
                handleWarning('Table Warning: Prop canRemove is true, but GROON does not allow deletion.')
            setDeleteActive(data.can_delete)
        }

        // Construct automatic columns
        const autoColumns = data.columns.map(column => {
            return {
                field: column.name,
                type: column.type,
                width: 200,
                groupable: true,
                sortable: true,
                editable: canEdit ? column.editable : false,
                headerName: column.verbose_name,
                ...(column.type === 'singleSelect' ? {
                    valueOptions: (column?.choices || [])?.map(v => {
                        return {value: v[0], label: v[1]}
                    }), renderCell: (data) => {
                        if(data.value === null)
                            return null;
                        // Check if data.value is an object
                        if (typeof data.value === 'object') {
                            return (
                                <Chip label={data.value?.name || data.value?.title || ('Objekt #' + data.value?.id)} />
                            )
                        } else {
                            return (
                                <Chip label={data.value}/>
                            )
                        }
                    }
                } : {}),
                ...(column.type === 'date' ? {
                    valueGetter: getDateTimeObject,
                    renderCell: renderDateObject
                } : {}),
                ...(column.type === 'dateTime' ? {
                    valueGetter: getDateTimeObject,
                    renderCell: renderDateTimeObject
                } : {}),
                ...(column.type === 'time' ? {
                    valueGetter: getDateTimeObject,
                    renderCell: data => moment(getDateTimeObject(data)).format('HH:mm')
                } : {}),
                ...(column.type === 'boolean' ? {
                    renderCell: (data) => {
                        if(data.value === null)
                            return null;
                        return (
                            <Chip
                                label={data.value ? t("common.yes") : t('common.no')}
                                color={data.value ? 'success' : 'error'}
                            />
                        )
                    }
                } : {}),
                ...(column.type === 'number' ? {
                    renderCell: (data) => {
                        const variable = data.value;
                        if (typeof variable === 'number') {
                            let decimals;
                            if (Number.isInteger(variable)) {
                                decimals = 0;
                            } else {
                                decimals = 2;
                            }
                            return (
                                <NumericFormat
                                    value={parseFloat(variable)}
                                    displayType={'text'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    decimalScale={decimals}
                                    fixedDecimalScale={true}
                                />
                            )
                        } else {
                            return variable;
                        }
                    }
                } : {})
            }
        });

        // Compute the final columns
        const finalColumns = autoColumns

            // Map the loaded fields
            .map(metaField => {

                // Find the item in array2 that has the same 'field' value as metaField
                let passedField = columns && columns.find(passedField => passedField.field === metaField.field);

                // Additional check for singleSelect fields
                if (passedField && metaField.type === 'singleSelect' && passedField.valueOptions) {
                    const validOptions = new Set(metaField.valueOptions.map(option => option.value));
                    const invalidOptions = passedField.valueOptions.filter(option => !validOptions.has(option.value));
                    if (invalidOptions.length > 0) {
                        handleWarning(`Table Warning: Invalid valueOptions for field ${metaField.field}:`, invalidOptions, ' (Overwriting with GROON API Feedback)');
                        passedField.valueOptions = metaField.valueOptions;
                    }
                }

                // Check for type mismatch
                if (passedField && metaField.type !== passedField.type) {
                    handleWarning(`Table Warning: Type mismatch for field ${metaField.field}. Expected ${metaField.type}, got ${passedField.type} (Overwriting with GROON API Feedback)`);
                    passedField.type = metaField.type;
                }

                // If found, merge passedField's properties into metaField, then override sortable to true, otherwise return metaField as is
                return passedField ? {
                    ...metaField, ...passedField,
                    sortable: true,
                    editable: excludeEditableFields.includes(metaField.field) ? false : metaField.editable
                } : metaField;

            })

            // Add any items from the passed columns that don't exist in the actual layout
            .concat(columns ? columns.filter(passedField => !autoColumns.some(metaField => metaField.field === passedField.field))
                    .map(passedField => {
                        handleWarning('Table Warning: Field "' + passedField.field + '" not found in GROON Model Meta. Disabled sorting.');
                        return {
                            ...passedField,
                            sortable: false,
                            editable: false,
                        }
                    })
                : [])

            // Add a default id column if it does not exist
            .concat(!autoColumns.some(metaField => metaField.field === 'id') ? [{field: 'id'}] : [])

            // If the user of this component wants some fields to be excluded, remove them
            .filter(col => excludeFields.includes(col.field) === false);

        // Set the automatic columns
        setFinalColumns(finalColumns);
    }

    useEffect(() => {
        dispatch(addEndpointToList(endpoint))
       return () => {
            dispatch(removeEndpointFromList(endpoint))
       };
    },[endpoint]);

    useEffect(() => {

        // Check if model meta is cached already
        if(modelMeta?.[endpoint]){

            // Process model meta
            processModelMeta(modelMeta?.[endpoint]);

            // Instantly disable the loading process
            setLoadingMeta(false);

        }

        // If the model meta is not loaded, load it and cache it
        else {

            setLoadingMeta(true);
            get(getCleanUrl(metaEndpoint || endpoint, 'model_meta'))

                // Process the response
                .then(data => {

                    // Store the model meta information in redux
                    dispatch(setModelMeta(endpoint, data));

                    // Process model meta
                    processModelMeta(data);

                })

                // Handle the errors (but throw nothing at the user. this state is okay)
                .catch(() => {
                    handleWarning('Table Warning: Loading GROON Model Meta failed.');
                    if (columns) {
                        setFinalColumns(columns);
                    } else {
                        handleWarning('Table Warning: No Fallback Columns Found')
                        setFinalColumns([{field: 'id'}]);
                    }
                })

                // Always set the meta loading process done
                .finally(() => {
                    setLoadingMeta(false);
                })

        }

    }, [endpoint, columns, modelMeta]);

    useEffect(() => {
        if (state === null) {
            let index = _.findIndex(tableStates, {'id': id});
            if (index !== -1) {
                setState(tableStates[index]['state']);
                const storedPaginationModel = tableStates[index]['state']?.['pagination']?.['paginationModel'] || null;
                if (storedPaginationModel) {
                    setPaginationModel(storedPaginationModel);
                }
            } else {

                // Check for default sorting
                if(typeof defaultState !== "undefined" && defaultState !== null){
                    if(defaultState?.filter(item => item?.sorted !== null)?.length || 0){
                        setState({
                            sorting: {
                                sortModel: (defaultState?.filter(item => item?.sorted !== null) || []).map(item => {
                                    return {
                                        field: item.field,
                                        sort: item.sorted
                                    }
                                })
                            }
                        });
                    } else {
                        setState({});
                    }
                } else {
                    setState({});
                }

            }
        }
    }, [tableStates, defaultState]);

    useEffect(() => {
        if (state !== null) {
            handleLoadData(true);
        }
    }, [state, endpoint, JSON.stringify(defaultQuery)]);

    useEffect(() => {
        if(!updateListenerInitial){
            const prevUpdateTimestamp = prevConfigRef.current.updateTimestamp;
            const prevGroupId = prevConfigRef.current.groupId;
            if ((updateTimestamp && (updateTimestamp > prevUpdateTimestamp)) || (user?.current_group?.id && (user?.current_group?.id !== prevGroupId))) {
                handleLoadData(false);
                prevConfigRef.current.updateTimestamp = updateTimestamp;
                prevConfigRef.current.groupId = user?.current_group?.id;
            }

        } else {
            setUpdateListenerInitial(false);
        }
    }, [updateTimestamp, user?.current_group?.id]);

    if (loadingMeta || !finalColumns){
        return (
            <div style={{height: height}}>
                {noCard ? (
                    <div style={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress/>
                    </div>
                ) : (
                    <Card sx={{height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <CircularProgress/>
                    </Card>
                )}
            </div>
        );
    }

    if (isMobile) {
        return (
            <MobileDataGrid
                title={props?.title}
                rows={rows}
                state={state || {}}
                defaultState={defaultState}
                columns={finalColumns}
                rowCount={count}
                isLoading={fetching}
                onStateUpdate={handleStateChange}
            />
        )
    }

    return (
        <DataTable
            {...rest}
            columns={finalColumns}
            id={id}
            data={rows}
            defaultState={transformToDefaultState(defaultState, finalColumns)}
            onReloadTable={handleLoadData}
            paginationModel={paginationModel}
            onGridLiveUpdate={handleStateChange}
            processRowUpdate={(updatedRow, originalRow) => {
                return put(endpoint, updatedRow);
            }}
            onProcessRowUpdateError={(data) => {
                flashErrors(data);
            }}
            rowCount={count}
            debounceDuration={0}
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            isLoading={fetching}
            height={height}
            noCard={noCard}
            handleRemove={deleteActive ? handleRemoveData : null}
        />
    )
}, (prevProps, nextProps) => {

    const idIsSame = prevProps.id === nextProps.id;
    const endpointIsSame = prevProps.endpoint === nextProps.endpoint;
    const defaultQueryIsSame = JSON.stringify(prevProps.defaultQuery) === JSON.stringify(nextProps.defaultQuery);
    const dataIsSame = JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data);
    const excludeFieldsIsSame = JSON.stringify(prevProps.excludeFields) === JSON.stringify(nextProps.excludeFields);
    const excludeEditableFieldsIsSame = JSON.stringify(prevProps.excludeEditableFields) === JSON.stringify(nextProps.excludeEditableFields);
    const canEditIsSame = prevProps.canEdit === nextProps.canEdit;
    const canRemoveIsSame = prevProps.canRemove === nextProps.canRemove;
    const columnsIsSame = nextProps.columns ? (JSON.stringify(prevProps.columns.map(c => c.field)) === JSON.stringify(nextProps.columns.map(c => c.field))) : true;
    const flashWarningsIsSame = prevProps.flashWarnings === nextProps.flashWarnings;
    const updateListenerIsSame = prevProps.updateListener === nextProps.updateListener;

    // IMPORTANT: this component will not re-render if methods change

    return idIsSame && endpointIsSame && defaultQueryIsSame && dataIsSame && excludeFieldsIsSame &&
        excludeEditableFieldsIsSame && canEditIsSame && canRemoveIsSame && columnsIsSame && flashWarningsIsSame &&
        updateListenerIsSame;
});

ServerSideDataTable.propTypes = {
    id: PropTypes.string.isRequired,
    subheader: PropTypes.string,
    title: PropTypes.string,
    endpoint: PropTypes.string.isRequired,
    metaEndpoint: PropTypes.string,
    defaultQuery: PropTypes.object,
    data: PropTypes.array,
    columns: PropTypes.array,
    excludeFields: PropTypes.array,
    defaultState: PropTypes.array,
    excludeEditableFields: PropTypes.array,
    flashWarnings: PropTypes.bool,
    canRemove: PropTypes.bool,
    updateListener: PropTypes.string,
    canEdit: PropTypes.bool,
    noCard: PropTypes.bool,
    actions: PropTypes.array,
    handleRemove: PropTypes.func,
    height: PropTypes.string
}

export default ServerSideDataTable;