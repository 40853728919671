import {Avatar, MenuItem, Popover, Stack, Typography} from '@mui/material';
import OnIcon from "../../../elements/icon";
import React from "react";
import {useSelector} from "react-redux";

export const TenantPopover = (props) => {
    const {anchorEl, onChange, disabled = false, onClose, open = false, tenants, ...other} = props;

    const user = useSelector(state => state.account.user);

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'right',
                vertical: 'bottom'
            }}
            disableScrollLock
            transformOrigin={{
                horizontal: 'right',
                vertical: 'top'
            }}
            keepMounted
            onClose={onClose}
            open={open}
            {...other}>
            {tenants.map((tenant) => (
                <MenuItem
                    key={tenant}
                    disabled={disabled}
                    selected={tenant?.id === user?.current_group?.id}
                    onClick={() => onChange?.(tenant)}
                >
                    <Stack direction="row" spacing={1} sx={{ p: 1, maxWidth: 200 }} alignItems="center">
                        <Avatar
                            sx={{borderRadius: '5px', width: 30, height: 30 }}
                            src={tenant?.avatar?.view}
                        >
                            <OnIcon iconName="Users01" size="small" />
                        </Avatar>
                        <Typography
                            noWrap
                            // sx={{maxWidth: 200}}
                            color="inherit"
                            variant="h6"
                        >
                            {tenant?.name}
                        </Typography>
                    </Stack>
                </MenuItem>
            ))}
        </Popover>
    );
};
