/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { produce } from 'immer';
import objFromArray from "../../utils/objFromArray";
import {
    ADD_SPACE,
    UPDATE_BOARD,
    UPDATE_SPACE,
    UPDATE_SPACES
} from 'src/omnia/store/actions/spaces-actions';
import _ from "lodash";

const initialState = {
    spaces: {
        byId: {},
        allIds: []
    },
    loaded: false
};

const spacesReducer = (state = initialState, action) => {

    switch (action.type) {

        case ADD_SPACE: {
            const space = action.payload;
            return produce(state, (draft) => {
                // append the new space to spaces list
                draft.spaces.byId[space.id] = space;
                draft.spaces.allIds.push(space.id);
            });
        }

        case UPDATE_BOARD: {
            const {board, space} = action.payload;
            return produce(state, (draft) => {
                // check if space might not exist in store
                if(!state.spaces.allIds.includes(space.id)){
                    draft.spaces.allIds.push(space.id);
                    draft.spaces.byId[space.id] = space;
                }
                // update the board
                draft.spaces.byId[space.id].description = board;
            });
        }

        case UPDATE_SPACE: {
            const { space } = action.payload;
            // produce new state with updated space
            return produce(state, (draft) => {
                // check if space might not exist in store
                if(!state.spaces.allIds.includes(space.id)){
                    // if it does not exist, add space
                    draft.spaces.allIds.push(space.id);
                    draft.spaces.byId[space.id] = space;
                } else {
                    // it it already exists, replace details object
                    return produce(state, (draft) => {
                        // merge old space entry with new details from API
                        _.merge(draft.spaces.byId[space.id], space);
                    });
                }
            });
        }

        case UPDATE_SPACES: {
            const spaces = action.payload;
            return produce(state, (draft) => {
                draft.spaces.byId = objFromArray(spaces);
                draft.spaces.allIds = Object.keys(draft.spaces.byId).map(k => parseInt(k));
                draft.loaded = true;
            });
        }

        default: {
            return state;
        }
    }

}

export default spacesReducer;