import React from "react";
import {Fade, Grid, Stack, SvgIcon, Typography, Link, Box} from "@mui/material";
import PropTypes from "prop-types";
import { useTheme } from "@mui/system";
import countToCol from "../../utils/get-column-for-count";
import { useTranslation } from "react-i18next";
import OnIcon from "./icon";
import { useSecurityCheck } from "../../hooks/use-security-check";
import { APP_SETTING } from "../../../setup";
import useGenerateVisibility from "../../hooks/use-generate-visibility";
import { Link as RouterLink } from "react-router-dom";

function SelectionTiles(props) {
    const {
        options,
        onSelect = null,
        selected = null,
        tileHeight = 175,
        paperBackground = false,
        lowestCol = 4,
        fixedCol = null,
        iconSize = 'large',
        notAvailableWarning = null,
        delay = 25,
        dataKind = null,
        ...rest
    } = props;

    const theme = useTheme();
    const { hasRights } = useSecurityCheck();
    const col = countToCol(options.length);
    const { t } = useTranslation();
    const { visible } = useGenerateVisibility(options.length || 0, delay);

    const renderTile = (option) => (
        <Stack
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
                height: tileHeight,
                width: "100%",
                border: selected && selected === (option?.value || option?.path)
                    ? "3px solid"
                    : "1px solid",
                borderColor: selected && selected === (option?.value || option?.path)
                    ? "primary.main"
                    : "divider",
                borderRadius: theme?.config?.card_radius + "px",
                textAlign: "center",
                cursor: onSelect ? "pointer" : "normal",
                backgroundColor: paperBackground
                    ? "background.paper"
                    : "transparent",
                position: "relative",
                overflow: "hidden",
                transition:
                    "background-color 0.3s ease, border-color 0.3s ease",
                "&:hover": (option.disabled || !onSelect)
                    ? {}
                    : {
                        backgroundColor:
                            theme.palette.mode === "light"
                                ? "background.discrete"
                                : "background.discrete",
                    },
                ...(option.bgImg && {
                    backgroundImage: `url(${option.bgImg})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }),
                ...(option.bgImg && (
                    {
                        "&:hover .overlay": {
                            opacity: 0.95,
                        },
                        "&:hover .overlayContent": {
                            opacity: 1,
                        },
                    }
                ))
            }}
            onClick={() => {
                if (option.disabled) {
                    alert(notAvailableWarning || t("common.not_available"));
                } else {
                    if (onSelect) onSelect(option);
                }
            }}
        >
            {option.bgImg && (
                <div
                    className="overlay"
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.7)",
                        opacity: 0,
                        transition: "opacity 0.3s ease",
                        zIndex: 1,
                    }}
                ></div>
            )}
            <div
                style={{
                    position: "relative",
                    zIndex: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%",
                    transition: "transform 0.3s ease",
                }}
            >
                {option.bgImg ? (
                    <Typography
                        className="overlayContent"
                        variant="body1"
                        color={option.disabled ? "text.disabled" : "text.primary"}
                        sx={{
                            width: "100%",
                            height: "100%",
                            lineHeight: 1.2,
                            opacity: 0,
                            transition: "opacity 0.3s ease, transform 0.3s ease",
                            position: "absolute",
                            textAlign: "center",
                            backgroundColor:
                                theme.palette.mode === "light"
                                    ? "rgba(255, 255, 255, 0.8)"
                                    : "rgba(0, 0, 0, 0.8)",
                        }}
                    >
                        <Stack
                            sx={{ height: '100%', width: '100%', px: 2 }}
                            direction="column"
                            spacing={1}
                            justifyContent="center"
                            alignItems="center"
                        >
                            {option?.icon && (
                                <SvgIcon
                                    fontSize="large"
                                    color={option.disabled ? "disabled" : "text.primary"}
                                >
                                    {typeof option.icon === "string" ? (
                                        <OnIcon iconName={option.icon} sx={{color: option.disabled ? theme.palette.text.disabled : theme.palette.text.primary}} />
                                    ) : (
                                        <option.icon />
                                    )}
                                </SvgIcon>
                            )}
                            <Typography
                                variant="h6"
                                noWrap={true}
                                color={option.disabled ? "text.disabled" : "text.primary"}
                                sx={{ width: "100%", lineHeight: 1.2 }}
                            >
                                {option.label}
                            </Typography>
                            <Typography
                                variant="body1"
                                color={option.disabled ? "text.disabled" : "text.primary"}
                                sx={{ width: "100%", lineHeight: 1.2 }}
                            >
                                {option.description}
                            </Typography>
                        </Stack>
                    </Typography>
                ) : (
                    <Stack
                        sx={{ height: '100%', width: '100%', px: 2 }}
                        direction="column"
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {option?.icon && (
                            <SvgIcon
                                fontSize={iconSize}
                                color={option.disabled ? "disabled" : "text.primary"}
                            >
                                {typeof option.icon === "string" ? (
                                    <OnIcon iconName={option.icon} sx={{color: option.disabled ? theme.palette.text.disabled : theme.palette.text.primary}} />
                                ) : (
                                    <option.icon />
                                )}
                            </SvgIcon>
                        )}
                        <Typography
                            variant="h6"
                            noWrap={true}
                            color={option.disabled ? "text.disabled" : "text.primary"}
                            sx={{ width: "100%", lineHeight: 1.2 }}
                        >
                            {option.label}
                        </Typography>
                        <Typography
                            variant="body1"
                            color={option.disabled ? "text.disabled" : "text.primary"}
                            sx={{ width: "100%", lineHeight: 1.2 }}
                        >
                            {option.description}
                        </Typography>
                    </Stack>
                )}
            </div>
        </Stack>
    )

    return (
        <Grid container spacing={2} {...rest}>
            {options
                .filter((o) => (o?.permissions ? hasRights(o?.permissions) : true))
                .map((option, index) => {
                    return (
                        <Fade
                            key={index}
                            in={visible[index]}
                            timeout={APP_SETTING.transitionDuration || 300}
                        >
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={fixedCol ? fixedCol : col < lowestCol ? lowestCol : col}
                                key={index}
                            >
                                {option?.path ? (
                                    <Link
                                        key={index}
                                        component={RouterLink}
                                        underline="none"
                                        to={option.path}
                                        sx={{width: "100%"}}
                                        data-kind={dataKind}
                                        data-id={option?.value || option?.path}
                                    >
                                        {renderTile(option, index)}
                                    </Link>
                                ) : (
                                    <Box
                                        sx={{width: "100%"}}
                                        data-kind={dataKind}
                                        data-id={option?.value || option?.path}
                                    >
                                        {renderTile(option, index)}
                                    </Box>
                                )}
                            </Grid>
                        </Fade>
                    )
                })
            }
        </Grid>
    );
}

SelectionTiles.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.any,
            path: PropTypes.string, // Added path prop type
            description: PropTypes.string,
            disabled: PropTypes.bool,
            icon: PropTypes.any,
            bgImg: PropTypes.string, // Added bgImg prop type
        })
    ).isRequired,
    notAvailableWarning: PropTypes.string,
    iconSize: PropTypes.string,
    dataKind: PropTypes.string,
    onSelect: PropTypes.func,
    paperBackground: PropTypes.bool,
    selected: PropTypes.any,
    lowestCol: PropTypes.number,
    fixedCol: PropTypes.number,
    delay: PropTypes.number,
    tileHeight: PropTypes.number,
};

export default SelectionTiles;
