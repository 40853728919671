import React, { useState, useEffect } from 'react';
import { Box, Fade, Typography } from '@mui/material';
import { useTheme } from "@mui/system";
import PropTypes from "prop-types";
import DelayedCircularProgress from "../elements/delayed-circular-progress";
import getSystemColorMode from "../../utils/get-system-color-mode";

function LoadingScreen(props) {

    const {
        in: inProp = true,
        showTransition = 'instant',
        hideTransition = 'instant',
        inScreen = true,
        bgColor = null
    } = props;
    const theme = useTheme();

    const colorMode = getSystemColorMode();
    const backgroundColor = bgColor || theme?.config ? theme.palette?.background?.default : (colorMode === 'dark' ? '#151513' : '#ffffff');

    const enterTimeout = showTransition === 'smooth' ? 500 : 0;
    const exitTimeout = hideTransition === 'smooth' ? 500 : 0;

    const LoaderContent = (
        <Box>
            {theme?.config && (
                <DelayedCircularProgress />
            )}
        </Box>
    );

    const Loader = (
        <Fade
            in={inProp}
            timeout={{ enter: enterTimeout, exit: exitTimeout }}
            appear={showTransition === 'smooth'}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: backgroundColor,
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'fixed',
                    top: 0,
                    zIndex: 9999,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    padding: 0,
                    margin: 0,
                }}
            >
                {LoaderContent}
            </div>
        </Fade>
    );

    if (inScreen) {
        return (
            <div style={{ height: '100vh', width: '100%', backgroundColor: backgroundColor, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {LoaderContent}
            </div>
        );
    }

    return Loader;
}

LoadingScreen.propTypes = {
    in: PropTypes.bool,
    showTransition: PropTypes.oneOf(['instant', 'smooth']),
    hideTransition: PropTypes.oneOf(['instant', 'smooth']),
    inScreen: PropTypes.bool,
    bgColor: PropTypes.string,
};

export default LoadingScreen;
