import React, {useEffect, useMemo, useState} from "react";
import {
    Checkbox,
    FormControlLabel,
    Grid,
    Box,
    IconButton,
    MenuItem,
    Slider,
    Stack,
    Switch,
    TextField,
    Typography,
    Button,
    Tooltip, Link, DialogTitle, DialogContent, DialogActions, Dialog
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useSecurityCheck} from "../../../hooks/use-security-check";
import {useIsMobile} from "../../../hooks/use-is-mobile";
import SelectionTiles from "../selection-tiles";
import ListIcon from "@untitled-ui/icons-react/build/esm/Dotpoints02";
import EventIcon from "@untitled-ui/icons-react/build/esm/CalendarHeart02";
import PropTypes from "prop-types";
import {produce} from "immer";

function SettingsItemDialog({field, open, onClose, onDone, onChange, onRemove, ...rest}) {

    const {t} = useTranslation();
    const {hasRights} = useSecurityCheck();
    const {isMobile} = useIsMobile();
    const [values, setValues] = useState(field);

    const FIELD_TYPES = [
        {
            value: 'number',
            disabled: false,
            label: t('dialogs.settings_items.item.number'),
            description: t('dialogs.settings_items.item.number_description'),
            icon: ListIcon
        },
        {
            value: 'boolean',
            disabled: true,
            label: t('dialogs.settings_items.item.boolean'),
            description: t('dialogs.settings_items.item.boolean_description'),
            icon: EventIcon
        },
        {
            value: 'list',
            disabled: true,
            label: t('dialogs.settings_items.item.list'),
            description: t('dialogs.settings_items.item.list_description'),
            icon: ListIcon
        },
    ];

    const handleChange = (event) => {
        setValues({...values, [event.target.name]: event.target.value});
    }

    const isNameOkay = useMemo(() => {
        return values?.name && !values?.name.includes(' ');
    });

    useEffect(() => {
        setValues(field);
    }, [field]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={isMobile}
            fullWidth={true}
            maxWidth={field ? "md" : "sm"}
            {...rest}
        >
            <DialogTitle>
                <Typography variant="h4">
                    {field ? t('dialogs.settings_items.title') : t('dialogs.settings_items.add_name_title')}
                </Typography>
                <Typography variant="subtitle1" sx={{mt: 2}}>
                    {field ? t('dialogs.settings_items.description') : t('dialogs.settings_items.add_name_description')}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box mt={1}>
                    {field ? (
                        <Grid container spacing={2}>
                            {field?.type ? (
                                <>
                                    <Grid item xs={6}>
                                        <TextField
                                            label={t('attributes.name')}
                                            value={values?.name}
                                            disabled
                                            onChange={handleChange}
                                            fullWidth
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label={t('attributes.label')}
                                            value={values?.label}
                                            name="label"
                                            onChange={handleChange}
                                            fullWidth
                                            type="text"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label={t('attributes.description')}
                                            multiline
                                            name="description"
                                            value={values?.description}
                                            rows={4}
                                            onChange={handleChange}
                                            type="text"
                                        />
                                    </Grid>
                                    {field?.type === 'number' && (
                                        <>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    label={t('attributes.default')}
                                                    value={values?.default}
                                                    rows={4}
                                                    onChange={handleChange}
                                                    name="default"
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    label={t('attributes.min')}
                                                    value={values?.min}
                                                    rows={4}
                                                    name="min"
                                                    onChange={handleChange}
                                                    type="number"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextField
                                                    fullWidth
                                                    label={t('attributes.max')}
                                                    value={values?.max}
                                                    rows={4}
                                                    onChange={handleChange}
                                                    variant="outlined"
                                                    name="max"
                                                    type="number"
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </>
                            ) : (
                                <Grid item xs={12}>
                                    <SelectionTiles
                                        options={FIELD_TYPES}
                                        onSelect={(option) => {
                                            onChange(produce(values, draft => {
                                                draft.type = option.value;
                                            }))
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    ) : (
                        <>
                            <TextField
                                name="name"
                                label={t('attributes.name')}
                                value={values?.name}
                                onChange={handleChange}
                                fullWidth
                            />
                            {values?.name && values?.name.includes(' ') && (
                                <Typography variant="caption" color="error">
                                    {t('dialogs.settings_items.name_whitespace_error')}
                                </Typography>
                            )}
                            {values?.name && values?.name.includes('.') && (
                                <Typography variant="caption" color="warning">
                                    {t('dialogs.settings_items.name_dot_warning')}
                                </Typography>
                            )}
                        </>
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                    <Stack direction="row" justifyContent="left" sx={{width: '100%'}} spacing={2}>
                        <Button variant="outlined" onClick={onClose}>
                            {t('common.cancel')}
                        </Button>
                        {(field && onRemove) && (
                            <Button variant="outlined" color="error" onClick={() => onRemove(field)}>
                                {t('common.delete')}
                            </Button>
                        )}
                    </Stack>
                    {isNameOkay && (
                        <Button variant="contained" onClick={() => {
                            onChange(values);
                            if (field)
                                onClose();
                        }}>
                            {field ? t('common.add') : t('common.continue')}
                        </Button>
                    )}
                </Stack>
            </DialogActions>
        </Dialog>
    );

}

SettingsItemDialog.propTypes = {
    field: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onDone: PropTypes.func,
}

export default SettingsItemDialog;
