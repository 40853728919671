import React from "react";
import PropTypes from "prop-types";
import {Box, Button, Dialog, DialogActions, DialogContent, Stack, TextField, Typography} from "@mui/material";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useTranslation} from "react-i18next";
import SaveButton from "../../../elements/save-button";
import useMgtFormik from "../../../../hooks/formik/use-mgt-formik";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import {useNotifications} from "../../../../hooks/use-notifications";

function AddThemeDialog({open, onClose, onDone}){

    const { t } = useTranslation();
    const { isMobile } = useIsMobile();
    const { notify, notifySuccess } = useNotifications();
    const { post } = useOmniaApi();

    const handleSubmit = (values, helpers) => {
        helpers.setSubmitting(true);
        post('core/themes', values).then((newTheme) => {
            if(onDone)
                onDone(newTheme);
            helpers.resetForm();
            notifySuccess(t);
            onClose();
        });
    }

    const { themeFormik } = useMgtFormik(null, handleSubmit);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullScreen={isMobile}
            fullWidth={true}
        >
            <DialogContent>
                <Typography variant="h4">
                    {t('mgt.settings.add_theme')}
                </Typography>
                <Box mt={4}>
                    <TextField
                        label={t('attributes.name')}
                        name='name'
                        autoFocus={true}
                        fullWidth={true}
                        onChange={themeFormik.handleChange}
                        value={themeFormik.values.name}
                        error={themeFormik.touched.name && Boolean(themeFormik.errors.name)}
                        onBlur={themeFormik.handleBlur}
                        placeholder={t('mgt.settings.theme_name_placeholder')}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" justifyContent="space-between" sx={{width: '100%'}}>
                    <Button onClick={onClose} variant="outlined">
                        {t('common.close')}
                    </Button>
                    <SaveButton
                        formik={themeFormik}

                        // onClick={themeFormik.handleSubmit}
                        // loading={themeFormik.isSubmitting}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

AddThemeDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onDone: PropTypes.func
}

export default AddThemeDialog;