import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Button, OutlinedInput, Link, Typography, SvgIcon} from '@mui/material';
import {updateList} from 'src/omnia/store/actions/kanban-actions';
import {useNotifications} from "src/omnia/hooks/use-notifications";
import {Plus as PlusIcon} from 'src/omnia/icons/plus';
import {useTheme} from "@mui/system";
import useApi from "src/omnia/hooks/use-api";

function ListAdd({...rest}) {

    const theme = useTheme();
    const dispatch = useDispatch();
    const {notify} = useNotifications();
    const {post} = useApi();
    const [isExpanded, setExpanded] = useState(false);
    const boardId = useSelector(state => state.kanban.boardId);
    const [name, setName] = useState('');

    const handleChange = (event) => {
        event.persist();
        setName(event.target.value);
    };

    const handleAddInit = () => {
        setExpanded(true);
    };

    const handleAddCancel = () => {
        setExpanded(false);
        setName('');
    };

    const handleAddConfirm = async () => {
        post('pm/project-boards/' + boardId + '/lists', {name: name, board: boardId}).then(data => {
            setExpanded(false);
            setName('');
            dispatch(updateList(boardId, data.id, data));
        })
    };

    return (
        <div {...rest}>
            <Box
                sx={{
                    backgroundColor: () => {
                        return theme.palette.mode === 'dark'
                            ? theme.palette.neutral[800]
                            : theme.palette.neutral[200]
                    },
                    borderRadius: 1,
                    mt: 7,
                    mx: 1,
                    width: {
                        sm: 380,
                        xs: 300
                    }
                }}
            >
                <Box sx={{p: 2}}>
                    {isExpanded
                        ? (
                            <>
                                <OutlinedInput
                                    autoFocus
                                    fullWidth
                                    placeholder="Neue Spalte"
                                    name="cardName"
                                    onChange={handleChange}
                                    value={name}
                                    onKeyDown={(e) => {
                                        if(e.keyCode === 13){
                                            handleAddConfirm();
                                        }
                                    }}
                                    sx={{
                                        backgroundColor: 'backgroundPaper',
                                        '& .MuiInputBase-input': {
                                            px: 2,
                                            py: 1
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: theme.palette.grey[400]
                                        }
                                    }}
                                />
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        mt: 2
                                    }}
                                >
                                    <Button
                                        onClick={handleAddConfirm}
                                        size="small"
                                        startIcon={(
                                            <SvgIcon fontSize="small">
                                                <PlusIcon />
                                            </SvgIcon>
                                        )}
                                        variant="contained"
                                    >
                                        Hinzufügen
                                    </Button>
                                    <Button
                                        onClick={handleAddCancel}
                                        size="small"
                                        sx={{ml: 2}}
                                    >
                                        Abbrechen
                                    </Button>
                                </Box>
                            </>
                        )
                        : (
                            <Link
                                onClick={handleAddInit}
                                sx={{
                                    alignItems: 'center',
                                    cursor: 'pointer',
                                    display: 'flex',
                                    justifyContent: 'flex-start'
                                }}
                                underline="none"
                            >
                                <PlusIcon sx={{color: 'action.active'}}/>
                                <Typography
                                    color="textSecondary"
                                    variant="subtitle1"
                                >
                                    Spalte hinzufügen
                                </Typography>
                            </Link>
                        )}
                </Box>
            </Box>
        </div>
    );
}

export default ListAdd;
