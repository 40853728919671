import PropTypes from 'prop-types';
import {Avatar, Box, CircularProgress, Divider, IconButton, Link, Stack, SvgIcon, Typography} from '@mui/material';
import {Link as RouterLink} from "react-router-dom";
import Logo from "../../../general/logo";
import React, {useEffect, useState} from "react";
import {useSettings} from "src/omnia/hooks/use-settings";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import usePopover from "../../../../hooks/use-popover";
import {TenantPopover} from "./tenant-popover";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";
import OnIcon from "../../../elements/icon";
import {setCurrentGroup, setIsTransitioning} from "../../../../store/actions/account-actions";
import useOmniaApi from "../../../../hooks/use-omnia-api";

export const TenantSwitch = ({sx = {}, ...props}) => {

    const { t } = useTranslation();
    const theme = useTheme();
    const popover = usePopover();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const { put } = useOmniaApi({autoError: false});
    const user = useSelector(state => state.account.user);
    const tenants = user?.user_groups || [];

    const hasMultipleGroups = tenants.length > 1;

    const handleChangeGroup = (newGroup) => {
        dispatch(setIsTransitioning(true));
        put('core/users', {id: user?.id, current_group: newGroup?.id}).then(() => {
            dispatch(setCurrentGroup(newGroup));
        }).finally(() => {
            dispatch(setIsTransitioning(false));
        })
        popover.handleClose();
    }

    const handleOpen = () => {
        if(hasMultipleGroups){
            popover.handleOpen();
        }
    }

    if(user?.user_groups?.length === 0)
        return null;

    return (
        <>
            <Stack
                alignItems="center"
                direction="row"
                spacing={2}
                onClick={handleOpen}
                ref={popover.anchorRef}
                sx={{
                    backgroundColor: 'transparent',
                    borderRadius: '5px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.divider,
                    borderWidth: 0.5,
                    cursor: hasMultipleGroups ? 'pointer' : 'default',
                    overflow: 'hidden',
                    ...sx
                }}
                {...props}
            >
                <Stack direction="row" spacing={1} sx={{ p: 1, width: '100%' }} alignItems="center">
                    <Avatar
                        sx={{borderRadius: '5px', width: 30, height: 30 }}
                        src={user?.current_group?.avatar?.view}
                    >
                        <OnIcon iconName="Users01" size="small" />
                    </Avatar>
                    <Typography
                        noWrap
                        color="inherit"
                        variant="h6"
                    >
                        {loading ? t('common.loading') : user?.current_group?.name}
                    </Typography>
                </Stack>
                <IconButton>
                    {loading ? (
                        <CircularProgress size={20} />
                    ) : hasMultipleGroups ? (
                        <OnIcon iconName="ChevronSelectorVertical" size="small" />
                    ) : null}
                </IconButton>
            </Stack>
            <TenantPopover
                disabled={loading}
                anchorEl={popover.anchorRef.current}
                onChange={handleChangeGroup}
                onClose={popover.handleClose}
                open={popover.open}
                tenants={tenants}
            />
        </>
    )

};

TenantSwitch.propTypes = {
    sx: PropTypes.object
};
