/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { produce } from 'immer';
import {UPDATE_GROUPS, UPDATE_GROUP, ADD_GROUP, REMOVE_GROUPS} from "../actions/user-groups-actions";
import _ from "lodash";

const initialState = {
    // list of user groups
    groups: [],
    // status of initialization
    initialized: false
};

const userGroupsReducer = (state = initialState, action) => {

    switch (action.type) {

        case ADD_GROUP: {
            // get group from action
            let group = action.payload;
            // create new list of groups
            let updatedGroups = state.groups.concat([group]);
            // append group to app state
            return produce(state, (draft) => {
                // write new draft state and return
                draft.groups = updatedGroups;
            });
        }

        case UPDATE_GROUP: {
            const updatedGroup = action.payload;
            // produce new state with updated space
            return produce(state, (draft) => {
                // check if space might not exist in store
                if(state.groups.filter(g => g.id === updatedGroup.id).length === 0){
                    // if it does not exist, add group
                    draft.groups.push(updatedGroup);
                } else {
                    // identify the index
                    let index = state.groups.indexOf(state.groups.find(g => g.id === updatedGroup.id));
                    // it it already exists, replace details object
                    return produce(state, (draft) => {
                        // merge old space entry with new details from API
                        _.merge(draft.groups[index], updatedGroup);
                    });
                }
            });
        }

        case REMOVE_GROUPS: {
            // get ids from action
            let ids = action.payload
            // create tmp state
            let groups = [];
            // check if groups have to be removed
            if(ids.length > 0){
                // iterate through all groups and only append valid groups
                for(let j = 0; j < state.groups.length; j++)
                    // check if this group is searched
                    if(!ids.includes(state.groups[j].id))
                        groups.push(state.groups[j])
                // produce new app state with removed groups
                return produce(state, (draft) => {
                    // update groups in app state
                    draft.groups = groups;
                });
            }
            // return current state
            return state;
        }

        case UPDATE_GROUPS: {
            // get groups from payload
            const groups = action.payload;
            // check if group amount has changed
            if((JSON.stringify(state.groups) !== JSON.stringify(groups)) || !state.initialized){
                // produce new app state with updated groups
                return produce(state, (draft) => {
                    // update groups in app state
                    draft.groups = groups;
                    // set initialized
                    draft.initialized = true;
                });
            } else {
                // return current state of app
                return state;
            }
        }

        default: {
            return state;
        }
    }
};

export default userGroupsReducer;
