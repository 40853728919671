import React, {useEffect, useState} from 'react';
import {
    Box,
    Fade,
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Stack,
    Typography,
    Switch,
    Chip,
    CardActions, Tooltip, TextField, MenuItem,
} from '@mui/material';
import useApi from "src/omnia/hooks/use-api";
import SaveButton from "../../../../../elements/save-button";
import {useNotifications} from "../../../../../../hooks/use-notifications";
import {useTranslation} from "react-i18next";
import {APP_SETTING} from "../../../../../../../setup";
import {useSettings} from "../../../../../../hooks/use-settings";
import {useDispatch} from "react-redux";
import usePerformTransition from "../../../../../../hooks/use-perform-transition";

function UserSettings() {

    const {t} = useTranslation();
    const [settings, setSettings] = useState(null);
    const [loading, setLoading] = useState(false);
    const {get, post} = useApi();
    const {notifySuccess} = useNotifications();
    const groonSettings = useSettings();
    const [toggleValue, setToggleValue] = useState(groonSettings?.desktopNotifications || false);
    const [isDisabled, setIsDisabled] = useState(false);
    const handleLayoutUpdate = usePerformTransition((value) => {
        handleFieldUpdate('layout', value);
    })

    const handleSave = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            notifySuccess(t);
        }, 500);
    }

    const handleChange = (name, value) => {
        post('core/user-settings/modify', {value: value, name: name}).then(() => {
            setSettings(prev => {
                return {
                    ...prev,
                    [name]: value
                }
            });
        });
    }

    const layoutOptions = [
        {
            label: t('common.on_settings.vertical'),
            value: 'vertical',
            icon: (
                <Box
                    sx={{
                        display: 'flex',
                        flex: '1 1 auto'
                    }}
                >
                    <Box
                        sx={{
                            borderRightColor: (theme) => theme.palette.mode === 'dark'
                                ? 'neutral.500'
                                : 'neutral.300',
                            borderRightStyle: 'dashed',
                            borderRightWidth: 1,
                            px: 1,
                            py: 0.5
                        }}
                    >
                        <Stack spacing={1}>
                            <Box
                                sx={{
                                    backgroundColor: 'primary.main',
                                    borderRadius: '3px',
                                    height: 6,
                                    // pt: 2,
                                    width: 26
                                }}
                            />
                            <Box
                                sx={{
                                    backgroundColor: (theme) => theme.palette.mode === 'dark'
                                        ? 'neutral.600'
                                        : 'neutral.300',
                                    borderRadius: '2px',
                                    height: 4,
                                    width: 26
                                }}
                            />
                            <Box
                                sx={{
                                    backgroundColor: (theme) => theme.palette.mode === 'dark'
                                        ? 'neutral.600'
                                        : 'neutral.300',
                                    borderRadius: '2px',
                                    height: 4,
                                    width: 26
                                }}
                            />
                            <Box
                                sx={{
                                    backgroundColor: (theme) => theme.palette.mode === 'dark'
                                        ? 'neutral.600'
                                        : 'neutral.300',
                                    borderRadius: '2px',
                                    height: 4,
                                    width: 26
                                }}
                            />
                        </Stack>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flex: '1 1 auto',
                            p: 1
                        }}
                    >
                        <Box
                            sx={{
                                backgroundColor: (theme) => theme.palette.mode === 'dark'
                                    ? 'neutral.800'
                                    : 'neutral.50',
                                borderColor: (theme) => theme.palette.mode === 'dark'
                                    ? 'neutral.500'
                                    : 'neutral.300',
                                borderRadius: 1,
                                borderStyle: 'dashed',
                                borderWidth: 1,
                                flex: '1 1 auto'
                            }}
                        />
                    </Box>
                </Box>
            )
        },
        {
            label: t('common.on_settings.flying'),
            value: 'flying',
            icon: (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flex: '1 1 auto',
                        position: 'relative'
                    }}
                >
                    {/* Left "flying" menu */}
                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            backgroundColor: (theme) => theme.palette.mode === 'dark'
                                ? 'neutral.500'
                                : 'neutral.300',
                            borderRadius: '3px',
                            height: '25%',
                            width: '6px',
                            margin: '0 4px'
                        }}
                    />

                    {/* Content area */}
                    <Box
                        sx={{
                            flex: '1 1 auto',
                            backgroundColor: (theme) => theme.palette.mode === 'dark'
                                ? 'neutral.800'
                                : 'neutral.50',
                            borderColor: (theme) => theme.palette.mode === 'dark'
                                ? 'neutral.500'
                                : 'neutral.300',
                            borderRadius: 1,
                            borderStyle: 'dashed',
                            borderWidth: 1,
                            margin: '4px 10%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {/* Content goes here */}
                    </Box>

                    {/* Right "flying" menu */}
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            backgroundColor: (theme) => theme.palette.mode === 'dark'
                                ? 'neutral.600'
                                : 'neutral.300',
                            borderRadius: '3px',
                            height: '25%',
                            width: '6px',
                            margin: '0 4px'
                        }}
                    />
                </Box>
            )
        }
    ];

    const languageOptions = [
        {
            label: 'Deutsch',
            value: 'de',
        },
        {
            label: 'English',
            value: 'en',
        },
    ];

    const paletteModeOptions = [
        {
            label: t('common.on_settings.light'),
            value: 'light',
        },
        {
            label: t('common.on_settings.dark'),
            value: 'dark',
        },
        {
            label: t('common.on_settings.system'),
            value: 'system',
        },
    ];

    const contrastOptions = [
        {
            label: t('common.normal'),
            value: 'normal'
        },
        {
            label: t('common.high'),
            value: 'high'
        }
    ];

    const navColorOptions = [
        {
            label: t('common.on_settings.blend_in'),
            value: 'blend-in'
        },
        {
            label: t('common.on_settings.discreet'),
            value: 'discreet'
        },
        {
            label: t('common.on_settings.evident'),
            value: 'evident'
        }
    ];

    const handleFieldUpdate = (field, value) => {
        groonSettings?.handleUpdate?.({
            [field]: value
        });
    };

    const handleDesktopNotificationChange = async (event, val) => {
        if (val) {
            if (Notification.permission === 'default') {
                // Request browser permission if not yet granted or denied
                const permission = await Notification.requestPermission();
                if (permission === 'granted') {
                    // Update Redux store to enable desktop notifications
                    setToggleValue(true);
                    handleFieldUpdate('desktopNotifications', true);
                } else {
                    // User denied permission
                    setToggleValue(false);
                    handleFieldUpdate('desktopNotifications', false);
                    setIsDisabled(true); // Once denied, permission can't be requested again unless changed in browser
                }
            } else if (Notification.permission === 'granted') {
                // Permission already granted, just update user preference
                setToggleValue(true);
                handleFieldUpdate('desktopNotifications', true);
            } else {
                // Permission was denied previously, toggle can't be turned on
                setToggleValue(false);
                handleFieldUpdate('desktopNotifications', false);
                setIsDisabled(true);
            }
        } else {
            // User toggles OFF desktop notifications
            setToggleValue(false);
            handleFieldUpdate('desktopNotifications', false);
        }
        handleFieldUpdate('desktopNotificationsSettingPending', false);
    };

    useEffect(() => {

        // Initialize the disabled state of the switch based on browser permission
        if (typeof Notification !== 'undefined') {
            if (Notification.permission === 'denied') {
                setIsDisabled(true);
            } else {
                setIsDisabled(false);
            }
        } else {
            // Notifications not supported in browser
            setIsDisabled(true);
        }

        // Get the users settings
        get('core/user-settings/obtain').then(response => {
            setSettings(response);
        });

    }, []);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Fade in={true} timeout={APP_SETTING?.transitionDuration || 500}>
                    <Card>
                        <CardHeader title={t('mgt.settings.ui_settings')}/>
                        <Divider/>
                        <CardContent>
                            <Stack direction="column" spacing={3}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h6" color="textPrimary">
                                            {t('attributes.language')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">

                                        </Typography>
                                    </Stack>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        flexWrap="wrap"
                                        gap={2}
                                    >
                                        {languageOptions.map((option) => (
                                            <Chip
                                                icon={option.icon}
                                                key={option.value}
                                                label={option.label}
                                                onClick={() => handleFieldUpdate?.('language', option.value)}
                                                sx={{
                                                    borderColor: 'transparent',
                                                    borderRadius: 1.5,
                                                    borderStyle: 'solid',
                                                    borderWidth: 2,
                                                    ...((option.value === groonSettings?.language) && {
                                                        borderColor: 'primary.main'
                                                    })
                                                }}
                                            />
                                        ))}
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h6" color="textPrimary">
                                            {t('common.on_settings.nav_color')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">

                                        </Typography>
                                    </Stack>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        flexWrap="wrap"
                                        gap={2}
                                    >
                                        {navColorOptions.map((option) => (
                                            <Chip
                                                icon={option.icon}
                                                key={option.value}
                                                label={option.label}
                                                onClick={() => handleFieldUpdate?.('navColor', option.value)}
                                                sx={{
                                                    borderColor: 'transparent',
                                                    borderRadius: 1.5,
                                                    borderStyle: 'solid',
                                                    borderWidth: 2,
                                                    ...((option.value === groonSettings?.navColor) && {
                                                        borderColor: 'primary.main'
                                                    })
                                                }}
                                            />
                                        ))}
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h6" color="textPrimary">
                                            {t('common.on_settings.color_scheme')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">

                                        </Typography>
                                    </Stack>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        flexWrap="wrap"
                                        gap={2}
                                    >
                                        {paletteModeOptions.map((option) => (
                                            <Chip
                                                icon={option.icon}
                                                key={option.value}
                                                label={option.label}
                                                onClick={() => {
                                                    if (option.value === 'system') {
                                                        handleFieldUpdate?.('systemPaletteMode', true);
                                                    } else {
                                                        handleFieldUpdate?.('paletteMode', option.value);
                                                        handleFieldUpdate?.('systemPaletteMode', false);
                                                    }
                                                }}
                                                sx={{
                                                    borderColor: 'transparent',
                                                    borderRadius: 1.5,
                                                    borderStyle: 'solid',
                                                    borderWidth: 2,
                                                    ...((groonSettings.systemPaletteMode ? (option.value === 'system') : (option.value === groonSettings?.paletteMode)) && {
                                                        borderColor: 'primary.main'
                                                    })
                                                }}
                                            />
                                        ))}
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h6" color="textPrimary">
                                            {t('common.on_settings.contrast')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">

                                        </Typography>
                                    </Stack>
                                    <Stack
                                        alignItems="center"
                                        direction="row"
                                        flexWrap="wrap"
                                        gap={2}
                                    >
                                        {contrastOptions.map((option) => (
                                            <Chip
                                                icon={option.icon}
                                                key={option.value}
                                                label={option.label}
                                                onClick={() => handleFieldUpdate?.('contrast', option.value)}
                                                sx={{
                                                    borderColor: 'transparent',
                                                    borderRadius: 1.5,
                                                    borderStyle: 'solid',
                                                    borderWidth: 2,
                                                    ...((option.value === groonSettings?.contrast) && {
                                                        borderColor: 'primary.main'
                                                    })
                                                }}
                                            />
                                        ))}
                                    </Stack>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h6" color="textPrimary">
                                            {t('attributes.layout')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">

                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        spacing={2}
                                        sx={{minWidth: 300}}
                                    >
                                        {layoutOptions.map((option) => (
                                            <Stack
                                                key={option.value}
                                                spacing={1}
                                                sx={{width: '100%'}}
                                            >
                                                <Box
                                                    onClick={() => handleLayoutUpdate?.(option.value)}
                                                    sx={{
                                                        backgroundColor: (theme) => theme.palette.mode === 'dark'
                                                            ? 'neutral.900'
                                                            : 'background.paper',
                                                        borderColor: 'divider',
                                                        borderRadius: 1,
                                                        borderStyle: 'solid',
                                                        borderWidth: 2,
                                                        cursor: 'pointer',
                                                        display: 'flex',
                                                        height: 88,
                                                        ...(option.value === groonSettings?.layout && {
                                                            borderColor: 'primary.main'
                                                        })
                                                    }}
                                                >
                                                    {option.icon}
                                                </Box>
                                                <Typography
                                                    align="center"
                                                    sx={{fontWight: 500}}
                                                    variant="body2"
                                                >
                                                    {t(option.label)}
                                                </Typography>
                                            </Stack>
                                        ))}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                </Fade>
            </Grid>
            <Grid item xs={12}>
                <Fade in={true} timeout={APP_SETTING?.transitionDuration || 500}>
                    <Card>
                        <CardHeader title={t('mgt.settings.core_settings')}/>
                        <Divider/>
                        <CardContent>
                            <Stack
                                direction="column"
                                spacing={4}
                                sx={{width: '100%'}}
                            >
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h6" color="textPrimary">
                                            {t('mgt.settings.email_notifications')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('mgt.settings.email_notifications_info')}
                                        </Typography>
                                    </Stack>
                                    <Switch
                                        onChange={(event, val) => {
                                            handleChange('email_notifications', val);
                                        }}
                                        checked={settings?.email_notifications}
                                    />
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h6" color="textPrimary">
                                            {t('mgt.settings.hide_personal_details')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('mgt.settings.hide_personal_details_info')}
                                        </Typography>
                                    </Stack>
                                    <Switch
                                        onChange={(event, val) => {
                                            handleChange('hide_personal_details', val);
                                        }}
                                        checked={settings?.hide_personal_details}
                                    />
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h6" color="textPrimary">
                                            {t('mgt.settings.hide_online_status')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('mgt.settings.hide_online_status_info')}
                                        </Typography>
                                    </Stack>
                                    <Switch
                                        onChange={(event, val) => {
                                            handleChange('hide_online_status', val);
                                        }}
                                        checked={settings?.hide_online_status}
                                    />
                                </Stack>
                                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h6" color="textPrimary">
                                            {t('mgt.settings.desktop_notifications')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('mgt.settings.desktop_notifications_info')}
                                        </Typography>
                                    </Stack>
                                    <Tooltip
                                        enterDelay={1000}
                                        title={isDisabled ? t('mgt.settings.desktop_notifications_disabled') : null}
                                    >
                                        <Box>
                                            <Switch
                                                checked={toggleValue}
                                                disabled={isDisabled}
                                                onChange={handleDesktopNotificationChange}
                                            />
                                        </Box>
                                    </Tooltip>
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Stack direction="column" spacing={1}>
                                        <Typography variant="h6" color="textPrimary">
                                            {t('mgt.settings.visibility_mode')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('mgt.settings.visibility_mode_info')}
                                        </Typography>
                                    </Stack>
                                    <TextField
                                        select
                                        value={settings?.visibility_mode || 'group'}
                                        sx={{width: 200}}
                                        label={t('mgt.settings.visibility_mode')}
                                        onChange={(event) => {
                                            handleChange('visibility_mode', event.target.value);
                                        }}
                                        name='visibility_mode'
                                    >
                                        {['always', 'group'].map((option) => (
                                            <MenuItem key={option} value={option}>
                                                {t('mgt.settings.visibility_modes.' + option)}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Stack>
                            </Stack>
                        </CardContent>
                        <CardActions>
                            <Stack direction="row-reverse" sx={{width: '100%'}}>
                                <SaveButton
                                    loading={loading}
                                    onClick={handleSave}
                                />
                            </Stack>
                        </CardActions>
                    </Card>
                </Fade>
            </Grid>
        </Grid>
    );
}

export default UserSettings;