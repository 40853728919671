import React, {useRef} from "react";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useTheme } from "@mui/system";
import { useNotifications } from "src/omnia/hooks/use-notifications";
import {useTranslation} from "react-i18next";

const ShippingMap = ({ data, zones }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { notify } = useNotifications();
    const zonesRef = useRef();
    zonesRef.current = zones;

    const handleClick = (e, countryCode) => {
        let result = null;
        if(zonesRef.current){
            for (let i = 0; i < zonesRef.current?.length; i++) {
                if (zonesRef.current[i].countries.includes(countryCode)) {
                    result = zonesRef.current[i].name;
                    break;
                }
            }
        }
        if (!result) {
            notify(t('resources.sales.not_assigned'), "error");
        } else {
            notify(t('resources.sales.belongs_to', {zoneName: result}), "info");
        }
    };

    return (
        <Box>
            <VectorMap
                map={worldMill}
                backgroundColor="transparent"
                zoomOnScroll={false}
                style={{
                    width: "100%",
                    height: "300px"
                }}
                onRegionClick={handleClick}
                regionStyle={{
                    initial: {
                        fill: "#e4e4e4",
                        fillOpacity: 0.9,
                        stroke: "none",
                        strokeWidth: 0,
                        strokeOpacity: 0
                    },
                    hover: {
                        fillOpacity: 0.8,
                        cursor: "pointer"
                    },
                    selected: {
                        fill: theme.palette.secondary.main
                    },
                    selectedHover: {}
                }}
                regionsSelectable={false}
                series={{
                    regions: [
                        {
                            values: data,
                            scale: ["#146804", theme.palette.primary.main],
                            normalizeFunction: "polynomial"
                        }
                    ]
                }}
            />
        </Box>
    );
};

ShippingMap.propTypes = {
    data: PropTypes.object
};

export default ShippingMap;
