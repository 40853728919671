import React, {useState} from "react";
import {KanbanCardAction} from "src/omnia/components/modules/projects/board-card/kanban-board/card-dialog/kanban-action-button";
import {TagOutlined as LabelIcon} from "../../../../icons/tag-outlined";
import {Button, Chip, Popover, Stack, Typography} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import {updateCard} from "../../../../store/actions/kanban-actions";
import {useDispatch, useSelector} from "react-redux";
import useApi from "../../../../hooks/use-api";
import LabelDesigner from "./label-designer";
import {Box, useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import PropTypes from 'prop-types';
import OnIcon from "../../../elements/icon";

function LabelSelector({card}){
    const theme = useTheme();
    const spaceId = useSelector(state => state.kanban.spaceId)
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const labels = useSelector(state => state.kanban.labels);
    const boardId = useSelector(state => state.kanban.boardId);
    const [isEditing, setIsEditing] = useState(false);
    const {post} = useApi();

    const handleAddLabel = (label) => {
        post(`pm/project-board-list-cards/${card.id}/add_label`, {label: label}).then(card => {
            dispatch(updateCard(boardId, card));
        });
    }

    const handleEdit = () => {
        setIsEditing(true);
    }

    const handleStopEdit = () => {
        setIsEditing(false);
    }

    const handleRemoveLabel = (label) => {
        post(`pm/project-board-list-cards/${card.id}/remove_label`, {label: label}).then(card => {
            dispatch(updateCard(boardId, card));
        })
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <KanbanCardAction
                icon={<LabelIcon fontSize="small"/>}
                onClick={handleClick}
            >
                Label
            </KanbanCardAction>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                {isEditing ? (
                    <Box p={2} sx={{width: 400, height: 400}}>
                        <LabelDesigner spaceId={spaceId} onDone={handleStopEdit} />
                    </Box>
                ) : (
                    <Box sx={{ width: 250, height: 400, display: 'flex', flexDirection: 'column' }}>
                        {labels.length === 0 ? (
                            <Box sx={{ flex: 1, display: 'flex'}}>
                                <Stack
                                    spacing={2}
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', height: '100%', textAlign: 'center' }}
                                >
                                    <OnIcon
                                        iconName="Bookmark"
                                        size="large"
                                        sx={{ color: theme.palette.text.secondary }}
                                    />
                                    <Typography variant="h5" color="textSecondary">
                                        {t("projects.settings.no_tags_yet")}
                                    </Typography>
                                </Stack>
                            </Box>
                        ) : (
                            <Box sx={{ flex: 1, overflowY: 'auto' }}>
                                {labels.map((label, i) => (
                                    <MenuItem
                                        key={'label_' + i}
                                        onClick={() => (card.labels.filter(l => l.id === label.id).length > 0) ? handleRemoveLabel(label) : handleAddLabel(label)}
                                        sx={{minWidth: 250}}
                                    >
                                        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{width: '100%'}}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Checkbox
                                                    checked={card.labels.filter(l => l.id === label.id).length > 0}
                                                />
                                                <Typography variant="body2">{label.name}</Typography>
                                            </Stack>
                                            <Box
                                                sx={{
                                                    backgroundColor: label.color,
                                                    borderRadius: '50%',
                                                    width: 12,
                                                    height: 12,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            />
                                        </Stack>
                                    </MenuItem>
                                ))}
                            </Box>
                        )}
                        <Box p={2}>
                            <Button fullWidth onClick={handleEdit} variant="outlined">
                                {t("Label bearbeiten")}
                            </Button>
                        </Box>
                    </Box>
                )}
            </Popover>
        </>
    )
}

LabelSelector.propTypes = {
    card: PropTypes.object.isRequired,
};
export default LabelSelector;
