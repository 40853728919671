import React from "react";
import {IconButton, Tooltip} from '@mui/material';
import {useTranslation} from "react-i18next";
import OnIcon from "../../../elements/icon";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {closeMiniThread, updateAssistantOpen} from "../../../../store/actions/messages-actions";
import {useTheme} from "@mui/system";

export const AssistantButton = () => {
    const { isFlying } = useIsMobile();
    const theme = useTheme();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const chatOpen = useSelector(state => state.messages.assistantOpen);
    const isLaunchpad = false;

    const handleOpenAssistantChat = () => {
        dispatch(closeMiniThread());
        dispatch(updateAssistantOpen(!chatOpen));
    }

    return (
        <Tooltip enterDelay={1000} title={(chatOpen) ? t("intelligence.hide_assistant") : t("intelligence.show_assistant")} placement={isFlying ? 'left' : 'bottom'}>
            <IconButton disabled={isLaunchpad} onClick={handleOpenAssistantChat}>
                <OnIcon iconName="Star06" sx={isLaunchpad ? {color: theme.palette.text.disabled} : {}} />
            </IconButton>
        </Tooltip>
    )

};
