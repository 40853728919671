import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    TextField,
    CardActions,
    Grid,
    Stack,
} from '@mui/material';
import {addUser} from "src/omnia/store/actions/users-actions";
import {useNotifications} from "src/omnia/hooks/use-notifications";
import useApi from "src/omnia/hooks/use-api";
import PropTypes from "prop-types";
import {DatePicker} from '@mui/x-date-pickers-pro';
import moment from "moment";
import {useTranslation} from "react-i18next";
import useCoreFormik from "../../../../../../hooks/formik/use-core-formik";
import SaveButton from "../../../../../elements/save-button";
import { produce } from "immer";
import {setUserData} from "../../../../../../store/actions/account-actions";

function UserData({className, ...rest}) {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const user = useSelector(state => state.account.user);
    const {notifySuccess} = useNotifications();
    const {post} = useApi();

    const submitData = (values, helpers) => {
        helpers.setSubmitting(true);
        post('core/me/update_data', produce(values, draft => {
            draft['date_of_birth'] = values.date_of_birth ? moment(values.date_of_birth).format('YYYY-MM-DD') : null;
            delete draft['user_rights'];
        })).then(user => {
            dispatch(addUser(user));
            dispatch(setUserData(user));
            notifySuccess(t);
        }).finally(() => {
            helpers.setSubmitting(false);
        })
    }
    
    const { userFormik } = useCoreFormik(user, submitData, true);

    return (
        <form onSubmit={userFormik.handleSubmit}>
            <Card {...rest}>
                <CardHeader title={t('core.profile_data')} />
                <Divider/>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item md={2} xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                label={t('attributes.title')}
                                name="title"
                                type="text"
                                value={userFormik.values.title}
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                label={t('attributes.first_name')}
                                name="first_name"
                                type="text"
                                value={userFormik.values.first_name}
                            />
                        </Grid>
                        <Grid item md={5} xs={12}>
                            <TextField
                                fullWidth
                                disabled
                                label={t('attributes.last_name')}
                                name="last_name"
                                type="last_name"
                                value={userFormik.values.last_name}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                error={Boolean(userFormik.touched.email && userFormik.errors.email)}
                                fullWidth
                                helperText={userFormik.touched.email && userFormik.errors.email}
                                label={t('attributes.email')}
                                name="email"
                                onBlur={userFormik.handleBlur}
                                onChange={userFormik.handleChange}
                                required
                                type="email"
                                value={userFormik.values.email}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                error={Boolean(userFormik.touched.phone && userFormik.errors.phone)}
                                fullWidth
                                helperText={userFormik.touched.phone && userFormik.errors.phone}
                                label={t('attributes.phone')}
                                name="phone"
                                onBlur={userFormik.handleBlur}
                                onChange={userFormik.handleChange}
                                value={userFormik.values.phone}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <TextField
                                error={Boolean(userFormik.touched.work_location && userFormik.errors.work_location)}
                                fullWidth
                                label={t('attributes.work_location')}
                                name="work_location"
                                onBlur={userFormik.handleBlur}
                                onChange={userFormik.handleChange}
                                type="text"
                                value={userFormik.values.work_location}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <DatePicker
                                maxDate={new Date()}
                                label={t('attributes.date_of_birth')}
                                value={userFormik.values.date_of_birth ? new Date(userFormik.values.date_of_birth) : null}
                                onChange={(newValue) => userFormik.setFieldValue('date_of_birth', newValue)}
                                onBlur={() => userFormik.setFieldTouched('date_of_birth')}
                                yearsOrder="desc"
                                format="dd.MM.yyyy"
                                views={['year', 'month', 'day']}
                                slotProps={{
                                    textField: {fullWidth: true}
                                }}
                            />
                        </Grid>
                        <Grid item md={12} xs={12}>
                            <TextField
                                label={t('attributes.bio')}
                                error={Boolean(userFormik.touched.bio && userFormik.errors.bio)}
                                helperText={userFormik.touched.bio && userFormik.errors.bio}
                                name="bio"
                                fullWidth
                                rows={4}
                                multiline
                                onBlur={userFormik.handleBlur}
                                onChange={userFormik.handleChange}
                                value={userFormik.values.bio ? userFormik.values.bio : ""}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider />
                <CardActions>
                    <Stack direction="row-reverse" sx={{width: '100%'}}>
                        <SaveButton
                            formik={userFormik}

                            // loading={userFormik.isSubmitting}
                            // onClick={userFormik.handleSubmit}
                        />
                    </Stack>
                </CardActions>
            </Card>
        </form>
    );
}

UserData.propTypes = {
    className: PropTypes.string
}

export default UserData;
