import {
    UPDATE_SETTING,
    UPDATE_SCROLL,
    UPDATE_VISITOR,
    UPDATE_CART,
    CLEAR_CART,
    REMOVE_VOUCHER,
    REMOVE_FROM_CART,
    ADD_VOUCHER,
    STORE_THEME,
    UPDATE_WISHLIST,
    CLEAR_WISHLIST,
    MULTILANGUAGE_CHANGE_LANGUAGE,
    REMOVE_FROM_WISHLIST,
    RESET_VOUCHERS,
    SET_COUNTRY,
    RESET_COUNTRY,
    SET_DELIVERABLE_PRODUCTS,
    SET_DELIVERABLE_COUNTRIES,
    UPDATE_SHIPPING_COST,
    SET_COUNTRIES,
    SET_PRIVACY_HANDLED,
    ADD_PRIVACY_SETTING,
    REMOVE_PRIVACY_SETTING,
    UPDATE_PRIVACY_SETTING,
    UPDATE_OPEN_MOTIVATIONS,
    UPDATE_SITE_SETUP,
    UPDATE_CURRENT_SITE,
    SET_INITIALIZED,
    SEND_MESSAGE, REMOVE_MESSAGE
} from "../actions/cms-frontend-actions";
import { produce } from "immer";
import _ from "lodash";

let messageId = 0;

export const initState = {

    // Initialized
    initialized: false,

    // Central details about the visitor, the client key and the country settings
    visitor: {},
    language: null,
    country: null,
    countries: [],

    // The list of websites available and the current site being visited
    sites: [],
    menus: [],
    configuration: {},
    currentSite: null,
    blogDefaultAuthor: null,

    // The languages that are available
    languages: [],

    // Whether maintenance is on or off
    maintenance: false,

    // Tracking software and 3rd party that is used
    metaPixel: null,
    omniaAnalytics: true,
    googleAnalyticsKey: null,

    // The theme setup
    theme: {},

    // This is the RAM for the motivation controller
    openMotivations: [],

    // Privacy settings and setup (also storing if the user has handled that already)
    handled: false,
    allowPrivacy: [],

    // Messages to the architect the be proxied to Omnia
    messages: [],

    // Where the visitor is currently scrolled
    scroll: 0,
};

const cmsFrontendReducer = (state = initState, action) => {

    // Check the action type
    switch (action.type) {

        case SEND_MESSAGE: {
            return {
                ...state,
                messages: [...state.messages, { id: messageId++, content: action.payload }],
            };
        }

        case REMOVE_MESSAGE: {
            return {
                ...state,
                messages: state.messages.filter((message) => message.id !== action.id),
            };
        }

        case SET_INITIALIZED: {
            return produce(state, draft => {
                draft.initialized = true;
            })
        }

        case UPDATE_SITE_SETUP: {
            const { siteSetup } = action.payload;
            return produce(state, draft => {
                draft.siteSetup = siteSetup;
            });
        }

        case UPDATE_CURRENT_SITE: {
            const { currentSite } = action.payload;
            return produce(state, draft => {
                draft.currentSite = currentSite;
            });
        }

        case UPDATE_OPEN_MOTIVATIONS: {
            const { motivations } = action.payload;
            return produce(state, draft => {
                draft.openMotivations = motivations;
            })
        }

        case UPDATE_PRIVACY_SETTING: {
            const { allPrivacySettings } = action.payload;
            return produce(state, draft => {
                draft.allowPrivacy = allPrivacySettings;
            });
        }

        case MULTILANGUAGE_CHANGE_LANGUAGE: {
            const { identifier } = action.payload;
            return produce(state, draft => {
                draft.currentLanguageCode = identifier;
            })
        }

        case STORE_THEME: {
            const { themeOptions } = action.payload;
            return produce(state, draft => {
                draft.theme = themeOptions;
            });
        }

        case ADD_PRIVACY_SETTING: {
            const { privateToAdd } = action.payload;
            return produce(state, draft => {
                if(!state.allowPrivacy.includes(privateToAdd)){
                    draft.allowPrivacy.push(privateToAdd);
                }
            });
        }

        case REMOVE_PRIVACY_SETTING: {
            const { privateToRemove } = action.payload;
            return produce(state, draft => {
                draft.allowPrivacy = state.allowPrivacy.filter(p => p !== privateToRemove);
            });
        }

        case SET_PRIVACY_HANDLED: {
            return produce(state, draft => {
                draft.handled = true;
            });
        }

        case SET_COUNTRIES: {
            const { allCountries } = action.payload;
            return produce(state, draft => {
                // Save the deliverable products
                draft.countries = allCountries;
            });
        }

        case UPDATE_SHIPPING_COST: {
            const { shippingCost } = action.payload;
            return produce(state, draft => {
                // Save the deliverable products
                draft.shippingCost = shippingCost;
            });
        }

        case SET_DELIVERABLE_PRODUCTS: {
            const { deliverables } = action.payload;
            return produce(state, draft => {
                // Save the deliverable products
                draft.deliverable_products = deliverables;
            });
        }

        case SET_DELIVERABLE_COUNTRIES: {
            const { deliverableCountries } = action.payload;
            return produce(state, draft => {
                // Save the deliverable products
                draft.deliverable_countries = deliverableCountries;
            })
        }

        case SET_COUNTRY: {
            const { countryData } = action.payload;
            return produce(state, draft => {
                draft.country = countryData;
                draft.visitor.country_code = countryData.code;
            })
        }

        case RESET_COUNTRY: {
            return produce(state, draft => {
                draft.country = null;
            })
        }

        case REMOVE_VOUCHER: {
            const { voucherCode } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.vouchers, {'code': voucherCode})
                if(index !== -1)
                    draft.vouchers = state.vouchers.filter(i => i.code !== voucherCode);
            })
        }

        case RESET_VOUCHERS: {
            return produce(state, draft => {
                draft.vouchers = [];
            });
        }

        case ADD_VOUCHER: {
            const { voucher } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.vouchers, {id: voucher.id});
                if(index !== -1){
                    draft.vouchers[index] = voucher;
                } else {
                    draft.vouchers = state.vouchers.concat([voucher])
                }
            });
        }

        case UPDATE_CART: {
            const { variantId, quantity } = action.payload;
            return produce(state, draft => {
                let index = _.findIndex(state.cart, {id: variantId});
                if(index !== -1){
                    if(quantity === 0){
                        draft.cart = state.cart.filter(c => c.id !== variantId);
                    } else {
                        draft.cart[index]['quantity'] = quantity;
                    }
                } else {
                    if(quantity > 0){
                        draft.cart = state.cart.concat([{
                            id: variantId,
                            quantity: quantity
                        }])
                    }
                }
            });
        }

        case UPDATE_WISHLIST: {
            const { productId } = action.payload;
            return produce(state, draft => {
                if(productId){
                    if(!state.wishlist.includes(productId))
                        draft.wishlist = state.wishlist.concat([productId]);
                }
                draft.wishlist = draft.wishlist.filter(i => i !== null);
            })
        }

        case REMOVE_FROM_WISHLIST: {
            const { removeProductId } = action.payload;
            return produce(state, draft => {
                let index = state.wishlist.indexOf(removeProductId);
                if(index !== -1)
                    draft.wishlist = state.wishlist.filter(i => i !== removeProductId);
            })
        }

        case REMOVE_FROM_CART: {
            const { removeCartId } = action.payload;
            return produce(state, draft => {
                // let index = state.cart.map(c => c.id).indexOf(removeCartId);
                let index = _.findIndex(state.cart, {'id': parseInt(removeCartId)})
                if(index !== -1)
                    draft.cart = state.cart.filter(i => i.id !== removeCartId);
            })
        }

        case CLEAR_CART: {
            return produce(state, draft => {
                draft.cart = [];
            })
        }

        case CLEAR_WISHLIST: {
            return produce(state, draft => {
                draft.wishlist = [];
            })
        }

        case UPDATE_VISITOR: {
            const { visitor } = action.payload;
            return produce(state, draft => {
                if(typeof(state.visitor) === "undefined")
                    draft.visitor = {}
                draft.visitor = visitor;
            });
        }

        case UPDATE_SCROLL: {
            const { scroll } = action.payload;
            return produce(state, draft => {
                draft.scroll = scroll;
            })
        }

        case UPDATE_SETTING: {
            const { data, key } = action.payload;

            return produce(state, draft => {
                // Check if the key exists in the data
                if(Object.keys(data).includes(key)){
                    draft[key] = data[key];
                    if(key === "sites")
                        draft.initialized = true;
                } else {
                    console.log('Key "' + key + '" not found in CMS data:', data);
                }
            });
        }

        default: {
            return state;
        }

    }

};

export default cmsFrontendReducer;
