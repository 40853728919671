import React from "react";
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

export const KanbanCardAction = (props) => {
    const { icon, children, menuRef = null, disabled = false, color = 'default', ...other } = props;

    return (
        <Button
            fullWidth
            disabled={disabled}
            startIcon={icon}
            ref={menuRef}
            color={color}
            sx={{
                justifyContent: 'flex-start',
            }}
            variant="contained"
            {...other}
        >
            {children}
        </Button>
    );
};

KanbanCardAction.propTypes = {
    icon: PropTypes.element,
    children: PropTypes.node,
    menuRef: PropTypes.node,
    disabled: PropTypes.bool
};
