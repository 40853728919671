import React, { useRef } from "react";
import PropTypes from "prop-types";
import {Button, Stack, Box, Typography, TextField, IconButton, MenuItem, Divider, Grid} from "@mui/material";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";

const AVAILABLE_WEIGHTS = ["200", "300", "400", "500", "600", "700"];

const TypographyFontFileComponent = ({ weight, formik }) => {
    const { t } = useTranslation();
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFontFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            formik.setFieldValue(`system_font_${weight}`, file);
        }
    };

    const fontName = formik.values?.[`system_font_${weight}`]?.name || 'No Custom Font';

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography
                noWrap
                variant="h6"
                sx={{fontWeight: weight}}
            >
                {t('mgt.settings.system_font_weight', {weight: weight})}
            </Typography>

            <Stack direction="row" spacing={2} alignItems="center">
                <Typography variant="body1">
                    {fontName}
                </Typography>
                <IconButton
                    variant="contained"
                    onClick={handleButtonClick}
                >
                    <UploadFileIcon />
                </IconButton>
                <input
                    ref={fileInputRef}
                    type="file"
                    hidden
                    accept=".ttf,.otf"
                    onChange={handleFontFileChange}
                />
            </Stack>
        </Stack>
    );
};

const TypographyVariantComponent = ({ variant, formik }) => {
    const { t } = useTranslation();

    const fontSize = formik.values?.[`font_${variant}_size`] || 16;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography
                noWrap
                variant={variant.includes('h') ? null : variant}
                sx={{
                    lineHeight: 1,
                    fontSize: fontSize,
                    fontWeight: formik.values?.[`font_${variant}_weight`] || 400
                }}
            >
                {/*{t('mgt.settings.font_variant_preview', {variant: variant})}*/}
                {variant}
            </Typography>

            <Stack direction="row" spacing={2} alignItems="center">
                <NumericFormat
                    value={parseInt(formik.values?.[`font_${variant}_size`])}
                    onValueChange={(values) => {
                        formik.setFieldValue(`font_${variant}_size`, values.floatValue);
                    }}
                    name={`font_${variant}_size`}
                    label={t("mgt.settings.font_size_label")}
                    customInput={TextField}
                    decimalScale={0}
                    thousandSeparator="."
                    decimalSeparator=","
                    isNumericString
                />
                <NumericFormat
                    value={parseFloat(formik.values?.[`font_${variant}_line_height`])}
                    onValueChange={(values) => {
                        formik.setFieldValue(`font_${variant}_line_height`, values.floatValue);
                    }}
                    name={`font_${variant}_line_height`}
                    label={t("mgt.settings.font_line_height_label")}
                    customInput={TextField}
                    decimalScale={2}
                    thousandSeparator="."
                    decimalSeparator=","
                    isNumericString
                />
                <TextField
                    select
                    sx={{minWidth: 130}}
                    value={formik.values?.[`font_${variant}_weight`]}
                    onChange={formik.handleChange}
                    name={`font_${variant}_weight`}
                    label={t('mgt.settings.font_weight_label')}
                >
                    {AVAILABLE_WEIGHTS.map(option => (
                        <MenuItem key={option} value={option} sx={{fontWeight: option}}>
                            {t('mgt.settings.font_weight', {weight: option})}
                        </MenuItem>
                    ))}
                </TextField>
            </Stack>
        </Stack>
    );
};

function SectionTypography({ formik }) {
    const { t } = useTranslation();
    const typographyVariants = ["h1", "h2", "h3", "h4", "h5", "h6", "subtitle1", "subtitle2", "body1", "body2", "caption", "button", "overline"];

    return (
        <>
            <Grid container spacing={2} sx={{p: 2}}>
                <Grid item xs={12} sx={{mb: 2}}>
                    <Typography
                        variant="h6"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.typography')}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.typography_instructions')}
                    </Typography>
                </Grid>
            </Grid>
            <Divider sx={{my: 4}} />
            <Box p={2}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    {t('mgt.settings.font_files')}
                </Typography>
            </Box>
            <Box mt={2} p={2}>
                <Stack direction="column" spacing={2}>
                    {AVAILABLE_WEIGHTS.map((weight) => (
                        <TypographyFontFileComponent key={weight} weight={weight} formik={formik} />
                    ))}
                </Stack>
            </Box>
            <Divider sx={{ my: 4 }} />
            <Box p={2}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    {t('mgt.settings.font_specification')}
                </Typography>
            </Box>
            <Box p={2}>
                <Stack direction="column" spacing={2}>
                    {typographyVariants.map((variant) => (
                        <TypographyVariantComponent key={variant} variant={variant} formik={formik} />
                    ))}
                </Stack>
            </Box>
        </>
    );
}

SectionTypography.propTypes = {
    formik: PropTypes.object.isRequired
};

export default SectionTypography;
