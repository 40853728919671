import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import {Avatar, Box, ButtonBase, SvgIcon} from '@mui/material';
import {usePopover} from 'src/omnia/hooks/use-popover';
import {AccountPopover} from './account-popover';
import {useSelector} from "react-redux";
import {useCreateSrc} from "../../../../hooks/use-create-src";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useCallback} from "react";
import debounce from "lodash.debounce";
import {useNavigate} from "react-router-dom";

export const AccountButton = () => {

    const { user } = useSelector(state => state.account);
    const popover = usePopover();
    const { isMobile } = useIsMobile();
    const createSource = useCreateSrc();
    const navigate = useNavigate();

    const handlePopoverOperation = (operation) => {
        if(operation === 'close') {
            popover.handleClose();
        }
    }

    const handleOpenProfile = () => {
        if(isMobile){
            popover.handleOpen();
        } else {
            navigate('/groon/profile')
        }
    }

    const queuePopoverOperation = useCallback(debounce(handlePopoverOperation, 200), []);

    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpenProfile}
                onMouseEnter={() => {
                    popover.handleOpen();
                    popover.queueOpen();
                }}
                onMouseLeave={() => {
                    if(!isMobile){
                        popover.queueClose();
                    }
                }}
                ref={popover.anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    borderWidth: 2,
                    borderStyle: 'solid',
                    borderColor: 'divider',
                    height: 40,
                    width: 40,
                    borderRadius: '50%'
                }}
            >
                <Avatar
                    sx={{
                        height: 32,
                        width: 32
                    }}
                    src={createSource(user.avatar)}
                >
                    <SvgIcon>
                        <User01Icon/>
                    </SvgIcon>
                </Avatar>
            </Box>
            <AccountPopover
                popover={popover}
                anchorEl={popover.anchorRef.current}
                onClose={popover.handleClose}
                onNewOperation={queuePopoverOperation}
                open={popover.open}
            />
        </>
    );
};
