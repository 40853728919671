import React  from 'react';
import * as Yup from "yup";
import {Box, Button, Dialog, DialogContent, DialogTitle, Grid, TextField, Typography} from "@mui/material";
import {Formik} from "formik";
import useApi from "src/omnia/hooks/use-api";
import {useNotifications} from "src/omnia/hooks/use-notifications";
import PropTypes from "prop-types";

function EditBoard({board, open, onSuccess, onClose, ...rest}){

    const { notify } = useNotifications();
    const { put } = useApi();

    return (
        <Formik
            {...rest}
            initialValues={board}
            validationSchema={Yup.object().shape({
                name: Yup.string().required('Das Board benötigt einen Namen'),
                description: Yup.string(),
            })}
            onSubmit={(values, {resetForm, setSubmitting}) => {
                setSubmitting(true);
                const submit = async () => {
                    // correct space id
                    values['space'] = board.space;
                    put('pm/project-boards', values)
                        .then(data => {
                            if(onSuccess)
                                onSuccess(data);
                            notify("Gespeichert", "success")
                            onClose();
                            resetForm();
                        })
                        .finally(() => {
                            setSubmitting(false);
                        })
                };
                submit();
            }}
        >
            {({errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values}) => (
                <Dialog open={open} fullWidth maxWidth="sm" onClose={onClose} >
                    <Box>
                        <DialogTitle>
                            <Typography variant="h4">
                                Bearbeiten
                            </Typography>
                        </DialogTitle>
                    </Box>
                    <DialogContent>
                        <form onSubmit={handleSubmit}>
                            <Box mt={2}>
                                <TextField
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    helperText={touched.name && errors.name}
                                    label="Name"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                />
                            </Box>
                            <Box mt={4}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Button
                                            disabled={isSubmitting}
                                            onClick={onClose}
                                            fullWidth
                                            type="button"
                                            variant="text">
                                            Abbrechen
                                        </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button
                                            color="primary"
                                            disabled={isSubmitting}
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                        >
                                            Speichern
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        </form>
                    </DialogContent>
                </Dialog>
            )}
        </Formik>
    );
}

EditBoard.propTypes = {
    board: PropTypes.object,
    open: PropTypes.bool,
    onSuccess: PropTypes.func,
    onClose: PropTypes.func
}

export default EditBoard;