import React from 'react';
import Avatar from '@mui/material/Avatar';
import PropTypes from "prop-types";
import OnIcon from "../../elements/icon";

function UserAvatar({user, children, ...rest}) {

    if(!user)
        return null;

    return (
        <Avatar
            src={user.avatar?.view || null}
            to={'/groon/profile/' + user.id}
            {...rest}
        >
            {children || <OnIcon iconName="User01" />}
        </Avatar>
    );
}

UserAvatar.propTypes = {
    noStar: PropTypes.bool,
    user: PropTypes.object,
    children: PropTypes.string,
    onLoad: PropTypes.func
};

export default UserAvatar;