import React from "react";
import {Grid, TextField, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

function SectionGeneral({formik}){

    const { t } = useTranslation();

    return (
        <>
            <Grid container spacing={2} sx={{p: 2}}>
                <Grid item xs={12} sx={{mb: 6}}>
                    <Typography
                        variant="h6"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.general')}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{mb: 2}}
                    >
                        {t('mgt.settings.general_instructions')}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label={t('attributes.name')}
                        name='name'
                        autoFocus={true}
                        fullWidth={true}
                        onChange={formik.handleChange}
                        value={formik.values.name || ''}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                        onBlur={formik.handleBlur}
                        placeholder={t('mgt.settings.theme_name_placeholder')}
                    />
                </Grid>
            </Grid>
        </>
    )
}

SectionGeneral.propTypes = {
    formik: PropTypes.object.isRequired
}

export default SectionGeneral;