/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import { produce } from 'immer';
import _ from 'lodash';
import {
    ADD_TRANSACTION,
    REMOVE_TRANSACTION,
    ADD_TRANSACTIONS_INITIAL,
    ADD_ACCOUNTS_INITIAL,
    ADD_ACCOUNT,
    REMOVE_ACCOUNT,
    UPDATE_TRANSACTION,
    UPDATE_ACCOUNT,
} from 'src/omnia/store/actions/transactions-actions';

const initialState = {
    transactions: [],
    accounts: [],
    accountsLoaded: false,
    transactionsLoaded: false,
};

const transactionsReducer = (state = initialState, action) => {

    let index = -1;
    let itemId = -1;
    let account = null;
    let transaction = null;
    let update = null;

    switch (action.type) {

        case ADD_TRANSACTIONS_INITIAL:
            return produce(state, (draft) => {
                draft.transactions = action.payload.transactions;
                draft.transactionsLoaded = true;
            })

        case ADD_ACCOUNTS_INITIAL:
            return produce(state, (draft) => {
                draft.accounts = action.payload.accounts;
                draft.accountsLoaded = true;
            })

        case ADD_TRANSACTION:
            transaction = action.payload.transaction;
            return produce(state, (draft) => {
                // Check if account already exists in redux
                index = _.findIndex(state.transactions, {id: transaction['id']});
                if( index !== -1 ){
                    draft.transactions[index] = transaction;
                } else {
                    draft.transactions = state.transactions.concat([transaction]);
                }
            })

        case REMOVE_TRANSACTION:
            itemId = action.payload.transactionId;
            return produce(state, (draft) => {
                draft.transactions = state.transactions.filter(a => a.id !== itemId);
            })

        case UPDATE_TRANSACTION:
            transaction = action.payload.transaction;
            update = action.payload.update;
            return produce(state, (draft) => {
                index = _.findIndex(state.transactions, {id: transaction.id});
                if( index !== -1 ){
                    let key = "";
                    for(let i = 0; i < Object.keys(update).length; i++){
                        key = Object.keys(update)[i];
                        draft.transactions[index][key] = update[key];
                    }
                }
            })

        case UPDATE_ACCOUNT:
            account = action.payload.account;
            update = action.payload.update;
            return produce(state, (draft) => {
                index = _.findIndex(state.accounts, {id: account.id});
                if( index !== -1 ){
                    let key = "";
                    for(let i = 0; i < Object.keys(update).length; i++){
                        key = Object.keys(update)[i];
                        draft.accounts[index][key] = update[key];
                    }
                }
            })


        case ADD_ACCOUNT:
            account = action.payload.account;
            return produce(state, (draft) => {
                // Check if account already exists in redux
                index = _.findIndex(state.accounts, {id: account['id']});
                if( index !== -1 ){
                    draft.accounts[index] = account;
                } else {
                    draft.accounts = state.accounts.concat([account]);
                }
            })

        case REMOVE_ACCOUNT:
            itemId = action.payload.accountId;
            return produce(state, (draft) => {
                draft.accounts = state.accounts.filter(a => a.id !== itemId);
            })

        default:
            return state;

    }
}

export default transactionsReducer;