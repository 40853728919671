import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Box, Button, OutlinedInput, SvgIcon} from '@mui/material';
import useApi from "src/omnia/hooks/use-api";
import {useNotifications} from "src/omnia/hooks/use-notifications";
import {Plus as PlusIcon} from "src/omnia/icons/plus";

function CheckItemAdd({checklist, ...rest}) {

    const {notify} = useNotifications();
    const {post} = useApi();
    const [name, setName] = useState('');
    const [isExpanded, setExpanded] = useState(false);

    const handleAdd = () => {
        setExpanded(true);
    };

    const handleCancel = () => {
        setExpanded(false);
        setName('');
    };

    const handleChange = (event) => {
        event.persist();
        setName(event.target.value);
    };

    const handleSave = async () => {
        if (!name) {
            return;
        }

        post('pm/project-board-list-card-checklists/' + checklist.id + '/items', {
            name: name,
            list: checklist.id
        }).then(() => {
            setExpanded(false);
            setName('');
        })
    };

    return (
        <Box
            sx={{width: '100%'}}
            {...rest}>
            {isExpanded
                ? (
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            width: '100%'
                        }}
                    >
                        <OutlinedInput
                            onChange={handleChange}
                            placeholder="Neuer Punkt"
                            onKeyDown={(e) => {
                                if(e.keyCode === 13){
                                    handleSave();
                                }
                            }}
                            value={name}
                            sx={{
                                flexGrow: 1,
                                '& .MuiInputBase-input': {
                                    px: 2,
                                    py: 1
                                }
                            }}
                        />
                        <Button
                            onClick={handleSave}
                            size="small"
                            sx={{ml: 2}}
                            variant="contained"
                        >
                          Hinzufügen
                        </Button>
                        <Button
                            onClick={handleCancel}
                            size="small"
                            sx={{ml: 2}}
                        >
                            Abbrechen
                        </Button>
                    </Box>
                )
                : (
                    <Button
                        onClick={handleAdd}
                        size="small"
                        startIcon={(
                            <SvgIcon fontSize="small">
                                <PlusIcon fontSize="small"/>
                            </SvgIcon>
                        )}
                    >
                        Hinzufügen
                    </Button>
                )}
        </Box>
    );
}

CheckItemAdd.propTypes = {
    card: PropTypes.object.isRequired,
    checklist: PropTypes.object.isRequired
};

export default CheckItemAdd;
