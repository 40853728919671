import React from "react";
import AssistantLogo from "../assistants/assistant-logo";
import {Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/system";

function AssistantImageTitle({ assistant, ...rest }){

    const { t } = useTranslation();

    return (
        <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            variant="text"
            {...rest}
        >
            <AssistantLogo assistant={assistant}/>
            <Typography variant="h6" color="textPrimary">
                {assistant?.name}
            </Typography>
        </Stack>
    )
}

export default AssistantImageTitle;