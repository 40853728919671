import React, {useEffect, useState} from "react";
import {
    Fade,
    CircularProgress,
    Typography, Chip, Button,
} from "@mui/material";
import moment from "moment";
import useApi from "src/omnia/hooks/use-api";
import useIsMountedRef from "src/omnia/hooks/use-is-mounted-ref";
import DataTable from "src/omnia/components/elements/tables/data-table";
import {useTranslation} from "react-i18next";
import {APP_SETTING} from "../../../../../../../setup";

function Sessions() {

    const [sessions, setSessions] = useState([]);
    const {t} = useTranslation();
    const [loading, setLoading] = useState(true);
    const [loadDelete, setLoadDelete] = useState(-1);
    const {get, post} = useApi();
    const isMounted = useIsMountedRef();

    const handleRemoveToken = (session) => {
        if (!session.is_this_device) {
            if (window.confirm(t('notify.are_you_sure'))) {
                setLoadDelete(session.token);
                post('core/me/kill_token', {token: session.token}).then(() => {
                    setSessions(prev => prev.filter(s => s.token !== session.token));
                }).finally(() => {
                    setLoadDelete(-1);
                })
            }
        }
    }

    const columns = [
        {
            headerName: t('common.device'),
            type: "string",
            field: "os",
            width: 240,
            renderCell: data => (
                <Typography color="textPrimary" variant="h6">
                    {data.row.os}
                </Typography>
            )
        },
        {
            headerName: t('attributes.last_location'),
            type: "string",
            field: "location",
            width: 240,
            renderCell: data => (
                <Typography color="textPrimary" variant="body2">
                    {data.row.location ? (
                        <>{data.row.location}</>
                    ) : (
                        <>{data.row.ip}</>
                    )}
                </Typography>
            )
        },
        {
            headerName: t('attributes.status'),
            type: "dateTime",
            headerAlign: 'left',
            align: 'left',
            field: "end_at",
            width: 240,
            valueGetter: data => new Date(data),
            renderCell: data => (
                <>
                    {data.row.is_this_device ? (
                        <Chip
                            color="secondary"
                            label={(
                                <Typography sx={{fontSize: '14px', fontWeight: '400'}}>
                                    {t('mgt.this_device')}
                                </Typography>
                            )}
                            size="small"
                        />
                    ) : (
                        <>
                            {data.row.end_at ? (
                                <Typography color="textPrimary" variant="body2">
                                    {t('common.last_active_from_now', {fromNow: moment(data.row.end_at).fromNow()})}
                                </Typography>
                            ) : (
                                <Chip
                                    color="secondary"
                                    label={(
                                        <Typography sx={{fontSize: '14px', fontWeight: '400'}}>
                                            {t('mgt.device_online')}
                                        </Typography>
                                    )}
                                    size="small"
                                />
                            )}
                        </>
                    )}
                </>
            )
        },
        {
            headerName: t('common.actions'),
            type: "string",
            headerAlign: 'left',
            align: 'left',
            sortable: false,
            field: "id",
            width: 180,
            renderCell: data => (
                <>
                    {!data.row.is_this_device && (
                        <Button
                            disabled={loadDelete === data.row.token}
                            size="small"
                            onClick={() => handleRemoveToken(data.row)}
                            variant="outlined"
                            color="primary"
                        >
                            {t('layout.logout')}
                        </Button>
                    )}
                </>
            )
        }
    ]

    const loadSessions = () => {
        get('core/users/sessions').then(data => {
            if (isMounted.current)
                setSessions(data);
        }).finally(() => {
            if (isMounted.current)
                setLoading(false);
        })
    }

    useEffect(() => {
        loadSessions();
    }, []);

    return (
        <Fade in={true} timeout={APP_SETTING?.transitionDuration || 500}>
            {loading ? (
                <div style={{height: 300, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <CircularProgress/>
                </div>
            ) : (
                <DataTable
                    title={t('mgt.sessions')}
                    id="sessions-table"
                    data={sessions}
                    columns={columns}
                    withoutCheckbox
                    withoutToolbar
                />
            )}
        </Fade>
    )
}

export default Sessions;