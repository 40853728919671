import React from 'react';
import PropTypes from "prop-types";
import * as Icons from "@untitled-ui/icons-react/build/cjs";
import { SvgIcon } from "@mui/material";

const OnIcon = ({ iconName, size = 'medium', sx = {}, ...rest }) => {

    const importIcon = (iconName) => {
        if (!iconName) return null;
        try {
            if (Object.keys(Icons).includes(iconName)) {
                return (
                    <SvgIcon
                        fontSize={size !== 'tiny' ? size : undefined}
                        sx={size === 'tiny' ? { fontSize: 'h6.fontSize', ...sx } : sx}
                        {...rest}
                    >
                        {React.createElement(Icons[iconName])}
                    </SvgIcon>
                );
            }
            return null;
        } catch (error) {
            console.error("Failed to load icon:", iconName, error);
            return null;
        }
    };

    if (!iconName)
        return null;

    return importIcon(iconName);
};

OnIcon.propTypes = {
    iconName: PropTypes.string,
    size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large', 'inherit']),
    sx: PropTypes.object
};

export default OnIcon;
