/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import {
    SET_ONLY_ACTIVE,
    SET_TASK_QUERY,
    SET_SERVERS,
    SET_WORKLOADS,
    UPDATE_SERVER,
    DELETE_SERVER,
    UPDATE_WORKLOAD,
    DELETE_WORKLOAD,
    SET_WORKERS,
    UDPATE_WORKER,
    UDPATE_WORKER_LOG,
    SET_QUEUES,
    UPDATE_QUEUE,
    DELETE_QUEUE,
    DELETE_WORKER,
    UPDATE_REQUEST_COUNT
} from 'src/omnia/store/actions/admin-actions';
import { produce } from "immer";

const initialState = {
    tasksQuery: "",
    onlyActiveTasks: false,
    servers: null,
    requestsCount: 0,
    workloads: [],
    queues: [],
    workers: [],
    settings: null,
};

const adminReducer = (state = initialState, action) => {

    switch (action.type) {

        case UPDATE_REQUEST_COUNT: {
            return produce(state, draft => {
                draft.requestsCount = action.payload;
            });
        }

        case DELETE_QUEUE: {
            return produce(state, draft => {
                draft.queues = draft.queues.filter(queue => queue.id !== action.payload);
            });
        }

        case DELETE_WORKER: {
            return produce(state, draft => {
                draft.workers = draft.workers.filter(worker => worker.id !== action.payload);
            })
        }

        case SET_QUEUES: {
            return produce(state, draft => {
                draft.queues = action.payload.sort((a, b) => a.name > b.name ? 1 : -1);
            })
        }

        case UPDATE_QUEUE: {
            return produce(state, draft => {
                const queueIndex = draft.queues.findIndex(queue => queue.id === action.payload.id);

                if (queueIndex === -1) {
                    draft.queues.push(action.payload);
                } else {
                    draft.queues[queueIndex] = action.payload;
                }

                draft.queues.sort((a, b) => a.name > b.name ? 1 : -1);
            });
        }

        case UDPATE_WORKER_LOG: {
            return produce(state, draft => {
                const workerIndex = draft.workers.findIndex(worker => worker.identifier === action.payload.worker);

                if(workerIndex !== -1){
                    draft.workers[workerIndex].last_log = {
                        message: action.payload.message,
                        timestamp: (new Date()).getTime(),
                        kind: action.payload.kind,
                    };
                }
            });
        }

        case SET_WORKERS: {
            return produce(state, draft => {
                draft.workers = action.payload.sort((a, b) => a.hostname > b.hostname ? 1 : -1);
            });
        }

        case UDPATE_WORKER: {
            return produce(state, draft => {
                const workerIndex = draft.workers.findIndex(worker => worker.id === action.payload.id);

                if (workerIndex === -1) {
                    draft.workers.push(action.payload);
                } else {
                    // draft.tasks[workerIndex] = action.payload;
                    Object.assign(draft.workers[workerIndex], action.payload);
                }

                draft.workers.sort((a, b) => a.hostname > b.hostname ? 1 : -1);
            });
        }

        case DELETE_SERVER: {
            return produce(state, draft => {
                draft.servers = draft.servers.filter(server => server.id !== action.payload);
            });
        }

        case DELETE_WORKLOAD: {
            return produce(state, draft => {
                draft.workloads = draft.workloads.filter(workload => workload.id !== action.payload);
                draft.servers = draft.servers.map(server => {

                    if(server.workloads.map(w => w.id).includes(action.payload)){
                        return {
                            ...server,
                            workloads: server.workloads.filter(workload => workload.id !== action.payload).sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0))
                        }
                    }

                    return server;
                })
            })
        }

        case UPDATE_SERVER: {
            return produce(state, draft => {
                const serverIndex = draft.servers.findIndex(server => server.id === action.payload.id);

                if (serverIndex === -1) {
                    draft.servers.push(action.payload);
                } else {
                    draft.servers[serverIndex] = action.payload;
                }

                draft.servers = draft.servers.sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0)).map(server => ({
                    ...server,
                    workloads: server.workloads.sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0))
                }));
            })
        }

        case SET_SERVERS: {
            return produce(state, draft => {
                draft.servers = action.payload.sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0)).map(server => ({
                    ...server,
                    workloads: server.workloads.sort((a, b) => a.name > b.name ? 1 : (a.name < b.name ? -1 : 0))
                }));
            })
        }

        case SET_WORKLOADS: {
            return produce(state, draft => {
                draft.workloads = action.payload.sort((a, b) => a.name > b.name ? 1 : -1);
            })
        }

        case SET_ONLY_ACTIVE: {
            return produce(state, draft => {
                draft.onlyActiveTasks = action.payload;
            })
        }

        case SET_TASK_QUERY: {
            return produce(state, draft => {
                draft.tasksQuery = action.payload;
            })
        }

        case UPDATE_WORKLOAD: {
            return produce(state, draft => {
                const workloadIndex = draft.workloads.findIndex(workload => workload.id === action.payload.id);

                if (workloadIndex === -1) {
                    draft.workloads.push(action.payload);
                } else {
                    draft.workloads[workloadIndex] = action.payload;
                }

                draft.workloads.sort((a, b) => a.name > b.name ? 1 : -1);
            })
        }

        default:
            return state;
    }
};

export default adminReducer;