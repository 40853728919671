import React, { useState, useEffect } from "react";
import {
    Card,
    CardHeader,
    Box,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Stack, Tooltip, SvgIcon, CircularProgress, Typography, Divider,
} from "@mui/material";
import useOmniaApi from "../../../hooks/use-omnia-api";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SaveButton from "../../elements/save-button";
import DeleteIcon from "@untitled-ui/icons-react/build/esm/Trash01"
import ContentCopyIcon from "@untitled-ui/icons-react/build/esm/Copy01"

function UserToken() {
    const { t } = useTranslation();
    const { post, del, get } = useOmniaApi();
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [tokens, setTokens] = useState([]);

    const fetchTokens = () => {
        get('core/tokens').then(response => {
            setTokens(response);
        }).catch(error => {
            console.error("Error taking all tokens:", error);
        });
    };

    const handleGenerateToken = () => {
        setLoading(true);
        post('core/tokens').then(() => {
            fetchTokens(); // Be up to date with the list of tokens after the creation
        }).catch(error => {
            console.error("Error generating custom token:", error);
        }).finally(() => {
            setLoading(false);
        })
    };

    const handleDeleteToken = (tokenId) => {
        if(window.confirm(t('notify.are_you_sure'))){
            del('core/tokens', tokenId).then(() => {
                fetchTokens(); // be up to date
            }).catch(error => {
                console.error("Error deleting this token:", error);
            });
        }
    };

    const handleCopyToken = (token) => {
        navigator.clipboard.writeText(token).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        }).catch(error => {
            console.error("Error copying token:", error);
        });
    };

    useEffect(() => {
        fetchTokens(); // loading the already existing tokens
    }, []);

    return (
        <Card>
            <CardHeader
                title={t('common.token')}
                action={(
                    <SaveButton
                        loading={loading}
                        variant="contained"
                        onClick={handleGenerateToken}
                        label={t('core.generate_token')}
                    />
                )}
            />
            <Divider />
            <Stack spacing={2}>
                {tokens?.length === 0 ? (
                    <div style={{height: 400, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography
                            variant="h5"
                            color="textSecondary"
                        >
                            {t('core.no_token_found')}
                        </Typography>
                    </div>
                ) : (
                    <List>
                        {tokens.map(token => (
                            <ListItem
                                key={token.id}
                                secondaryAction={
                                    <Box display="flex" gap={3}>
                                        <Tooltip enterDelay={1000} title={copied ? t('common.copied') : t('common.copy')} placement="left">
                                            <IconButton edge="end" aria-label="copy" onClick={() => handleCopyToken(token.token)}>
                                                <SvgIcon fontSize="small">
                                                    <ContentCopyIcon />
                                                </SvgIcon>
                                            </IconButton>
                                        </Tooltip>
                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteToken(token.id)}>
                                            <SvgIcon fontSize="small">
                                                <DeleteIcon />
                                            </SvgIcon>
                                        </IconButton>
                                    </Box>
                                }>
                                <ListItemText
                                    primary={token.token}
                                    secondary={`${t('common.expiry_date')}: ${moment(token.valid_until).calendar()}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                )}
            </Stack>
        </Card>
    );
}

export default UserToken;