import React from "react";
import PropTypes from "prop-types";
import {Box, Stack} from "@mui/material";
import OnIcon from "../../../elements/icon";
import {useTheme} from "@mui/system";

function AssistantLogo(props) {

    const {
        assistant,
        sizePixels = 30,
        sx = {},
        ...rest
    } = props;

    const theme = useTheme();

    if(!assistant)
        return null;

    return (
        <Box
            sx={{
                ...{
                    width: sizePixels + 'px !important',
                    height: sizePixels + 'px !important',
                    borderRadius: '10px',
                    backgroundColor: theme.palette.neutral[theme.palette.mode === 'light' ? 300 : 800]
                },
                ...sx
            }}
            {...rest}
        >
            <Stack sx={{width: '100%', height: '100%'}} justifyContent="center" alignItems="center">
                {assistant?.icon
                    ? <OnIcon iconName={assistant?.icon} size="small" fontSizeAdjust={10} />
                    : <img
                        alt='logo'
                        style={{height: 15, width: 15}}
                        src={assistant?.llm?.logo + (theme.palette.mode === 'light' ? '-dark.png' : '-light.png')}
                    />
                }
            </Stack>
        </Box>
    )
}

AssistantLogo.propTypes = {
    assistant: PropTypes.object.isRequired,
    sizePixels: PropTypes.number,
    sx: PropTypes.object
}

export default AssistantLogo;