import React, {useMemo} from 'react';
import { useSelector } from 'react-redux';
import { Box, Fade, LinearProgress } from '@mui/material';
import { APP_SETTING } from "../setup";
import { useAppMode } from "./hooks/use-app-mode";
import getSystemColorMode from "./utils/get-system-color-mode";
import { useTheme } from "@mui/system";
import {useSettings} from "./hooks/use-settings";

const TransitionOverlay = () => {
    const transitioning = useSelector(state => state.account.isTransitioning);
    const loadingState = useSelector(state => state.account.loadingState);
    const user = useSelector(state => state.account.user);
    const theme = useTheme();
    const settings = useSettings();
    const colorMode = getSystemColorMode();
    const appMode = useAppMode();
    const showLoading = ((user && loadingState?.percentage < 100) || appMode.loading) && appMode.isBackend;
    const isTransitioning = transitioning || showLoading;
    const rawLoadingValue = user ? loadingState?.percentage : (appMode.loading ? 0 : 100);
    const loadingValue = appMode.loading ? (0.9 * rawLoadingValue) : rawLoadingValue;

    // Pick the best match for the color (attention: this is tricky because things are loaded at different speeds)
    const backgroundColor = useMemo(() => {
        // Initiate the background color (device mode)
        let backgroundColor = colorMode === 'dark' ? '#151513' : '#ffffff';

        // Handle backend situation
        if(appMode.isBackend){
            if(settings?.isInitialized){
                if(settings?.systemPaletteMode){
                    backgroundColor = colorMode === 'dark' ? theme?.config?.dark_default_normal : theme?.config?.light_default_normal;
                } else {
                    if(settings?.paletteMode === theme?.palette?.mode){
                        backgroundColor = theme?.palette?.background?.default;
                    }
                }
            }
        }

        // Handle website situation
        if(appMode.isWebsite){
            // FIXME: we should probably use the
            backgroundColor = colorMode === 'dark' ? appMode.websiteTheme?.dark_default_normal : appMode.websiteTheme?.light_default_normal;
        }

        // TODO: Include service here as well
        return backgroundColor;
    }, [colorMode, settings, theme, appMode])

    return (
        <Box
            sx={{
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                position: 'fixed',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: backgroundColor,
                transition: 'opacity 0.5s ease-in-out, background-color 0.5s ease-in-out',
                opacity: isTransitioning ? 1 : 0,
                zIndex: 1600,
                pointerEvents: isTransitioning ? 'auto' : 'none'
            }}
        >
            <Fade in={showLoading} timeout={APP_SETTING?.transitionDuration || 500}>
                <Box sx={{ width: 300 }}>
                    <LinearProgress variant='determinate' value={loadingValue} />
                </Box>
            </Fade>
        </Box>
    );
};

export default TransitionOverlay;
