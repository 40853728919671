import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Box, Chip, Typography, Stack} from '@mui/material';
import {updateCard} from 'src/omnia/store/actions/kanban-actions';
import moment from "moment";
import useApi from "src/omnia/hooks/use-api";
import {TextEditor} from "../../../../../elements/text-editor/text-editor";
import InlineEditInput from "../../../../../elements/inline-edit-input";
import {useTranslation} from "react-i18next";

function Details({card, formik, ...rest}) {

    const dispatch = useDispatch();
    const [members, setMembers] = useState([]);
    const { t } = useTranslation();
    const { put, post } = useApi();
    const [initialTicketBody, setInitialTicketBody] = useState(-1);
    const boardId = useSelector(state => state.kanban.boardId);
    const users = useSelector(state => state.users.users);

    const handleRemoveDue = () => {
        put('pm/project-board-list-cards', {...{due: null}, ...{id: card.id}}).then(card => {
            dispatch(updateCard(boardId, card));
        });
    }

    const handleRemoveMember = (member) => {
        // dispatch remove member
        post('pm/project-board-list-cards/' + card.id + '/remove_member', {member: member.id}).then(card => {
            dispatch(updateCard(boardId, card));
        });
    }

    const handleRemoveLabel = (label) => {
        // dispatch remove member
        post('pm/project-board-list-cards/' + card.id + '/remove_label', {label: label}).then(card => {
            dispatch(updateCard(boardId, card));
        })
    }

    useEffect(() => {
        setInitialTicketBody(card.description);
        setMembers(users.filter(u => card.members.includes(u.id)));
    }, [card]);

    return (
        <div{...rest}>
            <Box mt={1} mb={4}>
                <Stack sx={{width: '100%'}}>
                    <InlineEditInput
                        variant="h4"
                        placeholder={t('attributes.name')}
                        fullWidth
                        withHint={false}
                        size='small'
                        name="name"
                        {...formik.getFieldProps('name')}
                        onChange={(value) => formik.setFieldValue('name', value)}
                        error={formik.touched.name && Boolean(formik.errors.name)}
                    />
                    {(formik.touched.name && formik.errors.name) && (
                        <Typography variant="caption"
                                    color={(formik.touched.name && Boolean(formik.errors.name)) ? 'error' : 'text.secondary'}>
                            {formik.touched.name && formik.errors.name}
                        </Typography>
                    )}
                </Stack>
            </Box>
            <Box mt={1}>
                <Box mt={2}>
                    {/*<Card variant="outlined">*/}
                        {(initialTicketBody !== -1) && (
                            <TextEditor
                                // autoFocus={true}
                                sx={{minHeight: 150, maxHeight: 400, overflow: 'auto'}}
                                onUpdate={({editor}) => {
                                    // debouncedChangeHandler({description: editor.getHTML()});
                                    formik.setFieldValue('description', editor.getHTML());
                                }}
                                content={initialTicketBody}
                            />
                        )}
                    {/*</Card>*/}
                </Box>
            </Box>
            {(members.length > 0 || card.labels.length > 0 || card.due) && (
                <Box
                    mt={2}
                    display="flex"
                    alignItems="center"
                    sx={{
                        overflowX: 'auto',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {members.length < 4 ? (
                        <>
                            {members.map((c, i) => {
                                return (
                                    <Chip
                                        key={'user_' + i}
                                        onDelete={() => handleRemoveMember(c)}
                                        color="primary"
                                        style={{margin: 3}}
                                        label={c.first_name}
                                    />
                                )
                            })}
                        </>
                    ) : (
                        <>
                            <Chip
                                key={'user_assignments'}
                                color="primary"
                                style={{margin: 3}}
                                label={members.length + ' zugewiesen'}
                            />
                        </>
                    )}
                    {card.due && (
                        <Chip
                            onDelete={handleRemoveDue}
                            color="primary"
                            style={card.is_overdue ? {
                                margin: 3,
                                backgroundColor: '#DC004E',
                                color: 'white'
                            } : {margin: 3}}
                            label={card.is_overdue ? 'Überfällig seit ' + moment(card.due).format('DD.MM.') : 'Fällig am ' + moment(card.due).format('DD. MMMM')}
                        />
                    )}
                    {card.labels.map((label, i) => (
                        <Chip key={"details_lab_" + i} label={label['name']} onDelete={() => handleRemoveLabel(label)}
                              color="primary" style={{backgroundColor: label['color'], margin: 3}}/>
                    ))}
                </Box>
            )}
        </div>
    );
}

Details.propTypes = {
    card: PropTypes.object.isRequired,
    formik: PropTypes.object.isRequired
};

export default Details;
