// src/omnia/components/viewers/ImageViewer.jsx
import React, { useState } from 'react';
import { Lightbox } from 'react-modal-image';

function ImageViewer({ file, ...rest }) {
    const [openedFile, setOpenedFile] = useState(null);

    return (
        <>
            <img
                alt={file?.name}
                src={file?.view_medium}
                style={{ width: '100%', height: 'auto' }}
                onClick={() => setOpenedFile(file?.view)}
                {...rest}
            />
            {openedFile && (
                <Lightbox
                    large={openedFile}
                    medium={openedFile} // Or use file.view_medium if you prefer
                    onClose={() => setOpenedFile(null)}
                />
            )}
        </>
    );
}

export default ImageViewer;
