import React, {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";
import useApi from "../../../../hooks/use-api";
import {useNotifications} from "../../../../hooks/use-notifications";
import { produce } from "immer";
import debounce from "lodash.debounce";
import {
    Box, Button,
    CircularProgress, Grid,
    IconButton,
    Stack, SvgIcon,
    TextField, Typography,
} from "@mui/material";
import ColorPicker from "src/omnia/components/elements/color-picker";
import {Menu} from "react-feather";
import Trash from "@untitled-ui/icons-react/build/esm/Trash01"
import {useTheme} from "@mui/system";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import OnIcon from "../../../elements/icon";
import SaveButton from "../../../elements/save-button";
import OnlineIndicator from "../../core/data-analytics/dashboards/online-indicator";
import {LoadingButton} from "@mui/lab";

function LabelDesigner({spaceId, onDone, ...rest}) {
    const { t } = useTranslation();
    const theme = useTheme();
    const [upToDate, setUpToDate] = useState(true);
    const [labels, setLabels] = useState([]);
    const [loading, setLoading] = useState(true);
    const { get, put, del, post } = useApi();
    const { notify, notifySuccess } = useNotifications();
    const labelRef = useRef();

    labelRef.current = labels;

    const handleSave = (values) => {
        put('pm/project-labels', values).then(result => {
            setUpToDate(true);
            setLabels(prev => produce(prev, draft => {
                let index = draft.findIndex(label => label.id === result.id);
                if(index !== -1){
                    draft[index] = result;
                } else {
                    draft.push(result);
                }
            }));
        });
    }

    const handleRemove = (id) => {
        if(window.confirm(t("notify.are_you_sure"))){
            del('pm/project-labels', id).then(() => {
                setLabels(prev => {
                    const newLabels = prev.filter(label => label.id !== id);
                    updatePositions(newLabels);
                    return newLabels;
                });
                notify(t("notify.deleted"), "success");
            })
        }
    }

    const updatePositions = (labels) => {
        setUpToDate(false);
        post('pm/project-labels/set_positions', labels.map((label, position) => {
            return {...{id: label.id}, ...{position: position}}
        })).then(() => {
            setUpToDate(true);
        });
    }

    const onDragEnd = result => {
        const { destination, source } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId &&
            destination.index === source.index) {
            return;
        }

        const newLabels = Array.from(labels);
        const [removed] = newLabels.splice(source.index, 1);
        newLabels.splice(destination.index, 0, removed);
        updatePositions(newLabels);
        setLabels(newLabels);
    }

    const debouncedChangeHandler = useCallback(debounce(handleSave, 1000), []);

    const handleLabelChange = (id, key, value) => {
        let label = labelRef.current.find(label => label.id === id);
        if(label === '') return;
        let values = {...label, ...{[key]: value}};
        setUpToDate(false);
        setLabels(prev => produce(prev, draft => {
            let index = draft.findIndex(label => label.id === id);
            if(index !== -1){
                draft[index] = values;
            }
        }));
        debouncedChangeHandler(values);
    };

    const handleAdd = () => {
        put(`pm/projects/${spaceId}/labels`, {
            name: 'New Label',
            color: theme.palette.primary.main,
            space: spaceId,
            position: labelRef.current.length
        }).then(result => {
            setLabels([...labels, result]);
        })
    }

    useEffect(() => {
        get(`pm/projects/${spaceId}/labels`).then(data => {
            setLabels(data.sort((a, b) => a.position - b.position));
        }).finally(() => {
            setLoading(false);
        });
    }, [spaceId]);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Box
                {...rest}
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <Box sx={{ flex: 1, overflowY: 'auto', py: 2 }}>
                    {loading ? (
                        <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent:'center', alignItems:'center' }}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <>
                            {labels.length === 0 ? (
                                <Stack
                                    spacing={2}
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{ width: '100%', height: '100%', textAlign: 'center' }}
                                >
                                    <OnIcon
                                        iconName="Bookmark"
                                        size="large"
                                        sx={{ color: theme.palette.text.secondary }}
                                    />
                                    <Typography variant="h5" color="textSecondary">
                                        {t("projects.settings.no_tags_yet")}
                                    </Typography>
                                </Stack>
                            ) : (
                                <Droppable
                                    direction="vertical"
                                    droppableId="labels"
                                    isDropDisabled={false}
                                    isCombineEnabled={true}
                                    ignoreContainerClipping={false}
                                >
                                    {provided => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.droppableProps}
                                        >
                                            {labels.map((label, index) => (
                                                <Draggable key={label.id} draggableId={label.id.toString()} index={index}>
                                                    {(provided) => (
                                                        <Box
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            sx={{ mt: 2 }}
                                                        >
                                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                                <Box {...provided.dragHandleProps}>
                                                                    <SvgIcon fontSize="medium" sx={{ mt: 1.5 }}>
                                                                        <Menu color={theme.palette.text.secondary} />
                                                                    </SvgIcon>
                                                                </Box>
                                                                <TextField
                                                                    value={label.name}
                                                                    fullWidth
                                                                    label={t("Name")}
                                                                    onChange={(event) => handleLabelChange(label.id, 'name', event.target.value)}
                                                                />
                                                                <ColorPicker
                                                                    style={{minWidth: '20%'}}
                                                                    color={label.color}
                                                                    onChange={(color) => handleLabelChange(label.id, 'color', color)}
                                                                />
                                                                <IconButton size="large" onClick={() => handleRemove(label.id)}>
                                                                    <SvgIcon fontSize="small">
                                                                        <OnIcon iconName="Trash01" size="small" />
                                                                    </SvgIcon>
                                                                </IconButton>
                                                            </Stack>
                                                        </Box>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            )}
                        </>
                    )}
                </Box>
                <Box>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={onDone ? 6 : 12}>
                            <Button fullWidth variant="outlined" onClick={handleAdd}>
                                {t("common.add")}
                            </Button>
                        </Grid>
                        {onDone && (
                            <Grid item xs={12} sm={6}>
                                <LoadingButton
                                    variant="contained"
                                    fullWidth={true}
                                    startIcon={(
                                        <OnlineIndicator
                                            status={!upToDate ? 'offline' : 'online'}
                                        />
                                    )}
                                    onClick={onDone}
                                >
                                    {!upToDate ? t("common.saving") : t('common.save')}
                                </LoadingButton>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
        </DragDropContext>
    )
}

export default LabelDesigner;
