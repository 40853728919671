import React, {useCallback, useMemo} from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const useIntelligenceFormik = (initialData = {}, onSubmit = null, enableReinitialize = false) => {
    const { t } = useTranslation();

    const stableInitialData = useMemo(() => initialData, [JSON.stringify(initialData)]);

    const allowReInit = stableInitialData !== null;

    // Constants

    const TASK_TYPES = [
        {name: 'audio-classification', id: 'audio-classification', label: t('intelligence.inferences.task_types_labels.audio_classification')},
        {name: 'automatic-speech-recognition', id: 'automatic-speech-recognition', label: t('intelligence.inferences.task_types_labels.automatic_speech_recognition')},
        {name: 'depth-estimation', id: 'depth-estimation', label: t('intelligence.inferences.task_types_labels.depth_estimation')},
        {name: 'document-question-answering', id: 'document-question-answering', label: t('intelligence.inferences.task_types_labels.document_question_answering')},
        {name: 'feature-extraction', id: 'feature-extraction', label: t('intelligence.inferences.task_types_labels.feature_extraction')},
        {name: 'fill-mask', id: 'fill-mask', label: t('intelligence.inferences.task_types_labels.fill_mask')},
        {name: 'image-classification', id: 'image-classification', label: t('intelligence.inferences.task_types_labels.image_classification')},
        {name: 'image-feature-extraction', id: 'image-feature-extraction', label: t('intelligence.inferences.task_types_labels.image_feature_extraction')},
        {name: 'image-segmentation', id: 'image-segmentation', label: t('intelligence.inferences.task_types_labels.image_segmentation')},
        {name: 'image-text-to-text', id: 'image-text-to-text', label: t('intelligence.inferences.task_types_labels.image_text_to_text')},
        {name: 'image-to-image', id: 'image-to-image', label: t('intelligence.inferences.task_types_labels.image_to_image')},
        {name: 'image-to-text', id: 'image-to-text', label: t('intelligence.inferences.task_types_labels.image_to_text')},
        {name: 'mask-generation', id: 'mask-generation', label: t('intelligence.inferences.task_types_labels.mask_generation')},
        {name: 'object-detection', id: 'object-detection', label: t('intelligence.inferences.task_types_labels.object_detection')},
        {name: 'question-answering', id: 'question-answering', label: t('intelligence.inferences.task_types_labels.question_answering')},
        {name: 'summarization', id: 'summarization', label: t('intelligence.inferences.task_types_labels.summarization')},
        {name: 'table-question-answering', id: 'table-question-answering', label: t('intelligence.inferences.task_types_labels.table_question_answering')},
        {name: 'text2text-generation', id: 'text2text-generation', label: t('intelligence.inferences.task_types_labels.text2text_generation')},
        {name: 'text-classification', id: 'text-classification', label: t('intelligence.inferences.task_types_labels.text_classification')},
        {name: 'text-generation', id: 'text-generation', label: t('intelligence.inferences.task_types_labels.text_generation')},
        {name: 'text-to-audio', id: 'text-to-audio', label: t('intelligence.inferences.task_types_labels.text_to_audio')},
        {name: 'token-classification', id: 'token-classification', label: t('intelligence.inferences.task_types_labels.token_classification')},
        {name: 'translation', id: 'translation', label: t('intelligence.inferences.task_types_labels.translation')},
        {name: 'translation_xx_to_yy', id: 'translation_xx_to_yy', label: t('intelligence.inferences.task_types_labels.translation_xx_to_yy')},
        {name: 'video-classification', id: 'video-classification', label: t('intelligence.inferences.task_types_labels.video_classification')},
        {name: 'visual-question-answering', id: 'visual-question-answering', label: t('intelligence.inferences.task_types_labels.visual_question_answering')},
        {name: 'zero-shot-classification', id: 'zero-shot-classification', label: t('intelligence.inferences.task_types_labels.zero_shot_classification')},
        {name: 'zero-shot-image-classification', id: 'zero-shot-image-classification', label: t('intelligence.inferences.task_types_labels.zero_shot_image_classification')},
        {name: 'zero-shot-audio-classification', id: 'zero-shot-audio-classification', label: t('intelligence.inferences.task_types_labels.zero_shot_audio_classification')},
        {name: 'image-generation', id: 'image-generation', label: t('intelligence.inferences.task_types_labels.image_generation')}
    ];


    const MODALITIES = [
        {name: 'audio', id: 'audio', label: t('intelligence.modalities.audio')},
        {name: 'image', id: 'image', label: t('intelligence.modalities.image')},
        {name: 'text', id: 'text', label: t('intelligence.modalities.text')},
        {name: 'video', id: 'video', label: t('intelligence.modalities.video')},
        {name: 'vector', id: 'vector', label: t('intelligence.modalities.vector')},
    ];

    const THEMES = [
        { value: 'creative', label: t('intelligence.themes.creative') },
        { value: 'dynamic', label: t('intelligence.themes.dynamic') },
        { value: 'environment', label: t('intelligence.themes.environment') },
        { value: 'general', label: t('intelligence.themes.general') },
        { value: 'photography', label: t('intelligence.themes.photography') },
        { value: 'sketch', label: t('intelligence.themes.sketch') },
        { value: 'none', label: t('intelligence.themes.none') },
    ];

    const RATIOS = [
        { value: 'square', label: t('intelligence.ratios.square') },
        { value: 'landscape', label: t('intelligence.ratios.landscape') },
        { value: 'portrait', label: t('intelligence.ratios.portrait') },
        { value: 'widescreen', label: t('intelligence.ratios.widescreen') },
    ];

    const IMAGE_MODELS = [
        { value: 'groon-sd-xl-1', label: 'GROON SDXL 1.0' },
        { value: 'groon-sd-xl-turbo', label: 'GROON SDXL Turbo' },
        { value: 'groon-sd-3-medium', label: 'GROON SD3 Medium' },
        { value: 'flux-1-fast', label: 'FLUX 1.0 Fast' },
    ];

    const WORKLOAD_KINDS = [
        { value: 'training', label: t('intelligence.workload_kind.training') },
        { value: 'inference', label: t('intelligence.workload_kind.inference') },
    ];

    const WORKLOAD_STATES = [
        { value: 'running', label: t('common.running') },
        { value: 'completed', label: t('common.completed') },
        { value: 'failed', label: t('common.failed') },
    ];

    const MODEL_USAGES = [
        { value: 'system', label: t('intelligence.model_usage.system') },
        { value: 'user', label: t('intelligence.model_usage.user') },
    ];

    const ORIGIN_KINDS = [
        { value: 'outside', label: t('intelligence.origin_kinds.outside') },
        { value: 'inside-pretrained', label: t('intelligence.origin_kinds.inside_pretrained') },
        { value: 'inside-custom', label: t('ai.origin_kinds.inside_custom') },
        { value: 'inside-fine-tuned', label: t('ai.origin_kinds.inside_fine_tuned') },
    ];

    const LEARNING_TYPES = [
        { value: 'supervised', label: t('ai.learning_types.supervised') },
        { value: 'unsupervised', label: t('ai.learning_types.unsupervised') },
        { value: 'semi-supervised', label: t('ai.learning_types.semi_supervised') },
    ];

    const TRAINING_STATES = [
        { value: 'pending', label: t('common.pending') },
        { value: 'running', label: t('common.running') },
        { value: 'completed', label: t('common.completed') },
        { value: 'failed', label: t('common.failed') },
    ];

    const STATE_COLORS = {
        online_outside: 'success',
        online_on_gpu: 'success',
        not_loaded: 'default',
        not_enough_ram: 'error',
        connector_offline: 'error',
        integration_error: 'error',
        connector_recent_errors: 'warning',
        configuration_error: 'error',
        groon_ai_offline: 'error',
    }

    // Formiks

    const generatedImageFormik = useCallback(useFormik({
        initialValues: initialData ? initialData : {
            prompt: '',
            theme: 'general',
            ratio: 'landscape',
            refine: false,
            upscale: true,
            seed: null,
            model: 'groon-sd-xl-1'
        },
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            prompt: Yup.string().required(t('form.is_required', { field: { name: t('ai.generate_images.prompt') } })),
            theme: Yup.string().oneOf(THEMES.map(theme => theme.value)).required(t('form.is_required', { field: { name: t('ai.theme') } })),
            ratio: Yup.string().oneOf(RATIOS.map(ratio => ratio.value)).required(t('form.is_required', { field: { name: t('ai.ratio') } })),
            model: Yup.string().oneOf(IMAGE_MODELS.map(model => model.value)).required(t('form.is_required', { field: { name: t('attributes.model') } })),
            seed: Yup.number().nullable(),
            refine: Yup.boolean().required(t('form.is_required', { field: { name: t('attributes.refine') } })),
            upscale: Yup.boolean().required(t('form.is_required', { field: { name: t('attributes.upscale') } })),
        }),
        onSubmit: onSubmit
    }), [initialData, onSubmit]);

    const aiModelFormik = useCallback(useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            subline: '',
            description: '',
            speed: 2,
            quality: 2,
            input_modalities: [],
            output_modalities: [],
            usage: 'user',
            origin: 'inside-custom',
            training_procedure: null,
            inference_procedure: null,
            architecture: null,
            training_config_options: [],
            inference_config_options: [],
            config: {},
            reference_id: '',
            input_text: '',
            input_vector: {},
            io_files: [],
            playground_input_text: '',
            playground_input_vector: {},
            playground_io_files: [],
            task_types: [],
        },
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('form.is_required', { field: { name: t('attributes.name') } })).max(200, t('form.max_characters', {value: 200})),
            subline: Yup.string().max(800, t('form.max_characters', {value: 800})),
            description: Yup.string(),
            speed: Yup.number().nullable(),
            quality: Yup.number().nullable(),
            input_modalities: Yup.array().of(Yup.string()).required(t('form.is_required', { field: { name: t('attributes.input_modalities') } })),
            output_modalities: Yup.array().of(Yup.string()).required(t('form.is_required', { field: { name: t('attributes.output_modalities') } })),
            usage: Yup.string().oneOf(MODEL_USAGES.map(usage => usage.value)).required(t('form.is_required', { field: { name: t('attributes.usage') } })),
            origin: Yup.string().oneOf(ORIGIN_KINDS.map(origin => origin.value)).required(t('form.is_required', { field: { name: t('attributes.origin') } })),
            training_procedure: Yup.string().nullable(),
            inference_procedure: Yup.string().nullable(),
            architecture: Yup.object().nullable(),
            training_config_options: Yup.array().nullable(),
            inference_config_options: Yup.array().nullable(),
            config: Yup.object().nullable(),
            reference_id: Yup.string().nullable(),
            input_text: Yup.string().nullable(),
            input_vector: Yup.object().nullable(),
            io_files: Yup.array(),
            playground_input_text: Yup.string().nullable(),
            playground_input_vector: Yup.object().nullable(),
            palyground_io_files: Yup.array(),
            task_types: Yup.array(),
        }),
        onSubmit: onSubmit
    }), [initialData, onSubmit]);

    const datasetFormik = useCallback(useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            description: '',
            learning_type: 'supervised',
            input_dimensionality: null,
            output_dimensionality: null
        },
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('form.is_required', { field: t('attributes.name') })),
            description: Yup.string().nullable(),
            input_dimensionality: Yup.number().nullable(),
            output_dimensionality: Yup.number().nullable(),
            learning_type: Yup.string().oneOf(LEARNING_TYPES.map(type => type.value)).required(t('form.is_required', { field: t('attributes.learning_type') })),
        }),
        onSubmit: onSubmit
    }), [initialData, onSubmit]);

    const assistantFormik = useCallback(useFormik({
        initialValues: initialData ? initialData : {
            name: '',
            description: '',
            instructions: '',
            icon: null,

            llm: null,
            img_model: null,

            files: [],
        },
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t('form.is_required', { field: t('attributes.name') })).max(255, t('form.max_characters', {value: 255})),
            description: Yup.string().min(50, t('form.min_characters', {value: 50})).max(150, t('form.max_characters', {value: 150})),
            instructions: Yup.string().max(2000, t('form.max_characters', {value: 8000})),
            icon: Yup.string().nullable(),

            llm: Yup.number().nullable(),
            img_model: Yup.number().nullable(),

            files: Yup.array(),
        }),
        onSubmit: onSubmit
    }), [initialData, onSubmit]);

    const trainingFormik = useCallback(useFormik({
        initialValues: initialData ? initialData : {
            state: 'pending',
            error_message: '',
            config: {},
            ai_model: '',
            dataset: ''
        },
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            state: Yup.string().oneOf(TRAINING_STATES.map(state => state.value)),
            error_message: Yup.string().nullable(),
            config: Yup.object().nullable(),
            ai_model: Yup.number().nullable().required(t('form.is_required', { field: t('attributes.ai_model') })),
            dataset: Yup.number().nullable().required(t('form.is_required', { field: t('attributes.dataset') }))
        }),
        onSubmit: onSubmit
    }), [initialData, onSubmit]);

    const inferenceJobFormik = useCallback(useFormik({
        initialValues: initialData ? initialData : {
            input_text: '',
            input_vector: {},
            io_files: [],
            config: {},
            ai_model: null
        },
        enableReinitialize: allowReInit,
        validationSchema: Yup.object().shape({
            input_text: Yup.string().nullable(),
            input_vector: Yup.object().nullable(),
            io_files: Yup.array(),
            config: Yup.object().nullable(),
            ai_model: Yup.number().nullable().required(t('form.is_required', { field: t('attributes.ai_model') })),
        }),
        onSubmit: onSubmit
    }), [initialData, onSubmit]);

    return {
        // Formiks
        generatedImageFormik,
        aiModelFormik,
        datasetFormik,
        trainingFormik,
        inferenceJobFormik,
        assistantFormik,

        // Constants
        THEMES,
        RATIOS,
        IMAGE_MODELS,
        WORKLOAD_KINDS,
        WORKLOAD_STATES,
        MODEL_USAGES,
        ORIGIN_KINDS,
        LEARNING_TYPES,
        TRAINING_STATES,
        STATE_COLORS,
        TASK_TYPES,
        MODALITIES
    };
};

export default useIntelligenceFormik;
