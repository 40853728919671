import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/system";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import {useIsMobile} from "../../../../hooks/use-is-mobile";
import {useTranslation} from "react-i18next";
import chroma from "chroma-js";
import useChartColors from "../../../../hooks/use-chart-colors";
import moment from "moment/moment";
import formatNumber from "../../../../utils/format-number";
import {Box, Card, Fade, Grid} from "@mui/material";
import {APP_SETTING} from "../../../../../setup";
import {Chart} from "../../../elements/chart";
import PropTypes from "prop-types";

const ShortLinkStats = ({selectedIds, withTitle = true, noCard = false, direction = "horizontal", height = 330}) => {

    const theme = useTheme();
    const { get } = useOmniaApi();
    const { isMobile } = useIsMobile();
    const { t } = useTranslation();
    const [ seriesData, setData ] = useState([]);
    const [ distSeriesData, setDistData ] = useState({});
    const scale = chroma.scale([theme.palette.primary.main, theme.palette.accent.main]);

    const colors = useChartColors(2);

    const isHorizontal = direction === "horizontal";

    const options = {
        chart: {
            toolbar: {
                show: false
            },
            sparkline: {
                enabled: isMobile
            },
            background: 'transparent',
            animations: { enabled: false },
        },
        title: withTitle ? {
            text: t('core.link_selection') + ' (' + (selectedIds.length === 0 ? t('common.all') : selectedIds.length) + ')',
            align: 'center'
        } : {enabled: false},
        dataLabels: {
            enabled: false,
        },
        grid: {
            borderColor: theme.palette.divider,
            strokeDashArray: 2,
            xaxis: {
                lines: {
                    show: false
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            }
        },
        xaxis: {
            type: 'datetime',
            tickAmount: 3,
            datetimeUTC: false, // use local time
            labels: {
                show: true,
                formatter: function (val) {
                    let format = 'DD.MM.YYYY';
                    return moment(new Date(val)).format(format);
                }
            },
        },
        yaxis: {
            tickAmount: 3,
            labels: {
                show: true,
                formatter: function (val) {
                    if (typeof (val) !== "undefined") {
                        return formatNumber({
                            number: val,
                            decimals: 2,
                            suffix: '',
                            abbreviate: true
                        });
                    } else {
                        return ""
                    }
                },
            },
        },
        legend: {
            show: false,
            position: 'top',
        },
        markers: {
            strokeColors: theme.palette.background.paper,
            size: 0
        },
        stroke: {
            curve: 'smooth',
            width: 2
        },
        theme: {
            mode: theme.palette.mode
        },
        tooltip: {
            shared: true,
            y: {
                formatter: function (val) {
                    if (typeof (val) !== "undefined") {

                        return formatNumber({
                            number: val,
                            decimals: 0,
                            suffix: '',
                            abbreviate: true
                        });
                    } else {
                        return ""
                    }
                }
            },
            x: {
                formatter: function (val) {
                    let format = 'DD.MM.YYYY';
                    if(typeof(val) !== "undefined"){
                        return moment(new Date(val)).format(format);
                    } else {
                        return ""
                    }
                }
            }
        },
        series: seriesData,
        colors: colors,
    };

    const pieOptions = {
        chart: {
            type: 'donut',
            toolbar: {
                show: false
            },
            sparkline: {
                enabled: isMobile
            },
            background: 'transparent',
            animations: { enabled: true },
        },
        stroke: {
            colors: [theme.palette.background.paper],
            width: 0
        },
        theme: {
            mode: theme.palette.mode
        },
        title: withTitle ? {
            text: t('core.link_device_dist') + ' (' + (selectedIds.length === 0 ? t('common.all') : selectedIds.length) + ')',
            align: 'center'
        } : {enabled: false},
        legend: {
            show: !isHorizontal
        },
        tooltip: {
            y: {
                formatter: (val) => {
                    if (typeof (val) !== "undefined") {
                        return formatNumber({
                            number: val,
                            decimals: 0,
                            suffix: '',
                            abbreviate: true
                        });
                    } else {
                        return ""
                    }
                }
            },
        },
        plotOptions: {
            pie: {
                chartType: 'donut',
                strokeWidth: 2,
                customScale: 1,
                donut: {
                    labels: {
                        show: true,
                        value: {
                            show: true,
                            color: theme.palette.text.primary,
                            formatter: function (w) {
                                return formatNumber({
                                    number: w,
                                    decimals: 0,
                                    suffix: '',
                                    abbreviate: true
                                });
                            }
                        },
                        total: {
                            show: true,
                            label: t('common.total'),
                            color: theme.palette.text.secondary,
                            formatter: function (w) {
                                let sum = 0;
                                for (let i = 0; i < w.globals.series.length; i++) {
                                    sum += w.globals.series[i];
                                }
                                return formatNumber({
                                    number: sum,
                                    decimals: 0,
                                    suffix: '',
                                    abbreviate: true
                                });
                            }
                        }
                    }
                }
            }
        },
        labels: Object.keys(distSeriesData),
        series: Object.values(distSeriesData),
        colors: scale.colors(Object.values(distSeriesData)?.length || 1),
    };

    useEffect(() => {
        get('core/short-links/statistics', {ids: selectedIds}).then(data => {
            setData([{
                name: t('attributes.clicks'),
                data: data['clicks_per_day']
            }]);
        })
        get('core/short-links/device_distribution', {ids: selectedIds}).then(data => {
            setDistData(data);
        })
    }, [selectedIds]);

    const PieChart = (
        <Fade in={options?.series?.length > 0} timeout={APP_SETTING?.transitionDuration || 500}>
            <Box>
                <Chart
                    options={pieOptions}
                    series={pieOptions.series}
                    type='donut'
                    width="100%"
                    height={height}
                />
            </Box>
        </Fade>
    )

    const LineChart = (
        <Fade in={options?.series?.length > 0} timeout={APP_SETTING?.transitionDuration || 500}>
            <Box>
                <Chart
                    options={options}
                    series={options.series}
                    type={'area'}
                    width="100%"
                    height={height}
                />
            </Box>
        </Fade>
    )

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={isHorizontal ? 5 : 12} lg={isHorizontal ? 4 : 12}>
                {noCard ? PieChart : (
                    <Card sx={{p: 1}}>
                        {PieChart}
                    </Card>
                )}
            </Grid>
            <Grid item xs={12} sm={isHorizontal ? 7 : 12} lg={isHorizontal ? 8 : 12}>
                {noCard ? LineChart : (
                    <Card sx={{p: 1}}>
                        {LineChart}
                    </Card>
                )}
            </Grid>
        </Grid>
    )
}

ShortLinkStats.propTypes = {
    selectedIds: PropTypes.array.isRequired,
    withTitle: PropTypes.bool
}

export default ShortLinkStats;