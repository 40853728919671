import React, {useEffect, useState} from "react";
import {useTheme} from "@mui/system";
import {useTranslation} from "react-i18next";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import {Box, Card, CardActions, CardHeader, Chip, Fade, IconButton, Link, Stack, Tooltip} from "@mui/material";
import {RouterLink} from "../../../elements/router-link";
import LoginIcon from "@untitled-ui/icons-react/build/esm/LogIn01";
import {APP_SETTING} from "../../../../../setup";
import {Chart} from "../../../elements/chart";
import PropTypes from "prop-types";
import SharingStatus from "../../../elements/sharing/sharing-status";

const ContextCard = ({context, selected = false}) => {

    const theme = useTheme();
    const { t } = useTranslation();
    const { get } = useOmniaApi();
    const [ series, setSeries ] = useState([]);

    const chartOptions = {
        chart: {
            background: 'transparent',
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            animations: { enabled: false },
        },
        colors: [theme.palette.primary.main],
        dataLabels: {
            enabled: false
        },
        fill: {
            gradient: {
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 100]
            },
            type: 'gradient'
        },
        grid: {
            show: false,
            padding: {
                bottom: 0,
                left: 0,
                right: 0,
                top: 0
            }
        },
        stroke: {
            curve: 'smooth',
            width: 3
        },
        theme: {
            mode: theme.palette.mode
        },
        tooltip: {
            enabled: true
        },
        xaxis: {
            type: 'datetime',
            tickAmount: 3,
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: true
            }
        },
        yaxis: {
            show: false
        }
    };

    const handleLogin = () => {
        window.open(context?.login_domain, '_blank', 'noopener,noreferrer');

    }

    useEffect(() => {
        get('crm/contexts/' + context.id + '/registrations_over_time').then(response => {
            setSeries(response);
        })
    }, [context]);

    return (
        <Card
            key={'context-' + context.id}
            sx={selected ? {
                border: "3px solid",
                borderColor: "primary.main",
            } : {}}
        >
            <CardHeader
                sx={{p: 2}}
                title={(
                    <Link
                        sx={{cursor: 'pointer'}}
                        color="textPrimary"
                        to={'/groon/connections/context/' + context.id}
                        component={RouterLink}
                    >
                        {context.name}
                    </Link>
                )}
                subheader={context.register_domain || t('connections.context.manual_registration')}
                action={context.kind === 'service' && (
                    <Tooltip enterDelay={1000} title={t('connections.context.login_now')} placement="left">
                        <IconButton color="primary" onClick={handleLogin}>
                            <LoginIcon />
                        </IconButton>
                    </Tooltip>
                )}
            />
            <Box sx={{width: '100%', px: 1, position: 'relative', height: 'auto'}}>
                {context.kind === 'service' && (
                    <Chip
                        style={{
                            position: 'absolute',
                            top: 10,
                            left: 10
                        }}
                        size="small"
                        color={context.online_count > 0 ? 'success' : 'default'}
                        label={context.online_count > 0 ? (context.online_count + ' online') : 'Keiner Online'}
                    />
                )}
                <Fade in={series.length > 0} timeout={APP_SETTING?.transitionDuration || 500}>
                    <Box>
                        <Chart
                            height={200}
                            options={chartOptions}
                            series={[{
                                name: t('connections.contexts.registrations'),
                                data: series
                            }]}
                            type="area"
                        />
                    </Box>
                </Fade>
            </Box>
            <CardActions sx={{p: 2}}>
                <Stack direction="row" sx={{width: '100%'}} justifyContent="space-between">
                    <Stack direction="row" spacing={1}>
                        {context.kind === 'service' ? (
                            <Chip label={APP_SETTING?.services?.find(s => s.identifier === context?.service_identifier)?.name || t('connections.context.' + context.kind)} color="primary" />
                        ) : (
                            <Chip label={t('connections.context.' + context.kind)} color="primary" />
                        )}
                        <Chip label={context.registration_count ? (context.registration_count === 1 ? t('connections.contexts.one_registration') : (context.registration_count + ' ' + t('connections.contexts.registrations'))) : t('connections.contexts.no_registrations')} />
                    </Stack>
                    <SharingStatus entity={context} />
                </Stack>
            </CardActions>
        </Card>
    )
}

ContextCard.propTypes = {
    context: PropTypes.object.isRequired,
    selected: PropTypes.bool
}

export default ContextCard;