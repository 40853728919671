import React from "react";
import {createTheme, inputLabelClasses, tableCellClasses} from '@mui/material';
import { Fade } from '@mui/material';
import {APP_SETTING} from "../../../setup";

const muiTheme = createTheme();

export const createComponents = (config) => {

    const buttonRadius = config?.button_radius;
    const cardRadius = config?.card_radius;
    const dialogRadius = config?.dialog_radius;
    const popoverRadius = config?.popover_radius || 10;
    const inputRadius = config?.input_radius || 10;

    return {
        MuiFab: {
            styleOverrides: {
                root: {
                    height: 45,
                    width: 45
                }
            }
        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    fontSize: 14,
                    fontWeight: 600,
                    letterSpacing: 0,
                    height: 35,
                    width: 35
                }
            }
        },
        MuiButton: {
            // defaultProps: {
            //     disableRipple: true,
            // },
            styleOverrides: {
                root: {
                    borderRadius: buttonRadius,
                    textTransform: 'none'
                },
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: cardRadius,
                }
            }
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '20px 20px',
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    padding: '10px 20px'
                }
            }
        },
        MuiCardHeader: {
            defaultProps: {
                titleTypographyProps: {
                    variant: 'h6'
                },
                subheaderTypographyProps: {
                    variant: 'subtitle2'
                }
            },
            styleOverrides: {
                root: {
                    padding: '20px 20px'
                }
            }
        },
        MuiCheckbox: {
            defaultProps: {
                checkedIcon: (
                    <svg
                        fill="none"
                        height="18"
                        viewBox="0 0 24 24"
                        width="18"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            clipRule="evenodd"
                            d="M9 3.5C5.68629 3.5 3 6.18629 3 9.5V15.5C3 18.8137 5.68629 21.5 9 21.5H15C18.3137 21.5 21 18.8137 21 15.5V9.5C21 6.18629 18.3137 3.5 15 3.5H9ZM16.7179 10.1961C17.1024 9.79966 17.0926 9.16657 16.6961 8.7821C16.2997 8.39763 15.6666 8.40737 15.2821 8.80385L10.6667 13.5635L8.7179 11.5539C8.33343 11.1574 7.70034 11.1476 7.30385 11.5321C6.90737 11.9166 6.89763 12.5497 7.2821 12.9461L9.94877 15.6961C10.1371 15.8904 10.3961 16 10.6667 16C10.9372 16 11.1962 15.8904 11.3846 15.6961L16.7179 10.1961Z"
                            fill="currentColor"
                            fillRule="evenodd"
                        />
                    </svg>
                ),
                color: config?.paletteMode === 'light' ? 'primary' : 'secondary',
                icon: (
                    <svg
                        fill="none"
                        height="18"
                        viewBox="0 0 24 24"
                        width="18"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <rect
                            height="16"
                            rx="5"
                            stroke="currentColor"
                            strokeWidth="2"
                            width="16"
                            x="4"
                            y="4.5"
                        />
                    </svg>
                ),
                indeterminateIcon: (
                    <svg
                        fill="none"
                        height="18"
                        viewBox="0 0 24 24"
                        width="18"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            clipRule="evenodd"
                            d="M9 5.5H15C17.2091 5.5 19 7.29086 19 9.5V15.5C19 17.7091 17.2091 19.5 15 19.5H9C6.79086 19.5 5 17.7091 5 15.5V9.5C5 7.29086 6.79086 5.5 9 5.5ZM3 9.5C3 6.18629 5.68629 3.5 9 3.5H15C18.3137 3.5 21 6.18629 21 9.5V15.5C21 18.8137 18.3137 21.5 15 21.5H9C5.68629 21.5 3 18.8137 3 15.5V9.5ZM8 11.5C7.44772 11.5 7 11.9477 7 12.5C7 13.0523 7.44772 13.5 8 13.5H16C16.5523 13.5 17 13.0523 17 12.5C17 11.9477 16.5523 11.5 16 11.5H8Z"
                            fill="currentColor"
                            fillRule="evenodd"
                        />
                    </svg>
                )
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    height: 28
                },
                deleteIcon: {
                    height: 16,
                    width: 16
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    boxSizing: 'border-box'
                },
                html: {
                    MozOsxFontSmoothing: 'grayscale',
                    WebkitFontSmoothing: 'antialiased',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                    width: '100%'
                },
                body: {
                    display: 'flex',
                    flex: '1 1 auto',
                    flexDirection: 'column',
                    minHeight: '100%',
                    width: '100%',
                    margin: 0
                },
                '#root, #__next': {
                    display: 'flex',
                    flex: '1 1 auto',
                    flexDirection: 'column',
                    height: '100%',
                    width: '100%'
                },
                '#nprogress': {
                    pointerEvents: 'none'
                },
                '#nprogress .bar': {
                    height: 3,
                    left: 0,
                    position: 'fixed',
                    top: 0,
                    width: '100%',
                    zIndex: 2000
                }
            }
        },
        MuiIconButton: {
            // defaultProps: {
            //     disableRipple: true,
            // },
            styleOverrides: {
                sizeSmall: {
                    padding: 4
                },
                root: {
                    borderRadius: '10px'  // TODO: this must go to themes
                }
            }
        },
        MuiDialog: {
            defaultProps: {
                TransitionComponent: Fade,
                TransitionProps: {
                    timeout: APP_SETTING?.transitionDuration || 500,
                },
                BackdropProps: {
                    transitionDuration: APP_SETTING?.transitionDuration || 500,
                },
            },
            styleOverrides: {
                root: ({ ownerState }) => ({
                    '& .MuiBackdrop-root': {
                        ...(ownerState.fullScreen
                            ? {
                                backdropFilter: 'none',
                                backgroundColor: 'transparent',
                            }
                            : {
                                backdropFilter: 'blur(6px)',
                                backgroundColor: 'rgba(0, 0, 0, 0.2)',
                            }),
                    },
                }),
                paper: {
                    borderRadius: dialogRadius,
                },
                paperFullScreen: {
                    borderRadius: 0,
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 15,
                }
            }
        },
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: 15
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingBottom: 15,
                }
            }
        },

        // Loaders
        MuiCircularProgress: {
            defaultProps: {
                size: 30,
            },
        },

        // Inputs
        MuiFilledInput: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent',
                    borderRadius: inputRadius,
                    borderStyle: 'solid',
                    borderWidth: 0.5,
                    overflow: 'hidden',
                    transition: muiTheme.transitions.create([
                        'border-color',
                        'box-shadow'
                    ]),
                    '&:before': {
                        display: 'none'
                    },
                    '&:after': {
                        display: 'none'
                    },
                    '&.MuiFilledInput-multiline': {
                        paddingTop: 3,
                    },
                },
                input: {
                    paddingTop: 20,
                    paddingBottom: 5,
                },
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    transition: muiTheme.transitions.create([
                        'border-color',
                        'box-shadow'
                    ])
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '&::placeholder': {
                        opacity: 1
                    },
                },
                multiline: {
                    height: 'auto'
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    top: -3
                },
            }
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled'
            },
        },

        MuiTablePagination: {
            styleOverrides: {
                select: {
                    paddingTop: 2,
                    paddingBottom: 2
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingLeft: 10,
                    paddingTop: 3,
                    paddingBottom: 3,
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 3,
                    overflow: 'hidden'
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: 'hover'
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    // paddingTop: 0,
                    // paddingBottom: 0,
                }
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    // paddingTop: 3,
                    // paddingBottom: 3
                }
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    // paddingTop: 3,
                    // paddingBottom: 3
                }
            }
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    marginRight: 10,
                    minWidth: 'unset',
                    // height: 16,
                    // width: 14
                }
            }
        },
        MuiListItemAvatar: {
            styleOverrides: {
                root: {
                    marginRight: 10,
                    minWidth: 10,
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    marginTop: 0,
                    marginBottom: 0
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none'
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderRadius: popoverRadius,
                }
            },
            defaultProps: {
                elevation: 16
            }
        },
        MuiRadio: {
            defaultProps: {
                color: config?.paletteMode === 'light' ? 'primary' : 'secondary'
            }
        },
        MuiSlider: {
            styleOverrides: {
                thumb: {
                    height: 15,
                    width: 15
                },
                rail: {
                    height: 3
                },
                track: {
                    height: 4
                }
            }
        },
        MuiSwitch: {
            defaultProps: {
                color: config?.paletteMode === 'light' ? 'primary' : 'secondary'
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    fontSize: 12,
                    fontWeight: 500,
                    lineHeight: 2.5,
                    minWidth: 'auto',
                    paddingLeft: 0,
                    paddingTop: 10,
                    paddingBottom: 10,
                    paddingRight: 0,
                    textTransform: 'none',
                    '& + &': {
                        marginLeft: 24
                    },
                    '&.Mui-selected': {
                        color: config?.paletteMode === 'light' ? config?.primary_color_main : config?.secondary_color_main,
                    },
                },
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: config?.paletteMode === 'light' ? config?.primary_color_main : config?.secondary_color_main
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: '15px 16px'
                },
                sizeSmall: {
                    padding: 10
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                    [`& .${tableCellClasses.root}`]: {
                        borderBottom: 'none',
                        fontSize: 12,
                        fontWeight: 600,
                        lineHeight: 1,
                        letterSpacing: 0.5,
                        textTransform: 'uppercase'
                    },
                    [`& .${tableCellClasses.paddingCheckbox}`]: {
                        paddingTop: 4,
                        paddingBottom: 4
                    }
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                fontSizeSmall: {
                    fontSize: 18
                },
                fontSizeMedium: {
                    fontSize: 20
                }
            }
        },
        MuiSkeleton: {
            styleOverrides: {
                rounded: {
                    borderRadius: cardRadius,
                },
            },
        },
    };

};
