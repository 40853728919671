export const GET_BOARD = '@kanban/get-board';
export const ADD_UPDATE_CARDS = '@kanban/add-update-cards';
export const UPDATE_LIST = '@kanban/update-list';
export const CLEAR_LIST = '@kanban/clear-list';
export const ARCHIVE_LIST = '@kanban/archive-list';
export const DELETE_LIST = '@kanban/delete-list';
export const UPDATE_CARD = '@kanban/update-card';
export const CHANGE_MEMBERS = '@kanban/change-members';
export const CHANGE_LABELS = '@kanban/change-labels';
export const CHANGE_ATTACHMENTS = '@kanban/change-attachments';
export const MOVE_CARD = '@kanban/move-card';
export const DELETE_CARD = '@kanban/delete-card';
export const ADD_COMMENT = '@kanban/add-comment';
export const ADD_CHECKLIST = '@kanban/add-checklist';
export const UPDATE_CHECKLIST = '@kanban/update-checklist';
export const DELETE_CHECKLIST = '@kanban/delete-checklist';
export const UPDATE_CHECK_ITEM = '@kanban/update-check-item';
export const DELETE_CHECK_ITEM = '@kanban/delete-check-item';
export const SHOW_ARCHIVED = '@kanban/show-archived';
export const HIDE_ARCHIVED = '@kanban/hide-archived';
export const UPDATE_QUERY = '@kanban/update-query';
export const UPDATE_LABELS = '@kanban/update-labels';
export const SET_SPACE_ID = '@kanban/update-space-id';
export const SET_LOADING = '@kanban/update-board-loading';
export const SET_BOARD_ID = '@kanban/set-board-id';


export function setBoard(board) {
    return (dispatch) => dispatch({
        type: GET_BOARD,
        payload: board
    });
}

export function setBoardLoading(loading){
    return (dispatch) => dispatch({
        type: SET_LOADING,
        payload: loading
    });
}

export function setBoardId(boardId){
    return (dispatch) => dispatch({
        type: SET_BOARD_ID,
        payload: boardId
    });
}

export function addUpdateCards(cards) {
    return (dispatch) => dispatch({
        type: ADD_UPDATE_CARDS,
        payload: cards
    });
}

export function updateSpaceId(id) {
    return (dispatch) => dispatch({
        type: SET_SPACE_ID,
        payload: id
    })
}

export function updateQuery(query) {
    return (dispatch) => dispatch({
        type: UPDATE_QUERY,
        payload: query
    })
}

export function updateLabels( labels ) {
    return (dispatch) => dispatch({
        type: UPDATE_LABELS,
        payload: labels
    })
}

export function showArchived() {
    return (dispatch) => dispatch({
        type: SHOW_ARCHIVED
    })
}

export function hideArchived() {
    return (dispatch) => dispatch({
        type: HIDE_ARCHIVED
    })
}

export function updateList( boardId, listId, update) {
    return (dispatch => dispatch({
        type: UPDATE_LIST,
        payload: {
            list: update,
            boardId: boardId
        }
    }))
}

export function clearList( boardId, listId) {
    return (dispatch) => dispatch({
        type: CLEAR_LIST,
        payload: {
            listId: listId,
            boardId: boardId
        }
    });
}

export function archiveList( boardId, listId) {
    return (dispatch) => dispatch({
        type: ARCHIVE_LIST,
        payload: {
            listId: listId,
            boardId: boardId
        }
    });
}

export function deleteList( boardId, listId) {
    return (dispatch) => dispatch({
        type: DELETE_LIST,
        payload: {
            listId: listId,
            boardId: boardId
        }
    })
}

export function updateCard( boardId, card) {
    return (dispatch) => dispatch({
        type: UPDATE_CARD,
        payload: {
            card: card,
            boardId: boardId
        }
    });
}

export function addLabel( boardId, card ) {
    return (dispatch) => dispatch({
        type: CHANGE_LABELS,
        payload: {
            card: card,
            boardId: boardId
        }
    });
}

export function removeLabel( boardId, card ) {
    return (dispatch) => dispatch({
        type: CHANGE_LABELS,
        payload: {
            card: card,
            boardId: boardId
        }
    });
}

export function updateAttachments( boardId, card) {
    return (dispatch) => {
        dispatch({
            type: CHANGE_ATTACHMENTS,
            payload: {card: card, boardId: boardId}
        });
        dispatch({
            type: UPDATE_CARD,
            payload: {card: card, boardId: boardId}
        });
    };
}

export function moveCard( boardId, cardId, position, listId) {
    // move card in same moment
    return (dispatch) => dispatch({
        type: MOVE_CARD,
        payload: {
            cardId: parseInt(cardId),
            position: position,
            listId: parseInt(listId),
        }
    })
}

export function deleteCard( boardId, cardId) {
    return (dispatch) => dispatch({
        type: DELETE_CARD,
        payload: {
            cardId,
            boardId
        }
    })
}

export function addChecklist( boardId, cardId, checklist) {
    return (dispatch) => dispatch({
        type: ADD_CHECKLIST,
        payload: {
            cardId: cardId,
            checklist: checklist,
            boardId: boardId
        }
    });
}

export function updateChecklist( boardId, cardId, checklist) {
    return (dispatch) => dispatch({
        type: UPDATE_CHECKLIST,
        payload: {
            cardId: cardId,
            checklistId: checklist.id,
            checklist: checklist,
            boardId: boardId
        }
    });
}

export function deleteChecklist( boardId, cardId, checklistId) {
    return (dispatch) => dispatch({
        type: DELETE_CHECKLIST,
        payload: {
            cardId: cardId,
            checklistId: checklistId,
            boardId: boardId
        }
    });
}

export function updateCheckItem( boardId, cardId, checklistId, checkItem) {
    return (dispatch) => dispatch({
        type: UPDATE_CHECK_ITEM,
        payload: {
            cardId: cardId,
            checklistId: checklistId,
            checkItemId: checkItem.id,
            checkItem: checkItem,
            boardId: boardId
        }
    });
}

export function deleteCheckItem( boardId, cardId, checklistId, checkItemId) {
    return (dispatch) => dispatch({
        type: DELETE_CHECK_ITEM,
        payload: {
            cardId,
            checklistId,
            checkItemId,
            boardId
        }
    });
}

