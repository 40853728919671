import {useState} from "react";

const UseTabs = (defaultTab) => {

    const [tab, setTab] = useState(defaultTab);

    const handleTabChange = (event, newTab) => {
        setTab(newTab);
    };

    return {
        tab,
        handleTabChange
    }
}

export default UseTabs;