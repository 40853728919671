import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Card, CardContent, Chip, CardHeader, Divider, Grid, Fade, TextField} from '@mui/material';
import {Autocomplete} from '@mui/material';
import useApi from "src/omnia/hooks/use-api";
import PropTypes from "prop-types";
import {useNotifications} from "src/omnia/hooks/use-notifications";
import {useTranslation} from "react-i18next";
import {Skeleton} from "@mui/lab";

function ResponsibilitySelector({className, ...rest}) {

    const user = useSelector(state => state.account.user);
    const [options, setOptions] = useState([]);
    const {t} = useTranslation();
    const [responsibilities, setResponsibilities] = useState(null);
    const [statusState, setStatusState] = useState(1);
    const {del, get, put} = useApi();
    const { notifySuccess } = useNotifications();

    const handleDelete = (id) => () => {
        del('connect/responsibilities', id)
            .then(() => {
                // remove id from responsibilities
                setResponsibilities(responsibilities.filter(c => c.id !== id));
                // update options
                updateAllOptions();
                notifySuccess(t);
            });
    }

    const updateAllOptions = () => {
        get('connect/responsibilities/all_options').then(options => {
            setOptions(options);
        });
    }

    const updateResponsibilities = () => {
        get('connect/responsibilities').then(cmps => {
            setResponsibilities(cmps)
        }).catch(() => {
            setResponsibilities([])
        })
    }

    useEffect(() => {
        updateResponsibilities();
        updateAllOptions();
    }, [])

    if (responsibilities === null)
        return (
            <Skeleton variant="rounded" height={200}/>
        );

    return (
        <Card className={className}{...rest}>
            <CardHeader title={t('core.responsibilities')}/>
            <Divider/>
            <CardContent>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        {responsibilities.map(c => {
                            return (
                                <Chip
                                    label={c.name}
                                    color="secondary"
                                    key={'rspnsblty_' + c.id}
                                    onDelete={handleDelete(c.id)}
                                    style={{margin: 2}}
                                />
                            )
                        })}
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            options={options}
                            key={statusState}
                            filterOptions={(opt, val) => {
                                let results = opt.filter(o => !user.competencies.includes(o.name)).filter(o => o.name.toLowerCase().includes(val.inputValue.toLowerCase()));
                                if (results.length === 0)
                                    results = [{
                                        name: val.inputValue
                                    }];
                                return results;
                            }}
                            getOptionLabel={(option) => option.name}
                            fullWidth={true}
                            onKeyDown={(e) => {
                                // wait for enter
                                if (e.keyCode === 13) {
                                    put('connect/responsibilities', {name: e.target.value}).then(newResponsibility => {
                                        setResponsibilities(responsibilities.concat([newResponsibility]));
                                        setStatusState(statusState + 1);
                                        setOptions(options.filter(o => o.name !== newResponsibility.name));
                                    })
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t('core.new_responsibility')}
                                    helperText={t('common.enter_to_add')}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

ResponsibilitySelector.propTypes = {
    className: PropTypes.string
}

export default ResponsibilitySelector;
