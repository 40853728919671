/* eslint-disable react/no-array-index-key */
import React, {lazy, Suspense} from 'react';
import {Navigate, Outlet} from 'react-router';
import { Layout as OmniaLayout } from 'src/omnia/components/layouts/dashboard';
import { Layout as AuthLayout } from 'src/omnia/components/layouts/auth/classic-layout';
import AuthGuard from 'src/omnia/components/general/auth/AuthGuard';
import LoadingScreen from "./components/general/loading-screen";
import GuestGuard from "./components/general/auth/GuestGuard";

// Pages
const Login = lazy(() => import('src/omnia/views/core/pages/login'));
const Setup = lazy(() => import('src/omnia/views/core/pages/setup'));
const Reset = lazy(() => import('src/omnia/views/core/pages/reset'));
const NotFound = lazy(() => import('src/omnia/views/core/pages/404'));

// Core
const CoreTasks = lazy(() => import('src/omnia/views/core/my-tasks'));
const CoreFiles = lazy(() => import("src/omnia/views/core/my-files"))
const CoreCalendar = lazy(() => import('src/omnia/views/core/calendar'));
const CoreChats = lazy(() => import('src/omnia/views/core/chat'));
const CoreTickets = lazy(() => import('src/omnia/views/core/my-tickets'));
const CoreWorkflows = lazy(() => import('src/omnia/views/core/workflows'));
const CoreWorkflow = lazy(() => import('src/omnia/views/core/workflow'));
const CoreWorkflowExecution = lazy(() => import('src/omnia/views/core/workflow-exec'));
const CoreOrganization = lazy(() => import('src/omnia/views/core/organization'));
const CoreProfile = lazy(() => import('src/omnia/views/core/profile'));
const CoreTeam = lazy(() => import('src/omnia/views/core/group'));
const CoreLearningCenter = lazy(() => import('src/omnia/views/core/learning-center'));
const CoreControlCenter = lazy(() => import('src/omnia/views/core/control-center'));
const CoreSettings = lazy(() => import('src/omnia/views/core/settings'));
const CoreJob = lazy(() => import('src/omnia/views/core/job'));
const CoreDevice = lazy(() => import("src/omnia/views/core/device"));
const CoreThemeBuilder = lazy(() => import("src/omnia/views/core/theme-builder"));
const CoreOAuth2Callback = lazy(() => import('src/omnia/views/core/oauth2'));  // External

// Intelligence
const IntelligenceAIStudio = lazy(() => import('src/omnia/views/intelligence/ai_studio'));
const IntelligenceTraining = lazy(() => import('src/omnia/views/intelligence/training'));
const IntelligenceModel = lazy(() => import('src/omnia/views/intelligence/model'));
const IntelligenceAIChat = lazy(() => import('src/omnia/views/intelligence/ai_chat'));
const IntelligenceAssistant = lazy(() => import('src/omnia/views/intelligence/assistant'));
const IntelligenceAssistants = lazy(() => import('src/omnia/views/intelligence/assistants'));

// Identity
const IdentityProjects = lazy(() => import('src/omnia/views/identity/projects'));
const IdentityProject = lazy(() => import('src/omnia/views/identity/project'));
const IdentityImageCreator = lazy(() => import('src/omnia/views/identity/image-creator'));
const IdentityTextCreator = lazy(() => import('src/omnia/views/identity/text-creator'));
const IdentityCreatedMedia = lazy(() => import('src/omnia/views/identity/created-media'));

// Analytics
const AnalyticsDashboards = lazy(() => import('src/omnia/views/analytics/dashboards'));
const AnalyticsDataHub = lazy(() => import('src/omnia/views/analytics/data-hub'));
const AnalyticsDatasets = lazy(() => import('src/omnia/views/analytics/datasets'));
const AnalyticsDataset = lazy(() => import('src/omnia/views/analytics/dataset'));
const AnalyticsShortLinks = lazy(() => import('src/omnia/views/analytics/short-links'));

// Marketing
const MarketingIndex = lazy(() => import('src/omnia/views/marketing'));
const MarketingPublishing = lazy(() => import('src/omnia/views/marketing/publishing'));
const MarketingChannels = lazy(() => import('src/omnia/views/marketing/channels'));
const MarketingChannel = lazy(() => import('src/omnia/views/marketing/channel'));
const MarketingCampaigns = lazy(() => import('src/omnia/views/marketing/campaigns'));
const MarketingCampaign = lazy(() => import('src/omnia/views/marketing/campaign'));
const MarketingAd = lazy(() => import('src/omnia/views/marketing/ad'));
const MarketingMediaLibrary = lazy(() => import('src/omnia/views/marketing/media-library'));
const MarketingCompetitors = lazy(() => import('src/omnia/views/marketing/competitors'));
const MarketingCompetitor = lazy(() => import('src/omnia/views/marketing/competitor'));
const MarketingContentManagement = lazy(() => import('src/omnia/views/marketing/cms'));
const MarketingVisitor = lazy(() => import('src/omnia/views/marketing/cms/visitor'));
const MarketingSession = lazy(() => import('src/omnia/views/marketing/cms/session'));
const MarketingSite = lazy(() => import('src/omnia/views/marketing/cms/site'));
const MarketingArticle = lazy(() => import('src/omnia/views/marketing/cms/article'));
const MarketingDictionaryEntry = lazy(() => import('src/omnia/views/marketing/cms/dictionary-entry'));
const MarketingWebsiteBuilder = lazy(() => import('src/omnia/views/marketing/cms/cms-builder'));  // External

// Resources
const ResourcesIndex = lazy(() => import('src/omnia/views/resources/index'));
const ResourcesFinancialAccount = lazy(() => import('src/omnia/views/resources/financial-accounting/account'));
const ResourcesTransactions = lazy(() => import('src/omnia/views/resources/transactions'));
const ResourcesTransaction = lazy(() => import('src/omnia/views/resources/transaction'));
const ResourcesProducts = lazy(() => import('src/omnia/views/resources/products'));
const ResourcesProduct = lazy(() => import('src/omnia/views/resources/product'));
const ResourcesOffers = lazy(() => import('src/omnia/views/resources/offers'));
const ResourcesOffer = lazy(() => import('src/omnia/views/resources/offer'));
const ResourcesOrders = lazy(() => import('src/omnia/views/resources/orders'));
const ResourcesOrder = lazy(() => import('src/omnia/views/resources/order'));
const ResourcesSubscriptions = lazy(() => import('src/omnia/views/resources/subscriptions'));
const ResourcesSubscription = lazy(() => import('src/omnia/views/resources/subscription'));
const ResourcesInvoices = lazy(() => import('src/omnia/views/resources/invoices'));
const ResourcesInvoice = lazy(() => import('src/omnia/views/resources/invoice'));

// Projects
const ProjectsList = lazy(() => import('src/omnia/views/projects/list'));
const ProjectsCreate = lazy(() => import('src/omnia/views/projects/create'));
const ProjectsProject = lazy(() => import('src/omnia/views/projects/project'));
const ProjectsFinished = lazy(() => import('src/omnia/views/projects/finished'));
const ProjectsBrowse = lazy(() => import('src/omnia/views/projects/browse'));

// Connections
const ConnectionsIndex = lazy(() => import('src/omnia/views/connections'));
const ConnectionsContexts = lazy(() => import('src/omnia/views/connections/contexts'));
const ConnectionsContext = lazy(() => import('src/omnia/views/connections/context'));
const ConnectionsContacts = lazy(() => import('src/omnia/views/connections/contacts'));
const ConnectionsContact = lazy(() => import('src/omnia/views/connections/contact'));
const ConnectionsLeads = lazy(() => import('src/omnia/views/connections/leads'));
const ConnectionsLead = lazy(() => import('src/omnia/views/connections/lead'));
const ConnectionsLeadQuery = lazy(() => import('src/omnia/views/connections/lead-query'));
const ConnectionsLogin = lazy(() => import('src/omnia/views/connections/login'));  // External
const ConnectionsRegister = lazy(() => import('src/omnia/views/connections/register'));  // External
const ConnectionsOptIn = lazy(() => import('src/omnia/views/connections/opt-in'));  // External
const ConnectionsOptOut = lazy(() => import('src/omnia/views/connections/opt-out'));  // External


export const mergeRoutes = (customRoutes=[]) => {

    // Initiate routes object
    let routesConfig = [];

    // Append login, setup and reset-password view
    routesConfig = routesConfig.concat([

        // GROON Authentication
        {
            path: '/login',
            element: <Login />,
        },
        {
            path: '/setup',
            element: <Setup />,
        },
        {
            path: '/reset/:key',
            element: <Reset />,
        },

        // GROON Modules External Interfaces
        {
            path: '/on/register/:contextId',
            element: <ConnectionsRegister />
        },
        {
            path: '/on/login/:contextId',
            element: <ConnectionsLogin />
        },
    ])

    // Initialize Omnia Core Routes
    let omniaRoutes = [
        {
            path: '/groon',
            element: <Navigate to="/groon/ai/chats/compose" />
        },
        {
            // rights placed
            path: '/groon/organization/:postId?',
            element: <CoreOrganization />
        },
        {
            path: '/groon/files',
            element: <CoreFiles />
        },
        {
            path: '/groon/calendar',
            element: <CoreCalendar />
        },
        {
            path: '/groon/tasks',
            element: <CoreTasks />
        },
        {
            path: '/groon/requests',
            element: <CoreTickets />
        },
        {
            path: '/groon/workflows',
            element: <CoreWorkflows />
        },
        {
            path: '/groon/workflows/:workflowId',
            element: <CoreWorkflow />
        },
        {
            path: '/groon/workflow-executions/:executionId',
            element: <CoreWorkflowExecution />
        },
        {
            path: '/groon/team/:groupId',
            element: <CoreTeam />
        },
        {
            path: '/groon/profile',
            element: <CoreProfile />
        },
        {
            path: '/groon/profile/:userId',
            element: <CoreProfile />
        },
        {
            path: '/groon/chat',
            element: <CoreChats />
        },
        {
            path: '/groon/chat/:threadKey',
            element: <CoreChats />
        },
        {
            path: '/groon/leaning-center',
            element: <CoreLearningCenter />
        },
        {
            path: '/groon/device/:deviceId',
            element: <CoreDevice />
        },
        {
            path: '/groon/themes',
            element: <CoreThemeBuilder />
        },
        {
            exact: true,
            path: '/groon/control',
            element: <CoreControlCenter />
        },
        {
            exact: true,
            path: '/groon/control/settings',
            element: <CoreSettings />
        },
        {
            exact: true,
            path: '/groon/control/job/:jobId',
            element: <CoreJob />
        },
        {
        path: '/groon/core/callbacks/oauth2callback',
        element: <CoreOAuth2Callback />
        }
    ];

    // Identity
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/id/created-media',
            element: <IdentityCreatedMedia />
        },
        {
            path: '/groon/id/projects',
            element: <IdentityProjects />
        },
        {
            path: '/groon/id/projects/:projectId',
            element: <IdentityProject />
        },
        {
            path: '/groon/id/image-creator',
            element: <IdentityImageCreator />
        },
        {
            path: '/groon/id/text-creator',
            element: <IdentityTextCreator />
        },
    ])

    // Intelligence
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/ai/studio/:modelId',
            element: <IntelligenceModel />
        },
        {
            path: '/groon/ai/trainings/:trainingId',
            element: <IntelligenceTraining />
        },
        {
            path: '/groon/ai/chats/:chatId',
            element: <IntelligenceAIChat />
        },
        {
            path: '/groon/ai/studio',
            element: <IntelligenceAIStudio />
        },
        {
            path: '/groon/ai/assistants/:assistantId',
            element: <IntelligenceAssistant />
        },
        {
            path: '/groon/ai/assistants',
            element: <IntelligenceAssistants />
        },
    ])

    // Analytics
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/analytics/dashboards',
            element: <AnalyticsDashboards />
        },
        {
            path: '/groon/analytics/short-links',
            element: <AnalyticsShortLinks />
        },
        {
            // rights placed
            path: '/groon/analytics/datahub',
            element: <AnalyticsDataHub />
        },
        {
            path: '/groon/analytics/datasets/:datasetId',
            element: <AnalyticsDataset />
        },
        {
            path: '/groon/analytics/datasets',
            element: <AnalyticsDatasets />
        },
    ]);

    // Marketing
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/marketing',
            element: <MarketingIndex />
        },

        // Website
        {
            path: '/groon/marketing/website/site/:siteId?',
            element: <MarketingSite />
        },
        {
            path: '/groon/marketing/articles/:articleId?',
            element: <MarketingArticle />
        },
        {
            path: '/groon/marketing/entries/:entryId?',
            element: <MarketingDictionaryEntry />
        },
        {
            path: '/groon/marketing/website',
            element: <MarketingContentManagement />
        },
        {
            path: '/groon/marketing/visitors/:visitorId?',
            element: <MarketingVisitor />
        },
        {
            path: '/groon/marketing/sessions/:sessionId?',
            element: <MarketingSession />
        },

        // Media
        {
            path: '/groon/marketing/media-library',
            element: <MarketingMediaLibrary />
        },

        // Competitors
        {
            path: '/groon/marketing/competitors',
            element: <MarketingCompetitors />
        },
        {
            // rights placed
            path: '/groon/marketing/competitor/:competitorId?',
            element: <MarketingCompetitor />
        },

        // Online Marketing
        {
            path: '/groon/marketing/channels',
            element: <MarketingChannels />
        },
        {
            path: '/groon/marketing/channels/:channelId',
            element: <MarketingChannel />
        },
        {
            path: '/groon/marketing/publishing',
            element: <MarketingPublishing />
        },
        {
            path: '/groon/marketing/campaigns',
            element: <MarketingCampaigns />
        },
        {
            path: '/groon/marketing/campaigns/:campaignId',
            element: <MarketingCampaign />
        },
        {
            path: '/groon/marketing/ads/:adId',
            element: <MarketingAd />
        }
    ])

    // Resources
    omniaRoutes = omniaRoutes.concat([

        // Index
        {
            path: '/groon/resources',
            element: <ResourcesIndex />
        },

        // Transaction Templates
        {
            path: '/groon/resources/transactions',
            element: <ResourcesTransactions />
        },
        {
            path: '/groon/resources/transaction/:id',
            element: <ResourcesTransaction />
        },

        // Financial Accounting
        {
            path: '/groon/resources/financial-accounting/account/:accountId',
            element: <ResourcesFinancialAccount />
        },

        // TMS
        {
            path: '/groon/resources/products',
            element: <ResourcesProducts />
        },
        {
            path: '/groon/resources/offer/:offerId?',
            element: <ResourcesOffer />
        },
        {
            path: '/groon/resources/offers',
            element: <ResourcesOffers />
        },
        {
            path: '/groon/resources/invoices',
            element: <ResourcesInvoices />
        },
        {
            path: '/groon/resources/invoice/:invoiceId?',
            element: <ResourcesInvoice />
        },
        {
            path: '/groon/resources/product/:productId?',
            element: <ResourcesProduct />
        },
        {
            path: '/groon/resources/orders',
            element: <ResourcesOrders />
        },
        {
            path: '/groon/resources/order/:orderId?',
            element: <ResourcesOrder />
        },
        {
            path: '/groon/resources/subscriptions',
            element: <ResourcesSubscriptions />
        },
        {
            path: '/groon/resources/subscriptions/:subscriptionId',
            element: <ResourcesSubscription />
        },
    ])

    // Projects
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/projects',
            element: <Navigate to='/groon/projects/list' />
        },
        {
            path: '/groon/projects/list',
            element: <ProjectsList />
        },
        {
            path: '/groon/projects/view/:spaceId/:content/:id?',
            element: <ProjectsProject />
        },
        {
            path: '/groon/projects/finished/:spaceId',
            element: <ProjectsFinished />
        },
        {
            path: '/groon/projects/add',
            element: <ProjectsCreate />
        },
        {
            path: '/groon/projects/browse',
            element: <ProjectsBrowse />
        }
    ])

    // Connections
    omniaRoutes = omniaRoutes.concat([
        {
            path: '/groon/connections',
            element: <Navigate to="/groon/connections/index"/>
        },
        {
            path: '/groon/connections/contexts',
            element: <ConnectionsContexts />
        },
        {
            path: '/groon/connections/context/:contextId',
            element: <ConnectionsContext />
        },
        {
            path: '/groon/connections/index',
            element: <ConnectionsContacts />
        },
        {
            path: '/groon/connections/contact/:contactId/:tab?',
            element: <ConnectionsContact />
        },
        {
            path: '/groon/connections/leads',
            element: <ConnectionsLeads />
        },
        {
            path: '/groon/connections/leads/:leadId',
            element: <ConnectionsLead />
        },
        {
            path: '/groon/connections/lead-query/:queryId',
            element: <ConnectionsLeadQuery />
        },
        {
            // rights placed
            path: '/groon/connections/dashboard',
            element: <ConnectionsIndex />
        },
    ])

    // GROON Routes
    routesConfig.push({
        path: '/groon',
        element: (
            <AuthGuard>
                <OmniaLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </OmniaLayout>
            </AuthGuard>
        ),
        children: omniaRoutes
    });

    // CRM Routes
    routesConfig.push({
        path: '/crm',
        element: (
            <GuestGuard>
                <AuthLayout>
                    <Suspense fallback={<LoadingScreen />}>
                        <Outlet />
                    </Suspense>
                </AuthLayout>
            </GuestGuard>
        ),
        children: [
            {
                path: '/crm/unsubscribe/:hash',
                element: <ConnectionsOptOut />
            },
            {
                path: '/crm/opt_in/:hash',
                element: <ConnectionsOptIn />
            }
        ]
    })

    // Custom Routes
    routesConfig = routesConfig.concat(customRoutes);

    // Fallback is CMS Entry Point FIXME: this will now always hit
    routesConfig.push({
        path: "/:url?/:identifier?",
        element: <MarketingWebsiteBuilder />,
    });

    // If nothing else hits
    routesConfig.push({
        path: '*',
        element: <NotFound />
    })

    // Return the final object
    return routesConfig;

}
