import {useTheme} from "@mui/system";
import chroma from "chroma-js";

export default function useChartColors(wantedCount){

    const theme = useTheme();
    const scale = chroma.scale([theme.palette.primary.main, theme.palette.accent.main]);

    if(wantedCount === 1)
        return [theme.palette.primary.main];
    if(wantedCount === 2)
        return [theme.palette.primary.main, theme.palette.accent.main];
    return scale.colors(wantedCount);
}