import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from '@mui/material';
import UserData from './UserData';
import CompetencesSelector from "./CompetencesSelector";
import ResponsibilitySelector from "./ResponsibilitySelector";
import {useSecurityCheck} from "../../../../../../hooks/use-security-check";

function General({...rest}) {

    const { hasRights } = useSecurityCheck();

    return (
        <>
            <Grid container spacing={4} {...rest}>
                <Grid item sm={12} xs={12}>
                    <UserData/>
                </Grid>
            </Grid>
            {hasRights("social_intranet") && (
                <Grid container spacing={2} sx={{mt: 1}} style={{display: 'flex', alignItems: 'stretch'}}>
                    <Grid item sm={6} xs={12}>
                        <CompetencesSelector style={{height: '100%'}} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                        <ResponsibilitySelector style={{height: '100%'}} />
                    </Grid>
                </Grid>
            )}
        </>
    )
}

General.propTypes = {
    className: PropTypes.string
};

export default General;
