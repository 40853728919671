import React from "react";
import StackAvatars from "./StackAvatars";
import PropTypes from "prop-types";

function ActiveUsersBubbles({users, ...rest}){

    return (
        <div
            style={{
                position: 'fixed',
                zIndex: 1350,
                bottom: 10,
                left: 15,
            }}
            {...rest}
        >
            <StackAvatars
                direction="column"
                avatars={users}
                limit={5}
            />
        </div>
    )
}

ActiveUsersBubbles.propTypes = {
    users: PropTypes.array
}

export default ActiveUsersBubbles;