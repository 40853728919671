import React from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch, TextField,
    Typography
} from "@mui/material";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {hideArchived, showArchived, updateQuery} from "../../../../store/actions/kanban-actions";
import {useTranslation} from "react-i18next";
import {useIsMobile} from "../../../../hooks/use-is-mobile";

function BoardFilters({open, onClose}){

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { isMobile } = useIsMobile();
    const { filter } = useSelector(state => state.kanban);

    const handleChange = (event, value) => {
        if(value){
            dispatch(showArchived());
        } else {
            dispatch(hideArchived());
        }
    }

    const handleQueryChange = (event) => {
        dispatch(updateQuery(event.target.value));
    }

    return (
        <Dialog open={open} fullScreen={isMobile} fullWidth maxWidth="sm" onClose={onClose} >
            <Box>
                <DialogTitle>
                    <Typography variant="h4">
                        {t("Filters")}
                    </Typography>
                </DialogTitle>
            </Box>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            autoFocus
                            label={t("Search")}
                            name="search"
                            onChange={handleQueryChange}
                            value={filter['query'] || ''}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            style={{width: '100%'}}
                            control={
                                <Switch
                                    checked={filter['showArchived']}
                                    onChange={handleChange}
                                    name="Archivierte anzeigen"
                                    color="primary"
                                />
                            }
                            label={t("Show archived tickets")}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t("common.done")}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

BoardFilters.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
}

export default BoardFilters;