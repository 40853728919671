import React, { useMemo } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from "prop-types";

function VideoViewer({ file, ...rest }) {

    const videoUrl = useMemo(() => file.view, [file.view]);

    return (
        <ReactPlayer
            url={videoUrl}
            controls
            playing
            width="100%"
            height="auto"
            playsinline
            volume={1}
            muted
            {...rest}
        />
    );
}

VideoViewer.propTypes = {
    file: PropTypes.object.isRequired,
}

export default VideoViewer;
