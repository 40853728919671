import React, {useEffect, useState} from "react";
import {
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    Button,
    Dialog,
    Stack,
    Box,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";
import SaveButton from "../../../elements/save-button";
import {useSelector} from "react-redux";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import {useNotifications} from "../../../../hooks/use-notifications";
import IntegrationConfig from "../../management/integrations/integration-config";
import {useIsMobile} from "../../../../hooks/use-is-mobile";

const defaultSettings = {name: '', config: {}};

function AddIntegrationDialog({open, onClose, connector}){
    const { notify, notifySuccess } = useNotifications();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const user = useSelector(state => state.account.user);
    const [settings, setSettings] = useState(defaultSettings);
    const { isMobile } = useIsMobile();
    const { post } = useOmniaApi();

    const handleSubmit = () => {
        setLoading(true);
        if(connector?.authenticator === 'Config Authenticator'){
            post('core/connectors/' + connector?.id + '/create_config_integration', settings).then(() => {
                onClose?.();
                notifySuccess(t);
                setSettings(defaultSettings);
            }).finally(() => {
                setLoading(false);
            })
        } else if(connector?.authenticator === 'Token Authenticator'){
            post('core/connectors/' + connector?.id + '/create_token_integration', settings).then(() => {
                onClose?.();
                notifySuccess(t);
                setSettings(defaultSettings);
            }).finally(() => {
                setLoading(false);
            })
        } else {
            notify(t('mgt.connectors.no_authenticator'), "error");
            setLoading(false);
        }
    }

    useEffect(() => {
        if(open && connector){
            const initialName = t('mgt.connectors.service_from_user', {name: connector?.name, user: user?.first_name});
            setSettings((prev) => ({...prev, name: initialName, config: {}}));
        }
    }, [open, connector]);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            fullScreen={isMobile}
            maxWidth="md"
        >
            <DialogTitle>
                <Typography variant="h4">
                    {connector?.name ? t('dialogs.add_integration.title_name', {name: connector?.name}) : t('dialogs.add_integration.title')}
                </Typography>
                <Typography variant="subtitle1" sx={{mt: 2}}>
                    {t('dialogs.add_integration.description')}
                </Typography>
            </DialogTitle>
            <DialogContent
                style={{
                    height: 600,
                    padding: 0,
                    paddingTop: 10,
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box sx={{flex: 1, overflow: 'hidden', px: 2, my: 2}}>
                    <Box sx={{height: '100%', overflow: 'auto', padding: 2}} px={2}>
                        <IntegrationConfig
                            connector={connector}
                            settings={settings}
                            setSettings={setSettings}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={1} sx={{width: '100%'}} justifyContent="space-between">
                    <Button variant="outlined" onClick={onClose}>
                        {t('common.cancel')}
                    </Button>
                    <SaveButton
                        onClick={handleSubmit}
                        label={t('common.continue')}
                        loading={loading}
                    />
                </Stack>
            </DialogActions>
        </Dialog>
    )
}

AddIntegrationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    connector: PropTypes.object,
}

export default AddIntegrationDialog;