import React, {useEffect, useState} from "react";
import {
    Card,
    Divider,
    Fade,
    IconButton,
    Stack,
    SvgIcon,
    Tooltip,
    Typography,
} from "@mui/material";
import CloseIcon from "@untitled-ui/icons-react/build/esm/XClose";
import {useDispatch, useSelector} from "react-redux";
import {closeMiniThread} from "../../../../../store/actions/messages-actions";
import UserOnlineDot from "../../UserOnlineDot";
import {useTheme} from "@mui/system";
import { useNavigate } from "react-router";
import {ChatThread} from "../chat/chat-thread";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {useIsMobile} from "../../../../../hooks/use-is-mobile";
import {APP_SETTING} from "../../../../../../setup";
import {useSettings} from "../../../../../hooks/use-settings";

export default function ChatWindow(){

    const theme = useTheme();
    const dispatch = useDispatch();
    const openMiniThread = useSelector(state => state.messages.openMiniThread);
    const assistantOpen = useSelector(state => state.messages.assistantOpen);
    const user = useSelector(state => state.account.user);
    const { isMobile, navBarMode } = useIsMobile();
    const users = useSelector(state => state.users.users);
    const [members, setMembers] = useState(null);
    const [single, setSingle] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const settings = useSettings();
    const { t } = useTranslation();
    const [lastOpenMiniThread, setLastOpenMiniThread] = useState(null);

    const isChannel = lastOpenMiniThread && !(typeof(lastOpenMiniThread) === "number") ? (lastOpenMiniThread.users.length > 2) : false;

    const closeChat = () => {
        dispatch(closeMiniThread());
    }

    const maximizeChat = () => {
        if((openMiniThread && (typeof(openMiniThread) !== "number"))){
            navigate('/groon/chat/' + openMiniThread.id);
        }
    }

    const handleFadeExited = () => {
        setLastOpenMiniThread(null);
    };

    const threadExists = (openMiniThread && (typeof(openMiniThread) !== "number"));

    const isLargeChatView = location.pathname.includes('/groon/chat/');

    const isOpen = !(!openMiniThread || assistantOpen || isLargeChatView || isMobile);

    useEffect(() => {
        if(openMiniThread && !(typeof(openMiniThread) === "number")) {
            let memberIds = openMiniThread.users.map(u => u.id);
            setMembers(users.filter(u => memberIds.includes(u.id) && u.id !== user.id));
            // Update the last valid openMiniThread
            setLastOpenMiniThread(openMiniThread);
        }
    }, [openMiniThread, users]);

    useEffect(() => {
        let title = isChannel ? (lastOpenMiniThread?.title || '') : (members?.[0]?.name || '');
        setSingle(title ? title : "Deleted User");
    }, [members, lastOpenMiniThread, isChannel]);

    return (
        <Fade in={isOpen} timeout={APP_SETTING.transitionDuration || 500} onExited={handleFadeExited}>
            <Card
                style={{
                    position: 'fixed',
                    backgroundColor: theme.palette.background.default,
                    width: 350,
                    zIndex: 1500,
                    bottom: 20,
                    right: (settings.layout === 'vertical') ? 20 : (navBarMode === 'flying' ? 78 : 20),
                    boxShadow: theme.shadows[21],
                }}
            >
                <Stack
                    sx={{p: 2}}
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Stack
                        direction="column"
                        spacing={0}
                    >
                        <Tooltip enterDelay={1000} title="Vollbild-Chat anzeigen" placement="left">
                            <Typography
                                variant="h6"
                                onClick={maximizeChat}
                                sx={{textTransform: 'none', cursor: 'pointer', textAlign: "left"}}
                                color="textPrimary"
                                underline="hover"
                            >
                                {(lastOpenMiniThread && (typeof(lastOpenMiniThread) !== "number")) ? single : t('common.loading')}
                            </Typography>
                        </Tooltip>
                        {((lastOpenMiniThread && (typeof(lastOpenMiniThread) !== "number")) && !isChannel && (members?.length > 0)) && (
                            <UserOnlineDot userId={members?.[0]?.id} withText />
                        )}
                    </Stack>
                    <Stack direction="row">
                        <IconButton color="inherit" onClick={closeChat} size="large">
                            <SvgIcon fontSize="small">
                                <CloseIcon />
                            </SvgIcon>
                        </IconButton>
                    </Stack>
                </Stack>
                <Divider />
                {(threadExists || lastOpenMiniThread) && (
                    <div style={{
                        flexGrow: 1,
                        display: 'flex',
                        height: 460,
                        flexDirection: 'column',
                        backgroundColor: theme.palette.background.dark
                    }}>
                        <ChatThread
                            threadKey={'' + (openMiniThread ? openMiniThread.id : lastOpenMiniThread?.id)}
                            noToolbar={true}
                            smallVersion={true}
                        />
                    </div>
                )}
            </Card>
        </Fade>
    );

}