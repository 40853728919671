import React from "react";
import {Card, CardContent, CardHeader, Divider, Tab, Tabs} from "@mui/material";
import {useTranslation} from "react-i18next";
import useMgtFormik from "../../../../hooks/formik/use-mgt-formik";
import SaveButton from "../../../elements/save-button";
import useOmniaApi from "../../../../hooks/use-omnia-api";
import {useNotifications} from "../../../../hooks/use-notifications";
import PropTypes from "prop-types";
import useTabs from "../../../../hooks/use-tabs";
import SectionGeneral from "./section-general";
import SectionLogo from "./section-logo";
import SectionColors from "./section-colors";
import SectionTypography from "./section-typography";
import SectionShapes from "./section-shapes";
import SectionMedia from "./section-media";

function ThemeSetup({theme, onChange, ...rest}){

    const { t } = useTranslation();
    const { notifySuccess } = useNotifications();
    const { tab, handleTabChange } = useTabs('general');
    const { put } = useOmniaApi({
        fileFields: ['main_background', 'auth_background', 'error_403', 'error_404', 'error_500', 'favicon', 'icon',
            'main_logo_light', 'mobile_logo_light', 'main_logo_dark', 'mobile_logo_dark', 'system_font_200',
            'system_font_300', 'system_font_400', 'system_font_500', 'system_font_600', 'system_font_700']
    });

    const handleSubmit = (values, helpers) => {
        helpers.setSubmitting(true);
        put('core/themes', values).then((theme) => {
            if(onChange)
                onChange(theme);

            // Check if theme?.is_used is true. If so, reload window
            if(theme?.is_used){
                window.location.reload();
            }

            notifySuccess(t);
        }).finally(() => {
            helpers.setSubmitting(false);
        })
    }

    const { themeFormik } = useMgtFormik(theme, handleSubmit, true);

    return (
        <Card {...rest}>
            <CardHeader
                title={theme?.name}
                // subheader={t('mgt.settings.configure_theme')}
                action={(
                    <SaveButton
                        formik={themeFormik}

                        // onClick={themeFormik.handleSubmit}
                        // loading={themeFormik.isSubmitting}
                    />
                )}
            />
            <Tabs
                sx={{mt: 2}}
                value={tab}
                onChange={handleTabChange}
                variant="fullWidth"
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab label={t('common.general')} value="general" />
                <Tab label={t('mgt.settings.logo')} value="logo" />
                <Tab label={t('mgt.settings.colors')} value="colors" />
                <Tab label={t('mgt.settings.shapes')} value="shapes" />
                <Tab label={t('mgt.settings.typography')} value="typography" />
                <Tab label={t('mgt.settings.media')} value="media" />
            </Tabs>
            <Divider />
            {tab === 'general' && (
                <SectionGeneral formik={themeFormik} />
            )}
            {tab === 'logo' && (
                <SectionLogo formik={themeFormik} />
            )}
            {tab === 'colors' && (
                <SectionColors formik={themeFormik} />
            )}
            {tab === 'shapes' && (
                <SectionShapes formik={themeFormik} />
            )}
            {tab === 'typography' && (
                <SectionTypography formik={themeFormik} />
            )}
            {tab === 'media' && (
                <SectionMedia formik={themeFormik} />
            )}
        </Card>
    )
}

ThemeSetup.propTypes = {
    theme: PropTypes.object.isRequired,
    onChange: PropTypes.func
}

export default ThemeSetup;